import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SupplyNodeSearchService from './services/supply-node-search-service';
import SupplyNodeDetailsService from './services/supply-node-details-service';
import Address from './models/address';
import { camelCaseKeys } from './utils/text-transforms';
import ManualInformationEntry from './components/address-search/manualInformationEntry';
import AddressBar from './components/address-search/addressBar';
import AddressFormDialog from './components/address-search/addressFormDialog';
import IcpFormDialog from './components/address-search/icpFormDialog';
import {
  ANALYTICS_ENTER_ADDRESS,
  ANALYTICS_MANUAL_ADDRESS_ENTRY,
  ANALYTICS_AUTOMATIC_ADDRESS_ENTRY,
  ANALYTICS_VALUE_TRUE,
  ANALYTICS_VALUE_FALSE,
  ANALYTICS_ADDRESS_MANY_FOUND,
  ANALYTICS_VALUE_MAYBE,
} from './utils/constants';

import sendAnalytics, { sendAnalyticsToDataLayer } from './utils/analytics';

const AddressSearch = ({
  addressFinderKey,
  apiBaseUrl,
  addressPlaceholderText,
  joining,
  continueJoiningCallback,
  enableICPSearch,
}) => {
  const supplyNodeSearchService = new SupplyNodeSearchService(apiBaseUrl);
  const supplyNodeDetailsService = new SupplyNodeDetailsService(apiBaseUrl);
  const [loading, setLoading] = useState(false);
  const [supplyNodes, setSupplyNodes] = useState(null);
  const [selectedSupplyNode, setSelectedSupplyNode] = useState(null);
  const [address, setAddress] = useState(null);
  const [icpError, setIcpError] = useState(false);
  const [searchMethod, setSearchMethod] = useState(null);
  const containerRef = React.useRef();
  const icpDialogRef = React.useRef();
  const addressDialogRef = React.useRef();

  const handleEmptyResultContentClicks = (event) => {
    if (event.target.classList.contains('js-enterAddressManually')) {
      addressDialogRef.current.show();
      sendAnalytics('Enter address manually', 'Clicked');
    } else if (event.target.classList.contains('js-enterIcpManually')) {
      icpDialogRef.current.show();
      sendAnalytics('Enter ICP manually', 'Clicked');
    }
  };

  const resetSupplyNodes = () => {
    setSupplyNodes(null);
    setSelectedSupplyNode(null);
    setAddress(null);
    continueJoiningCallback(null, null);
    setIcpError(false);
    setSearchMethod(null);
  };

  // eslint-disable-next-line no-unused-vars
  const addressSelected = (shortAddress, data, manual = null) => {
    resetSupplyNodes();
    setLoading(true);
    const addressData = camelCaseKeys(data);
    const searchedAddress = new Address(addressData);
    setAddress(searchedAddress);
    supplyNodeSearchService
      .fetch(searchedAddress)
      .then((results) => {
        setSupplyNodes(results);
        setLoading(false);

        const label = manual ? 'Manual address search' : 'Address search';
        const method = manual ? ANALYTICS_MANUAL_ADDRESS_ENTRY : ANALYTICS_AUTOMATIC_ADDRESS_ENTRY;
        setSearchMethod(method);

        if (results.length === 0) {
          sendAnalytics(label, 'Not found');
          sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
            method,
            address_found: ANALYTICS_VALUE_FALSE,
          });
        }

        if (results.length === 1) {
          sendAnalytics(label, 'Found');
        }

        if (results.length > 1) {
          sendAnalytics(label, 'Many found');
          sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
            method,
            address_found: ANALYTICS_ADDRESS_MANY_FOUND,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const icpSelected = (icpSupplyNode) => {
    setSelectedSupplyNode(icpSupplyNode);
  };

  const icpNumberSelected = (icpNumber) => {
    resetSupplyNodes();
    setLoading(true);
    supplyNodeDetailsService
      .fetch(icpNumber)
      .then((result) => {
        setSelectedSupplyNode(result);
        setLoading(false);
        if (result) {
          sendAnalytics('ICP search', 'Found');
          sendAnalytics(
            result.isEligible() ? 'Serviceable' : 'Ineligible',
            result.shortEligibilityReason()
          );
          sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
            method: ANALYTICS_MANUAL_ADDRESS_ENTRY,
            address_found: ANALYTICS_VALUE_TRUE,
            icp_number: result.icpNumber,
            eligibility: result.isEligible() ? ANALYTICS_VALUE_TRUE : ANALYTICS_VALUE_FALSE,
            eligibility_reason: result.shortEligibilityReason(),
            customer_type_system: result.locationClassification?.toLowerCase(),
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setIcpError(true);
        sendAnalytics('ICP search', 'Not found');
        sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
          method: ANALYTICS_MANUAL_ADDRESS_ENTRY,
          icp_number: icpNumber,
          address_found: ANALYTICS_VALUE_FALSE,
        });
      });
  };

  const continueJoiningWithoutIcp = () => {
    sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
      method: searchMethod,
      address_found: ANALYTICS_VALUE_TRUE,
      eligibility: ANALYTICS_VALUE_MAYBE,
    });
    continueJoiningCallback(null, address);
  };

  useEffect(() => {
    if (document) {
      document.addEventListener('mousedown', handleEmptyResultContentClicks);
    }

    // This is a callback used when you want code to run before the component is destroyed.
    // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
    return () => {
      if (document) {
        document.removeEventListener('mousedown', handleEmptyResultContentClicks);
      }
    };
  }, []);

  useEffect(() => {
    if (supplyNodes && supplyNodes.length === 1) {
      setSelectedSupplyNode(supplyNodes[0]);
    }
  }, [supplyNodes]);

  useEffect(() => {
    if (selectedSupplyNode && selectedSupplyNode.icpNumber && !selectedSupplyNode.eligibility) {
      setLoading(true);
      supplyNodeDetailsService
        .fetch(selectedSupplyNode.icpNumber)
        .then((result) => {
          setSelectedSupplyNode(result);
          setLoading(false);
          sendAnalytics(
            result.isEligible() ? 'Serviceable' : 'Ineligible',
            result.shortEligibilityReason()
          );
          sendAnalyticsToDataLayer(ANALYTICS_ENTER_ADDRESS, {
            method: searchMethod,
            address_found: ANALYTICS_VALUE_TRUE,
            icp_number: result.icpNumber,
            eligibility: result.isEligible() ? ANALYTICS_VALUE_TRUE : ANALYTICS_VALUE_FALSE,
            eligibilityReason: result.shortEligibilityReason(),
            customer_type_system: result.locationClassification?.toLowerCase(),
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [selectedSupplyNode]);

  useEffect(() => {
    if (
      selectedSupplyNode &&
      selectedSupplyNode.isEligible() &&
      // TODO remove this when we cont to allow business customers
      selectedSupplyNode.locationClassification?.toLowerCase() === 'residential'
    ) {
      continueJoiningCallback(selectedSupplyNode, null);
    }
  }, [selectedSupplyNode]);

  if (typeof window === 'undefined') {
    return <p>Server Render</p>;
  }

  return (
    <section>
      <div className="address_bar_wrapper" ref={containerRef}>
        <AddressBar
          addressFinderKey={addressFinderKey}
          addressPlaceholderText={addressPlaceholderText}
          loading={loading}
          joining={joining}
          clearAddressSearch={resetSupplyNodes}
          addressSelected={addressSelected}
          address={address}
          icpSelected={icpSelected}
          icpError={icpError}
          selectedSupplyNode={selectedSupplyNode}
          supplyNodes={supplyNodes}
          manualAddressLine={address?.shortDescription()}
          continueJoiningWithoutIcp={continueJoiningWithoutIcp}
          enableICPSearch={enableICPSearch}
        />
        <AddressFormDialog onSelect={addressSelected} dialogRef={addressDialogRef} />
        <IcpFormDialog onSelect={icpNumberSelected} dialogRef={icpDialogRef} />
      </div>
      <ManualInformationEntry enableICPSearch={enableICPSearch} />
    </section>
  );
};

AddressSearch.propTypes = {
  addressFinderKey: PropTypes.string.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  joining: PropTypes.bool.isRequired,
  continueJoiningCallback: PropTypes.func.isRequired,
  addressPlaceholderText: PropTypes.string,
  enableICPSearch: PropTypes.bool,
};

AddressSearch.defaultProps = {
  addressPlaceholderText: 'Enter your address to sign up...',
  enableICPSearch: true,
};

export default AddressSearch;
