export const NZD = new Intl.NumberFormat("en-NZ", {
  style: "currency",
  currency: "NZD",
});

// return currency with NZD as unit so we can trim it later
export const NZDNoDollarSign = new Intl.NumberFormat("en-NZ", {
  style: "currency",
  currency: "NZD",
  currencyDisplay: "code",
});

export const NZ_GST = 0.15;
export const ADD_NZ_GST = 1.15;
