import { ReactComponent as BoltIconSvg } from "app/assets/images/icons/bolt-pointy-solid.svg";
import { renderDeltaMessage } from "elements/DeltaMessage/DeltaMessageLegacy";
import React, { useMemo } from "react";
import { calculatePercentageChangeDelta } from "utils/calculatePercentageChangeDelta";
import { UNIT_CODE_TYPES } from "utils/constants";
import { prepareComparisonChartData } from "utils/progressComparisonChart/prepareComparisonChartData";

import { LoadingIcon } from "../../../../components/LoadingIcon";
import { ProgressComparisonChart } from "../../../../components/ProgressComparisonChart";
import {
  formattedAggregateData,
  getDataLength,
  getPeriodUsage,
  periodUsageTotal,
} from "./UsageSection.utils";

type UsageSectionProps = {
  completedBill: boolean;
  isCustomDatePeriod?: boolean;
  isPreviousPeriodAggregatesSuccess?: boolean;
  isThisPeriodAggregatesFetching: boolean;
  isThisPeriodAggregatesSuccess: boolean;
  isYearAgoAggregatesSuccess?: boolean;
  periodName?: string;
  previousPeriodAggregatesData?: Aggregates;
  thisPeriodAggregatesData?: Aggregates;
  unitCode: string;
  yearAgoAggregatesData?: Aggregates;
};

/**
 * @description Used in (BillUsageSection)
 */
export const UsageSection: React.FC<UsageSectionProps> = ({
  completedBill,
  isCustomDatePeriod = false,
  isPreviousPeriodAggregatesSuccess,
  isThisPeriodAggregatesFetching,
  isThisPeriodAggregatesSuccess,
  isYearAgoAggregatesSuccess,
  periodName,
  previousPeriodAggregatesData,
  thisPeriodAggregatesData,
  unitCode,
  yearAgoAggregatesData,
}) => {
  const thisPeriodFormattedAggregates = useMemo(() => {
    return formattedAggregateData(
      isThisPeriodAggregatesSuccess,
      thisPeriodAggregatesData,
    );
  }, [isThisPeriodAggregatesSuccess, thisPeriodAggregatesData]);

  const previousPeriodFormattedAggregates = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return formattedAggregateData(
      isPreviousPeriodAggregatesSuccess,
      previousPeriodAggregatesData,
    );
  }, [
    isPreviousPeriodAggregatesSuccess,
    previousPeriodAggregatesData,
    isCustomDatePeriod,
  ]);

  const yearAgoPeriodFormattedAggregates = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return formattedAggregateData(
      isYearAgoAggregatesSuccess,
      yearAgoAggregatesData,
    );
  }, [isYearAgoAggregatesSuccess, yearAgoAggregatesData, isCustomDatePeriod]);

  const previousPeriodPercentageChange = useMemo(() => {
    return calculatePercentageChangeDelta(
      getPeriodUsage(thisPeriodFormattedAggregates),
      getPeriodUsage(previousPeriodFormattedAggregates),
    );
  }, [thisPeriodFormattedAggregates, previousPeriodAggregatesData]);

  const yearAgoPercentageChange = useMemo(() => {
    return calculatePercentageChangeDelta(
      getPeriodUsage(thisPeriodFormattedAggregates),
      getPeriodUsage(yearAgoPeriodFormattedAggregates),
    );
  }, [thisPeriodFormattedAggregates, yearAgoPeriodFormattedAggregates]);

  const displayPreviousPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isPreviousPeriodAggregatesSuccess &&
    previousPeriodAggregatesData &&
    isThisPeriodAggregatesSuccess;

  const displayYearAgoPeriodDeltaMessage =
    !isCustomDatePeriod &&
    yearAgoAggregatesData &&
    isThisPeriodAggregatesSuccess;

  return (
    <section>
      <section className="header_section">
        <BoltIconSvg data-testid="icon" />
        <h2 className="heading h4">
          {isThisPeriodAggregatesFetching ? (
            <LoadingIcon />
          ) : (
            periodUsageTotal({
              completedBill,
              dataLength: getDataLength(thisPeriodAggregatesData),
              isCustomDatePeriod,
              periodName,
              thisPeriodUsage: getPeriodUsage(thisPeriodFormattedAggregates),
              unitCode,
            })
          )}
        </h2>
        {displayPreviousPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: previousPeriodPercentageChange,
            periodLabel: `${
              completedBill ? "" : "this time "
            }last ${periodName ?? "period"}`,
          })}
        {displayYearAgoPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: yearAgoPercentageChange,
            periodLabel: "this time last year",
          })}
      </section>
      {!isCustomDatePeriod && (
        <ProgressComparisonChart
          data={prepareComparisonChartData({
            thisPeriodData: getPeriodUsage(thisPeriodFormattedAggregates),
            previousPeriodData: getPeriodUsage(
              previousPeriodFormattedAggregates,
            ),
            yearAgoData: getPeriodUsage(yearAgoPeriodFormattedAggregates),
            isThisPeriodAggregatesSuccess,
            isPreviousPeriodAggregatesSuccess,
            isYearAgoAggregatesSuccess,
            periodName: periodName,
            unitType: UNIT_CODE_TYPES.usage,
          })}
        />
      )}
    </section>
  );
};
