import { AccountHeaderSection } from "components/AccountHeaderSection";
import { CancelButton } from "components/CancelButton";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

type AccountPageWrapperProps = {
  cancelLabel?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  hasCancelButton?: boolean;
  headerItems?: { key: string; value?: string | number }[];
  headerLabel?: string;
};

export const AccountPageWrapper: React.FC<AccountPageWrapperProps> = ({
  cancelLabel,
  children,
  className = "",
  hasCancelButton = true,
  headerItems,
  headerLabel,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { active } = useAppSelector((store) => store.currentAccount);

  // If customer selects a closed account from dropdown, nav back to account page
  useEffect(() => {
    if (active === false && pathname !== "/account") {
      navigate("/account");
    }
  }, [active]);

  return (
    <div className={`page-section ${className}`}>
      <section className="page-section__column page-section__column--single">
        <AccountHeaderSection
          label={headerLabel}
          items={headerItems}
          hasHeaderIcon={pathname === "/account"}
        />
        {children}
        {hasCancelButton && <CancelButton label={cancelLabel} />}
      </section>
    </div>
  );
};
