import { createApi } from "@reduxjs/toolkit/query/react";
import { endOfDay } from "date-fns/endOfDay";
import { startOfDay } from "date-fns/startOfDay";
import { JwtClient } from "utils/jwtClient";

import { formatAvailablePlans } from "./pricing.utils";
import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const { data } = await response.json();

  return { data };
};

export type RateCardParams = {
  generationPriceContract?: string;
  icpNumber?: string;
  usageType?: string;
  locationClassification?: string;
  jwtClient: JwtClient;
};

export type RateAvailablePlansParams = {
  jwtClient: JwtClient;
  supplyNodeRef: string;
  usageType?: string;
  locationClassification?: string;
};

export type TimeTableParams = {
  jwtClient: JwtClient;
  supplyNodeRef: string;
  generationPriceContract?: string;
};

/**
 * Define the pricingRateApi slice
 */
export const pricingRateCardApi = createApi({
  reducerPath: "pricingRateCardApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getRateCards: builder.query<RateCard[], RateCardParams>({
      query: ({
        jwtClient,
        icpNumber,
        generationPriceContract,
        usageType,
        locationClassification,
      }) => {
        const queryParams = {
          ea_icp_id: icpNumber,
          start_at: startOfDay(new Date()).toISOString(),
          end_at: endOfDay(new Date()).toISOString(),
          generation_price_contract: generationPriceContract,
          ...(usageType && { usage_type: usageType }),
          ...(locationClassification && {
            location_classification: locationClassification,
          }),
        };
        return {
          jwtClient: jwtClient,
          path: "/pricing/rate_cards",
          queryParams,
        };
      },
    }),
    getRateAvailablePlans: builder.query<
      AvailablePlan[],
      RateAvailablePlansParams
    >({
      query: ({
        jwtClient,
        supplyNodeRef,
        usageType,
        locationClassification,
      }) => {
        const queryParams = {
          start_at: startOfDay(new Date()).toISOString(),
          end_at: endOfDay(new Date()).toISOString(),
          brand: "flick",
          existing_customer_plans: "true",
          supply_node_ref: supplyNodeRef,
          ...(Boolean(usageType) && { usage_type: usageType }),
          ...(Boolean(locationClassification) && {
            location_classification: locationClassification,
          }),
        };
        return {
          jwtClient: jwtClient,
          path: "/pricing/rate_cards",
          queryParams,
        };
      },
      transformResponse: (data) => {
        return formatAvailablePlans(data);
      },
    }),
    /**
     * Returns timetable for a specific price contract
     * we don't need to pass usageType or locationClassification cause we don't care about the usage_rates return,
     * and timetables are the same between usage types
     */
    getTimeTableForContract: builder.query<Timetables[], TimeTableParams>({
      query: ({ jwtClient, supplyNodeRef, generationPriceContract }) => {
        const queryParams = {
          supply_node_ref: supplyNodeRef,
          generation_price_contract: generationPriceContract,
          existing_customer_plans: "false",
        };
        return {
          jwtClient: jwtClient,
          path: "/pricing/rate_cards",
          queryParams,
        };
      },
      transformResponse: (data) => {
        return data[0].attributes.timetables;
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetRateCardsQuery,
  useGetRateAvailablePlansQuery,
  useGetTimeTableForContractQuery,
} = pricingRateCardApi;
