import { createSelector } from "@reduxjs/toolkit";
import { TIME_OF_DAY } from "utils/constants";
import { ADD_NZ_GST } from "utils/currency";

import { selectCurrentRateCard } from "./currentRateCardSelector";

/**
 * Selector to compute the rate calculations based on the current rate card.
 */
export const selectRateCalculations = createSelector(
  [selectCurrentRateCard],
  (currentRateCard) => {
    if (!currentRateCard) {
      return undefined;
    }

    const offpeakRatesTimetables = currentRateCard.attributes?.timetables?.find(
      (timetables) =>
        timetables.rate_code.toLowerCase() === TIME_OF_DAY.offPeak,
    );

    const offpeakWeekdaysTimetable = offpeakRatesTimetables?.timetable?.find(
      (timetable) => timetable.weekends === false,
    );

    const offpeakWeekendTimetable = offpeakRatesTimetables?.timetable?.find(
      (timetable) => timetable.weekends === true,
    );

    const shiftLastsAllDay =
      offpeakWeekendTimetable?.shifts?.[0]?.start_at ===
      offpeakWeekendTimetable?.shifts?.[0]?.end_at;

    const usageRates = currentRateCard.attributes?.usage_rates?.filter(
      (usage_rates) =>
        usage_rates.rates?.some(
          (rate) => rate.rate_code.toLowerCase() === TIME_OF_DAY.offPeak,
        ),
    );

    const offpeakRate = usageRates?.map((rates) =>
      rates.rates.find(
        (rate) => rate.rate_code.toLowerCase() === TIME_OF_DAY.offPeak,
      ),
    );

    const offpeakTotalInclGst = Number(offpeakRate?.[0]?.total) * ADD_NZ_GST;

    const peakRate = usageRates?.map((rates) =>
      rates.rates.find(
        (rate) => rate.rate_code.toLowerCase() === TIME_OF_DAY.peak,
      ),
    );

    const peakTotalInclGst = Number(peakRate?.[0]?.total) * ADD_NZ_GST;

    const offpeakSavingsPercentage =
      ((Number(peakTotalInclGst) - Number(offpeakTotalInclGst)) /
        Number(peakTotalInclGst)) *
      100;

    return {
      offpeakWeekdaysTimetable,
      shiftLastsAllDay,
      offpeakTotalInclGst,
      peakTotalInclGst,
      offpeakSavingsPercentage,
    };
  },
);
