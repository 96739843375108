/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./OffpeakDetailsSection.scss";

import { Card, CardBody } from "@flick/fep-library";
import { ReactComponent as DownArrowSvg } from "app/assets/images/icons/arrow-down-sm.svg";
import { ReactComponent as LeafIcon } from "app/assets/images/icons/leaf-solid.svg";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import { usePollRemainingShiftTime } from "customHooks/usePollRemainingShiftTime";
import { formatRelative } from "date-fns/formatRelative";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { selectRateCalculations } from "selectors/rateCalculationSelectors";
import { TIME_OF_DAY } from "utils/constants";
import { convertTimeFrom24To12Format } from "utils/offpeakCalculations/convertTimeFrom24To12Format";

export const OffpeakDetailsSection: React.FC = () => {
  const { isRateCardError, isRateCardLoading } = useAppSelector(
    (store) => store.rateCards,
  );

  /**
   * calculate the offpeak details based on the current rate card using the selectRateCalculations selector
   */
  const rateCalculations = useAppSelector(selectRateCalculations);

  const currentDate = new Date();

  const { currentShiftDetails, calculatedShiftTime } =
    usePollRemainingShiftTime();

  if (isRateCardLoading) {
    return <LoadingSection />;
  }

  if (isRateCardError || (!isRateCardLoading && !rateCalculations)) {
    return <ComponentItemFallback componentTitle={"Off peak details"} />;
  }

  const {
    offpeakWeekdaysTimetable,
    shiftLastsAllDay,
    offpeakTotalInclGst,
    peakTotalInclGst,
    offpeakSavingsPercentage,
  } = rateCalculations;

  const shiftIsOffPeak =
    currentShiftDetails?.currentShift?.rateCode.toLowerCase() ===
    TIME_OF_DAY.offPeak;

  return (
    <Card>
      <CardBody>
        <section className="offpeak-details-header">
          {shiftIsOffPeak ? (
            <h2 className="h4 background--green text__colour--inverse">
              Off-peak now
            </h2>
          ) : (
            <h2 className="h4 background--black text__colour--contrast">
              Off-peak soon
            </h2>
          )}
          <LeafIcon />
        </section>
        {currentShiftDetails && calculatedShiftTime && (
          <section className="">
            <div className="">
              {currentShiftDetails?.currentShift?.rateCode &&
                calculatedShiftTime.timeRemainingInWords && (
                  <p className="h5">
                    {shiftIsOffPeak ? "Ends" : "Starts"} in{" "}
                    {calculatedShiftTime.timeRemainingInWords}
                  </p>
                )}
              <p className="small">
                {shiftIsOffPeak ? "Ends" : "Starts"}{" "}
                {formatRelative(
                  new Date(currentShiftDetails.endDate),
                  currentDate,
                )}
              </p>
            </div>
          </section>
        )}
        {offpeakTotalInclGst && (
          <div className="offpeak-details-accordion-wrapper">
            <details className="accordion offpeak-details-accordion">
              <summary className="accordion__summary">Off-peak times</summary>
              <section className="offpeak-details-section customer_tools_list__item">
                <div className="offpeak-details-section__content">
                  <p className="h5">Weekdays</p>
                  {offpeakWeekdaysTimetable?.shifts?.map((shift, index) => (
                    <p
                      key={`${index}`}
                      className="offpeak-details-section__shifts"
                    >
                      {shift.start_at &&
                        convertTimeFrom24To12Format(shift.start_at)}{" "}
                      -{" "}
                      {shift.end_at &&
                        convertTimeFrom24To12Format(shift.end_at)}
                    </p>
                  ))}
                </div>
                <div className="offpeak-details-section__content">
                  <p className="h5">Weekends</p>
                  <p>{shiftLastsAllDay ? "All day" : " "}</p>
                </div>
              </section>
              <section className="offpeak-details-section ">
                <div className="customer_tools_list__item">
                  <div className="offpeak-details-section__content">
                    <p>Off-peak rate</p>
                    <p className="h3">
                      {(Number(offpeakTotalInclGst) * 100).toFixed(3)}{" "}
                      <span className="h5">c/kWh</span>
                    </p>
                  </div>
                  <div className="offpeak-details-section__content">
                    <p>Peak rate</p>
                    <p className="h3">
                      {(Number(peakTotalInclGst) * 100).toFixed(3)}{" "}
                      <span className="h5">c/kWh</span>
                    </p>
                  </div>
                </div>
                <p>Rates include GST.</p>
                <div className="spacer--small" />
              </section>
              {offpeakSavingsPercentage > 0 && (
                <div className="subheading--with-icon">
                  <DownArrowSvg className="icon_colour--green" />
                  <p>
                    <span className="h5">
                      Save {offpeakSavingsPercentage.toFixed(0)}%
                    </span>{" "}
                    at off-peak times
                  </p>
                </div>
              )}
            </details>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
