/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { deserialize } from "@flick/flick-api-client";
import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import { BaseQueryParams } from "./queries.utils";

export type CarbonParams = {
  jwtClient: JwtClient;
};

type CarBonMeasurements = {
  carbonIntensityChangeGkwh: string;
  carbonIntensityGkwh: string;
  carbonTonnes: string;
  currentMonthAvgGkwh: string;
  currentYearAvgGkwh: string;
  endAt: string;
  id: string;
  max_24hrsGkwh: string;
  min_24hrsGkwh: string;
  percentOfCurrentYearGkwh: string;
  renewablePercentage: string;
  startAt: string;
  tradingDate: string;
  type: string;
};

type AggregatesQueryParams = {
  endAt: Date | number;
  jwtClient: JwtClient;
  startAt: Date | number;
  supplyNodeRef: string;
};

type CarbonIntensityResponse = {
  cost: string;
  costUnit: string;
  endAt: string;
  exportCost?: string;
  exportQuantity: string;
  id: string;
  importCost: string;
  importQuantity?: string;
  quantity: string;
  quantityUnit: string;
  renewableQuantity: string;
  startAt: string;
  status: string;
  type: string;
};

type CarbonIntensity = {
  weekBefore?: number;
  lastWeek?: number;
};

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();

  const flatResult: CarBonMeasurements[] | CarbonIntensityResponse[] =
    deserialize(result);
  return { data: flatResult };
};

/**
 * Define the carbonApi slice
 * this slice uses flick_api to fetch the new carbon measurements data
 * and is using flick-api-client to deserialize the response ( this package only work with flick_api)
 * https://static.flickelectric.co.nz/flick_api/index.html
 */
export const carbonApi = createApi({
  reducerPath: "carbonApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getCarbonMeasurements: builder.query<CarBonMeasurements, CarbonParams>({
      query({ jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: "/market/v1/carbon_measurements",
        };
      },
      transformResponse: (data: CarBonMeasurements[]) => {
        // Carbon measurements API will return arrays of data so we extract the first element here
        const reorderData = data.sort(
          (a, b) =>
            new Date(b.startAt).getTime() - new Date(a.startAt).getTime(),
        );
        return reorderData[0];
      },
    }),
    // @TODO move this to rating api when the rating is move to use flick_api
    getCarbonIntensityAggregates: builder.query<
      CarbonIntensity,
      AggregatesQueryParams
    >({
      query: ({ jwtClient, supplyNodeRef, startAt, endAt }) => {
        const queryParams = {
          supply_node_ref: supplyNodeRef,
          start_at: new Date(startAt).toISOString(),
          end_at: new Date(endAt).toISOString(),
          aggregation_frequency: "week",
          timezone: "Pacific/Auckland",
          rate_by: "co2e",
        };
        return {
          jwtClient: jwtClient,
          path: "/rating/v1/rated_periods",
          queryParams,
        };
      },
      transformResponse: (data: CarbonIntensityResponse[]): CarbonIntensity => {
        return {
          lastWeek:
            data[0].cost && data[0].importQuantity
              ? Number(
                  (
                    parseFloat(data[0].cost) /
                    parseFloat(data[0].importQuantity)
                  ).toFixed(2),
                )
              : undefined,
          weekBefore:
            data[1].cost && data[1].importQuantity
              ? Number(
                  (
                    parseFloat(data[1].cost) /
                    parseFloat(data[1].importQuantity)
                  ).toFixed(2),
                )
              : undefined,
        };
      },
    }),
  }),
});

export const {
  useGetCarbonMeasurementsQuery,
  useGetCarbonIntensityAggregatesQuery,
} = carbonApi;
