/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, FormRow } from "@flick/fep-library";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { TextInput } from "components/Inputs/TextInput";
import { LoadingSection } from "components/LoadingSection";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { SubmitButton } from "components/SubmitButton";
import { useBillingDetailsFormContext } from "pages/BillingDetailsPage/BillingDetailsPageContext";
import { BillingDetailsPagePaymentMethod } from "pages/BillingDetailsPage/BillingDetailsPagePaymentMethod";
import { billingApi } from "queries/billingApi";
import {
  customerApi,
  UpdateBillingPreferencesParams,
  useGetWindcaveAuthResultQuery,
  useUpdateBillingPreferencesMutation,
} from "queries/customerApi";
import React, { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { setDefaultPaymentMethod } from "reducers/accountDetails";
import { setUpdateDefaultPaymentMethod } from "reducers/billingEntityData";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  BILLING_OFFSET_WEEKS,
  NotificationBannerNames,
  NotificationBannerTypes,
  PERIOD_CODES,
} from "utils/constants";
import { hasInputValueChanged } from "utils/forms/forms";
import { jwtClient } from "utils/jwtClient";

import {
  formatDefaultPaymentMethod,
  getPaymentMethodById,
} from "./BillingDetailsPage.utils";
import { BillingDetailsPageBillingFrequency } from "./BillingDetailsPageBillingFrequency";
import content from "./static/content.json";
const {
  headerAccount,
  emailLabel,
  nameHint,
  nameLabel,
  pageHeader,
  submitLabel,
} = content;

export const BillingDetailsPageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const { preferredName } = useAppSelector((store) => store.accountDetails);
  const { accountNumber, nativeRenderMode } = useAppSelector(
    (store) => store.currentAccount,
  );
  const { paymentMethods } = useAppSelector((store) => store.accountDetails);

  // state variables to detect if fields in child components change
  const [hasPaymentMethodChanged, setHasPaymentMethodChanged] = useState(false);
  const [hasFrequencyWeeksChanged, setHasFrequencyWeeksChanged] =
    useState(false);
  const [hasBillingFrequencyChanged, setHasBillingFrequencyChanged] =
    useState(false);

  const [windcaveTransactionId, setWindcaveTransactionId] = useState<
    string | null
  >(null);

  /*
    If on page load there is a 'windcaveTransactionId' in session, we have been
    redirected to this page from Windcave after an attempt to authorise a new
    credit or debit card, an additional query must be made to Windcave to fetch
    the result and show it in a banner.
    More details in doc/architecture/decisions/0010-processing-credit-card-payments.md
  */
  useEffect(() => {
    const wcId = window.sessionStorage.getItem("windcaveTransactionId");
    if (wcId) {
      setWindcaveTransactionId(wcId);
    }
  }, []);

  const { data: WCAuthResultData } = useGetWindcaveAuthResultQuery(
    {
      jwtClient,
      windcaveTransactionId,
    },
    { skip: !windcaveTransactionId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (WCAuthResultData) {
      const newCardAuthenticationResult = WCAuthResultData?.attributes.success;
      window.sessionStorage.removeItem("windcaveTransactionId");
      // Move this into Redux store
      if (newCardAuthenticationResult) {
        dispatch(
          setNotificationBanner({
            type: NotificationBannerTypes.notice,
            name: NotificationBannerNames.addCardPaymentSuccess,
            page: pathname,
          }),
        );
        // refetch account details to pull out new CC details in paymentMethods
        // this is need for native app due to no override from window.location
        if (nativeRenderMode) {
          dispatch(customerApi.util.invalidateTags(["AccountDetails"]));
        }
      } else {
        dispatch(
          setNotificationBanner({
            type: NotificationBannerTypes.notice,
            name: NotificationBannerNames.addCardPayment,
            page: pathname,
          }),
        );
      }
    }
  }, [WCAuthResultData]);

  const [updateBillingPreferences, { isLoading }] =
    useUpdateBillingPreferencesMutation();

  const handleUpdateBillingPreferences = async ({
    params: {
      accountIdentifier,
      billingEmail,
      billingPeriod,
      billingPeriodOffsetWeeks,
      selectedPaymentMethod,
    },
  }: Omit<UpdateBillingPreferencesParams, "jwtClient">) => {
    try {
      const {
        attributes: { default_payment_method_id },
      } = await updateBillingPreferences({
        jwtClient,
        params: {
          accountIdentifier,
          billingEmail,
          billingPeriod,
          billingPeriodOffsetWeeks,
          selectedPaymentMethod,
        },
      }).unwrap();

      const defaultPaymentMethod = getPaymentMethodById(
        default_payment_method_id.toString(),
        paymentMethods,
      );

      if (hasPaymentMethodChanged) {
        dispatch(
          setDefaultPaymentMethod({
            paymentMethodId: default_payment_method_id.toString(),
          }),
        );
      }

      dispatch(
        setUpdateDefaultPaymentMethod({
          ...(hasPaymentMethodChanged && {
            default_payment_method: defaultPaymentMethod
              ? formatDefaultPaymentMethod(defaultPaymentMethod)
              : undefined,
          }),
        }),
      );
      // refetch Billing Entity to get all new details
      dispatch(billingApi.util.invalidateTags(["BillingEntity"]));

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.success,
          name: NotificationBannerNames.updateBillingPreferences,
          page: pathname,
        }),
      );
    } catch (err) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.updateBillingPreferences,
          page: pathname,
          additionalContent: errorReasonDetails(err),
        }),
      );
    }
  };
  const {
    isFormValid,
    email: {
      fieldValue: { value: emailValue, error: emailError },
      handleOnChange: setEmail,
      handleOnBlur: handleEmailBlur,
    },
    billingFrequency: {
      fieldValue: { value: billingFrequencyValue },
    },
    frequencyWeek: {
      fieldValue: { value: frequencyWeekValue },
    },
    paymentMethod: {
      fieldValue: { value: paymentMethodValue },
    },
  } = useBillingDetailsFormContext();

  useEffect(() => {
    if (billingEntityData?.billing_contact_email) {
      setEmail(billingEntityData?.billing_contact_email);
    }
  }, [billingEntityData?.billing_contact_email]);

  // detect whether form field value is different from value in state
  const hasEmailChanged = hasInputValueChanged(
    billingEntityData?.billing_contact_email,
    emailValue,
  );

  const anyInputChanged = [
    hasEmailChanged,
    hasBillingFrequencyChanged,
    hasFrequencyWeeksChanged,
    hasPaymentMethodChanged,
  ].includes(true);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const selectedPaymentMethod = getPaymentMethodById(
      paymentMethodValue,
      paymentMethods,
    );
    handleUpdateBillingPreferences({
      params: {
        accountIdentifier: accountNumber,
        ...(hasEmailChanged && { billingEmail: emailValue }),
        ...(hasBillingFrequencyChanged && {
          billingPeriod: billingFrequencyValue,
          billingPeriodOffsetWeeks:
            billingFrequencyValue === PERIOD_CODES.P2W
              ? frequencyWeekValue
              : BILLING_OFFSET_WEEKS.thisWeek, // if weekly or monthly, billingPeriodOffsetWeeks must be updated to "0"
        }),
        ...(hasFrequencyWeeksChanged && {
          billingPeriodOffsetWeeks:
            billingFrequencyValue === PERIOD_CODES.P2W
              ? frequencyWeekValue
              : BILLING_OFFSET_WEEKS.thisWeek,
        }),
        ...(hasPaymentMethodChanged && { selectedPaymentMethod }),
      },
    });
  };

  if (!preferredName) return <LoadingSection />;

  return (
    <AccountPageWrapper
      headerLabel={pageHeader}
      headerItems={[
        { key: preferredName, value: null },
        { key: headerAccount, value: accountNumber },
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              disabled
              hint={nameHint}
              inputWidth="full"
              label={nameLabel}
              name="billing-name"
              required={true}
              value={preferredName}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              errorMessage={emailError}
              handleOnBlur={handleEmailBlur}
              inputMode="email"
              inputWidth="full"
              label={emailLabel}
              name="billing-email"
              required={true}
              setValue={setEmail}
              type="email"
              value={emailValue}
            />
          </div>
        </FormRow>
        <BillingDetailsPageBillingFrequency
          setBillingFrequencyHasChanged={(valueChanged) =>
            setHasBillingFrequencyChanged(valueChanged)
          }
          setFrequencyWeekHasChanged={(valueChanged) =>
            setHasFrequencyWeeksChanged(valueChanged)
          }
        />
        <BillingDetailsPagePaymentMethod
          setValueHasChanged={(valueChanged) =>
            setHasPaymentMethodChanged(valueChanged)
          }
        />
        <SubmitButton
          isSubmitDisabled={!isFormValid || !anyInputChanged || isLoading}
          label={submitLabel}
        />
      </Form>
    </AccountPageWrapper>
  );
};
