import "./HourlyUsageChart.scss";

import { BarChartWrapper } from "components/charts/BarChartWrapper";
import { LoadingSection } from "components/LoadingSection";
import React, { useMemo } from "react";

import { generateBarChartConfig } from "./HourlyUsageChart.utils";

type HourlyUsageChartProps = {
  displayLoadingIcon: boolean;
  isSelectedDayAggregateError: boolean;
  productIsOffpeak: boolean;
  selectedDayMaxYValue: number | [];
  selectedDayUsageData: ChartUsage[];
};

export const HourlyUsageChart: React.FC<HourlyUsageChartProps> = ({
  displayLoadingIcon,
  isSelectedDayAggregateError,
  productIsOffpeak,
  selectedDayMaxYValue,
  selectedDayUsageData,
}) => {
  if (displayLoadingIcon) return <LoadingSection />;

  const barChartConfig = useMemo(
    () => generateBarChartConfig(selectedDayUsageData, productIsOffpeak),
    [productIsOffpeak, selectedDayUsageData],
  );

  return (
    <>
      {isSelectedDayAggregateError && (
        <>
          <div className="notification_banner notification_notice icon">
            <p>Hourly usage breakdown currently not available</p>
          </div>
        </>
      )}
      <BarChartWrapper
        chartConfig={barChartConfig}
        maxYValue={selectedDayMaxYValue}
      />
    </>
  );
};
