import { differenceInMinutes } from "date-fns/differenceInMinutes";
import { formatDuration } from "date-fns/formatDuration";
import { intervalToDuration } from "date-fns/intervalToDuration";

/**
 * calculate the total time in a shift, the remaining time left of the current shift, the remaining time until the next shift as a percentage
 */

export type RemainingShiftTime = {
  timeRemainingInWords: string;
  timeRemainingPercentage: number;
};

export const calculateRemainingShiftTime = (
  currentShiftDetails: CurrentShiftDetails | undefined,
  currentDate: Date,
): RemainingShiftTime | undefined => {
  if (
    !currentShiftDetails ||
    Object.keys(currentShiftDetails).length === 0 ||
    !currentShiftDetails.startDate ||
    !currentShiftDetails.endDate
  ) {
    return undefined;
  }

  const shiftStartDate = new Date(currentShiftDetails.startDate);
  const shiftEndDate = new Date(currentShiftDetails.endDate);

  const totalTimeInMinutes = differenceInMinutes(shiftEndDate, shiftStartDate);

  const timeLeftInMinutes = differenceInMinutes(shiftEndDate, currentDate);

  const remainingTimeShiftDuration = intervalToDuration({
    start: currentDate,
    end: shiftEndDate,
  });

  const remainingHours = () => {
    if (!remainingTimeShiftDuration?.hours) {
      return undefined;
    }

    return (
      remainingTimeShiftDuration.hours +
      (remainingTimeShiftDuration?.days || 0) * 24
    );
  };

  const timeRemainingInWords = formatDuration({
    hours: remainingHours(),
    minutes: remainingTimeShiftDuration?.minutes,
  });

  // percentage of time (minutes) left until the next shift starts
  const timeRemainingPercentage =
    (timeLeftInMinutes / totalTimeInMinutes) * 100;

  const result = {
    timeRemainingInWords,
    timeRemainingPercentage,
  };

  return result;
};
