import { LoadingSection } from "components/LoadingSection";
import { isBefore } from "date-fns/isBefore";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { AVAILABLE_PERIODS } from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { UsageSection } from "../../components/UsageSection";

const calulatedBillPeriod = (bill: Bill) => {
  return {
    startAt: bill ? new Date(bill.billing_period.period_started_at) : null,
    endAt: bill ? new Date(bill.billing_period.period_ended_at) : null,
  };
};

const calulatedPreviousBillPeriod = (bill: Bill) => {
  return {
    startAt: bill ? new Date(bill.comparison_data.previous.started_at) : null,
    endAt: bill ? new Date(bill.comparison_data.previous.ended_at) : null,
  };
};

const calulatedYearAgoBillPeriod = (bill: Bill) => {
  return {
    startAt: bill
      ? new Date(bill.comparison_data.one_year_ago.started_at)
      : null,
    endAt: bill ? new Date(bill.comparison_data.one_year_ago.ended_at) : null,
  };
};

type BillUsageSectionProps = {
  bill?: Bill | null;
};

/**
 * @description Wrapper container for the UsageSection that gets rendered on the page of individual bills
 */
export const BillUsageSection: React.FC<BillUsageSectionProps> = ({ bill }) => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const billPeriod = calulatedBillPeriod(bill);
  const previousBillPeriod = calulatedPreviousBillPeriod(bill);

  const yearAgoBillPeriod = calulatedYearAgoBillPeriod(bill);

  const unitCode = bill.usage.unit_code;
  const { periodName = undefined } =
    AVAILABLE_PERIODS[bill?.billing_period?.duration] || {};

  const {
    isFetching: isBillPeriodAggregatesFetching,
    isSuccess: isBillPeriodAggregatesSuccess,
    data: billPeriodAggregatesData,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: billPeriod.startAt,
      endAt: billPeriod.endAt,
      jwtClient,
      source: "Individual Bill - Bill Period",
    },
    {
      skip:
        !supplyNodeRef ||
        !billPeriod.endAt ||
        !billPeriod.startAt ||
        // query start date must not before user's start date else it will invalid request and expire token
        isBefore(billPeriod.startAt, new Date(billingEntityData?.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: previousPeriodAggregatesData,
    isSuccess: isPreviousPeriodAggregatesSuccess,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: previousBillPeriod.startAt,
      endAt: previousBillPeriod.endAt,
      jwtClient,
      source: "Individual Bill - Previous Bill Period",
    },
    {
      skip:
        !supplyNodeRef ||
        !previousBillPeriod.startAt ||
        !previousBillPeriod.endAt ||
        !billingEntityData ||
        // query start date must not before user's start date else it will invalid request and expire token
        isBefore(
          previousBillPeriod.startAt,
          new Date(billingEntityData?.start_at),
        ),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const { isSuccess: isYearAgoAggregatesSuccess, data: yearAgoAggregatesData } =
    useGetAggregateRatesQuery(
      {
        supplyNodeRef,
        startAt: yearAgoBillPeriod.startAt,
        endAt: yearAgoBillPeriod.endAt,
        jwtClient,
        source: "Individual Bill - Year Ago Bill Period",
      },
      {
        skip:
          !supplyNodeRef ||
          !yearAgoBillPeriod.startAt ||
          !yearAgoBillPeriod.endAt ||
          !billingEntityData ||
          // query start date must not before user's start date else it will invalid request and expire token
          isBefore(
            yearAgoBillPeriod.startAt,
            new Date(billingEntityData?.start_at),
          ),
        refetchOnMountOrArgChange: expireTime,
      },
    );

  // Error state for failed api calls is handled in the child component (UsageSection)
  if (isBillPeriodAggregatesFetching) {
    return <LoadingSection />;
  }

  return (
    <UsageSection
      thisPeriodAggregatesData={billPeriodAggregatesData}
      previousPeriodAggregatesData={previousPeriodAggregatesData}
      yearAgoAggregatesData={yearAgoAggregatesData}
      unitCode={unitCode}
      periodName={periodName}
      completedBill={true}
      isThisPeriodAggregatesSuccess={isBillPeriodAggregatesSuccess}
      isThisPeriodAggregatesFetching={isBillPeriodAggregatesFetching}
      isPreviousPeriodAggregatesSuccess={isPreviousPeriodAggregatesSuccess}
      isYearAgoAggregatesSuccess={isYearAgoAggregatesSuccess}
    />
  );
};
