import { createSlice } from "@reduxjs/toolkit";
import { paymentSmootherApi } from "queries/paymentSmootherApi";

const billSmootherSlice = createSlice({
  name: "billSmootherConfig",
  initialState: {
    billSmootherConfig: null,
  } as { billSmootherConfig: BillSmootherConfig | null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        paymentSmootherApi.endpoints.getBillSmootherConfig.matchPending,
        (state, {}) => {
          state.billSmootherConfig = null;
        },
      )
      .addMatcher(
        paymentSmootherApi.endpoints.getBillSmootherConfig.matchFulfilled,
        (state, { payload }) => {
          state.billSmootherConfig = payload;
        },
      )
      .addMatcher(
        paymentSmootherApi.endpoints.updateBillSmootherConfig.matchFulfilled,
        (state, { payload }) => {
          state.billSmootherConfig = payload;
        },
      )
      .addMatcher(
        paymentSmootherApi.endpoints.createBillSmootherConfig.matchFulfilled,
        (state, { payload }) => {
          state.billSmootherConfig = payload;
        },
      );
  },
});

export const {} = billSmootherSlice.actions;
export const billSmootherSliceReducer = billSmootherSlice.reducer;
