export const formatValueToString = (value?: string | number) => {
  if (!value || isNaN(Number(value))) return "0";
  return value.toString();
};

/**
 * @description Return the total amount of usage imported by given aggregate data
 */
export const prepareUsageTotal = (
  aggregates: Aggregate[] = [],
): string | undefined => {
  if (!aggregates || aggregates.length === 0) return undefined;
  const usageTotal = aggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(formatValueToString(aggregate.usage?.import_value)),
    0,
  );

  return usageTotal.toFixed(3);
};

/**
 * @description Return the total amount of usage exported by given aggregate data
 */
export const prepareUsageExportTotal = (
  aggregates: Aggregate[] = [],
): string => {
  if (!aggregates || aggregates.length === 0) return undefined;
  const exportUsageTotal = aggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(formatValueToString(aggregate.usage?.export_value)),
    0,
  );

  return exportUsageTotal.toFixed(3);
};
