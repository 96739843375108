import { ReactComponent as CartIconSvg } from "app/assets/images/icons/cart-solid.svg";
import { ReactComponent as BackIcon } from "app/assets/images/icons/chevron-left-lg.svg";
import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import { ReactComponent as PriceTagIconSvg } from "app/assets/images/icons/label-sold.svg";
import { PeriodComparisonMessage } from "components/PeriodComparisonMessage";
import { format } from "date-fns/format";
import { Subheading } from "elements/Subheading";
import { useInvoiceUsageChart } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import React, { memo } from "react";
import { DisplayUnit } from "utils/constants";
import { NZD } from "utils/currency";
import { safeNumber } from "utils/safeNumber/safeNumber";

type SolarInvoiceDayDetailProps = {
  periodName: string;
  selectedDayData: DailyUsage;
};

export const SolarInvoiceDayDetail: React.FC<SolarInvoiceDayDetailProps> = memo(
  ({ periodName, selectedDayData }) => {
    const {
      setSelectedDayIndex,
      isSelectedDayNotFirstDay,
      isSelectedDayNotLastDay,
    } = useInvoiceUsageChart();

    return (
      <section className="usage-detail-container background--grey">
        <span className="spacer spacer--small" />
        <div className="usage-detail">
          <h3 className="h4 usage-detail__title">
            {format(selectedDayData.date, "EEEE d MMMM")}
          </h3>
          <div className="usage-detail__controls">
            <button
              aria-disabled={!isSelectedDayNotFirstDay}
              disabled={!isSelectedDayNotFirstDay}
              data-testid="previous-button"
              className="chevron-arrows chevron-arrows--alt-background"
              onClick={() => {
                setSelectedDayIndex((prev) => prev - 1);
              }}
            >
              <BackIcon aria-label="Previous day" />
            </button>

            <button
              aria-disabled={!isSelectedDayNotLastDay}
              disabled={!isSelectedDayNotLastDay}
              data-testid="next-button"
              className="chevron-arrows chevron-arrows--alt-background"
              onClick={() => {
                setSelectedDayIndex((prev) => prev + 1);
              }}
            >
              <ForwardIcon aria-label="Next day" />
            </button>
          </div>
        </div>
        {selectedDayData.status !== "missing" && (
          <>
            <section>
              <Subheading
                title={`Sold ${NZD.format(selectedDayData.thisPeriod.earned)} (${safeNumber(selectedDayData.thisPeriod.generation, 3)} kWh)`}
                icon={<PriceTagIconSvg />}
              />
              <PeriodComparisonMessage
                displayUnit={DisplayUnit.dollar}
                lastPeriod={selectedDayData.lastPeriod.earned}
                currentPeriod={selectedDayData.thisPeriod.earned}
                periodLabel={`this time last ${periodName}`}
              />
            </section>
            <div className="spacer" />
            <section>
              <Subheading
                title={`Bought ${NZD.format(selectedDayData.thisPeriod.spend)} (${safeNumber(selectedDayData.thisPeriod.consumption, 3)} kWh)`}
                icon={<CartIconSvg />}
              />
              <PeriodComparisonMessage
                displayUnit={DisplayUnit.dollar}
                lastPeriod={selectedDayData.lastPeriod.spend}
                currentPeriod={selectedDayData.thisPeriod.spend}
                periodLabel={`this time last ${periodName}`}
              />
            </section>
          </>
        )}
      </section>
    );
  },
);
