import { BRAND_COLOURS, Y_AXIS_DISPLAY_TYPE } from "utils/constants";

/** Scale the Y axis to fit 110% of the largest day's usage */
export const getMaxYValueForSolarDisplayType = (
  dailyUsageData: DailyUsage[],
  displayType: string,
) => {
  return Math.ceil(
    1.1 *
      Math.max(
        ...dailyUsageData.map((d) => d.thisPeriod[displayType]),
        ...dailyUsageData.map((d) =>
          displayType === "spend"
            ? d.thisPeriod.earned
            : d.thisPeriod.generation,
        ),
      ),
  );
};

export const generateSolarDailyUsageGroupedBarChartConfig = (
  dailyUsageData: DailyUsage[],
  displayType: string,
): GroupedBarChartConfig => {
  const dates: Date[] = [];
  const labels: string[][] = [];
  const status: string[] = [];
  const spendData: number[] = [];
  const earnedData: number[] = [];
  const consumptionData: number[] = [];
  const generationData: number[] = [];

  dailyUsageData.forEach((item) => {
    dates.push(item.date);
    labels.push([item.weekday, item.ordinal]);
    status.push(item.status);
    spendData.push(item.thisPeriod.spend);
    earnedData.push(item.thisPeriod.earned);
    consumptionData.push(item.thisPeriod.consumption);
    generationData.push(item.thisPeriod.generation);
  });

  return {
    labels,
    dates,
    yAxisText: Y_AXIS_DISPLAY_TYPE[displayType],
    chartTitle: "Daily sold & bought",
    datasets: [
      {
        label: "Sold",
        data: displayType === "spend" ? earnedData : generationData,
        status,
        barColour: BRAND_COLOURS.green,
        hoverBackgroundColour: BRAND_COLOURS.greenDark,
      },
      {
        label: "Bought",
        data: displayType === "spend" ? spendData : consumptionData,
        status,
        barColour: BRAND_COLOURS.yellow,
        hoverBackgroundColour: BRAND_COLOURS.yellowDark,
      },
    ],
    showZoom: true,
  };
};
