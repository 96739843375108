import {
  clearPreviousNavTab,
  currentPathIsIndex,
  getNavTabFromPath,
  setNavTabInSession,
} from "components/BackLink/BackLink.utils";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router";

export const useBackNavigation = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // tracks if the page has been newly loaded/refreshed
  const pageRefreshedFlag: MutableRefObject<boolean> = useRef(true);

  // previous navigation tab from session
  const previousNavTab: MutableRefObject<string | null> = useRef(
    window.sessionStorage.getItem("previousNavTab"),
  );

  // current navigation tab from session
  const navTabOnLoad: MutableRefObject<string | null> = useRef(
    window.sessionStorage.getItem("currentNavTab"),
  );

  /**
   * Render the back button if:
   * - the current path is not a main index route
   * - the previous path was sent on location state of a clicked link (from another tab)
   * - the previous navigation tab is set in session and page has just been refreshed
   */
  const shouldRenderBackButton = useMemo(() => {
    return (
      !currentPathIsIndex(pathname) ||
      !!state?.previousPath ||
      (previousNavTab.current !== null && pageRefreshedFlag.current)
    );
  }, [pathname, state]);

  useEffect(() => {
    const currentNavTab = getNavTabFromPath(pathname);
    // track the current navigation tab for when page is refreshed.
    if (currentPathIsIndex(pathname)) {
      setNavTabInSession("currentNavTab", currentNavTab);
    }

    /**
     * flag that the page has completed an initial load/refresh
     */
    if (pageRefreshedFlag.current) {
      pageRefreshedFlag.current = false;
      return;
    }

    // route has changed since load so we can forget the navTabOnLoad and resume normal back nav (navigate(-1))
    if (navTabOnLoad.current) {
      navTabOnLoad.current = null;
    }

    // when returning from one nav tab to the previous via back button, clear the previous tab
    if (currentNavTab === previousNavTab.current) {
      clearPreviousNavTab(previousNavTab);
    }
  }, [pathname]);

  /**
   * if a page sends a previous path in location state,
   * use this to track whether the back button should return to a previous navigation tab
   */
  useEffect(() => {
    // a main navigation tab was clicked
    if (state?.previousPath === null) {
      clearPreviousNavTab(previousNavTab);
      return;
    }
    // a link that goes to a new navigation tab was clicked
    if (state?.previousPath) {
      previousNavTab.current = getNavTabFromPath(state?.previousPath);
      setNavTabInSession(
        "previousNavTab",
        getNavTabFromPath(previousNavTab.current),
      );
    }
  }, [state]);

  /**
   * if app was refreshed / reloaded, go back to the root of the current
   * or previous navigation tab (usually current, but previous in cases where links go to different tab)
   * else go back 1 step
   */
  const handleBackClicked = (e) => {
    e.preventDefault();
    /**
     * in native app browser does not do window.location replace for windcave,
     * which cause `?success=` route is add ontop of invoice route
     * so when user click back button, we need to override navigation tree
     */
    if (searchParams?.get("success")) {
      const currentTab = window.sessionStorage.getItem("currentNavTab");
      const prevTab = window.sessionStorage.getItem("previousNavTab");
      navigate(`/${prevTab ?? currentTab}`);
    }

    /**
     * When loading a page from the authentication redirect_url eg when linking to app from an email,
     * we have no back history, so back navigation goes to root of the current tab
     */
    if (state?.from === "auth_redirect_url") {
      navigate(`/${getNavTabFromPath(pathname)}`);
      return;
    }

    if (!navTabOnLoad.current) {
      navigate(-1);
      return;
    }
    const backPath = previousNavTab.current || navTabOnLoad.current;
    navigate(`/${backPath}`);
  };

  return {
    handleBackClicked,
    shouldRenderBackButton,
  };
};
