import "./BillSmootherSettingsPage.scss";

import { BillSmootherExampleSetup } from "components/BillSmootherExampleSetup";
import { BillSmootherFAQs } from "components/BillSmootherFAQs";
import { BillSmootherSetupSteps } from "components/BillSmootherSetupSteps";
import React from "react";

import { BillSmootherSettingsForm } from "./compositions/BillSmootherSettingsForm/BillSmootherSettingsForm";
import content from "./static/content.json";

/**
 * @description The bill smoother settings page.
 */
export const BillSmootherSettingsPage: React.FC = () => {
  const { billSmootherSettingsPageHeading, billSmootherSettingsPageContent } =
    content;

  return (
    <div className="page-section">
      <section className="page-section__column page-section__column--left">
        <h1 className="h2 page__heading">{billSmootherSettingsPageHeading}</h1>
        <p>{billSmootherSettingsPageContent}</p>
        <BillSmootherSettingsForm />
      </section>
      <section className="page-section__column page-section__column--right">
        <details className="accordion bill-smoother-accordion">
          <summary className="accordion__summary">How it works</summary>
          <BillSmootherSetupSteps />
        </details>
        <div className="spacer--large" />
        <details className="accordion bill-smoother-accordion">
          <summary className="accordion__summary">
            Example Bill Smoother setups
          </summary>
          <BillSmootherExampleSetup />
        </details>
        <div className="spacer--large" />
        <BillSmootherFAQs />
      </section>
    </div>
  );
};
