import "./TabPanel.scss";

import React, { PropsWithChildren, useMemo } from "react";

import { useTabContext } from "./TabContext";

type TabProps = {
  label: string;
  value: string;
};

const Tab: React.FC<TabProps> = ({ label, value }) => {
  const { activeTab, handleChange } = useTabContext();
  return (
    <button
      onClick={() => handleChange(value)}
      className={`tab ${activeTab === value ? "tab--active" : ""}`}
    >
      {label}
    </button>
  );
};

//@TODO create a new component call LargeTab to handle Solar/Usage case

const TabList: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="tab-list">{children}</div>;
};

interface TabPanelProps extends PropsWithChildren {
  value: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, children }) => {
  const { activeTab } = useTabContext();
  const memoizedContent = useMemo(() => children, [children]);

  return (
    <div
      className={`tab-panel ${activeTab === value ? "tab-panel--active" : "tab-panel--inactive"}`}
    >
      {memoizedContent}
    </div>
  );
};

export { Tab, TabList, TabPanel };
