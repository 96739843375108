import { ReactComponent as SubtractIconSvg } from "assets/icon-subtract.svg";
import { BarChartWrapper } from "components/charts/BarChartWrapper";
import { withErrorBoundary } from "components/fallback/withErrorBoundary";
import { LoadingSection } from "components/LoadingSection";
import { addHours } from "date-fns/addHours";
import { format } from "date-fns/format";
import { formatRelative } from "date-fns/formatRelative";
import { isBefore } from "date-fns/isBefore";
import { subHours } from "date-fns/subHours";
import { subMinutes } from "date-fns/subMinutes";
import { TotalWithUnit } from "elements/TotalWithUnit";
import { expireTime } from "queries/queries.utils";
import { useGetForecastedPricesQuery } from "queries/ratingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { forecastPriceLegend } from "utils/charts";
import { jwtClient } from "utils/jwtClient";
import { prepareForecastedPriceData } from "utils/prepareForecastedPriceData";

/**
 *  @description WholeSalePriceSection component
 *  display Wholesale details in HomePage
 */
export const WholeSalePriceSection: React.FC = withErrorBoundary(() => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  /*
   * setSeconds method is required to set the seconds for a specified date to local time.
   * This prevents the api being called whenever the seconds changes.
   * startOfPeriod date-time is 12 hours prior to the current local date-time.
   * endOfPeriod date-time is 3 hours ahead of the current local date-time,
   * due to this being the longest period of time that we have data for and know we can reliably render.
   */
  const startOfPeriod = subHours(new Date(), 12).setSeconds(0, 0);
  const endOfPeriod = addHours(new Date(), 3).setMinutes(0, 0, 0);

  const {
    data: todaysForecastPrices,
    isLoading: istodaysForecastPricesLoading,
  } = useGetForecastedPricesQuery(
    {
      supplyNodeRef,
      startAt: startOfPeriod,
      endAt: endOfPeriod,
      jwtClient,
    },
    {
      skip:
        !supplyNodeRef ||
        !startOfPeriod ||
        !endOfPeriod ||
        // query start date must not before user's start date else it will invalid request and expire token
        isBefore(startOfPeriod, new Date(billingEntityData?.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const upcomingForecastPeriodStart = subMinutes(new Date(), 30);
  const upcomingForecastPeriodEnd = addHours(upcomingForecastPeriodStart, 3);

  const { priceData: todaysForecastData, maxYValue: todaysPricesMaxYValue } =
    prepareForecastedPriceData(todaysForecastPrices);

  const upcomingForecastPrices = todaysForecastPrices?.filter(
    (prices) =>
      prices.start_at >= upcomingForecastPeriodStart.toISOString() &&
      prices.start_at <= upcomingForecastPeriodEnd.toISOString(),
  );

  const {
    priceData: upcomingForecastData,
    maxYValue: upcomingPricesMaxYValue,
  } = prepareForecastedPriceData(upcomingForecastPrices);

  if (istodaysForecastPricesLoading) return <LoadingSection />;

  const todaysPricesBarChartConfig = {
    labels: todaysForecastData?.map((data) =>
      format(data.time, "h:mma").toLocaleLowerCase(),
    ),
    yAxisText: "c/kWh",
    legendOptions: {
      display: true,
      labels: forecastPriceLegend,
    },
    datasets: [
      {
        barColours: todaysForecastData?.map((data) => data.barColour),
        data: todaysForecastData?.map((data) =>
          !data.price || data.status === "missing" ? 0 : data.price,
        ),
        status: todaysForecastData?.map((data) => data.status),
      },
    ],
    showZoom: true,
  };

  const upcomingForecastDataPriceLabels = upcomingForecastData?.map(
    (data) => `${Math.round(data.price)} c`,
  );

  const upcomingForecastDataTimeLabels = upcomingForecastData?.map((data) =>
    data?.time ? format(data.time, "h:mma").toLocaleLowerCase() : "",
  );

  const upcomingPeriodsBarChartConfig: BarChartConfig = {
    labels: upcomingForecastDataPriceLabels,
    legendOptions: {
      display: false,
    },
    datasets: [
      {
        barColours: upcomingForecastData?.map((data) => data.barColour),
        data: upcomingForecastData?.map((data) => data.price || 0),
        status: upcomingForecastData?.map((data) => data.status),
        barPercentage: 0.4,
      },
    ],
    scales: {
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          font: {
            weight: "bold",
            size: 14,
          },
        },
      },
      x2: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
          font: {
            weight: [400],
            size: 9,
          },
          padding: 0,
          callback: (value) => upcomingForecastDataTimeLabels[value],
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          align: "center",
        },
      },
    },
  };

  return (
    <>
      {!todaysForecastPrices && (
        <>
          <div
            data-testid="forecastPricesNotificationBanner"
            className="notification_banner notification_notice icon"
          >
            <p className="heading">
              Wholesale price estimate is currently unavailable
            </p>
          </div>
        </>
      )}
      <h2 className="h5">Live price</h2>
      <p className="text__colour--muted">
        As at{" "}
        {format(upcomingForecastData[0].time, "h:mma").toLocaleLowerCase()}{" "}
        today
      </p>
      <TotalWithUnit
        icon={<SubtractIconSvg />}
        total={`${Number(upcomingForecastData[0].price).toFixed(2)}`}
        unitCode="c/kWh"
      />
      <h2 className="h5">Wholesale price estimate</h2>
      <p className="small text__colour--muted">Cents per kWh</p>
      <span className="spacer"></span>
      <BarChartWrapper
        maxYValue={upcomingPricesMaxYValue}
        chartConfig={upcomingPeriodsBarChartConfig}
        classNames="chart__container--medium"
      />

      <h2 className="h5">Wholesale prices</h2>
      <p className="small text__colour--muted">
        From {formatRelative(startOfPeriod, new Date()).toLocaleLowerCase()} to{" "}
        {formatRelative(endOfPeriod, new Date()).toLocaleLowerCase()}
      </p>
      <BarChartWrapper
        maxYValue={todaysPricesMaxYValue}
        chartConfig={todaysPricesBarChartConfig}
      />
    </>
  );
});
