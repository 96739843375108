import "./BillPage.scss";

import { Button, Card, CardBody, CardHeader } from "@flick/fep-library";
import { InlineBanner } from "components/InlineBanner";
import { format } from "date-fns/format";
import { ErrorPage } from "pages/ErrorPage";
import { LoadingPage } from "pages/LoadingPage";
import {
  billingApi,
  useGetInvoiceQuery,
  useLazyPayOverdueBillQuery,
} from "queries/billingApi";
import { useGetContractForInvoiceQuery } from "queries/contractManagerApi";
import { expireTime } from "queries/queries.utils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  AVAILABLE_PERIODS,
  INVOICED_PAYMENT_STATUSES,
  NotificationBannerNames,
  NotificationBannerTypes,
  PRODUCT_DISPLAY_NAMES,
  PRODUCT_NAMES,
} from "utils/constants";
import { NZD } from "utils/currency";
import { friendlyPaymentMethod } from "utils/friendlyPaymentMethod";
import { visitLink } from "utils/htmlLinks/visitLink";
import { jwtClient, rollbar } from "utils/jwtClient";
import { getLineItemGroups } from "utils/lineItems/lineItems";
import { normaliseProductName } from "utils/productNameUtils";

import { WholesalePriceSectionWrapper } from "./components/WholesalePriceSectionWrapper";
import { BillBreakdownSection } from "./compositions/BillBreakdownSection";
import { BillPDFSection } from "./compositions/BillPDFSection";
import { BillUsageSection } from "./compositions/BillUsageSection";
import { OtherCostsSection } from "./compositions/OtherCostsSection";

const friendlyPaymentStatusDetails = (
  bill: Bill,
): { verb: string; type: string } => {
  const defaultStatusDetails = { verb: "Due", type: "tag--warning" };
  if (!bill) return defaultStatusDetails;
  const paymentDatePassed = new Date() > new Date(bill.payment_due_date);

  if (bill.payment_status === INVOICED_PAYMENT_STATUSES.complete) {
    return { verb: "Paid", type: "tag--success" };
  }

  if (
    bill.payment_status === INVOICED_PAYMENT_STATUSES.pastDue &&
    paymentDatePassed
  ) {
    return { verb: "Overdue", type: "tag--error" };
  }

  return defaultStatusDetails;
};

/**
 * Render a banner allowing 'pay now' of overdue bill
 * or informing user of the results of 'pay now' attempt
 */
const renderInlineBanner = ({
  handlePayNow,
  isOverdue,
  windcaveNavigatedBack,
  windcaveSuccess,
}): JSX.Element => {
  if (windcaveSuccess === "true") {
    return <InlineBanner status="success" />;
  }

  if (windcaveNavigatedBack || windcaveSuccess === "false") {
    return (
      <InlineBanner
        status={"failedPayment"}
        onHandlePayNowClick={handlePayNow}
      />
    );
  }

  if (isOverdue) {
    return <InlineBanner status="notice" onHandlePayNowClick={handlePayNow} />;
  }

  return null;
};

export const BillPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { invoiceNumber } = useParams();
  const [searchParams] = useSearchParams();
  const [windcaveNavigatedBack, setWindcaveNavigatedBack] = useState(false);
  const { customerNumber, nativeRenderMode, supplyNodeRef, active } =
    useAppSelector((store) => store.currentAccount);
  const windcaveSuccess = searchParams.get("success") ?? undefined;
  const [triggerOverdueBillQuery] = useLazyPayOverdueBillQuery();

  useEffect(() => {
    if (window.sessionStorage.getItem("windcaveVisited")) {
      if (!windcaveSuccess) {
        setWindcaveNavigatedBack(true);
      }
      window.sessionStorage.removeItem("windcaveVisited");
    }
  }, [window.sessionStorage.getItem("windcaveVisited")]);

  /**
   * This checks that if invoiceNumber is passed "null"  - a string,
   * (so it is not filtered out by the filteredQueryParams function)
   * then it will instead navigate back to bills and show an error due to not having an invoice number
   */
  if (invoiceNumber === "null") {
    navigate("/bills");
    dispatch(
      setNotificationBanner({
        type: NotificationBannerTypes.error,
        name: NotificationBannerNames.billInvoice,
        page: "/bills",
      }),
    );
  }

  const {
    data: billData,
    isError: isBillError,
    isLoading: isBillLoading,
    error: billError,
    refetch: refetchBillData,
  } = useGetInvoiceQuery(
    { invoiceNumber, jwtClient },
    {
      skip: !invoiceNumber || isNaN(Number(invoiceNumber)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  useEffect(() => {
    if (!windcaveSuccess) return;
    /**
     * if bill is paid successfully,
     * in mobile we need to force refetch bill status due to page is not remount
     * we bust the caching of outstanding invoices and refetch it
     * this is for outstanding invoices display of homepage
     * we do not to do this in webapp because Windcave redirect trigger whole app remount
     * by using window.location
     */
    if (nativeRenderMode) {
      refetchBillData();
      dispatch(billingApi.util.invalidateTags(["OutstandingInvoices"]));
    }
  }, [windcaveSuccess]);

  const bill = billData?.bill;
  const invoiceStart = bill
    ? new Date(bill.billing_period.period_started_at)
    : undefined;

  const invoiceEnd = bill
    ? new Date(bill.billing_period.period_ended_at)
    : undefined;

  const {
    data: contractData,
    isLoading: isContractLoading,
    isError: isContractError,
    error: contractError,
  } = useGetContractForInvoiceQuery(
    {
      jwtClient,
      asAtDate: invoiceEnd,
      customerNumber,
      supplyNodeRef,
    },
    {
      skip: !billData || !supplyNodeRef,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (isBillLoading || isContractLoading) return <LoadingPage />;

  /**
   * In RTK Query, the error returned from query or mutation hooks is typed as unknown.
   * This is because the error being returned can be in various formats depending on the API or the network request
   * Hence we checking the error type here before passing through
   */
  if (
    !billData ||
    ((isContractError || isBillError) && (contractError || billError))
  ) {
    return (
      <ErrorPage
        error={
          isBillError && billError
            ? (billError as Error)
            : contractError
              ? (contractError as Error)
              : new Error(
                  `Cannot find detail information for invoice #${invoiceNumber}`,
                )
        }
      />
    );
  }

  const lineItemGroups = getLineItemGroups(billData);

  const normalisedProductName = normaliseProductName(contractData?.name);
  const paymentDueDate = bill?.payment_due_date
    ? new Date(bill.payment_due_date)
    : undefined;

  const invoiceSpansNewYear =
    invoiceStart && invoiceEnd
      ? invoiceStart.getFullYear() !== invoiceEnd.getFullYear()
      : false;
  const invoiceStartFormatted = invoiceStart
    ? format(
        invoiceStart,
        invoiceSpansNewYear ? "EEEE d MMMM yyyy" : "EEEE d MMMM",
      )
    : undefined;
  const invoiceEndFormatted = invoiceEnd
    ? format(invoiceEnd, "EEEE d MMMM yyyy")
    : undefined;

  const productIsWholesale = normalisedProductName === PRODUCT_NAMES.wholesale;
  const paymentStatusDetails = friendlyPaymentStatusDetails(bill);

  const handlePayNow = async () => {
    let response;
    let windCaveUrl: string;
    try {
      response = await triggerOverdueBillQuery({
        invoiceID: billData.id,
        jwtClient,
        invoiceNumber,
      });
      /** @TODO handle case of no data returned, likely will need to be some form of notification not an error thrown.
       * Requires higher level planning when we get to Bill page rebuild
       */
      if (response?.data) {
        windCaveUrl = response.data.dps_uri;
      }
    } catch (error) {
      rollbar.error("Cannot generate windCave url for paying overdue bill", {
        invoiceNumber,
        invoiceID: billData.id,
        customerNumber,
        error: error.message,
      });
    }

    /**
     * in mobile, we create a hidden tempt link to open the url outside of the app
     * we also passing the set windcave visited item into local storage
     */
    if (windCaveUrl && nativeRenderMode) {
      visitLink({
        url: windCaveUrl,
        extraCallBack: () => {
          window.sessionStorage.setItem("windcaveVisited", windCaveUrl);
        },
      });
      return;
    }

    /**
     * in desktop we replace the windcave URL directly to the current session
     * and making sure windcaveVisited is only set when the page is about to unload
     * this prevents the flash of failed payment
     * which triggers setWindcaveNavigatedBack that is used in renderInlineBanner
     */
    if (windCaveUrl) {
      const setWindcaveVisited = () => {
        window.sessionStorage.setItem("windcaveVisited", windCaveUrl);
        // cleanup
        window.removeEventListener("beforeunload", setWindcaveVisited);
      };
      window.addEventListener("beforeunload", setWindcaveVisited);
      window.location.href = windCaveUrl;
    }
  };

  const isOverdue = paymentStatusDetails.verb === "Overdue";

  return (
    <div className="bill_period_page">
      <section className="page-section">
        <section className="page-section__column page-section__column--even">
          <Card>
            <CardHeader
              headingRightContent={
                <p
                  data-testid="billSmootherTag"
                  className={`tag ${paymentStatusDetails.type}`}
                >
                  {paymentStatusDetails.verb}
                </p>
              }
            >
              <h2 className="h4">
                <p className="h4">Invoice #{bill?.number} </p>
              </h2>
            </CardHeader>
            <CardBody>
              {renderInlineBanner({
                handlePayNow,
                isOverdue,
                windcaveNavigatedBack,
                windcaveSuccess,
              })}
              {invoiceStartFormatted && invoiceEndFormatted && (
                <p>
                  {invoiceStartFormatted} – {invoiceEndFormatted}
                </p>
              )}

              <h1 className="bill_total_due">
                {NZD.format(parseFloat(bill?.total_payment_due.price))}
              </h1>

              <p className="text__secondary">
                {paymentStatusDetails.verb}{" "}
                {paymentDueDate && format(paymentDueDate, "EEEE d MMMM")}{" "}
                {friendlyPaymentMethod(bill?.payment_method)}
              </p>
            </CardBody>
          </Card>
          <OtherCostsSection
            lineItemGroups={lineItemGroups}
            productName={normalisedProductName}
          />
        </section>
        {active && (
          <section className="page-section__column page-section__column--even">
            {bill && <BillUsageSection bill={bill} />}
            <div className="spacer--large" />
            {productIsWholesale && bill && (
              <WholesalePriceSectionWrapper
                bill={bill}
                lineItemGroups={lineItemGroups}
              />
            )}
            <Button
              extraClasses="button--expanded"
              onClick={() => {
                navigate(`/usage?invoice=${bill?.number}&tab=usage`);
              }}
            >
              View usage details
            </Button>
          </section>
        )}
      </section>
      <section className="page-section">
        <section className="page-section__column page-section__column--even">
          <BillBreakdownSection
            lineItemGroups={lineItemGroups}
            bill={bill}
            productName={normalisedProductName}
          />

          {billData?.id && (
            <BillPDFSection
              invoiceUUID={billData.id}
              invoiceNumber={bill?.number}
            />
          )}
        </section>
      </section>
    </div>
  );
};
