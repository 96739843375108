import "../Subheading/Subheading.scss";

import React from "react";

type SubheadingProps = {
  title: string;
  icon?: JSX.Element;
};

export const Subheading: React.FC<SubheadingProps> = ({ title, icon }) => {
  if (!icon) return <p className="h5">{title}</p>;

  return (
    <div className="subheading--with-icon" data-testid="subheadingWithIcon">
      {icon}
      <p className="h5">{title}</p>
    </div>
  );
};
