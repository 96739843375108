/**
 * returns a new date value from string or undefined
 * this to prevent date converter to return default value 1970-01-01 or "Invalid Date"
 * both those returns bypass (!) check
 * @param value: string | undefined
 * @returns new converted Date from string value | undefined
 */
export const safeDate = (value: string | undefined): Date | undefined => {
  return !value || !value.replace(/\s/g, "").length
    ? undefined
    : new Date(value);
};
