import "./GetTheFlickApp.scss";

import { ReactComponent as AppStoreBadge } from "app/assets/images/app_store_badge.svg";
import { ReactComponent as GooglePlayBadge } from "app/assets/images/google_play_badge.svg";
import imageUsageIphone from "assets/image_usage_iphone.png";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";

import content from "./static/content.json";
const { cta, imageAlt } = content;

export const GetTheFlickApp: React.FC = ({}) => {
  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  if (!nativeRenderMode) {
    return (
      <>
        <div className="app_cta background--yellow">
          <div className="app_cta__title">
            <h2 className="heading h4">{cta}</h2>
          </div>
          <div className="app_cta__app_store_badge">
            <a href="https://apps.apple.com/nz/app/flick-electric-co/id1081680985">
              <AppStoreBadge />
            </a>
          </div>
          <div className="app_cta__google_play_badge">
            <a href="https://play.google.com/store/apps/details?id=nz.co.flickelectric.androidapp&hl=en&gl=US">
              <GooglePlayBadge />
            </a>
          </div>
          <div className="app_cta__flick_app">
            <img src={imageUsageIphone} alt={imageAlt} />
          </div>
        </div>
      </>
    );
  }
};
