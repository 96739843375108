import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxUtils/store";
import { findCurrentRateCard } from "utils/rateCardHelpers/findCurrentRateCard";

/**
 * select the ratebased on the contract name,
 * this is to cover when previous invoices is different from current contract user is on
 */
export const selectRateCard = createSelector(
  (_, currentProductName) => currentProductName,
  (state: RootState) => state.rateCards.rateCards,

  (currentProductName, rateCards) => {
    if (rateCards.length === 0) return undefined;

    return findCurrentRateCard(rateCards, currentProductName, new Date());
  },
);

/**
 * select the current rates based on the current contract and time
 */
export const selectCurrentRateCard = createSelector(
  (state: RootState) => state.currentProduct,
  (state: RootState) => state.rateCards.rateCards,
  (currentProduct, rateCards) => {
    if (rateCards.length === 0 || !currentProduct.normalisedName)
      return undefined;

    return findCurrentRateCard(
      rateCards,
      currentProduct.normalisedName,
      new Date(),
    );
  },
);

/**
 * see if User having any export channel (aka Solar User)
 * based on current rates
 */
export const isUserHavingExportChannel = createSelector(
  selectCurrentRateCard,
  (currentRateCard) => {
    if (!currentRateCard) return undefined;

    const exportChannel = currentRateCard.attributes.usage_rates.find((rate) =>
      rate.channels.some((channel) => channel.content_code === "EG"),
    );

    return Boolean(exportChannel);
  },
);
