import "elements/DeltaMessage/DeltaMessage.scss";

import { ReactComponent as DownArrowSvg } from "app/assets/images/icons/circle-arrow-down.svg";
import { ReactComponent as RightArrowSvg } from "app/assets/images/icons/circle-arrow-right.svg";
import { ReactComponent as UpArrowSvg } from "app/assets/images/icons/circle-arrow-up.svg";
import React, { memo } from "react";

const DELTA_THRESHOLD = 5;

type DeltaMessageProps = {
  delta?: number;
  formatter?: (value: number) => string;
  periodLabel?: string;
};

/* @TODO Still currently used in the UsageSection and WholesaleUsageSection components on the BillsPage. This entire file should be deleted and the new PeriodComparisonMessage component (renders the new DeltaMessage element) should be used instead, once the new BillsPage work is underway.
 */

export const renderDeltaMessage = ({
  delta,
  formatter,
  periodLabel,
}: DeltaMessageProps) => {
  return (
    <DeltaMessageLegacy
      delta={delta}
      periodLabel={periodLabel}
      formatter={formatter}
    />
  );
};

export const DeltaMessageLegacy: React.FC<DeltaMessageProps> = memo(
  ({
    delta,
    formatter = (v) => `${v}%`,
    periodLabel = "the previous period",
  }) => {
    if (delta === null || delta === undefined) return null;

    const roundedDelta = Math.round(Math.abs(delta));

    const calculateComparisonData = () => {
      let ArrowIcon,
        deltaText = null,
        iconColour = `icon_colour`;

      if (roundedDelta <= DELTA_THRESHOLD) {
        ArrowIcon = RightArrowSvg;
        deltaText = `about the same as ${periodLabel}`;
        iconColour = `icon_colour--secondary`;
      } else {
        ArrowIcon = delta < 0 ? DownArrowSvg : UpArrowSvg;
        deltaText =
          delta < 0
            ? `${formatter(roundedDelta)} less than ${periodLabel}`
            : `${formatter(roundedDelta)} more than ${periodLabel}`;
        iconColour = delta < 0 ? `icon_colour--green` : `icon_colour--crimson`;
      }

      return { ArrowIcon, deltaText, iconColour };
    };
    const { ArrowIcon, deltaText, iconColour } = calculateComparisonData();

    if (!ArrowIcon && !deltaText) return null;

    return (
      <>
        <ArrowIcon data-testid="arrow-icon" className={`arrow ${iconColour}`} />
        <p className="text text__secondary">{deltaText}</p>
      </>
    );
  },
);
