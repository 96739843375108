/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeltaMessage } from "elements/DeltaMessage";
import React, { memo } from "react";
import { DisplayUnit } from "utils/constants";
import { NZD } from "utils/currency";

export const PeriodComparisonMessage: React.FC<{
  displayUnit: DisplayUnit;
  currentPeriod?: number;
  lastPeriod?: number;
  periodLabel?: string;
}> = memo(
  ({
    displayUnit = DisplayUnit.number,
    currentPeriod = undefined,
    lastPeriod = undefined,
    periodLabel = "the previous period",
  }) => {
    if (!currentPeriod || !lastPeriod) return <></>;

    const difference = currentPeriod - lastPeriod;

    if (displayUnit === DisplayUnit.dollar) {
      return (
        <DeltaMessage
          delta={difference}
          formatter={(v) => NZD.format(v)}
          periodLabel={periodLabel}
        />
      );
    }

    if (displayUnit === DisplayUnit.kWh) {
      return (
        <DeltaMessage
          delta={difference}
          formatter={(v) => `${v} kWh`}
          periodLabel={periodLabel}
        />
      );
    }

    if (displayUnit === DisplayUnit.percentage) {
      const percentageDifference = (difference / Math.abs(lastPeriod)) * 100;

      return (
        <DeltaMessage
          delta={percentageDifference}
          formatter={(v) => `${v}%`}
          periodLabel={periodLabel}
        />
      );
    }

    return <DeltaMessage delta={difference} periodLabel={periodLabel} />;
  },
);
