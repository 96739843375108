import "../LargeTabPanel/LargeTabPanel.scss";

import { ReactComponent as BoltIcon } from "app/assets/images/icons/bolt-pointy-solid.svg";
import { ReactComponent as SolarIcon } from "app/assets/images/icons/sun-outline.svg";
import React, { PropsWithChildren, useMemo } from "react";

import { useLargeTabContext } from "./LargeTabProvider";

type TabProps = {
  label: string;
  value: string;
};

const LargeTab: React.FC<TabProps> = ({ label, value }) => {
  const { activeTab, handleChange } = useLargeTabContext();
  return (
    <button
      className={`large-tab ${activeTab === value ? "large-tab--active" : ""}`}
      onClick={() => handleChange(value)}
    >
      {label === "Solar" ? (
        <SolarIcon className="large-tab__label-icon" />
      ) : (
        <BoltIcon className="large-tab__label-icon" />
      )}
      {label}
    </button>
  );
};

const LargeTabList: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="large-tab-list">{children}</div>;
};

interface TabPanelProps extends PropsWithChildren {
  value: string;
}

const LargeTabPanel: React.FC<TabPanelProps> = ({ value, children }) => {
  const { activeTab } = useLargeTabContext();
  const memoizedContent = useMemo(() => children, [children]);

  return (
    <div
      className={`large-tab-panel ${activeTab === value ? "large-tab-panel--active" : "tab-panel--inactive"}`}
    >
      {memoizedContent}
    </div>
  );
};

export { LargeTab, LargeTabList, LargeTabPanel };
