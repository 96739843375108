import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxUtils/store";

//-------------------Helper selectors to extract Bill Smoother config from state----------------

export const selectBillSmootherConfig = (state: RootState) =>
  state.billSmootherConfig.billSmootherConfig;

//-------------------Selectors to check if user is enrolled in Bill Smoother----------------
export const selectIsEnrolledInBillSmoother = createSelector(
  selectBillSmootherConfig,
  (billSmootherConfig) => {
    return billSmootherConfig ? billSmootherConfig.enrolled : false;
  },
);
