import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  SecondaryButton,
} from "@flick/fep-library";
import { BasicInput } from "components/Inputs/BasicInput";
import { CheckBoxInput } from "components/Inputs/CheckBoxInput";
import { BillSmootherStatusTagBuilder } from "pages/BillSmootherSettingsPage/components/BillSmootherStatusTagBuilder";
import { PauseBillSmootherDialog } from "pages/BillSmootherSettingsPage/components/PauseBillSmootherDialog";
import { StopBillSmootherDialog } from "pages/BillSmootherSettingsPage/components/StopBillSmootherDialog";
import { changeBillSmootherStatus } from "pages/BillSmootherSettingsPage/compositions/BillSmootherSettingsForm/BillSmootherSettingsForm.utils";
import {
  useCreateBillSmootherConfigMutation,
  useUpdateBillSmootherConfigMutation,
} from "queries/paymentSmootherApi";
import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "reduxUtils/hook";
import { selectBillSmootherConfig } from "selectors/billSmootherSelector";
import { BILL_SMOOTHER_STATE } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

import content from "./static/content.json";

export const BillSmootherSettingsForm: React.FC = () => {
  const navigate = useNavigate();
  const { icpNumber } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const billSmootherConfigState = useAppSelector(selectBillSmootherConfig);

  const [billSmootherData, setBillSmootherData] = useState(
    billSmootherConfigState,
  );
  const [bannerText, setBannerText] = useState(null);
  const [hidePauseBillSmootherDialog, setHidePauseBillSmootherDialog] =
    useState(true);
  const [hideStopBillSmootherDialog, setHideStopBillSmootherDialog] =
    useState(true);
  const [matchThresholdAndWithdrawal, setMatchThresholdAndWithdrawal] =
    useState(false);

  const [createBillSmootherConfig] = useCreateBillSmootherConfigMutation();
  const [updateBillSmootherConfig] = useUpdateBillSmootherConfigMutation();

  useMemo(() => {
    if (billSmootherConfigState) setBillSmootherData(billSmootherConfigState);
  }, [billSmootherConfigState]);

  const billSmootherSetUpWithDifferentValues = useMemo(() => {
    return !billSmootherData
      ? false
      : billSmootherData?.contribution_threshold !==
          billSmootherData?.withdrawal_trigger;
  }, [
    billSmootherData?.contribution_threshold,
    billSmootherData?.withdrawal_trigger,
  ]);

  const handleFieldChange = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;
    if (matchThresholdAndWithdrawal || !billSmootherSetUpWithDifferentValues) {
      setBillSmootherData({
        ...billSmootherData,
        contribution_threshold: value,
        withdrawal_trigger: value,
      });
      return;
    }

    setBillSmootherData({
      ...billSmootherData,
      [id]: value,
    });
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target as HTMLInputElement;
    setMatchThresholdAndWithdrawal(checked);

    if (!checked && !billSmootherData) return;

    setBillSmootherData({
      ...billSmootherData,
      withdrawal_trigger: billSmootherData.contribution_threshold,
    });
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    newBannerText: string,
  ) => {
    event.preventDefault();

    // active and enrolled are both false for customers that were once enrolled
    // but have stopped using Bill Smoother. We strip them out for subsequent re-enrollments
    // because otherwise their false values stop successfully reenabling this feature.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { active = false, enrolled = false, ...createData } = data;

    const updatedBillSmootherConfig = billSmootherData?.enrolled
      ? await updateBillSmootherConfig({
          jwtClient,
          billingEntityId: billingEntityData?.id,
          data: data as BillSmootherConfig,
        })
      : await createBillSmootherConfig({
          jwtClient,
          billingEntityId: billingEntityData?.id,
          data: createData,
        });

    // if update bill smoother fails, reset to current data stored in redux store
    // @TODO need to handle it more elegate
    if (updatedBillSmootherConfig.error) {
      setBannerText("cannot update");
      setBillSmootherData(billSmootherConfigState);
      return;
    }

    sendAnalyticsEvent("bill_smoother", {
      icpNumber,
      event_category: "Bill Smoother",
      event_action: newBannerText,
      minimum_payment: updatedBillSmootherConfig.data.contribution_threshold,
      bill_threshold: updatedBillSmootherConfig.data.withdrawal_trigger,
    });
    setBannerText(newBannerText);
    setHidePauseBillSmootherDialog(true);
    setHideStopBillSmootherDialog(true);
    window.scrollTo(0, 0);
  };

  const {
    billSmootherSettingsFormCardHeading,
    billSmootherSettingsFormCardContent,
    contributionThresholdInputLabel,
    withdrawalTriggerInputLabel,
    sameContributionAndWithdrawlAmountCheckboxLabel,
    stopBillSmootherFormSectionHeading,
    stopBillSmootherFormSectionContent,
    stopBillSmootherFormSectionButtonText,
  } = content;

  return (
    <>
      {bannerText && (
        <>
          <div className="spacer--large" />
          <div
            id="notification_dismissable"
            className={`notification_banner notification_${bannerText === "cannot update" ? "error" : "success"} icon`}
            data-testid="billSmootherSettingsBanner"
          >
            <p className="heading">Bill Smoother {bannerText}!</p>
          </div>
          <div className="spacer" />
        </>
      )}
      <div className="customer_tools_section__item--borderless customer_tools_section__item_container">
        <BillSmootherStatusTagBuilder
          enrolled={billSmootherData?.enrolled}
          active={billSmootherData?.active}
        />
        {billSmootherData?.enrolled && (
          <>
            <div id="page_content">
              <SecondaryButton
                onClick={(event) =>
                  billSmootherData.active
                    ? setHidePauseBillSmootherDialog(false)
                    : handleClick(
                        event,
                        { active: !billSmootherData.active },
                        !billSmootherData.active
                          ? BILL_SMOOTHER_STATE.activated
                          : BILL_SMOOTHER_STATE.paused,
                      )
                }
              >
                {changeBillSmootherStatus(
                  billSmootherData.enrolled,
                  billSmootherData.active,
                )}
              </SecondaryButton>
            </div>
            {!hidePauseBillSmootherDialog && (
              <PauseBillSmootherDialog
                handleDialogClose={() => setHidePauseBillSmootherDialog(true)}
                hidePauseBillSmootherDialog={hidePauseBillSmootherDialog}
                handleClick={handleClick}
                billSmootherData={billSmootherData}
              />
            )}
          </>
        )}
      </div>
      <div className="spacer--medium" />
      <Card>
        <CardHeader>
          <h2 className="h4">{billSmootherSettingsFormCardHeading}</h2>
        </CardHeader>
        <CardBody>
          <p>{billSmootherSettingsFormCardContent}</p>
          <Form
            data-validate
            data-synthetic-submit
            onSubmit={(event) =>
              handleClick(
                event,
                billSmootherData,
                billSmootherData?.enrolled
                  ? BILL_SMOOTHER_STATE.updated
                  : BILL_SMOOTHER_STATE.activated,
              )
            }
          >
            <BasicInput
              dataTestId="contributionThresholdInput"
              inputMode="numeric"
              inputWidth="full"
              label={contributionThresholdInputLabel}
              name="contribution_threshold"
              required
              handleInputChange={handleFieldChange}
              type="number"
              value={billSmootherData?.contribution_threshold || ""}
            />
            {/* @TODO: Remove the below code block: checkbox and withdrawal trigger input in the future*/}
            {billSmootherSetUpWithDifferentValues && (
              <>
                <CheckBoxInput
                  isChecked={matchThresholdAndWithdrawal}
                  label={sameContributionAndWithdrawlAmountCheckboxLabel}
                  name="same_minimum_payment_and_trigger_amount"
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCheckbox(e)
                  }
                />
                <BasicInput
                  dataTestId="withdrawalTriggerInput"
                  label={withdrawalTriggerInputLabel}
                  inputWidth="full"
                  name="withdrawal_trigger"
                  required
                  handleInputChange={handleFieldChange}
                  type="number"
                  value={billSmootherData?.withdrawal_trigger || ""}
                />
              </>
            )}
            <div className="spacer--large" />
            <Button extraClasses="button--expanded spacer" type="submit">
              {billSmootherData?.enrolled
                ? "Update settings"
                : "Activate Bill Smoother"}
            </Button>
            <Button
              buttonColor="outline"
              extraClasses="button--expanded"
              onClick={() => navigate("/bills")}
            >
              Cancel
            </Button>
            <div className="spacer--large" />
          </Form>
          {billSmootherData?.enrolled && (
            <>
              <hr className="content_divider" />
              <section>
                <h3 className="h4">{stopBillSmootherFormSectionHeading}</h3>
                <div className="spacer" />
                <p>{stopBillSmootherFormSectionContent}</p>
                <div className="spacer" />
                <div id="page_content">
                  <Button
                    buttonColor="danger"
                    extraClasses="button--expanded"
                    onClick={() => setHideStopBillSmootherDialog(false)}
                  >
                    {stopBillSmootherFormSectionButtonText}
                  </Button>
                </div>
                {!hideStopBillSmootherDialog && (
                  <StopBillSmootherDialog
                    handleDialogClose={() =>
                      setHideStopBillSmootherDialog(true)
                    }
                    hideStopBillSmootherDialog={hideStopBillSmootherDialog}
                    handleClick={handleClick}
                  />
                )}
              </section>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};
