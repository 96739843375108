import React from "react";

import content from "./static/content.json";
const {
  directDebitHelperText,
  multiplePeopleMethodDownloadLink,
  multiplePeopleMethodDownloadText,
  multiplePeopleMethodInfoSubText,
  multiplePeopleMethodInfoText,
} = content;

export const MultiplePersonDirectDebit: React.FC = () => {
  return (
    <div className="payment-method-container">
      <div
        data-testid="forecastPricesNotificationBanner"
        className="notification_banner notification_notice icon"
      >
        <p className="heading">{multiplePeopleMethodInfoText}</p>
        <a
          className="spacer spacer--small"
          target="_blank"
          rel="noopener noreferrer"
          href={multiplePeopleMethodDownloadLink}
        >
          {multiplePeopleMethodDownloadText}
        </a>
      </div>
      <p>{directDebitHelperText}</p>
      <p>{multiplePeopleMethodInfoSubText}</p>
    </div>
  );
};
