import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxUtils/store";
import { PRODUCT_NAMES } from "utils/constants";

export const selectCurrentProduct = (state: RootState) => state.currentProduct;

export const selectProductIsOffPeak = createSelector(
  selectCurrentProduct,
  (currentProduct) => {
    return currentProduct && currentProduct.normalisedName
      ? currentProduct.normalisedName === PRODUCT_NAMES.offPeak
      : false;
  },
);

export const selectProductIsOffWholesale = createSelector(
  selectCurrentProduct,
  (currentProduct) => {
    return currentProduct && currentProduct.normalisedName
      ? currentProduct.normalisedName === PRODUCT_NAMES.wholesale
      : false;
  },
);
