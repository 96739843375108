import { LoadingSection } from "components/LoadingSection";
import { isBefore } from "date-fns/isBefore";
import { HourlyKwhCostChart } from "pages/UsagePage/components/HourlyKwhCostChart";
import { expireTime } from "queries/queries.utils";
import { useGetWholesalePricesQuery } from "queries/ratingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";

import { prepareHourlyKwhCostData } from "./HourlyKwhCostSection.utils";
import content from "./static/content.json";

const { wholesalePriceErrorText } = content;

type HourlyKwhCostSectionProps = {
  endAt: Date;
  startAt: Date;
  supplyNodeRef: string;
};

export const HourlyKwhCostSection: React.FC<HourlyKwhCostSectionProps> = ({
  endAt,
  startAt,
  supplyNodeRef,
}) => {
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const {
    data: wholesalePrices,
    isFetching: isWholesalePriceFetching,
    isError: isWholesalePriceError,
  } = useGetWholesalePricesQuery(
    {
      supplyNodeRef,
      startAt,
      endAt,
      jwtClient,
    },

    {
      skip:
        !supplyNodeRef ||
        // query start date must not before user's start date else it will invalid request and expire token
        isBefore(startAt, billingEntityData.start_at),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (isWholesalePriceFetching) return <LoadingSection />;

  if (isWholesalePriceError)
    return (
      <div className="notification_banner notification_notice icon">
        <p>{wholesalePriceErrorText}</p>
      </div>
    );
  const { maxYValue, dailyKwhValues } =
    prepareHourlyKwhCostData(wholesalePrices);

  return (
    <HourlyKwhCostChart
      selectedDayMaxYValue={maxYValue}
      dailyKwhValues={dailyKwhValues}
    />
  );
};
