import { Card, CardBody, CardHeader } from "@flick/fep-library";
import {
  ErrorBoundaryHOCProps,
  withErrorBoundary,
} from "components/fallback/withErrorBoundary";
import { AccountBalance } from "pages/HomePage/components/AccountBalance";
import { OutstandingBillsList } from "pages/HomePage/components/OutstandingBills";
import { useGetBillingBalanceQuery } from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";

export const YourAccountBalance: React.FC<ErrorBoundaryHOCProps> =
  withErrorBoundary(() => {
    const { customerNumber } = useAppSelector((store) => store.currentAccount);

    const {
      data: accountBalance,
      isFetching: isAccountBalanceFetching,
      isError: isAccountBalanceError,
    } = useGetBillingBalanceQuery(
      { customerNumber, jwtClient },
      {
        skip: !customerNumber,
        refetchOnMountOrArgChange: expireTime,
      },
    );
    return (
      <Card>
        <CardHeader>
          <h2 className="h4">Your account balance</h2>
        </CardHeader>
        <CardBody>
          <AccountBalance
            accountBalance={accountBalance}
            isAccountBalanceError={isAccountBalanceError}
            isAccountBalanceFetching={isAccountBalanceFetching}
          />
          <div className="spacer--small" />
          <OutstandingBillsList />
        </CardBody>
      </Card>
    );
  });
