import { BasicInput } from "components/Inputs/BasicInput";
import {
  AvailableInputModes,
  AvailableInputTypes,
} from "components/Inputs/BasicInput/BasicInput.types";
import React, { ChangeEvent } from "react";

type TextInputProps = {
  ariaLabel?: string;
  disabled?: boolean;
  errorMessage?: string;
  handleOnBlur?: () => void;
  hint?: string;
  inputMode?: AvailableInputModes;
  inputSize?: "default" | "large";
  inputWidth?: "default" | "full";
  label: string;
  name: string;
  placeholderText?: string;
  required?: boolean;
  setValue?: (value: string) => void;
  type?: AvailableInputTypes;
  value: string;
};

export const TextInput: React.FC<TextInputProps> = ({
  ariaLabel,
  disabled,
  errorMessage,
  handleOnBlur,
  hint,
  inputMode = "text",
  inputSize,
  inputWidth,
  label,
  name,
  placeholderText,
  required,
  setValue,
  type = "text",
  value,
}) => {
  const handleInputChange = (event: ChangeEvent) => {
    const { value: valueInput } = event.target as HTMLInputElement;
    setValue(valueInput);
  };
  return (
    <BasicInput
      ariaLabel={ariaLabel}
      errorMsg={errorMessage}
      handleInputChange={handleInputChange}
      handleOnBlur={handleOnBlur}
      hint={hint}
      inputMode={inputMode}
      inputSize={inputSize}
      inputWidth={inputWidth}
      label={label}
      name={name}
      placeholderText={placeholderText}
      required={required}
      state={disabled ? "disabled" : errorMessage ? "error" : "default"}
      type={type}
      value={value}
    />
  );
};
