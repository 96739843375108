import "./MovingHousePage.scss";

import { CheckBoxInput } from "components/Inputs/CheckBoxInput";
import React from "react";
import ReactMarkdown from "react-markdown";

import { useMovingHouseFormContext } from "./MovingHouseContext";
import content from "./static/content.json";
const { termsAndConditionsLabel } = content;

export const MovingHouseTermsConditions: React.FC = () => {
  const {
    termsAndConditions: {
      fieldValue: { value, error },
      handleOnChange: setTermsAndConditions,
    },
  } = useMovingHouseFormContext();

  const handleTermsAndConditionsChange = () => {
    setTermsAndConditions();
  };

  return (
    <CheckBoxInput
      errorMessage={error}
      label={
        <ReactMarkdown components={{ p: "span" }}>
          {termsAndConditionsLabel}
        </ReactMarkdown>
      }
      handleOnChange={handleTermsAndConditionsChange}
      isChecked={value}
      name="termsAndConditions"
    />
  );
};
