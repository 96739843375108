import { Button } from "@flick/fep-library";
import { BillSmootherExampleSetup } from "components/BillSmootherExampleSetup";
import { BillSmootherFAQs } from "components/BillSmootherFAQs";
import { BillSmootherSetupSteps } from "components/BillSmootherSetupSteps";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

import content from "./static/content.json";

export const BillSmootherInformationPage: React.FC = () => {
  const navigate = useNavigate();

  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const canUseBillSmoother = billingEntityData?.id && supplyNodeRef;

  const {
    billSmootherInformationPageHeading,
    billSmootherInformationPageBodyContentText,
    setupBillSmootherButtonText,
  } = content;

  return (
    <>
      <div className="page-section">
        <section className="page-section__column page-section__column--left">
          <h2 className="page__heading">
            {billSmootherInformationPageHeading}
          </h2>

          <ReactMarkdown className="spacer">
            {billSmootherInformationPageBodyContentText}
          </ReactMarkdown>
          {canUseBillSmoother && (
            <Button
              extraClasses="spacer"
              onClick={() => navigate("/bills/bill-smoother/settings")}
            >
              {setupBillSmootherButtonText}
            </Button>
          )}
        </section>
        <section className="page-section__column page-section__column--right">
          <BillSmootherSetupSteps />
          <div className="spacer--large" />
          <BillSmootherExampleSetup />
          <div className="spacer--large" />
          <BillSmootherFAQs />
        </section>
      </div>
    </>
  );
};
