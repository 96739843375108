import React from "react";

type NoAvailableDataProps = {
  startDate?: string;
};

/**
 * @description Fallback for when (AggregatedUsageSection | UsagePage) could not fetch data
 */
export const NoAvailableData: React.FC<NoAvailableDataProps> = () => (
  <div className="page-section">
    <section className="page-section__column page-section__column--single">
      <h1 className="h2 page__heading">No usage data yet!</h1>
      <p>
        Your daily usage information can take a few days to appear here. Please
        check back soon!
      </p>
    </section>
  </div>
);

export const NoAvailableRates: React.FC<NoAvailableDataProps> = () => (
  <div className="page-section">
    <section className="page-section__column page-section__column--single">
      <h1 className="h2 page__heading">No usage data!</h1>
      <p>
        We can't show any current usage data as we couldn't access information
        about your price details.
      </p>
    </section>
  </div>
);

export const NoInvoiceUsageData: React.FC<NoAvailableDataProps> = () => (
  <div>
    <h2 className="section-title heading h4">No usage data!</h2>
    <p>We can't show any usage data as we couldn't access this information.</p>
  </div>
);
