import { PRODUCT_DISPLAY_NAMES } from "utils/constants";

/**
 * @description finds the current plan from an array of available plans
 * @param availablePlans
 * @param currentPlanId
 * @returns AvailablePlan | null - An AvailablePlan object or undefined
 */
export const getCurrentPlan = (
  availablePlans: Array<AvailablePlan>,
  currentPlanId: string,
): AvailablePlan | undefined => {
  if (
    !availablePlans ||
    !Array.isArray(availablePlans) ||
    availablePlans.length === 0
  )
    return undefined;
  return availablePlans.find(
    (availablePlan) => availablePlan.id === currentPlanId,
  );
};

/**
 * @description filters the array of AvailablePlans to return only plans that are not the current plan and not a Wholesale plan or Business plan
 * @param availablePlans
 * @param currentPlanId
 * @returns AvailablePlan[] | [] - An array of AvailablePlan objects or an empty array
 */

export const getOtherPlans = (
  availablePlans: Array<AvailablePlan>,
  currentPlanId: string,
): Array<AvailablePlan> | [] => {
  if (
    !availablePlans ||
    !Array.isArray(availablePlans) ||
    availablePlans.length === 0
  )
    return [];
  return availablePlans.filter(
    (availablePlan) =>
      availablePlan.id !== currentPlanId &&
      availablePlan.productName.toLowerCase() !==
        PRODUCT_DISPLAY_NAMES.freestyle &&
      availablePlan.productName.toLowerCase() !==
        PRODUCT_DISPLAY_NAMES.business,
  );
};
