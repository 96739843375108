import classnames from "classnames";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

type PageProps = {
  children: JSX.Element | JSX.Element[];
  isAuthenticated: boolean;
};

export const Page: React.FC<PageProps> = ({ children, isAuthenticated }) => {
  const location = useLocation();
  const announcementBanner: AnnouncementBanner = useAppSelector(
    (store) => store.announcementBanner,
  );

  // todo after re-skin when (if?) all pages are grey, we can remove this and put class='backround--grey' on the body tag in index.html
  useEffect(() => {
    const { body } = document;
    if (!isAuthenticated) return;

    if (location.pathname.includes("/account")) {
      body.classList.remove("background--grey");
      body.classList.add("background--white");
    } else {
      body.classList.remove("background--white");
      body.classList.add("background--grey");
    }
  }, [location]);

  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );

  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  const isDesktopModeBannerEnabled =
    !nativeRenderMode && announcementBanner?.enableDesktopMode; // is desktop mode, banner for desktop enabled
  const isNativeModeBannerEnabled =
    nativeRenderMode && announcementBanner?.enableNativeMode; // is native mode, banner for native enabled

  const hasAnnouncementBanner =
    announcementBanner &&
    !announcementBanner.hideBanner &&
    (isDesktopModeBannerEnabled || isNativeModeBannerEnabled);

  const hasNotificationBanner = notificationBanner !== null;

  return (
    <div
      className={classnames("page", {
        "page--authenticated": isAuthenticated,
        "page--unauthenticated": !isAuthenticated,
        "page--has_notification_banner":
          hasAnnouncementBanner || hasNotificationBanner,
        "page--max-width": isAuthenticated,
      })}
    >
      {children}
    </div>
  );
};
