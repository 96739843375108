import { Button, SecondaryButton } from "@flick/fep-library";
import { Dialog } from "components/Dialog";
import React from "react";
import ReactMarkdown from "react-markdown";
import { BILL_SMOOTHER_STATE } from "utils/constants";

import content from "./static/content.json";

type StopBillSmootherDialogProps = {
  handleClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      createData?: BillSmootherConfig;
    },
    newBannerText: string,
  ) => void;
  handleDialogClose: () => void;
  hideStopBillSmootherDialog: boolean;
};

/**
 * @description Used in BillSmootherSettingsPage
 */
export const StopBillSmootherDialog: React.FC<StopBillSmootherDialogProps> = ({
  hideStopBillSmootherDialog,
  handleDialogClose,
  handleClick,
}) => {
  const {
    stopBillSmootherDialogHeading,
    stopBillSmootherDialogHeadingSubtext,
    stopBillSmootherDialogContentOneText,
    stopBillSmootherDialogContentOneListItems,
    stopBillSmootherDialogContentTwoText,
    stopBillSmootherDialogContentThreeText,
    confirmStopBillSmootherDialogButtonText,
    continueWithBillSmootherDialogButtonText,
  } = content;
  return (
    <Dialog
      handleDialogClose={handleDialogClose}
      dialogIsHidden={hideStopBillSmootherDialog}
    >
      <h1 id="dialog-title" className="dialog_heading">
        {stopBillSmootherDialogHeading}
      </h1>
      <p>{stopBillSmootherDialogHeadingSubtext}</p>
      <p>{stopBillSmootherDialogContentOneText}</p>
      <ul className="unordered-list">
        {stopBillSmootherDialogContentOneListItems.map((listItem, index) => (
          <li key={index}>{listItem}</li>
        ))}
      </ul>
      <ReactMarkdown>{stopBillSmootherDialogContentTwoText}</ReactMarkdown>
      <p>{stopBillSmootherDialogContentThreeText}</p>
      <div className="dialog_contents__buttons spacer--medium">
        <Button
          buttonColor="danger"
          onClick={(event) =>
            handleClick(
              event,
              { active: false, enrolled: false },
              BILL_SMOOTHER_STATE.deactivated,
            )
          }
        >
          {confirmStopBillSmootherDialogButtonText}
        </Button>

        <div className="spacer--small" />
        <SecondaryButton onClick={handleDialogClose}>
          {continueWithBillSmootherDialogButtonText}
        </SecondaryButton>
      </div>
    </Dialog>
  );
};
