import { StarburstIcon } from "@flick/fep-library";
import { CardColors, PromoCard } from "components/PromoCard";
import React, { useMemo } from "react";
import { FRIEND_GET_FRIEND_CREDIT_AMOUNT } from "utils/constants";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import content from "./static/content.json";

const {
  fgfCreditAmount,
  fgfHeaderText: cardHeaderText,
  fgfCardBody: cardBody,
  fgfButtonText: buttonText,
} = content;

type FriendGetFriendCardProps = {
  cardColor?: CardColors;
};

export const FriendGetFriendCard: React.FC<FriendGetFriendCardProps> = ({
  cardColor = CardColors.pink,
}) => {
  const promoAmount = useMemo(() => {
    return replaceContentParams(fgfCreditAmount || "", {
      creditAmount: FRIEND_GET_FRIEND_CREDIT_AMOUNT,
    });
  }, []);

  const cardBodyText = useMemo(() => {
    return replaceContentParams(cardBody || "", {
      creditAmount: FRIEND_GET_FRIEND_CREDIT_AMOUNT,
    });
  }, []);

  return (
    <PromoCard
      bodyRightContent={
        <StarburstIcon textColor={cardColor}>
          <p className="aria-hidden starburst__icon-text">
            {promoAmount}{" "}
            <span className="starburst__icon-sub-text">credit</span>
          </p>
        </StarburstIcon>
      }
      bodyText={cardBodyText}
      buttonLink="/account/friend-get-friend"
      buttonText={buttonText}
      cardColor={cardColor}
      headerText={cardHeaderText}
      previousPath={window.location.pathname}
    />
  );
};
