import React from 'react';

const IcpIsBusiness = () => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">
      Sorry, we&apos;ve taken a pause on supplying new business properties as we look to evolve our
      electricity plan for business owners.
    </h4>
    <p>
      If this address is for a residential property and you want to sign-up, please contact our
      customer experience team on 0800 435 425 to help you out.
    </p>
  </div>
);

export default IcpIsBusiness;
