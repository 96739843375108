import { decodeState } from "auth/generateCodeChallenge";
import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router";
import { useAppDispatch } from "reduxUtils/hook";
import { LOGOUT_ACTION } from "utils/constants";
import { jwtClient, rollbar } from "utils/jwtClient";

type AuthCallbackProps = {
  setIsAuthenticated: (value: boolean) => void;
};

export const AuthCallback: React.FC<AuthCallbackProps> = ({
  setIsAuthenticated,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // hitting the `auth/callback` route with no url params is a post log out redirect
  if (searchParams.size === 0) {
    jwtClient.setJwt(null);
    dispatch({ type: LOGOUT_ACTION });
    return <Navigate to="/welcome" />;
  }

  let stateCodeFromURL: string;
  let code: string;
  let codeVerifier: string;
  let initialCode: string;
  let decryptedStateCode: string;
  let decryptedState: { from: string; stateCode: string };
  try {
    stateCodeFromURL = searchParams.get("state");
    code = searchParams.get("code");
    codeVerifier = window.sessionStorage.getItem("code_verifier");
    initialCode = window.localStorage.getItem("state_code");
    decryptedState = decodeState(stateCodeFromURL);
    decryptedStateCode = decryptedState?.stateCode;
  } catch (error) {
    /** If an error occurs, it is likely because the login is not a genuine login,
     * so it is not a true error and how we expect the verification process to work.
     * Therefore, we log this as info to monitor instead of letting it throw an error. */
    rollbar.info("Redirect URI state code verification failed", {
      urlCode: code,
      codeVerifier,
      urlStateCode: stateCodeFromURL,
      initialStateCode: initialCode,
      errorMessage: error.message,
    });
  }

  useEffect(() => {
    const getTokenWithAuthorizationCode = async () => {
      await jwtClient.verifyStateCode(initialCode, decryptedStateCode);
      await jwtClient.getTokenWithAuthorizationCode(code, codeVerifier);
      setIsAuthenticated(jwtClient.isAuthenticated());
    };
    if (!jwtClient.isAuthenticated()) {
      getTokenWithAuthorizationCode();
    }
  }, []);

  return <Navigate to={decryptedState?.from ?? "/"} />;
};
