import { format } from "date-fns/format";
import { BRAND_COLOURS } from "utils/constants";

/**
 * @description Create an array of months in the MMM format.
 * Starts by creating an empty array of length 12 and uses the index to represent each month.
 */
export const createMonthLabels = (): string[] => {
  return Array.from(Array(12), (_, monthIndex) =>
    format(new Date(0, monthIndex, 1), "MMM"),
  );
};

/**
 * @description Create an array of costs (strings)
 * The chart always assigns the first array value to the month of January and so on.
 * If the first value available for a given year is for a different month, eg March, prepend the
 * array with empty entries, eg 2 empty entries for Jan and Feb.
 */
export const createCostsArray = (yearData: CostYearDataSet[]) => {
  if (yearData.length == 0) return [];
  const result = [...yearData];

  const firstMonth = yearData[0].month;
  let numberMonthsToPrepend = firstMonth - 1;

  while (numberMonthsToPrepend > 0) {
    result.unshift({ total_cost: "0", month: numberMonthsToPrepend });
    numberMonthsToPrepend--;
  }

  return result?.map((data) => data.total_cost);
};

/**
 * @description Generate the configuration for the grouped bar chart
 */
export const generateGroupedBarChartConfig = ({
  lastYearData,
  thisYearData,
  lastYear,
  thisYear,
}): GroupedBarChartConfig => ({
  labels: createMonthLabels(),
  yAxisText: "$",
  chartTitle: "Costs",
  datasets: [
    {
      label: lastYear,
      data: createCostsArray(lastYearData),
      status: "pending",
      barColour: BRAND_COLOURS.greyShade10,
    },
    {
      label: thisYear,
      data: createCostsArray(thisYearData),
      status: "pending",
      barColour: BRAND_COLOURS.green,
    },
  ],
  showZoom: false,
});
