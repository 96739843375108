import { createSelector } from "@reduxjs/toolkit";
import { billingApi } from "queries/billingApi";
import { contractManagerApi } from "queries/contractManagerApi";
import { customerApi } from "queries/customerApi";
import { identityApi } from "queries/identityApi";
import { paymentSmootherApi } from "queries/paymentSmootherApi";
import { pricingRateCardApi } from "queries/pricingRateCardApi";
import { provisioningApi } from "queries/provisioningApi";
import { ratingApi } from "queries/ratingApi";
import { telemetryApi } from "queries/telemetryApi";
import { RootState } from "reduxUtils/store";

const apiSlices = [
  provisioningApi,
  contractManagerApi,
  customerApi,
  paymentSmootherApi,
  telemetryApi,
  pricingRateCardApi,
  identityApi,
  ratingApi,
  billingApi,
];

/**
 * @description: check if any queries is still in process
 */
export const selectIsAnyFetching = createSelector(
  (state: RootState) => state,
  (state) =>
    apiSlices.some((api) =>
      Object.values(api.endpoints).some(
        (endpoint) => endpoint.select()(state).isFetching,
      ),
    ),
);
