import "./OutstandingBillListItem.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import { LoadingSection } from "components/LoadingSection";
import { format } from "date-fns/format";
import { useGetInvoiceQuery } from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React from "react";
import { Link } from "react-router";
import { NZD } from "utils/currency";
import { invoicePaymentStatus } from "utils/invoicePaymentStatus";
import { jwtClient } from "utils/jwtClient";

type OutstandingBillListItemProps = {
  invoice: OutstandingInvoiceSummary;
};

export const OutstandingBillListItem: React.FC<
  OutstandingBillListItemProps
> = ({ invoice }) => {
  const { number: invoiceNumber, due_at, total, balance } = invoice.attributes;
  const {
    data: billData,
    isError: isBillError,
    isFetching: isBillFetching,
  } = useGetInvoiceQuery(
    { invoiceNumber, jwtClient },
    {
      skip: !invoiceNumber || isNaN(Number(invoiceNumber)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const bill = billData?.bill;
  const paymentStatusDetails = invoicePaymentStatus(invoice);

  if (isBillFetching) {
    return <LoadingSection />;
  }

  return (
    <li className="customer_tools_list__item" key={`invoice-${invoiceNumber}`}>
      <Link
        to={`/bills/${invoiceNumber}`}
        state={{ previousPath: window.location.pathname }}
        className="customer_tools_list__link outstanding_bill_item__link"
      >
        <div
          className={`customer_tools_list__left_text customer_tools_list__left_text--${paymentStatusDetails.type}`}
        >
          {bill && (
            <span>
              {format(
                new Date(bill.billing_period?.period_started_at),
                "d MMMM",
              )}{" "}
              -{" "}
              {format(new Date(bill.billing_period?.period_ended_at), "d MMMM")}{" "}
            </span>
          )}

          {isBillError && `Invoice Number: ${invoiceNumber}`}
          <br />
          <small className="text__colour--muted">
            {paymentStatusDetails.verb} {format(new Date(due_at), "EEE d MMM")}{" "}
          </small>
        </div>

        <div className="customer_tools_list__right_aligned_text_icon_content">
          <div className="customer_tools_list__right_aligned_text_icon_content--text">
            <p>{NZD.format(parseFloat(balance))}</p>

            {balance !== total && (
              <small className="text__colour--muted">Remaining</small>
            )}
          </div>

          <ForwardIcon />
        </div>
      </Link>
    </li>
  );
};
