import { ErrorBoundary, LEVEL_WARN } from "@rollbar/react";
import React from "react";

import { ComponentItemFallback } from "./ComponentItemFallback";

export type ErrorBoundaryHOCProps = {
  componentTitle: string;
};

/**
 * @param WrappedComponent - react component that throw errors
 * @returns a new HOC component that logs any caught error to RollBar and a default fallback UI that can change component display name
 */
const withErrorBoundary = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P & ErrorBoundaryHOCProps> => {
  return function ErrorBoundaryHOC(props: P & ErrorBoundaryHOCProps) {
    const { componentTitle, ...restProps } = props;

    return (
      <ErrorBoundary
        // @ts-ignore
        // known issue from rollbar https://github.com/rollbar/rollbar-react/issues/69
        fallbackUI={() =>
          ComponentItemFallback({
            componentTitle,
          })
        }
        level={LEVEL_WARN}
        errorMessage={`Error in React render : ${componentTitle}`}
        extra={(error, errorInfo) => ({
          componentStack: errorInfo.componentStack,
          errorDetais: JSON.stringify(error),
        })}
      >
        <WrappedComponent {...(restProps as P)} />
      </ErrorBoundary>
    );
  };
};

export { withErrorBoundary };
