import { PRODUCT_NAMES } from "./constants";

export const normaliseProductName = (productName: string): string =>
  productName?.toLowerCase().replace(/[\s\_]/, "");

export const getContractName = (contractName: string): string => {
  if (contractName?.toLocaleLowerCase().includes(PRODUCT_NAMES.flat))
    return PRODUCT_NAMES.flat;
  if (contractName?.toLocaleLowerCase().includes(PRODUCT_NAMES.offPeak))
    return PRODUCT_NAMES.offPeak;
  if (contractName?.toLocaleLowerCase().includes(PRODUCT_NAMES.business))
    return PRODUCT_NAMES.business;
  if (contractName?.toLocaleLowerCase().includes(PRODUCT_NAMES.wholesale))
    return PRODUCT_NAMES.wholesale;
};

export const capitaliseProductName = (productName?: string): string => {
  if (!productName) {
    return "";
  }
  return productName
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
