import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { selectOffpeakDetails } from "selectors/offpeakDetailsSelector";
import { calculateRemainingShiftTime } from "utils/offpeakCalculations/calculateRemainingShiftTime";

/**
 * Custom hook to calculate remaining shift time and poll every minute.
 * Listen for visibility change to pause polling when app is out of focus.
 * Poll immediately when app is back in focus and continue polling every minute.
 * When shift ends, force recomputation in the timetable selector to update the current timetable in the store using `shiftEndTrigger`.
 */
export const usePollRemainingShiftTime = () => {
  const [shiftEndTrigger, setShiftEndTrigger] = useState(Date.now());

  const [currentDate, setCurrentDate] = useState(new Date());

  const currentShiftDetails = useAppSelector((state) =>
    selectOffpeakDetails(state, new Date(shiftEndTrigger)),
  );

  const calculatedShiftTime = useMemo(
    () => calculateRemainingShiftTime(currentShiftDetails, currentDate),
    [currentShiftDetails, currentDate],
  );

  useEffect(() => {
    let interval = null;
    const startPolling = () => {
      interval = setInterval(() => {
        setCurrentDate(new Date());
      }, 60000);
    };

    const stopPolling = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopPolling(); // stop polling when app is out of focus
        return;
      } else {
        setCurrentDate(new Date()); // immediately poll when app is back in focus
        startPolling();
      }
    };

    startPolling();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      stopPolling();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Check if shift has ended and force recomputation in the timetable selector
  useEffect(() => {
    if (calculatedShiftTime?.timeRemainingPercentage <= 0) {
      setShiftEndTrigger(Date.now());
    }
  }, [calculatedShiftTime?.timeRemainingPercentage]);

  return { currentShiftDetails, calculatedShiftTime };
};
