import classnames from "classnames";

/**
 * @description Adjusts the position of the tooltip content
 * based on where space is available by changing classname applied
 * @defaults to bottom left first or if no other available space is found
 * Note: You cannot chose your preferred placement for an individual tooltip
 */

export const adjustPosition = ({
  tooltipContentRef,
  containerIconRef,
  setPlacement,
}) => {
  if (!tooltipContentRef.current || !window || !containerIconRef.current)
    return;

  const tooltipContentRect = tooltipContentRef.current.getBoundingClientRect();
  const containerIconRect = containerIconRef.current.getBoundingClientRect();
  const { innerHeight: viewportHeight, innerWidth: viewportWidth } = window;

  /**
   * @description Determines if there is enough space in the viewport for the tooltip in various positions
   */
  const hasRoomBelow =
    containerIconRect.bottom + tooltipContentRect.height < viewportHeight;
  const hasRoomAbove = containerIconRect.top - tooltipContentRect.height > 0;
  const hasRoomRight =
    containerIconRect.left + tooltipContentRect.width < viewportWidth;
  const hasRoomLeft = containerIconRect.right - tooltipContentRect.width > 0;

  /**
   * @description Determines the best placement for the tooltip based on available space
   * with a preference to be below and left over right and above
   * @defaults to bottom left
   */
  const newPlacement =
    classnames({
      "tooltip-responsive__content-container--bottom-left":
        hasRoomBelow && hasRoomLeft,
      "tooltip-responsive__content-container--bottom-right":
        hasRoomBelow && hasRoomRight && !hasRoomLeft,
      "tooltip-responsive__content-container--top-left":
        !hasRoomBelow && hasRoomAbove && hasRoomLeft,
      "tooltip-responsive__content-container--top-right":
        !hasRoomBelow && hasRoomAbove && hasRoomRight && !hasRoomLeft,
    }) || "tooltip-responsive__content-container--bottom-left";

  setPlacement(newPlacement);
};
