import { prepareUsageTotal } from "utils/usageCalculations/prepareUsageTotal";

export const getDataLength = (aggregatesData: Aggregates): number => {
  if (!aggregatesData || !Array.isArray(aggregatesData.aggregates)) {
    return 0;
  }
  return aggregatesData.aggregates.length;
};

export const getPeriodUsage = (aggregates: Aggregate[]): string =>
  prepareUsageTotal(aggregates);

/**
 * @description formatted aggregate data before display with default value is empty array
 */
export const formattedAggregateData = (
  isGetAggregateDataSuccess: boolean,
  aggregateData: Aggregates,
) => {
  return !isGetAggregateDataSuccess || !aggregateData
    ? []
    : aggregateData.aggregates;
};

type PeriodUsageTotalProps = {
  completedBill: boolean;
  dataLength: number;
  isCustomDatePeriod?: boolean;
  periodName?: string;
  thisPeriodUsage: string;
  unitCode: string;
};

/**
 * @description The correct heading with the usage total if it is available, or a default in cases when thisPeriodAggregateData does not exist
 */
export const periodUsageTotal = ({
  completedBill,
  dataLength,
  isCustomDatePeriod = false,
  periodName = "period",
  thisPeriodUsage,
  unitCode,
}: PeriodUsageTotalProps): string => {
  if (!Boolean(dataLength)) {
    return isCustomDatePeriod
      ? `Total usage for this period is currently not available`
      : `Total usage for this ${periodName} is currently not available`;
  }

  return isCustomDatePeriod
    ? `Used ${thisPeriodUsage} ${unitCode} of power`
    : `Used ${thisPeriodUsage} ${unitCode} of power${
        completedBill ? "" : ` so far this ${periodName}`
      }`;
};

// /**
//  * @description The correct heading with the usage total if it is available, or a default in cases when thisPeriodAggregateData does not exist
//  */
// export const currentPeriodUsageTotal = ({
//   dataLength,
//   thisPeriodUsage,
// }: {
//   dataLength: number;
//   thisPeriodUsage: string;
//   unitCode: string;
// }): string => {
//   // if (!Boolean(dataLength)) {
//   //   return `Total usage is currently not available`;
//   // }
//   return {
//     usageTotal: !Boolean(dataLength) ? undefined : Number(thisPeriodUsage)
//   }

//   return `${thisPeriodUsage};
// };
