/* eslint-disable jsx-a11y/anchor-has-content */
import "./CsvDownloader.scss";

import { BaseInput, Button, Form } from "@flick/fep-library";
import { format } from "date-fns/format";
import { isAfter } from "date-fns/isAfter";
import { isBefore } from "date-fns/isBefore";
import { useLazyGetUsageDataCSVQuery } from "queries/telemetryApi";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type CsvDownloaderProps = {
  billingEntityStartAt: Date | string;
  billingEntityEndAt: Date | string;
  currentPeriodEndDate?: Date | string;
  viewport: "desktop" | "mobile";
};

/**
 * @description CSV download component
 */
export const CsvDownloader: React.FC<CsvDownloaderProps> = ({
  viewport,
  billingEntityStartAt,
  billingEntityEndAt,
  currentPeriodEndDate,
}) => {
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  // tempt fix for error message, this whole component need to be refactored later on
  const [errorStartDate, setErrorStartDate] = useState(undefined);
  const [errorEndDate, setErrorEndDate] = useState(undefined);

  const dataAvailableEndAt = billingEntityEndAt
    ? new Date(billingEntityEndAt)
    : new Date(currentPeriodEndDate);

  const billingEntityStartDate = billingEntityStartAt
    ? new Date(billingEntityStartAt)
    : new Date();

  const [csvDates, setCsvDates] = useState({
    startAt: null,
    endAt: null,
  });

  useEffect(() => {
    setCsvDates({
      ...csvDates,
      endAt: billingEntityEndAt
        ? format(new Date(billingEntityEndAt), "yyyy-MM-dd")
        : null,
    });
  }, [billingEntityEndAt]);

  const [trigger, { isFetching: fetchingCsvData }] =
    useLazyGetUsageDataCSVQuery();

  const handleClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!csvDates.startAt || !csvDates.endAt) {
      if (!csvDates.startAt) setErrorStartDate("Start date is required.");
      if (!csvDates.endAt) setErrorEndDate("End date is required.");
      return;
    }
    if (!event.currentTarget.checkValidity()) return;

    sendAnalyticsEvent("download_usage_csv", {
      icpNumber,
      csv_start_date: csvDates.startAt,
      csv_end_date: csvDates.endAt,
    });
    const response = await trigger({
      jwtClient,
      icpNumber,
      startAt: csvDates.startAt,
      endAt: csvDates.endAt,
    });
    const blob = new Blob([response.data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    // To perform a download in JS we need to create a download link to interact with.
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("href", url);
    link.setAttribute("download", `${csvDates.startAt}_${csvDates.endAt}`);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleChange = (event: ChangeEvent) => {
    const { id, value } = event.target as HTMLInputElement;
    // clearing a date in the datepicker sets the value to an empty string which causes an error when rendering, we need to be manually reset it here
    const checkedValue = Boolean(value) ? value : null;

    if (!checkedValue) {
      setCsvDates({
        ...csvDates,
        [id]: checkedValue,
      });
      setErrorEndDate(undefined);
      setErrorStartDate(undefined);
      return;
    }

    const maxDate = new Date(format(dataAvailableEndAt, "yyyy-MM-dd"));
    const minDate = new Date(format(billingEntityStartDate, "yyyy-MM-dd"));

    if (id === "startAt" && isBefore(new Date(checkedValue), minDate)) {
      setErrorStartDate(
        `Start date cannot be earlier than ${format(
          billingEntityStartDate,
          "dd-MM-yyyy",
        )}`,
      );
      return;
    }

    if (id === "startAt" && isAfter(new Date(checkedValue), maxDate)) {
      setErrorStartDate(
        `Start date cannot be later than ${format(
          dataAvailableEndAt,
          "dd-MM-yyyy",
        )}`,
      );
      return;
    }

    if (id === "endAt" && isAfter(new Date(checkedValue), maxDate)) {
      setErrorEndDate(
        `End date cannot be later than ${format(
          dataAvailableEndAt,
          "dd-MM-yyyy",
        )}`,
      );
      return;
    }

    if (
      id === "endAt" &&
      isBefore(new Date(checkedValue), new Date(csvDates.startAt))
    ) {
      setErrorEndDate(`End date needs to be after the start date.`);
      return;
    }

    setCsvDates({
      ...csvDates,
      [id]: checkedValue,
    });

    setErrorEndDate(undefined);
    setErrorStartDate(undefined);
  };

  return (
    <section className={`csv-download--${viewport}`}>
      <p className="section-sub-title">
        Download your half-hour usage information
      </p>
      <Form data-validate data-synthetic-submit onSubmit={handleClick}>
        {/* including class `form-row` here so that the validation in flick_form.js works - todo: remove when the temp front-end-patterns js files are gone */}
        <div className="start-and-end-inputs spacer">
          {/* TODO move to use DateInput component */}
          <BaseInput
            labelText="Start date"
            name="startAt"
            placeholderText="Placeholder text goes here"
            type="date"
            max={format(dataAvailableEndAt, "yyyy-MM-dd")}
            min={format(billingEntityStartDate, "yyyy-MM-dd")}
            onChange={handleChange}
            errorText={errorStartDate}
          />
          {/* TODO move to use DateInput component */}
          <BaseInput
            labelText="End date"
            name="endAt"
            placeholderText="Placeholder text goes here"
            type="date"
            defaultValue={csvDates.endAt}
            max={format(dataAvailableEndAt, "yyyy-MM-dd")}
            min={format(new Date(csvDates.startAt), "yyyy-MM-dd")}
            onChange={handleChange}
            errorText={errorEndDate}
          />
        </div>
        <Button
          type="submit"
          extraClasses="button--expanded"
          disabled={fetchingCsvData}
        >
          Download
        </Button>
        <p className="small spacer">
          We have data available for you between{" "}
          {format(billingEntityStartDate, "dd/MM/yyyy")} and{" "}
          {format(dataAvailableEndAt, "dd/MM/yyyy")}.
        </p>
      </Form>
    </section>
  );
};
