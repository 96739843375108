import { ReactComponent as BillsIcon } from "app/assets/images/icons/dollar-solid.svg";
import { ReactComponent as FlickIcon } from "app/assets/images/icons/flick-logo-sm.svg";
import { ReactComponent as LogoutIcon } from "app/assets/images/icons/log-out.svg";
import { ReactComponent as AccountIcon } from "app/assets/images/icons/person-solid.svg";
import { ReactComponent as UsageIcon } from "app/assets/images/icons/usage-solid.svg";
import React from "react";
import { Link, useLocation } from "react-router";

type NavigationProps = {
  handleLogout: () => void;
};

export const Navigation: React.FC<NavigationProps> = ({ handleLogout }) => {
  const { pathname } = useLocation();

  return (
    <nav className="nav_customer_tools" aria-label="Primary">
      <ul className="nav_customer_tools__list page--max-width">
        <li
          className={`${
            pathname === "/" && "active"
          } nav_customer_tools__item nav_customer_tools__item--home`}
        >
          <Link
            to="/home"
            className="nav_customer_tools__item_link"
            state={{ previousPath: null }}
          >
            <FlickIcon className="nav_customer_tools__item_link_icon" />
            <p
              data-testid="navigation"
              className="nav_customer_tools__item_link_text"
            >
              Home
            </p>
          </Link>
        </li>
        <li
          className={`${
            pathname.includes("/bills") && "active"
          } nav_customer_tools__item nav_customer_tools__item--bills`}
        >
          <Link
            to="/bills"
            className="nav_customer_tools__item_link nav_customer_tools__item_link--content_light"
            state={{ previousPath: null }}
          >
            <BillsIcon className="nav_customer_tools__item_link_icon" />
            <p className="nav_customer_tools__item_link_text">Bills</p>
          </Link>
        </li>
        <li
          className={`${
            pathname.includes("/usage") && "active"
          } nav_customer_tools__item nav_customer_tools__item--usage`}
        >
          <Link
            to="/usage"
            className="nav_customer_tools__item_link"
            state={{ previousPath: null }}
          >
            <UsageIcon className="nav_customer_tools__item_link_icon" />
            <p className="nav_customer_tools__item_link_text">Usage</p>
          </Link>
        </li>
        <li
          className={`${
            pathname.includes("/account") && "active"
          } nav_customer_tools__item nav_customer_tools__item--account`}
        >
          <Link
            to="/account"
            className="nav_customer_tools__item_link nav_customer_tools__item_link--content_light"
            state={{ previousPath: null }}
          >
            <AccountIcon className="nav_customer_tools__item_link_icon" />
            <p className="nav_customer_tools__item_link_text">Account</p>
          </Link>
        </li>
        <li className="nav_customer_tools__item nav_customer_tools__item--logout">
          <div className="nav_customer_tools__item_link_container">
            <Link
              to="/"
              className="nav_customer_tools__item_link nav_customer_tools__item_link--content_light nav_customer_tools__item_link_text"
              onClick={handleLogout}
            >
              Log out
            </Link>
            <LogoutIcon />
          </div>
        </li>
      </ul>
    </nav>
  );
};
