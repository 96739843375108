/* eslint-disable @typescript-eslint/no-unused-vars */
import "./BillingPeriodView.scss";

import { DateRangeNavigator } from "pages/UsagePage/components/DateRangeNavigator/DateRangeNavigator";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import { useAppSelector } from "reduxUtils/hook";
import { selectCurrentUsageToInvoice } from "selectors/currentUsageToInvoiceSelector";

import { InvoiceProvider } from "../InvoiceProvider";
import { InvoiceTotal } from "../InvoiceTotal";
import { InvoiceUsageDetails } from "../InvoiceUsageDetails";

export const BillingPeriodView: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { customerNumber } = useAppSelector((store) => store.currentAccount);
  const { currentBillingPeriod, currentUsageData } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const { currentLatestInvoices } = useAppSelector(
    (store) => store.currentInvoices,
  );

  const fakeCurrentInvoice = useAppSelector(selectCurrentUsageToInvoice);

  // Initialize storedInvoices using selector values
  const [storedInvoices, setStoredInvoices] = useState([]);

  useEffect(() => {
    let updatedInvoices = [...currentLatestInvoices];

    // Ensure fakeCurrentInvoice is always the first if available
    if (fakeCurrentInvoice) {
      const existingIndex = updatedInvoices.findIndex(
        (invoice) => invoice.id === fakeCurrentInvoice.id,
      );

      if (existingIndex > -1) {
        // Replace the existing fake invoice if found
        updatedInvoices[existingIndex] = {
          ...fakeCurrentInvoice,
          attributes: {
            ...fakeCurrentInvoice.attributes,
            previous_invoice_id: currentLatestInvoices[0]?.id,
          },
        };
      } else {
        // Prepend the fake invoice if not found
        updatedInvoices = [
          {
            ...fakeCurrentInvoice,
            attributes: {
              ...fakeCurrentInvoice.attributes,
              previous_invoice_id: currentLatestInvoices[0]?.id,
            },
          },
          ...updatedInvoices,
        ];
      }
    }

    // Update storedInvoices only if the new array differs
    if (JSON.stringify(updatedInvoices) !== JSON.stringify(storedInvoices)) {
      setStoredInvoices(updatedInvoices);
    }
  }, [currentLatestInvoices, fakeCurrentInvoice, storedInvoices]);

  return (
    <>
      <div className="background--white">
        {storedInvoices.length > 0 && (
          <div className="billing-period-container">
            <div className="billing-period-container__label">
              <h2 className="h5">Billing period</h2>
            </div>
            <InvoiceProvider>
              <DateRangeNavigator invoices={storedInvoices} />
              <InvoiceTotal invoices={storedInvoices} />
              <span className="spacer spacer--medium" />
              <InvoiceUsageDetails
                invoices={storedInvoices}
                componentTitle="Breakdown detail"
              />
            </InvoiceProvider>
          </div>
        )}
      </div>
    </>
  );
};
