import "./MovingHousePage.scss";

import { Form } from "@flick/fep-library";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { SubmitButton } from "components/SubmitButton";
import { MovingHousePlan } from "pages/MovingHousePage/MovingHousePlan";
import { MovingHouseTermsConditions } from "pages/MovingHousePage/MovingHouseTermsConditions";
import { useMovingHouseMutation } from "queries/customerApi";
import React, { FormEvent, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { MovingHouseAddress } from "./MovingHouseAddress";
import { useMovingHouseFormContext } from "./MovingHouseContext";
import {
  MovingHouseMoveInDate,
  MovingHouseMoveOutDate,
} from "./MovingHouseDate";
import { MovingHouseMedicallyDependent } from "./MovingHouseMedicallyDependent";
import { MovingHouseMovingOut } from "./MovingHouseMovingOut";
import content from "./static/content.json";

const { buttonLabel, heading, importantInfoHint, importantInfoLabel } = content;

export const MovingHousePageContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountNumber } = useAppSelector((store) => store.currentAccount);
  const {
    isFormValid,
    isMovingOut,
    address: {
      fieldValue: { value: addressValue },
    },
    medicallyDependent: {
      fieldValue: { value: medicalDependencyValue },
    },
    moveDate: {
      fieldValue: { value: moveInDateValue },
    },
    moveOutDate: {
      fieldValue: { value: moveOutDateValue },
    },
    termsAndConditions: {
      fieldValue: { value: termsAcceptedValue },
    },
    userPlan: {
      fieldValue: { value: usageTypeValue },
    },
  } = useMovingHouseFormContext();

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [movingHouse] = useMovingHouseMutation();

  const handleMovingHouse = async ({
    address,
    icpNumber,
    moveInDate,
    moveOutDate,
    medicalDependency,
    termsAccepted,
    usageType,
  }) => {
    try {
      await movingHouse({
        accountNumber,
        address,
        icpNumber,
        moveInDate,
        moveOutDate,
        medicalDependency,
        termsAccepted,
        usageType,
        jwtClient,
      }).unwrap();

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.success,
          name: NotificationBannerNames.movingHouse,
        }),
      );
      navigate("/account/address");
    } catch (err) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.movingHouse,
          additionalContent: errorReasonDetails(err),
        }),
      );
      setSubmitDisabled(false); // reset submit button only on error, as page redirects on success
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitDisabled(true);
    // if address value is empty then skip submitting
    if (Object.keys(addressValue).length === 0) return;
    const { icpNumber, ...address } = addressValue;
    handleMovingHouse({
      address,
      icpNumber,
      moveInDate: moveInDateValue,
      medicalDependency: medicalDependencyValue.value === "true",
      termsAccepted: termsAcceptedValue,
      usageType: usageTypeValue.value,
      ...(isMovingOut && { moveOutDate: moveOutDateValue }),
    });
  };

  return (
    <AccountPageWrapper
      className="moving-house-page"
      headerLabel={heading}
      hasCancelButton={false}
    >
      <MovingHouseAddress />
      <Form onSubmit={handleSubmit}>
        <MovingHouseMoveInDate />
        <MovingHouseMovingOut />
        {isMovingOut && <MovingHouseMoveOutDate />}
        <MovingHouseMedicallyDependent />
        <MovingHousePlan />
        <div className="spacer spacer--medium">
          <label className="h5">{importantInfoLabel}</label>
          <ReactMarkdown>{importantInfoHint}</ReactMarkdown>
          <MovingHouseTermsConditions />
        </div>
        <SubmitButton
          isSubmitDisabled={!isFormValid || submitDisabled}
          label={buttonLabel}
        />
      </Form>
    </AccountPageWrapper>
  );
};
