import "./AnnouncementBanner.scss";

import classnames from "classnames";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router";
import { hideAnnouncementBanner } from "reducers/announcementBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import content from "./static/content.json";

/*
 * AnnouncementBanner is to be used for any announcements like incidents or info on campaigns
 * @TODO: There is work to be done to get this working with GTM again
 */

export const AnnouncementBanner: React.FC = () => {
  const announcementBanner: AnnouncementBanner = useAppSelector(
    (store) => store.announcementBanner,
  );
  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  if (!announcementBanner) return null;

  const { type, name, params, additionalContent, modifier } =
    announcementBanner;
  /*
   * A banner temporarily not works for Account Tab
   * @TODO: remove this block code when new design ready for announcement Banner and notification Banner to work together on the same page
   */
  useEffect(() => {
    if (pathname.includes("account")) {
      dispatch(hideAnnouncementBanner(true));
      return;
    }

    if (pathname.includes("account") && nativeRenderMode) {
      dispatch(hideAnnouncementBanner(true));
      return;
    }

    dispatch(hideAnnouncementBanner(false));
  }, [pathname]);

  const message = replaceContentParams(
    content[`${type}${name}Message`] || "",
    params,
  );
  const subText = replaceContentParams(
    content[`${type}${name}SubText`] || "",
    params,
  );

  return (
    type && (
      <div
        className={classnames(
          "notification_banner notification_banner--large icon",
          {
            [`notification_${type}`]: type,
            [modifier]: modifier,
          },
        )}
        id={`notification-${type}-${name}`}
      >
        <p className="heading">{message}</p>
        <p>{subText}</p>
        {additionalContent && (
          <>
            {additionalContent.map(
              (
                {
                  detail = undefined,
                  title = undefined,
                  contentType = undefined,
                },
                index,
              ) =>
                contentType?.includes("link") &&
                (detail?.includes("http") ? (
                  <React.Fragment key={title ?? detail}>
                    <a href={detail} className="link" target="_blank">
                      {title}
                    </a>
                    {index < additionalContent.length - 1 && <br />}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={title ?? detail}>
                    <Link to={detail} className="link ">
                      {title}
                    </Link>
                    {index < additionalContent.length - 1 && <br />}
                  </React.Fragment>
                )),
            )}
            <ul>
              {additionalContent.map(
                ({
                  detail = undefined,
                  title = undefined,
                  contentType = undefined,
                }) =>
                  !contentType?.includes("link") && (
                    <li key={title ?? detail}>
                      <p className="errorList">
                        {title && <span className="h4">{title}</span>}
                        {detail}
                      </p>
                    </li>
                  ),
              )}
            </ul>
          </>
        )}
      </div>
    )
  );
};
