import { Balance } from "components/Balance";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import React from "react";

type BillSmootherBalanceProps = {
  accountBalance: BillingBalance;
  isAccountBalanceError: boolean;
  isAccountBalanceFetching: boolean;
};

export const BillSmootherBalance: React.FC<BillSmootherBalanceProps> = ({
  accountBalance,
  isAccountBalanceError,
  isAccountBalanceFetching,
}) => {
  if (isAccountBalanceFetching) {
    return <LoadingSection />;
  }

  if (isAccountBalanceError) {
    return <ComponentItemFallback componentTitle="Bill smoother balance" />;
  }

  return (
    <>
      <Balance
        balanceType="Bill Smoother credit"
        amount={Number(accountBalance?.total_credits?.amount)}
      />
    </>
  );
};
