type PrepareComparisonChartDataProps = {
  thisPeriodData: string; //The total value of a specific data point for all available days in the current period
  previousPeriodData: string; // The total value of a specific data point for all available days in the previous period
  yearAgoData: string; // The total value of a specific data point for all available days in the selected period a year ago
  isThisPeriodAggregatesSuccess: boolean; // The status of the getAggregates API call for the current period
  isPreviousPeriodAggregatesSuccess: boolean; // The status of the getAggregates API call for the previous period
  isYearAgoAggregatesSuccess: boolean; // The status of the getAggregates API call for a year ago
  periodName: string; // The name of the selected period (week, fortnight, month)
  barColour?: string; // The colour of the progress bar for the currentPeriod
  unitType?: string; // The unit type that the data relates to (cost, usage)
};

/**
 * @returns An array of ComparisonChartData objects. Each object contains the following attributes:
 * label with the period name - string,
 * value - string or null (when the success status is "false" or the value is NaN),
 * currentPeriod - boolean (whether or not this is the current period),
 * unitType (optional) - string (unit type that the data relates to i.e. cost or usage),
 * barColour (optional) - string (colour of the progress bar) - should only be added to the object where currentPeriod is true
 */
export const prepareComparisonChartData = ({
  thisPeriodData,
  previousPeriodData,
  yearAgoData,
  isThisPeriodAggregatesSuccess,
  isPreviousPeriodAggregatesSuccess,
  isYearAgoAggregatesSuccess,
  periodName,
  barColour,
  unitType,
}: PrepareComparisonChartDataProps): ComparisonChartData[] => {
  return [
    {
      label: `This ${periodName ?? "period"}`,
      value:
        !isThisPeriodAggregatesSuccess || isNaN(parseFloat(thisPeriodData))
          ? null
          : parseFloat(thisPeriodData),
      currentPeriod: true,
      unitType: unitType,
      barColour: barColour,
    },
    {
      label: `Last ${periodName ?? "period"}`,
      value:
        !isPreviousPeriodAggregatesSuccess ||
        isNaN(parseFloat(previousPeriodData))
          ? null
          : parseFloat(previousPeriodData),
      currentPeriod: false,
      unitType: unitType,
    },
    {
      label: "Last year",
      value:
        !isYearAgoAggregatesSuccess || isNaN(parseFloat(yearAgoData))
          ? null
          : parseFloat(yearAgoData),
      currentPeriod: false,
      unitType: unitType,
    },
  ];
};
