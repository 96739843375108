import "./Tooltip.scss";

import { debounce } from "lodash";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { adjustPosition } from "./Tooltip.utils";

type TooltipContextType = {
  placement: string;
  tooltipContentRef: React.MutableRefObject<HTMLDivElement> | null;
};

export const TooltipContext = React.createContext<
  TooltipContextType | undefined
>(undefined);

export const Tooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const [placement, setPlacement] = useState<string>(
    "tooltip-responsive__content-container--bottom-left",
  );

  const tooltipContentRef = useRef<HTMLDivElement>(null);
  const containerIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustPositionParams = {
      tooltipContentRef,
      containerIconRef,
      setPlacement,
    };

    const debouncedAdjustPosition = debounce(
      () => adjustPosition(adjustPositionParams),
      150,
    );
    debouncedAdjustPosition();

    window.addEventListener("scroll", debouncedAdjustPosition);
    return () => {
      window.removeEventListener("scroll", debouncedAdjustPosition);
    };
  }, []);

  return (
    <TooltipContext.Provider value={{ placement, tooltipContentRef }}>
      <div ref={containerIconRef} className={`tooltip-responsive ${placement}`}>
        {children}
      </div>
    </TooltipContext.Provider>
  );
};

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);
  if (!context) {
    throw new Error("useTooltipContext must be used within a Tooltip");
  }
  return context;
};
