import "./SolarTotalSoldAndBought.scss";

import { ReactComponent as BoltIconSvg } from "app/assets/images/icons/bolt-pointy-solid.svg";
import { ReactComponent as CartIconSvg } from "app/assets/images/icons/cart-solid.svg";
import { ReactComponent as DollarSignIconSvg } from "app/assets/images/icons/dollar-solid.svg";
import { ReactComponent as PriceTagIconSvg } from "app/assets/images/icons/label-sold.svg";
import { withErrorBoundary } from "components/fallback/withErrorBoundary";
import { LoadingSection } from "components/LoadingSection";
import { TotalValueComparisonChart } from "components/TotalValueComparisonChart";
import { Subheading } from "elements/Subheading";
import { TotalWithUnit } from "elements/TotalWithUnit";
import { TabProvider } from "pages/UsagePage/components/TabPanel/TabContext";
import {
  Tab,
  TabList,
  TabPanel,
} from "pages/UsagePage/components/TabPanel/TabPanel";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import {
  selectCurrentTotalExportInDollarsWithFormat,
  selectCurrentTotalExportInPower,
  selectCurrentTotalImportInDollarsWithFormat,
  selectCurrentTotalImportInPower,
  selectLatestValidDate,
  selectPrevTotalExportInDollars,
  selectPrevTotalExportInPower,
  selectPrevTotalImportInDollars,
  selectPrevTotalImportInPower,
  selectYearAgoTotalExportInDollars,
  selectYearAgoTotalExportInPower,
  selectYearAgoTotalImportInDollars,
  selectYearAgoTotalImportInPower,
} from "selectors/currentUsageSelector";
import { UNIT_CODE_TYPES } from "utils/constants";

export const SolarTotalSoldAndBought = withErrorBoundary(() => {
  const { currentBillingPeriod, isCurrentUsageDataFetching, currentUsageData } =
    useAppSelector((store) => store.billingEntityData);

  const currentBoughtInDollars = useAppSelector(
    selectCurrentTotalImportInDollarsWithFormat,
  );
  const currentBoughtInPower = useAppSelector(selectCurrentTotalImportInPower);
  const currentSoldInDollars = useAppSelector(
    selectCurrentTotalExportInDollarsWithFormat,
  );
  const currentSoldInPower = useAppSelector(selectCurrentTotalExportInPower);

  const latestValidDate = useAppSelector(selectLatestValidDate);
  const lastPeriodBoughtInDollars = useAppSelector(
    selectPrevTotalImportInDollars,
  );
  const lastPeriodBoughtInPower = useAppSelector(selectPrevTotalImportInPower);
  const lastPeriodSoldInDollars = useAppSelector(
    selectPrevTotalExportInDollars,
  );
  const lastPeriodSoldInPower = useAppSelector(selectPrevTotalExportInPower);

  const yearAgoPeriodBoughtInDollars = useAppSelector(
    selectYearAgoTotalImportInDollars,
  );
  const yearAgoPeriodBoughtInPower = useAppSelector(
    selectYearAgoTotalImportInPower,
  );
  const yearAgoPeriodSoldInDollars = useAppSelector(
    selectYearAgoTotalExportInDollars,
  );
  const yearAgoPeriodSoldInPower = useAppSelector(
    selectYearAgoTotalExportInPower,
  );

  if (!currentUsageData && isCurrentUsageDataFetching) {
    return <LoadingSection />;
  }

  return (
    <>
      <h2 className="section-title heading h4">Sold & bought so far</h2>
      <div className="spacer--medium" />
      <TabProvider value="dollars">
        <TabList>
          <Tab label="Dollars" value="dollars" />
          <Tab label="kWh" value="kwh" />
        </TabList>
        <TabPanel value="dollars">
          <div className="solar-tab__container background--white">
            <section className="solar-tab__section">
              {latestValidDate && <p>As at {latestValidDate}</p>}
              <Subheading title={"Sold"} icon={<PriceTagIconSvg />} />
              {currentSoldInDollars ? (
                <TotalWithUnit
                  icon={<DollarSignIconSvg />}
                  total={currentSoldInDollars}
                />
              ) : (
                <p>Current sold amount is not available</p>
              )}
              <TotalValueComparisonChart
                previousValue={lastPeriodSoldInDollars}
                comparisonValue={yearAgoPeriodSoldInDollars}
                periodName={currentBillingPeriod.periodName}
                unitType={UNIT_CODE_TYPES.cost}
              />
            </section>
            <section>
              <Subheading title={"Bought"} icon={<CartIconSvg />} />
              {currentBoughtInDollars ? (
                <TotalWithUnit
                  icon={<DollarSignIconSvg />}
                  total={currentBoughtInDollars}
                />
              ) : (
                <p>Current bought amount is not available</p>
              )}
              <TotalValueComparisonChart
                previousValue={lastPeriodBoughtInDollars}
                comparisonValue={yearAgoPeriodBoughtInDollars}
                periodName={currentBillingPeriod.periodName}
                unitType={UNIT_CODE_TYPES.cost}
              />
            </section>
          </div>
        </TabPanel>
        <TabPanel value="kwh">
          <div className="solar-tab__container background--white">
            <section className="solar-tab__section">
              {latestValidDate && <p>As at {latestValidDate}</p>}
              <Subheading title={"Sold"} icon={<PriceTagIconSvg />} />
              {currentSoldInPower ? (
                <TotalWithUnit
                  icon={<BoltIconSvg />}
                  total={String(currentSoldInPower)}
                  unitCode="kWh"
                />
              ) : (
                <p>Current sold amount is not available</p>
              )}
              <TotalValueComparisonChart
                previousValue={lastPeriodSoldInPower}
                comparisonValue={yearAgoPeriodSoldInPower}
                periodName={currentBillingPeriod.periodName}
                unitType={UNIT_CODE_TYPES.usage}
              />
            </section>
            <section>
              <Subheading title={"Bought"} icon={<CartIconSvg />} />
              {currentBoughtInPower ? (
                <TotalWithUnit
                  icon={<BoltIconSvg />}
                  total={String(currentBoughtInPower)}
                  unitCode="kWh"
                />
              ) : (
                <p>Current bought amount is not available</p>
              )}
              <TotalValueComparisonChart
                previousValue={lastPeriodBoughtInPower}
                comparisonValue={yearAgoPeriodBoughtInPower}
                periodName={currentBillingPeriod.periodName}
                unitType={UNIT_CODE_TYPES.usage}
              />
            </section>
          </div>
        </TabPanel>
      </TabProvider>
    </>
  );
});
