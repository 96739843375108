/* eslint-disable jsx-a11y/anchor-is-valid */
// (it has to be an <a> tag for the FEP styling to work)
import { Button } from "@flick/fep-library";
import { useLazyGetInvoiceResourceUrlsQuery } from "queries/billingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { downloadLink } from "utils/htmlLinks/downloadLink";
import { jwtClient, rollbar } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type BillPDFSectionProps = {
  invoiceNumber: number;
  invoiceUUID: string;
};

export const BillPDFSection: React.FC<BillPDFSectionProps> = ({
  invoiceUUID,
  invoiceNumber,
}) => {
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  const [trigger, { isFetching: fetchingBillURL }] =
    useLazyGetInvoiceResourceUrlsQuery();

  const downloadPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    sendAnalyticsEvent("download_bill", {
      icpNumber,
      invoice_number: invoiceNumber,
    });

    try {
      const response = await trigger({
        jwtClient,
        invoiceUUID,
      });

      /** @TODO handle the case of no data returned, likely will need to be some form of notification not an error throw.
       * Requires higher level planning when we get to Bill page rebuild
       **/
      if (response?.data) {
        downloadLink({
          url: response.data.resource_urls.s3_object_url,
          id: `Flick-Electric-invoice-${invoiceUUID}`,
        });
      }
    } catch (error) {
      rollbar.error("Cannot find PDF version for invoice to be downloaded", {
        invoiceNumber,
        invoiceUUID,
        icp: icpNumber,
        error: error.message,
      });
    }
  };

  return (
    <Button
      disabled={fetchingBillURL}
      extraClasses="button--expanded"
      onClick={downloadPDF}
    >
      Download Bill PDF
    </Button>
  );
};
