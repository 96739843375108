import React from "react";

/**
 * @description When a customer has closed their account they will see this on the Usage page
 */
export const UsageClosedAccountDefault: React.FC = () => (
  <div className="page-section">
    <section className="page-section__column page-section__column--single">
      <h1 className="h2 page__heading">No data</h1>
      <p>
        We can't show any current usage data as this account has been closed.
      </p>
    </section>
  </div>
);
