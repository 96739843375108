import { createSelector } from "@reduxjs/toolkit";
import { add } from "date-fns/add";
import { endOfDay } from "date-fns/endOfDay";
import { getDaysInMonth } from "date-fns/getDaysInMonth";
import { sub } from "date-fns/sub";
import { subMonths } from "date-fns/subMonths";
import { subYears } from "date-fns/subYears";
import { RootState } from "reduxUtils/store";

export const selectPreviousPeriodDates = createSelector(
  (state: RootState) => state.billingEntityData?.currentBillingPeriod,
  (currentBillingPeriod) => {
    if (!currentBillingPeriod)
      return {
        previousPeriodStart: undefined,
        previousPeriodEnd: undefined,
      };

    const prevMonth = subMonths(currentBillingPeriod.startAt, 1);
    /** We need to account for all months which could either have 28 (or 29 if leap year), 30, 31 days */
    const monthDuration = getDaysInMonth(prevMonth);

    const previousPeriodStartDate = currentBillingPeriod
      ? sub(new Date(currentBillingPeriod.startAt), {
          days:
            currentBillingPeriod.duration === 30
              ? monthDuration
              : currentBillingPeriod.duration,
        })
      : undefined;

    const previousPeriodEndDate = previousPeriodStartDate
      ? endOfDay(
          add(previousPeriodStartDate, {
            /** We need to minus 1 off the number of days we are adding to the previousPeriodStartDate, otherwise the previousPeriodEndDate will be the first day of the current period */
            days:
              currentBillingPeriod.duration === 30
                ? monthDuration - 1
                : currentBillingPeriod.duration - 1,
          }),
        )
      : undefined;

    return {
      previousPeriodStart: previousPeriodStartDate,
      previousPeriodEnd: previousPeriodEndDate,
    };
  },
);

export const selectYearAgoPeriodDates = createSelector(
  (state: RootState) => state.billingEntityData?.currentBillingPeriod,
  (currentBillingPeriod) => {
    if (!currentBillingPeriod)
      return {
        yearAgoPeriodStart: undefined,
        yearAgoPeriodEnd: undefined,
      };
    return {
      yearAgoPeriodStart: subYears(new Date(currentBillingPeriod.startAt), 1),
      yearAgoPeriodEnd: subYears(new Date(currentBillingPeriod.endAt), 1),
    };
  },
);
