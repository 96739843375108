import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import React from "react";
import { Link } from "react-router";

export const SectionLinks: React.FC<{
  links?: { label: string; path: string }[];
}> = ({ links = [] }) => {
  return links.length > 0 ? (
    <div className="customer_tools_list customer_tools_list--endless">
      {links.map((link, index) => (
        <Link
          className="customer_tools_list__item customer_tools_section__link"
          key={index}
          state={{ previousPath: window.location.pathname }}
          to={link.path}
          discover="none"
        >
          <p className="customer_tools_list__text">
            <strong>{link.label}</strong>
          </p>
          <ForwardIcon />
        </Link>
      ))}
    </div>
  ) : (
    <></>
  );
};
