/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { billingApi } from "queries/billingApi";
import { ratingApi } from "queries/ratingApi";
import { ratingQueryDateRange } from "reduxUtils/ratingQueryDateRange";
import { AVAILABLE_PERIODS } from "utils/constants";

type BillingEntityState = {
  billingEntityData?: BillingEntity;
  currentBillingPeriod?: {
    endAt?: Date;
    startAt?: Date;
    code?: string;
    duration?: number;
    periodName?: string;
  };
  currentUsageData?: any;
  isCurrentUsageDataFetching?: boolean;
  prevUsageData?: any;
  yearAgoUsageData?: any;
};

const initialState: BillingEntityState = {
  billingEntityData: undefined,
  currentBillingPeriod: undefined,
  currentUsageData: undefined,
  prevUsageData: undefined,
  yearAgoUsageData: undefined,
  isCurrentUsageDataFetching: false,
};

const billingEntityDataSlice = createSlice({
  name: "billingEnityData",
  initialState,
  reducers: {
    setUpdateDefaultPaymentMethod: (
      state,
      action: PayloadAction<{
        default_payment_method?: AccountDetailPaymentMethod;
      }>,
    ) => {
      const { payload } = action;
      Object.entries(payload).forEach(([key, param]) => {
        if (param) {
          state.billingEntityData[key] = param;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        billingApi.endpoints.getBillingEntity.matchPending,
        (state) => {
          Object.assign(state, initialState);
        },
      )
      .addMatcher(
        billingApi.endpoints.getBillingEntity.matchFulfilled,
        (state, { payload }) => {
          state.billingEntityData = { ...payload };
          state.currentBillingPeriod = {
            ...ratingQueryDateRange(payload),
            ...AVAILABLE_PERIODS[payload.nominated_billing_period],
          };
        },
      )
      .addMatcher(
        ratingApi.endpoints.getCurrentAggregateUsage.matchFulfilled,
        (state, { payload }) => {
          state.isCurrentUsageDataFetching = false;
          state.currentUsageData = { ...payload };
        },
      )
      .addMatcher(
        ratingApi.endpoints.getCurrentAggregateUsage.matchRejected,
        (state) => {
          state.isCurrentUsageDataFetching = false;
          state.currentUsageData = undefined;
        },
      )
      .addMatcher(
        ratingApi.endpoints.getCurrentAggregateUsage.matchPending,
        (state) => {
          state.currentUsageData = undefined;
          state.isCurrentUsageDataFetching = true;
        },
      )
      .addMatcher(
        ratingApi.endpoints.getPreviousAggregateUsage.matchFulfilled,
        (state, { payload }) => {
          state.prevUsageData = { ...payload };
        },
      )
      .addMatcher(
        ratingApi.endpoints.getPreviousYearAggregateUsage.matchFulfilled,
        (state, { payload }) => {
          state.yearAgoUsageData = { ...payload };
        },
      );
  },
});

export const { setUpdateDefaultPaymentMethod } = billingEntityDataSlice.actions;
export const billingEntityDataSliceReducer = billingEntityDataSlice.reducer;
