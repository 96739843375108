import { differenceInDays } from "date-fns/differenceInDays";
import { isBefore } from "date-fns/isBefore";

/**
 * @description Calculates the number of days remaining between two given dates. If currentDate is before currentBillingPeriodEndAt date then it returns the difference in days between these two dates. Otherwise returns 0.
 */
export const calculateDaysRemaining = (
  currentDate: Date,
  currentBillingPeriodEndAt: Date,
) => {
  return isBefore(currentDate, currentBillingPeriodEndAt)
    ? differenceInDays(currentBillingPeriodEndAt, currentDate)
    : 0;
};
/**
 * @description Calculates the percentage of progress made in the current billing cycle. Uses the billing cycle duration and the number of days remaining in the billing cycle to determine the width of the progress bar colour.
 */
export const calculateProgressPercentage = (
  duration: number,
  daysRemaining: number,
) => {
  return ((duration - daysRemaining) / duration) * 100;
};
