import "./AccountHeaderSection.scss";

import { ReactComponent as AccountIcon } from "app/assets/images/icons/person-circle-lg.svg";
import React from "react";

type HeaderWithIconSectionProps = {
  items?: { key: string; value?: string | number }[];
  label: string;
  hasHeaderIcon?: boolean;
  headerIcon?: JSX.Element;
  headingLevel?: "h2" | "h3" | "h4"; // Allow flexibility in heading levels
};

export const HeaderWithIconSection: React.FC<HeaderWithIconSectionProps> = ({
  label,
  items,
  hasHeaderIcon = false,
  headerIcon,
  headingLevel = "h2", // Default to h2 if not provided
}) => {
  const HeadingTag = headingLevel; // Dynamic tag assignment

  return (
    <div>
      <div className="account_header__label">
        {hasHeaderIcon && headerIcon}
        <HeadingTag className="account_header__label_text heading">
          {label}
        </HeadingTag>
      </div>
      {items && (
        <div className="account_header__customer_info">
          {items.map(({ key, value }) => (
            <p key={`${key}-${value}`}>
              <span className="account_header--capitalize h5">{key}</span>
              {value && <span>{`: ${value}`}</span>}
            </p>
          ))}
          <span className="spacer spacer--large"></span>
        </div>
      )}
    </div>
  );
};

export const AccountHeaderSection: React.FC<HeaderWithIconSectionProps> = ({
  label,
  items,
  hasHeaderIcon = false,
}) => {
  return (
    <HeaderWithIconSection
      label={label}
      items={items}
      hasHeaderIcon={hasHeaderIcon}
      headerIcon={<AccountIcon className="account_header__label_icon" />}
    />
  );
};
