import { format } from "date-fns/format";
import { BILL_QUERY_LIMIT } from "utils/constants";
import { safeDate } from "utils/safeDate/safeDate";

/**
 * invoices true total from latest invoices api is the current total minus the fake invoice for current usage data
 */
export const isTotalInvoicesMultipleOfInvoiceApiLimit = (
  invoices: InvoiceSummary[],
) => {
  return (invoices.length - 1) % BILL_QUERY_LIMIT === 0;
};

/**
 * Utility function to format billing period
 */
export const formatBillingPeriod = (
  periodStartedAt?: string,
  periodEndedAt?: string,
): string => {
  const startDate = safeDate(periodStartedAt);
  const endDate = safeDate(periodEndedAt);

  const formattedStart = startDate ? format(startDate, "d MMM") : "";
  const formattedEnd = endDate ? format(endDate, "d MMM yyyy") : "";

  return `${formattedStart} — ${formattedEnd}`;
};
