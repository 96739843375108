import "./LatestReceivableInvoice.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import { format } from "date-fns/format";
import React from "react";
import { Link } from "react-router";
import { NZD } from "utils/currency";
import { invoicePaymentStatus } from "utils/invoicePaymentStatus";

type latestReceivableInvoiceProps = {
  invoice: ReceivableInvoice;
  bill: Bill;
};

export const LatestReceivableInvoice: React.FC<
  latestReceivableInvoiceProps
> = ({ invoice, bill }) => {
  const { invoice_number, due_at, total_inc_gst } = invoice.attributes;

  const paymentStatusDetails = invoicePaymentStatus(invoice);

  return (
    <li className="customer_tools_list__item">
      <Link
        to={`/bills/${invoice_number}`}
        state={{ previousPath: window.location.pathname }}
        className="customer_tools_list__link latest_receivable_item__link"
      >
        <div
          className={`customer_tools_list__left_text customer_tools_list__left_text--${paymentStatusDetails.type}`}
        >
          {bill && (
            <span>
              {format(
                new Date(bill.billing_period.period_started_at),
                "d MMMM",
              )}{" "}
              -{" "}
              {format(new Date(bill.billing_period.period_ended_at), "d MMMM")}{" "}
            </span>
          )}
          <br />
          <small className="text__colour--muted">
            {paymentStatusDetails.verb} {format(new Date(due_at), "EEE d MMM")}{" "}
          </small>
        </div>

        <div className="customer_tools_list__right_aligned_text_icon_content">
          <div className="customer_tools_list__right_aligned_text_icon_content--text">
            <p>{NZD.format(parseFloat(total_inc_gst))}</p>
          </div>

          <ForwardIcon />
        </div>
      </Link>
    </li>
  );
};
