import { withErrorBoundary } from "components/fallback/withErrorBoundary";
import { LoadingSection } from "components/LoadingSection";
import { useInvoiceNumber } from "pages/UsagePage/compositions/InvoiceProvider";
import React from "react";
import { AVAILABLE_PERIODS } from "utils/constants";

import { AggregatedInvoiceSection } from "./AggregatedInvoiceSection";

type InvoiceUsageDetailsProps = {
  invoices: InvoiceSummary[];
};

export const InvoiceUsageDetails = withErrorBoundary<InvoiceUsageDetailsProps>(
  ({ invoices }) => {
    const { invoiceNumber } = useInvoiceNumber();

    const invoice = invoices.find(
      ({ attributes }) => attributes.number === invoiceNumber,
    );

    if (Boolean(!invoice?.attributes)) {
      return <LoadingSection />;
    }

    const hasPreviousData = Boolean(invoice.attributes.previous_invoice_id);
    const endAt = new Date(invoice.attributes.billing_period.period_ended_at);
    const startAt = new Date(
      invoice.attributes.billing_period.period_started_at,
    );
    const { periodName } =
      AVAILABLE_PERIODS[invoice.attributes.billing_period.duration];

    const previousPeriodStartDate = invoice.attributes.comparison_data?.previous
      .started_at
      ? new Date(invoice.attributes.comparison_data.previous.started_at)
      : undefined;

    return (
      <AggregatedInvoiceSection
        hasPreviousData={hasPreviousData}
        periodEndDate={endAt}
        periodName={periodName}
        periodStartDate={startAt}
        previousPeriodStartDate={previousPeriodStartDate}
      />
    );
  },
);
