/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import OffPeakSvg from '@flick/front_end_patterns/src/images/plan_graphics_offpeak.svg';
import FlatSvg from '@flick/front_end_patterns/src/images/plan_graphics_flat.svg';
import WholesaleSvg from '@flick/front_end_patterns/src/images/plan_graphics_wholesale.svg';
import FlatBusinessSvg from '@flick/front_end_patterns/src/images/plan_graphics_business_flat.svg';
import OffPeakBusinessSvg from '@flick/front_end_patterns/src/images/plan_graphics_business_offpeak.svg';
import BestPlanBurst from '@flick/front_end_patterns/src/images/plan_burst.svg';
import PlanDetailsDialog from './planDetailsDialog';
import { findOffpeakTimetables, formatTimetables } from '../../utils/timetables';
import sendAnalytics, { sendAnalyticsToDataLayer } from '../../utils/analytics';
import {
  BEST_PLAN_PROMISE_PRODUCTS,
  ANALYTICS_CHOOSE_PLAN,
  ANALYTICS_VALUE_TRUE,
  ANALYTICS_VALUE_MAYBE,
  PRODUCT_NAMES,
  CONTENT_SLUG_TO_CLASS_MAPPINGS,
  CONTENT_SLUG_TO_THEME_MAPPINGS,
  SAVINGS_THRESHOLD,
  METER_NAME,
} from '../../utils/constants';
import { BillEstimate } from './billEstimate';
import { PlanProofPoints } from './planProofPoints';
import { PlanRates } from './planRates';
import { titleCase } from '../../utils/text-transforms';
import BillEstimateDialog from './billEstimateDialog';
import { calculateGstExclusiveSavings } from '../../utils/calculateGstExclusiveSavings';
import { LinkButton } from './linkButton';

const PLAN_VARIANT_TO_IMAGE_MAPPINGS = {
  business_non_TOU: FlatBusinessSvg,
  business_TOU: OffPeakBusinessSvg,
  flat: FlatSvg,
  off_peak: OffPeakSvg,
  wholesale: WholesaleSvg,
};

const { business, off_peak: offPeak } = PRODUCT_NAMES;

const mapPlanVariant = (billEstimate, planName) => {
  const timetables = billEstimate?.subscriptionRates?.timetables;
  if (planName === business && !billEstimate) {
    return 'business_TOU';
  }

  if (planName === business && timetables?.length > 1) {
    return 'business_TOU';
  }
  if (planName === business && (!timetables || timetables.length < 1)) {
    return 'business_non_TOU';
  }
  return planName;
};

const getSignupLink = (address, plan, icp) => {
  const origin = `${window.location.protocol}//${window.location.host}`;
  const params = new URLSearchParams({
    number: address.number,
    street: address.street,
    suburb: address.suburb,
    city: address.city,
    postcode: address.postcode,
    region: address.region,
    product: plan.contentSlug,
    unit: address.unitIdentifier || '',
    icp,
  });
  return `${origin}/join/?${params.toString()}`;
};

const displayEstimatedSavings = (estimatedSavings) => estimatedSavings >= SAVINGS_THRESHOLD;

const displayDisclaimer = (address, hasPersonalisedEstimate, billEstimate, estimatedSavings) =>
  address &&
  hasPersonalisedEstimate &&
  Boolean(billEstimate) &&
  displayEstimatedSavings(estimatedSavings);

const BurstImage = () => (
  <div className="plan_card_burst">
    <img alt="flickin' best plan promise" className="plan_card_burst_image" src={BestPlanBurst} />
  </div>
);

const Plan = ({
  plan,
  copy,
  region,
  userType,
  gstInclusion,
  address,
  icp,
  billDetails,
  billEstimateLoading,
  updateBillDetails,
  setGstInclusion,
}) => {
  const billEstimate = plan.estimate;
  const planDetailsDialogRef = useRef();
  const planVariant = mapPlanVariant(billEstimate, plan.contentSlug);

  const showBurst = BEST_PLAN_PROMISE_PRODUCTS.includes(planVariant);

  const hasPersonalisedEstimate = !Object?.values(billDetails).includes(null);

  const estimatedSavingsExclGst = calculateGstExclusiveSavings(
    billDetails?.cost,
    billEstimate?.totalEstimate
  );

  // sendAnalytics is the function that will inform stakeholders via Google Analytics that the dialog box has been hit.
  const showDialog = () => {
    sendAnalytics('Pricing and details link: Pricing and Plans details', 'Clicked');
    planDetailsDialogRef.current.show();
  };

  const planIsAvailable = CONTENT_SLUG_TO_CLASS_MAPPINGS[plan.contentSlug] !== 'market';

  const analyticsEvents = (planName) => {
    const analyticsParams = {
      address_found: ANALYTICS_VALUE_TRUE,
      eligibility: ANALYTICS_VALUE_MAYBE,
      plan_type: planName,
    };
    // if ICP exists then add property specific data to analytics event
    if (icp) {
      Object.assign(analyticsParams, {
        icp_number: icp,
        eligibility: ANALYTICS_VALUE_TRUE,
        customer_type_system: billEstimate?.locationClassification?.toLowerCase(),
        registry_load_type: billEstimate?.usageType,
      });
    }
    sendAnalytics('PlanChooser Sign up: Sign up button', 'Clicked');
    sendAnalyticsToDataLayer(ANALYTICS_CHOOSE_PLAN, analyticsParams);
  };

  const subscriptionRates = billEstimate?.subscriptionRates?.rates;

  const usageRates = billEstimate?.usageRates;

  const embeddedGenerationMeter = usageRates?.meterConfigurations.filter(
    (meterConfiguration) => meterConfiguration.name === METER_NAME.EMBEDDED_GENERATION
  );

  const hasEmbeddedGeneration = embeddedGenerationMeter?.length >= 1;

  const offpeakTimetableValues = findOffpeakTimetables(plan);

  const formattedTimetables =
    offpeakTimetableValues?.descriptions && formatTimetables(offpeakTimetableValues.descriptions);

  return (
    <div
      className={`plan_card plan_card_${
        CONTENT_SLUG_TO_CLASS_MAPPINGS[plan.contentSlug]
      } carousel_slide`}
    >
      <div className="plan_card_heading">
        <h3>{copy?.title}</h3>
        {copy?.byline}

        <div className="plan_card_graph">
          <img alt={plan.contentSlug} src={PLAN_VARIANT_TO_IMAGE_MAPPINGS[planVariant]} />
        </div>
      </div>

      <div className="plan_card_body">
        {!planIsAvailable && (
          <div className="plan_card_details">
            <h5>
              Due to continued wholesale market volatility we&apos;re not offering this plan to new
              customers at the moment.
            </h5>
          </div>
        )}

        {planIsAvailable && !address && (
          <>
            {showBurst && <BurstImage />}

            <PlanProofPoints
              copy={copy}
              planVariant={planVariant}
              theme={CONTENT_SLUG_TO_THEME_MAPPINGS[plan.contentSlug]}
            />

            <p>
              <b>Want to sign up?</b>
              <br />
              Enter your address in the search bar above to see your rates and pricing details.
            </p>
          </>
        )}

        {planIsAvailable && address && (
          <>
            {hasPersonalisedEstimate && Boolean(billEstimate) && (
              <BillEstimate
                billEstimateLoading={billEstimateLoading}
                billEstimateTotal={billEstimate?.totalEstimate}
                displayEstimatedSavings={displayEstimatedSavings(estimatedSavingsExclGst)}
                planName={plan.contentSlug}
                estimatedSavings={estimatedSavingsExclGst}
                gstInclusion={gstInclusion}
                icp={icp}
              />
            )}

            {billEstimate && !hasPersonalisedEstimate && (
              <BillEstimateDialog
                billDetails={billDetails}
                updateBillDetails={updateBillDetails}
                setGstInclusion={setGstInclusion}
                icpNumber={icp}
              />
            )}

            {!billEstimate && showBurst && <BurstImage />}

            {hasPersonalisedEstimate && (
              <div className="plan_card_button">
                <LinkButton
                  href={getSignupLink(address, plan, icp)}
                  handleOnClick={analyticsEvents(copy?.title)}
                  linkCopy="Sign Up Now"
                  extraClasses="button--black"
                />
              </div>
            )}

            {(!billEstimate || (billEstimate && hasPersonalisedEstimate)) && (
              <PlanProofPoints
                copy={copy}
                planVariant={planVariant}
                theme={CONTENT_SLUG_TO_THEME_MAPPINGS[plan.contentSlug]}
              />
            )}

            {subscriptionRates && usageRates && (
              <div className="plan_card_details">
                {showBurst && <BurstImage />}

                <div className="plan_card_details_text">
                  <h4>
                    {plan.contentSlug !== business
                      ? `${titleCase(billEstimate?.usageType)} user `
                      : 'Your '}
                    rates
                  </h4>

                  <PlanRates
                    subscriptionRates={subscriptionRates}
                    usageRates={usageRates}
                    planId={plan?.contentSlug}
                    gstInclusion={gstInclusion}
                  />
                  {hasEmbeddedGeneration && (
                    <>
                      <p>
                        *Wholesale power rates are a variable rate that changes every 30 minutes.
                      </p>
                      <br />
                    </>
                  )}

                  {plan.contentSlug === offPeak && (
                    <div className="plan_card_timetable_text">
                      <h4>Your off-peak hours</h4>

                      {formattedTimetables &&
                        formattedTimetables.map((timetable) => (
                          <p>
                            <span className="period-label">{timetable.label}</span>{' '}
                            {timetable.period}
                          </p>
                        ))}
                    </div>
                  )}

                  <div className="plan_card_link">
                    <a
                      href="#details"
                      onClick={() => {
                        showDialog();
                      }}
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>
            )}

            {(!billEstimate || (billEstimate && !hasPersonalisedEstimate)) && (
              <div className="plan_card_button">
                <LinkButton
                  href={getSignupLink(address, plan, icp)}
                  handleOnClick={analyticsEvents(copy?.title)}
                  linkCopy="Sign Up Now"
                  extraClasses="button--black"
                />
              </div>
            )}

            {displayDisclaimer(
              address,
              hasPersonalisedEstimate,
              billEstimate,
              estimatedSavingsExclGst
            ) && (
              <div className="plan_card_disclaimer">
                <p>
                  *This savings amount is based on the bill information you’ve provided in the
                  personalised bill estimate tool. We take the total cost of your bill, the bill
                  period and the kWh consumption to calculate how much this would have cost you with
                  Flick.
                </p>
              </div>
            )}

            {billEstimate && (
              <PlanDetailsDialog
                planName={plan.contentSlug}
                title={copy?.title}
                pricingDetailsContent={copy?.pricingDetailsContent}
                dialogRef={planDetailsDialogRef}
                billEstimate={billEstimate}
                userType={userType}
                gstInclusion={gstInclusion}
                region={region}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  gstInclusion: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  icp: PropTypes.string.isRequired,
  billDetails: PropTypes.object.isRequired,
  billEstimateLoading: PropTypes.bool.isRequired,
  updateBillDetails: PropTypes.func.isRequired,
  setGstInclusion: PropTypes.func.isRequired,
};

export default Plan;
