import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import { LatestReceivableInvoice } from "pages/HomePage/components/LatestReceivableInvoice";
import {
  useGetInvoiceQuery,
  useGetLatestReceivableInvoiceQuery,
  useGetOutstandingInvoicesQuery,
} from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { INVOICED_PAYMENT_STATUSES } from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { OutstandingBillListItem } from "./OutstandingBillListItem";

/**
 * @description filterOutstandingInvoices filters out the latestReceivableInvoice if it is present in the outstandingInvoices array
 * @param {ReceivableInvoice} latestReceivableInvoice
 * @param {OutstandingInvoiceSummary[]} outstandingInvoices
 * @returns {OutstandingInvoiceSummary[]} a filtered array that does not contain the lastestReceivableInvoice
 */
const filterOutstandingInvoices = (
  latestReceivableInvoice: ReceivableInvoice,
  outstandingInvoices: OutstandingInvoiceSummary[],
): OutstandingInvoiceSummary[] => {
  if (!outstandingInvoices || outstandingInvoices.length === 0) {
    return;
  }

  if (!latestReceivableInvoice && outstandingInvoices) {
    return outstandingInvoices;
  }

  const latestInvoiceNumber =
    latestReceivableInvoice.attributes?.invoice_number;

  return outstandingInvoices
    .filter(
      (outstandingInvoice) =>
        outstandingInvoice.attributes.number !== latestInvoiceNumber,
    )
    .reverse();
};

export const OutstandingBillsList: React.FC = () => {
  const { customerNumber } = useAppSelector((store) => store.currentAccount);

  const {
    data: outstandingInvoices,
    isError: isOutstandingInvoicesError,
    isFetching: isOutstandingInvoicesFetching,
  } = useGetOutstandingInvoicesQuery(
    { customerNumber, jwtClient },
    {
      skip: !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: latestReceivableInvoice,
    isError: isLatestReceivableInvoiceError,
    isFetching: isLatestReceivableInvoiceFetching,
  } = useGetLatestReceivableInvoiceQuery(
    { customerNumber, jwtClient },
    {
      skip: !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: billData,
    isError: isBillError,
    isFetching: isBillFetching,
  } = useGetInvoiceQuery(
    {
      invoiceNumber: latestReceivableInvoice?.attributes?.invoice_number,
      jwtClient,
    },
    {
      skip:
        !latestReceivableInvoice?.attributes?.invoice_number ||
        isNaN(Number(latestReceivableInvoice.attributes.invoice_number)),
    },
  );

  const filteredOutstandingInvoices = useMemo(() => {
    return filterOutstandingInvoices(
      latestReceivableInvoice,
      outstandingInvoices,
    );
  }, [latestReceivableInvoice, outstandingInvoices]);

  if (
    isOutstandingInvoicesFetching ||
    isLatestReceivableInvoiceFetching ||
    isBillFetching
  ) {
    return <LoadingSection />;
  }

  const bill = billData?.bill;

  const noOutstandingInvoices =
    !outstandingInvoices || outstandingInvoices.length === 0;

  const successfullyFetchedNoOutstandingInvoices =
    noOutstandingInvoices &&
    !isOutstandingInvoicesError &&
    !isOutstandingInvoicesFetching;

  const successfullyFetchedNoLatestReceivableInvoice =
    !latestReceivableInvoice &&
    !isLatestReceivableInvoiceError &&
    !isLatestReceivableInvoiceFetching;

  if (
    successfullyFetchedNoLatestReceivableInvoice &&
    successfullyFetchedNoOutstandingInvoices
  ) {
    return <></>;
  }

  const paidLatestReceivableInvoiceBill = bill?.payment_status
    ? bill.payment_status === INVOICED_PAYMENT_STATUSES.complete
    : false;

  if (
    noOutstandingInvoices &&
    latestReceivableInvoice &&
    paidLatestReceivableInvoiceBill
  ) {
    return <></>;
  }

  const displayUnpaidLatestReceivableInvoice =
    latestReceivableInvoice && bill && !paidLatestReceivableInvoiceBill;

  const latestReceivableInvoiceOrBillError =
    isLatestReceivableInvoiceError || isBillError;

  return (
    <nav>
      <ul className="customer_tools_list">
        <li className="customer_tools_list__title">Outstanding bills</li>
        {latestReceivableInvoiceOrBillError && (
          <ComponentItemFallback componentTitle={"Latest receivable invoice"} />
        )}
        {displayUnpaidLatestReceivableInvoice && (
          <LatestReceivableInvoice
            invoice={latestReceivableInvoice}
            bill={bill}
          />
        )}

        {isOutstandingInvoicesError && (
          <ComponentItemFallback componentTitle={"Outstanding invoices"} />
        )}

        {filteredOutstandingInvoices?.map((outstandingInvoice, i) => (
          <OutstandingBillListItem invoice={outstandingInvoice} key={i} />
        ))}
      </ul>
    </nav>
  );
};
