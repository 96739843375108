import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { GroupedBarChart } from "components/charts/GroupedBarChart";
import { generateGroupedBarChartConfig } from "components/CurrentYearCostsSection/CurrentYearCostsSection.utils";
import { isBefore } from "date-fns/isBefore";
import { useGetChargesQuery } from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";

import { LoadingSection } from "../LoadingSection";

type CurrentYearCostsSectionProps = {
  billingEntityData?: BillingEntity;
  cardChildren?: React.ReactNode;
};

/**
 *
 * @description Container element with a year of bill data rendered in a bar chart.
 */
export const CurrentYearCostsSection: React.FC<
  CurrentYearCostsSectionProps
> = ({ billingEntityData, cardChildren }) => {
  const { customerNumber, supplyNodeRef } = useAppSelector(
    (store) => store.currentAccount,
  );
  const thisYear = new Date().getFullYear();
  const lastYear = thisYear - 1;

  const thisYearQueryEnabled =
    Boolean(customerNumber) && Boolean(supplyNodeRef);

  const {
    data: thisYearData,
    isLoading: isThisYearLoading,
    isSuccess: isThisYearSuccess,
    isError: isThisYearError,
  } = useGetChargesQuery(
    {
      customerNumber,
      supplyNodeRef,
      startAt: `${thisYear}-01-01`,
      endAt: `${thisYear}-12-31`,
      jwtClient,
    },
    {
      skip:
        !thisYearQueryEnabled ||
        !thisYear ||
        !billingEntityData ||
        isBefore(
          new Date(`${thisYear}-01-01`),
          new Date(billingEntityData?.start_at),
        ),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const billingEntityStartYear = new Date(
    billingEntityData?.start_at,
  ).getFullYear();

  const validLastYearDate = billingEntityStartYear <= lastYear;

  const lastYearQueryEnabled =
    Boolean(customerNumber) && Boolean(supplyNodeRef) && validLastYearDate;
  const {
    data: lastYearData,
    isLoading: isLastYearLoading,
    isSuccess: isLastYearSuccess,
  } = useGetChargesQuery(
    {
      customerNumber,
      supplyNodeRef,
      startAt: `${lastYear}-01-01`,
      endAt: `${lastYear}-12-31`,
      jwtClient,
    },

    {
      skip:
        !lastYearQueryEnabled ||
        !lastYear ||
        !billingEntityData ||
        isBefore(
          new Date(`${lastYear}-01-01`),
          new Date(billingEntityData.start_at),
        ),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (
    (isThisYearLoading && thisYearQueryEnabled && !isThisYearError) ||
    (isLastYearLoading && lastYearQueryEnabled)
  ) {
    return <LoadingSection />;
  }

  const thisYearTotals =
    isThisYearSuccess && thisYearData?.summed_charges
      ? thisYearData.summed_charges.map((charge) => +charge.total_cost)
      : [];

  const lastYearTotals =
    isLastYearSuccess && lastYearData?.summed_charges
      ? lastYearData.summed_charges.map((charge) => +charge.total_cost)
      : [];

  const maxYValue = thisYearTotals.length
    ? Math.max(...thisYearTotals, ...lastYearTotals)
    : 0;

  const groupedBarChartConfig = generateGroupedBarChartConfig({
    lastYearData: isLastYearSuccess ? lastYearData.summed_charges : [],
    thisYearData: isThisYearSuccess ? thisYearData.summed_charges : [],
    lastYear,
    thisYear,
  });

  return (
    <Card>
      {thisYearData && (
        <CardHeader>
          <h2 className="h4">Your bills this year</h2>
        </CardHeader>
      )}
      <CardBody>
        {thisYearData && (
          <GroupedBarChart
            className="chart__container--small"
            chartConfig={groupedBarChartConfig}
            maxYValue={maxYValue}
            smallChart
          />
        )}
        {cardChildren}
      </CardBody>
    </Card>
  );
};
