import "./UsagePage.scss";

import { Card, CardBody } from "@flick/fep-library";
import { CurrentBillingPeriodCountdown } from "compositions/CurrentBillingPeriodCountdown";
import { TotalUsageSection } from "compositions/TotalUsageSection";
import { LoadingPage } from "pages/LoadingPage";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { isUserHavingExportChannel } from "selectors/currentRateCardSelector";

import {
  LargeTab,
  LargeTabList,
  LargeTabPanel,
} from "./components/LargeTabPanel/LargeTabPanel";
import { LargeTabProvider } from "./components/LargeTabPanel/LargeTabProvider";
import {
  NoAvailableData,
  UsageClosedAccountDefault,
} from "./components/NoAvailableData";
import { NoAvailableRates } from "./components/NoAvailableData/NoAvailableData";
import { CsvDownloader } from "./compositions/CsvDownloader";
import { PowerOrSolarBreakDown } from "./compositions/PowerOrSolarBreakdown";
import { SolarTotalSoldAndBought } from "./compositions/SolarTotalSoldAndBought";

export const UsagePage = () => {
  const { nativeRenderMode, active, accountNumber } = useAppSelector(
    (store) => store.currentAccount,
  );
  const { billingEntityData, currentBillingPeriod } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const { isRateCardError } = useAppSelector((store) => store.rateCards);

  const currentDate = new Date();
  const isSolar = useAppSelector(isUserHavingExportChannel);

  if (accountNumber && !active) return <UsageClosedAccountDefault />;
  if (!accountNumber && !active) return <NoAvailableData />;
  if (!billingEntityData || !currentBillingPeriod || isSolar === undefined) {
    if (isRateCardError) return <NoAvailableRates />;
    return <LoadingPage />;
  }

  const CSVDownload = ({ viewport }) => {
    return (
      <div className={`spacer--large csv-download--${viewport}`}>
        <Card>
          <CardBody>
            <CsvDownloader
              viewport={viewport}
              billingEntityStartAt={billingEntityData?.start_at}
              billingEntityEndAt={billingEntityData?.end_at}
              currentPeriodEndDate={currentDate}
            />
          </CardBody>
        </Card>
      </div>
    );
  };

  /**
   * in term of no solar, we still need large tab provider
   * since we use the useLargeTabContext() in <PowerOrSolarBreakDown/> children
   */
  if (isSolar === false) {
    return (
      <div className="usage-page">
        <LargeTabProvider value="usage">
          <section className="page-section">
            <section className="page-section__column page-section__column--left">
              <CurrentBillingPeriodCountdown />

              <div className="spacer--large" />
              <TotalUsageSection
                componentTitle="Current Usage So Far"
                links={[{ label: "Plans & rates", path: "/account/plan" }]}
                sectionHeading="Used so far"
              />
              {billingEntityData && !nativeRenderMode && (
                <CSVDownload viewport="desktop" />
              )}
              <div className="spacer--large" />
            </section>
            <section className="page-section__column page-section__column--right">
              <PowerOrSolarBreakDown />
            </section>
          </section>
        </LargeTabProvider>
        {billingEntityData && !nativeRenderMode && (
          <CSVDownload viewport="mobile" />
        )}
      </div>
    );
  }

  return (
    <div className="usage-page">
      <LargeTabProvider value="solar">
        <section className="page-section">
          <section className="page-section__column page-section__column--left background--grey">
            {/* Tab List */}
            <LargeTabList>
              <LargeTab label="Solar" value="solar" />
              <LargeTab label="Usage" value="usage" />
            </LargeTabList>

            <div className="spacer--large" />
            {/* only use Large Tab Panel view for non-shared compositions */}
            <LargeTabPanel value="solar">
              <h2 className="section-title heading h4">Your Solar</h2>
            </LargeTabPanel>
            <LargeTabPanel value="usage">
              <h2 className="section-title heading h4">Your Usage</h2>
            </LargeTabPanel>

            <div className="spacer--medium" />
            <CurrentBillingPeriodCountdown />
            <div className="spacer--large" />

            {/* only use Large Tab Panel view for non-shared compositions */}
            <LargeTabPanel value="solar">
              <SolarTotalSoldAndBought componentTitle="Current Sold & Bought So Far" />
            </LargeTabPanel>
            <LargeTabPanel value="usage">
              <TotalUsageSection
                componentTitle="Current Usage So Far"
                links={[{ label: "Plans & rates", path: "/account/plan" }]}
                sectionHeading="Used so far"
              />
            </LargeTabPanel>

            {billingEntityData && !nativeRenderMode && (
              <CSVDownload viewport="desktop" />
            )}
            <div className="spacer--large" />
          </section>
          <section className="page-section__column page-section__column--right">
            <PowerOrSolarBreakDown />
          </section>
        </section>
      </LargeTabProvider>
      {billingEntityData && !nativeRenderMode && (
        <CSVDownload viewport="mobile" />
      )}
    </div>
  );
};
