import { InvoiceUsageChart } from "pages/UsagePage/components/InvoiceUsageChart";
import { useInvoiceUsageChart } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import React, { useMemo } from "react";

import { InvoiceDayDetail } from "../InvoiceDayDetail";

type InvoiceUsageSectionProps = {
  dailyUsageData: DailyUsage[];
  periodName: string;
  sectionSubHeading?: string;
  displayType?: string;
};

/**
 * @description used in AggregatedInvoiceSection
 * daily usage data cannot be empty array
 */
export const InvoiceUsageSection: React.FC<InvoiceUsageSectionProps> = ({
  dailyUsageData,
  periodName,
  sectionSubHeading,
  displayType = "consumption",
}) => {
  const { selectedDayIndex, lastDayIndex } = useInvoiceUsageChart();

  const selectedDayData = useMemo(() => {
    if (selectedDayIndex > lastDayIndex) {
      // and default return first item in array
      return dailyUsageData[0];
    }
    return dailyUsageData[selectedDayIndex];
  }, [selectedDayIndex, dailyUsageData]);

  return (
    <>
      {sectionSubHeading && <p>{sectionSubHeading}</p>}
      <InvoiceUsageChart
        displayType={displayType}
        dailyUsageData={dailyUsageData}
        periodName={periodName}
      />
      <InvoiceDayDetail periodName={periodName} usageData={selectedDayData} />
    </>
  );
};
