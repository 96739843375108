/* eslint-disable @typescript-eslint/no-explicit-any */
import "styles/Chart.scss";

import { BarChart } from "components/charts/BarChart";
import React, { memo } from "react";
import { globalBarChartOptions } from "utils/charts";

import { prepareDataForBarChart } from "./BarChartWrapper.utils";

/**
 * @description Displays a simple bar chart
 */
export const BarChartWrapper: React.FC<{
  maxYValue: number | [];
  chartConfig: BarChartConfig;
  classNames?: string;
}> = memo(({ maxYValue, chartConfig, classNames }) => {
  const chartOptions: BarChartOptions = globalBarChartOptions();
  chartOptions.scales.y.max = maxYValue;
  chartOptions.scales.y.title.text = chartConfig.yAxisText;
  chartOptions.scales.x.ticks.backdropColor = "transparent";
  chartOptions.scales.x.ticks.font = chartConfig.scales?.x.ticks.font;
  chartOptions.scales.x.grid.drawTicks = chartConfig.scales?.x.grid.drawTicks;
  chartOptions.scales.y.ticks.display = chartConfig.scales?.y.ticks.display;
  chartOptions.scales.y.grid.display = chartConfig.scales?.y.grid.display;
  chartOptions.scales.y.display = chartConfig.scales?.y.display;
  chartOptions.scales.y.title.align = chartConfig.scales?.y.title.align;
  if (chartConfig.scales?.x2) {
    chartOptions.scales.x2 = chartConfig.scales?.x2;
  }
  chartOptions.events = [];
  chartOptions.plugins.legend.display = chartConfig.legendOptions?.display;
  if (chartConfig.legendOptions.labels) {
    chartOptions.plugins.legend.labels.generateLabels = () => {
      return chartConfig.legendOptions.labels as unknown as string[];
    };
  }

  const data = prepareDataForBarChart(chartConfig);

  return (
    <BarChart
      chartConfig={chartConfig}
      data={data}
      options={chartOptions}
      className={classNames}
    />
  );
});
