import React from "react";

import { useTooltipContext } from "./Tooltip";

type TooltipContentProps = {
  children: string;
};

export const TooltipContent: React.FC<TooltipContentProps> = ({ children }) => {
  const { placement, tooltipContentRef } = useTooltipContext();

  return (
    <div
      ref={tooltipContentRef}
      className={`tooltip-responsive__content-container ${placement}`}
    >
      <div className={`tooltip-responsive__content`}>{children}</div>
    </div>
  );
};
