import { createSelector } from "@reduxjs/toolkit";
import { findCurrentShift } from "utils/offpeakCalculations/findCurrentShift";
import { findCurrentTimeTable } from "utils/rateCardHelpers/findCurrentTimetable";

import { selectCurrentRateCard } from "./currentRateCardSelector";

/**
 * Selector to compute the current shift details based on the current timetable.
 * When the value of triggerRecompute changes, the selector will recompute.
 */

export const selectOffpeakDetails = createSelector(
  [selectCurrentRateCard, (_, triggerRecompute: Date) => triggerRecompute],
  (currentRateCard) => {
    if (!currentRateCard) return undefined;

    const timetable = findCurrentTimeTable(currentRateCard);

    return Boolean(timetable) ? findCurrentShift(timetable) : undefined;
  },
);
