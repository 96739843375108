/* eslint-disable import/named */
import { Chart, ChartEvent, defaults, Scale } from "chart.js";
import { getRelativePosition } from "chart.js/helpers";
// @ts-ignore
import colours from "styles/_colours.scss";

defaults.font.family = "HCo Gotham";

// Returns a copy of the values each time to avoid charts overriding each others config
export const globalBarChartOptions = (): BarChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  events: ["click", "touchstart", "touchmove", "clickmove"],
  plugins: {
    legend: {
      position: "top",
      align: "end",
      onClick: null,
      labels: {
        pointStyle: "rectRounded",
        usePointStyle: true,
        boxHeight: 10,
      },
    },
    zoom: {
      zoom: {
        pinch: {
          enabled: false,
        },
        wheel: {
          enabled: false,
        },
        mode: "x",
      },
    },
    datalabels: {
      color: "black",
      display: (context) => {
        if (!Boolean(context.dataset?.status)) return;
        const data = context.dataset?.data[context.dataIndex];
        const status = context.dataset?.status[context.dataIndex];
        return data === 0 && status === "missing";
      },
      formatter: function (_, context) {
        return context.dataset.noDataText ?? "";
      },
      rotation: 270,
      font: {
        lineHeight: 0,
      },
      clamp: true,
      backgroundColor: () => colours.white,
    },
  },
  elements: {
    bar: {
      borderRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        drawTicks: false,
        tickWidth: 2,
      },
      ticks: {
        font: {
          weight: [400],
        },
        backdropColor: [colours.white],
        showLabelBackdrop: true,
        backdropPadding: { top: 0, left: 10, right: 10, bottom: 15 },
        padding: 10,
      },
    },
    y: {
      title: {
        display: true,
        align: "start",
      },
      ticks: {
        autoSkip: true,
        font: {
          weight: 400,
        },
      },
      grid: {
        drawBorder: false,
        lineWidth: 2,
      },
    },
  },
  interaction: {
    mode: "index",
  },
  intersect: true,
});

export const selectedItemIndex = (
  event: MouseEvent | ChartEvent,
  chart: Chart,
): number => {
  // Allow for click events in x scale (outside of main chart click area)
  const position = getRelativePosition(event, chart).x;
  return chart.scales.x.getValueForPixel(position);
};

export const highlightSelectedItem = (itemIndex: number, chart: Chart) => {
  // The library is returning ticks as an array of Tick.
  // That doesn't match our usage of then setting the font weight and the backdrop color.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { ticks }: { ticks: any } = chart.options.scales.x as Scale;
  // reset styles for tick labels to default and highlight active one.
  const fontWeights = chart.data.labels.map((_label, labelIndex) =>
    itemIndex === labelIndex ? 600 : 400,
  );
  const backdropColors = chart.data.labels.map((_label, labelIndex) =>
    itemIndex === labelIndex ? colours.greyTint5 : colours.white,
  );
  ticks.font.weight = fontWeights;
  ticks.backdropColor = backdropColors;
  chart.update();
};

/**
 * Used for grouped bar charts - where each dataset has it's own specific bar colour
 */
export const barBackgroundColour = (
  barColour: string,
  modifier?: string,
): string => {
  return `${colours[barColour]}${modifier || ""}`;
};

/**
 * Used for single bar charts - where a single dataset can have different bar colours for each item
 */
export const barBackgroundColours = (
  barColours: string[],
  modifier?: string,
): string[] | void => {
  if (!barColours) {
    return;
  }

  return barColours.map((colour) => `${colours[colour]}${modifier || ""}`);
};

export const barHoverBackgroundColour = (
  hoverBackgroundColour: string,
): string => {
  return `${colours[hoverBackgroundColour]}`;
};

export const offpeakLegend: BarChartConfigLabel[] = [
  {
    text: "peak",
    fillStyle: colours["crimson"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
  {
    text: "off-peak",
    fillStyle: colours["green"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
  {
    text: "peak (estimate)",
    fillStyle: colours["pink"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
  {
    text: "off-peak (estimate)",
    fillStyle: colours["greyShade30"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
];

export const forecastPriceLegend: BarChartConfigLabel[] = [
  {
    text: "previous",
    fillStyle: colours["yellow"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
  {
    text: "upcoming",
    fillStyle: colours["greyShade30"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
];

export const hourlyUsageBreakdownLegend: BarChartConfigLabel[] = [
  {
    text: "Actual",
    fillStyle: colours["green"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
  {
    text: "Estimate",
    fillStyle: colours["pink"],
    pointStyle: "rectRounded",
    usePointStyle: true,
    boxHeight: 10,
    lineWidth: 0,
  },
];
