/* eslint-disable max-params */
import {
  AVAILABLE_PERIODS,
  BRAND_COLOURS,
  Y_AXIS_DISPLAY_TYPE,
} from "utils/constants";

/**
 * @description Takes the periodName and determines what the label for the period should be.
 * Months are hard-coded to 30 days, so 'previous' values in this graph do not represent the same equivalent day 1 month prior.
 */
export const determineLabelPeriod = (periodName: string): string => {
  return periodName === AVAILABLE_PERIODS.P1M.periodName
    ? "30 days"
    : periodName;
};

/**
 *
 * @description Takes the dailyUsage and displayType. Returns dailyusage processed into an object with arrays for this period and previous period final and missing, and pending usage or spend (depending on displayType)
 * @returns
 */
const getPreviousAndCurrentPeriodData = ({
  dailyUsageData,
  displayType,
}: {
  dailyUsageData: DailyUsage[];
  displayType: string;
}) => {
  const result = {
    thisPeriodFinalAndMissing: [],
    prevPeriodFinalAndMissing: [],
    thisPeriodPending: [],
    prevPeriodPending: [],
  };

  dailyUsageData.forEach((data) => {
    if (["final", "missing"].includes(data.status)) {
      result.thisPeriodFinalAndMissing.push(data.thisPeriod[displayType]);
      result.thisPeriodPending.push(0);
    }
    if (data.status === "pending") {
      result.thisPeriodPending.push(data.thisPeriod[displayType]);
      result.thisPeriodFinalAndMissing.push(0);
    }

    if (["final", "missing"].includes(data.previousStatus)) {
      result.prevPeriodFinalAndMissing.push(data.lastPeriod[displayType]);
      result.prevPeriodPending.push(0);
    }
    if (data.previousStatus === "pending") {
      result.prevPeriodPending.push(data.lastPeriod[displayType]);
      result.prevPeriodFinalAndMissing.push(0);
    }
  });

  return result;
};

/**
 * @description Takes the dailyUsageData, labelPeriod, and displayType (dollars or kWh). Returns groupedBarChartConfig object.
 */
export const generateDailyUsageGroupedBarChartConfig = ({
  displayType,
  dailyUsageData,
  labelPeriod,
}: {
  displayType: string;
  dailyUsageData: DailyUsage[];
  labelPeriod: string;
}): GroupedBarChartConfig => {
  const {
    thisPeriodFinalAndMissing,
    thisPeriodPending,
    prevPeriodFinalAndMissing,
    prevPeriodPending,
  } = getPreviousAndCurrentPeriodData({ dailyUsageData, displayType });

  return {
    labels: dailyUsageData.map((data) => [data.weekday, data.ordinal]),
    dates: dailyUsageData.map((data) => data.date),
    yAxisText: Y_AXIS_DISPLAY_TYPE[displayType],
    chartTitle: "Daily usage",
    datasets: [
      {
        label: `Previous ${labelPeriod}`,
        data: prevPeriodFinalAndMissing,
        status: dailyUsageData.map((data) => data.previousStatus),
        barColour: BRAND_COLOURS.greyShade30,
        hoverBackgroundColour: BRAND_COLOURS.greyShade50,
        stack: `Previous ${labelPeriod}`,
      },
      {
        label: `Current ${labelPeriod}`,
        data: thisPeriodFinalAndMissing,
        status: dailyUsageData.map((data) => data.status),
        barColour: BRAND_COLOURS.yellow,
        hoverBackgroundColour: BRAND_COLOURS.yellowDark,
        stack: `Current ${labelPeriod}`,
      },
      {
        label: "Previous (estimate)",
        data: prevPeriodPending,
        status: dailyUsageData.map((data) => data.previousStatus),
        barColour: BRAND_COLOURS.greyShade10,
        hoverBackgroundColour: BRAND_COLOURS.greyShade50,
        stack: `Previous ${labelPeriod}`,
      },
      {
        label: "Current (estimate)",
        data: thisPeriodPending,
        status: dailyUsageData.map((data) => data.status),
        barColour: BRAND_COLOURS.pink,
        hoverBackgroundColour: BRAND_COLOURS.pinkDark,
        stack: `Current ${labelPeriod}`,
      },
    ],
    showZoom: true,
  };
};
