import { isAfter } from "date-fns/isAfter";
import {
  BILL_PAYMENT_STATUSES,
  PAYMENT_ACTION_FAILED,
  PAYMENT_ACTION_PENDING,
} from "utils/constants";

const totalPaymentsValue = (payments: Array<PaymentAction>): number => {
  if (!payments) return;
  // successful payments can also include account credits which are passed with a different status
  const successfulPayments = payments.filter(
    (payment) =>
      ![PAYMENT_ACTION_PENDING, PAYMENT_ACTION_FAILED].includes(payment.status),
  );
  return successfulPayments.reduce(
    (sum, action) => sum + Number(action.amount.value),
    0,
  );
};

export const paymentStatus = (bill: Bill): string => {
  // check the total amount of successful payments is at least the same as the total bill value
  const billPaid =
    Number(bill.total_payment_due.price) <=
    totalPaymentsValue(bill.payment_actions);
  const dueDateInFuture = isAfter(new Date(bill.payment_due_date), new Date());
  // check if the payment has been attempted but not fully paid
  const overdue = bill.payment_actions?.some((item) => item) && !billPaid;

  if (billPaid) return BILL_PAYMENT_STATUSES.complete;
  if (dueDateInFuture) return BILL_PAYMENT_STATUSES.due;
  if (overdue) return BILL_PAYMENT_STATUSES.pastDue;

  return BILL_PAYMENT_STATUSES.notDetermined;
};
