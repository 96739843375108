import { TZDate } from "@date-fns/tz/date";
import { format } from "date-fns/format";
import { PERIOD_CODES } from "utils/constants";

/**
 * @description Returns the formatted next billing date based on the frequency selected
 */
export const getNextBillDate = (
  frequency: string,
  invoiceDates: UpcomingInvoicedAtDates,
  index: number,
): string => {
  if (!frequency || !invoiceDates) return "";
  return format(
    new TZDate(
      new Date(
        frequency === PERIOD_CODES.P2W
          ? invoiceDates[frequency][index]
          : invoiceDates[frequency][0] || "",
      ),
      "Pacific/Auckland",
    ),
    "EEEE d MMMM",
  );
};

export const getPaymentMethodById = (
  id: string,
  paymentMethods: AccountDetailPaymentMethod[],
) => {
  return paymentMethods?.find((pm) => pm.payment_method_id === id);
};

const typesMap = {
  creditcard: "urn:flick:bill:paymth:creditcard",
  directdebit: "urn:flick:bill:paymth:directdebit",
};

/**
 * @description Takes a payment method in the form returned by `customer/accounts` endpoint,
 * returns it in the `billing/customer_billing_entities` enpoint default_payment_method
 */
export const formatDefaultPaymentMethod = (
  paymentMethod: AccountDetailPaymentMethod,
): BillingEntityPaymentMethod => {
  const { payment_type, payment_number_reference } = paymentMethod;

  // Different APIs send different types, need to normalise them
  const paymentType = payment_type.replaceAll("_", "").toLowerCase();

  return {
    payment_type: typesMap[paymentType],
    token: paymentType === "creditcard" ? payment_number_reference : null,
    account_number:
      paymentType === "directdebit" ? payment_number_reference : null,
  };
};
