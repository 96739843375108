import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

/** Define the type for the context value */
interface InvoiceUsageChartContextType {
  selectedDayIndex: number;
  setSelectedDayIndex: Dispatch<SetStateAction<number>>;
  lastDayIndex: number;
  isSelectedDayNotFirstDay: boolean;
  isSelectedDayNotLastDay: boolean;
}

interface InvoiceUsageChartProviderProps {
  lastDayIndex?: number; // Optional prop to pass lastDayIndex on create
}

/** Create the context with a default value */
export const InvoiceUsageChartContext = createContext<
  InvoiceUsageChartContextType | undefined
>(undefined);

export const InvoiceUsageChartProvider: React.FC<
  PropsWithChildren<InvoiceUsageChartProviderProps>
> = ({ children, lastDayIndex = 0 }) => {
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0);

  useEffect(() => {
    // lastDayIndex only change when user navigates between invoices
    // so we use this to reset the chart always selected to the first day between invoices
    if (lastDayIndex > 0) {
      setSelectedDayIndex(0);
    }
  }, [lastDayIndex]);

  const isSelectedDayNotFirstDay = useMemo(
    () => selectedDayIndex > 0,
    [selectedDayIndex, lastDayIndex],
  );
  const isSelectedDayNotLastDay = useMemo(
    () => selectedDayIndex < lastDayIndex,
    [selectedDayIndex, lastDayIndex],
  );

  return (
    <InvoiceUsageChartContext.Provider
      value={{
        selectedDayIndex,
        setSelectedDayIndex,
        lastDayIndex,
        isSelectedDayNotFirstDay,
        isSelectedDayNotLastDay,
      }}
    >
      {children}
    </InvoiceUsageChartContext.Provider>
  );
};

export const useInvoiceUsageChart = () => {
  const context = useContext(InvoiceUsageChartContext);
  if (context === undefined) {
    throw new Error(
      "useInvoiceUsageChart must be used within a InvoiceUsageChartProvider",
    );
  }
  return context;
};
