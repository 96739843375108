/* eslint-disable prettier/prettier */
import "./index.scss";

import { Provider as RollbarProvider } from "@rollbar/react";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router";
import { jwtClient } from "utils/jwtClient";
import { rollbarConfig } from "utils/rollbarConfig";

import { AppWrapper } from "./AppWrapper";

const root = createRoot(document.getElementById("root"));

const RootApp = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAGMANAGER_ID || "",
    });
    // Define the setNativeJwt function globally on window
    window["setNativeJwt"] = (token) => {
      jwtClient.setNativeJwt(token);

      // Dispatch a custom event with the new token as detail
      const event = new CustomEvent("setNativeJwtChanged", { detail: token });
      window.dispatchEvent(event);
    };
  }, []);

  return (
    <React.StrictMode>
      <Router>
        <RollbarProvider config={rollbarConfig}>
          <HelmetProvider>
            <Helmet>
              <link
                rel="icon"
                href={`${process.env.REACT_APP_PUBLIC_URL}/favicon.ico`}
              />
              <link
                rel="manifest"
                href={`${process.env.REACT_APP_PUBLIC_URL}/manifest.json`}
              />
            </Helmet>
            <main>
              <AppWrapper />
            </main>
          </HelmetProvider>
        </RollbarProvider>
      </Router>
    </React.StrictMode>
  );
};

root.render(<RootApp />);
