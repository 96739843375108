import { BaseInput } from "@flick/fep-library";
import React, { ChangeEvent } from "react";

import { AvailableInputModes, AvailableInputTypes } from "./BasicInput.types";

type BasicInputProps = {
  ariaLabel?: string;
  dataTestId?: string;
  errorMsg?: string;
  handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
  handleOnBlur?(event: ChangeEvent<HTMLInputElement>): void;
  hint?: string;
  inputMode?: AvailableInputModes;
  inputSize?: "default" | "large";
  inputWidth?: "default" | "full";
  label: string;
  name: string;
  placeholderText?: string;
  required?: boolean;
  state?: "default" | "disabled" | "error";
  type?: AvailableInputTypes;
  value?: string;
};

export const BasicInput: React.FC<BasicInputProps> = ({
  ariaLabel,
  dataTestId,
  errorMsg,
  handleInputChange,
  handleOnBlur = () => ({}),
  hint,
  inputMode = "text",
  inputSize,
  inputWidth,
  label,
  name,
  placeholderText,
  required,
  type = "text",
  value = "",
  state,
}) => {
  return (
    <BaseInput
      data-testid={dataTestId}
      aria-label={ariaLabel}
      id={name}
      //    * due to how in dashboard, we do custom validation and this does not tie to native HTML valid state
      //    * (for example, mobile field,`0000000` is consider valid by the HTML
      //    * because it is a number, our custom validation however will state it is error because it is not NZ number)
      //    * so to work around the new wrapper input, we just display an empty error message to trigger error styling
      //    * we cannot just swap to use error message because in _input-base.scss
      //    *  &:valid { // "000000" is :valid so the error message will be hidden
      //    *    &~.input__hellper--error {
      //    *      display:none;
      //    *   }
      //    * }
      //    */
      errorText={state === "error" && errorMsg ? errorMsg : undefined}
      helperText={hint}
      labelText={label}
      name={name}
      placeholderText={placeholderText}
      required={required}
      type={type}
      inputWidth={inputWidth}
      inputStyle={inputSize}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      value={value}
      disabled={state === "disabled"}
      inputMode={inputMode}
    />
  );
};
