import { ReactComponent as BackIcon } from "app/assets/images/icons/chevron-left-lg.svg";
import { useBackNavigation } from "customHooks/useBackNavigation";
import React from "react";
import { Link } from "react-router";

export const BackLink: React.FC = () => {
  const { handleBackClicked, shouldRenderBackButton } = useBackNavigation();

  return (
    shouldRenderBackButton && (
      <nav className="nav_customer_tools_subnav__item">
        <BackIcon className="nav_customer_tools_subnav__icon" />
        <Link
          to="#"
          replace
          className="link link--strong"
          onClick={handleBackClicked}
        >
          Back
        </Link>
      </nav>
    )
  );
};
