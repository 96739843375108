import "./BillBreakdownSection.scss";

import React from "react";
import { NZD } from "utils/currency";

import {
  labelForTotal,
  prepareBillBreakdown,
} from "./BillBreakdownSection.utils";

/**
 * @description use in Bill Page to display details added up to a Bill
 */
type BillBreakdownSectionProps = {
  bill: Bill;
  lineItemGroups: LineItemGroup[];
  productName: string;
};

export const BillBreakdownSection: React.FC<BillBreakdownSectionProps> = ({
  lineItemGroups,
  bill,
  productName,
}) => {
  const includesSellback = Number(bill?.total_payment_due.sellback_price) > 0;
  const billBreakdown = prepareBillBreakdown(lineItemGroups, productName);

  return (
    <div className="breakdown">
      <h2 className="h4">Cost breakdown</h2>

      <table className="breakdown-table">
        <tbody>
          {Boolean(billBreakdown?.usage) &&
            Array.isArray(billBreakdown.usage) && (
              <>
                <tr key="daily" className="breakdown-header">
                  <td>Usage Charges</td>
                </tr>
                {billBreakdown.usage.length > 0 &&
                  billBreakdown.usage.map((item) => (
                    <tr
                      key={`usage-charge-value-${item.label}`}
                      className="breakdown-component"
                    >
                      <td>
                        {item.quantity} kWh {item.label}
                      </td>
                      <td className="breakdown-price">
                        {NZD.format(parseFloat(item.cost))}
                      </td>
                    </tr>
                  ))}
              </>
            )}
          {Boolean(billBreakdown?.daily) && (
            <>
              <tr key="daily" className="breakdown-header">
                <td>Daily Charges</td>
              </tr>
              <tr key="daily-charge-value" className="breakdown-component">
                <td>{billBreakdown.daily.quantity}</td>
                <td className="breakdown-price">
                  {NZD.format(parseFloat(billBreakdown.daily.cost))}
                </td>
              </tr>
            </>
          )}
          {Boolean(billBreakdown?.washup) &&
            Array.isArray(billBreakdown.usage) &&
            billBreakdown.usage.length > 0 && (
              <>
                {billBreakdown.washup.map(
                  (item) =>
                    Boolean(Number(item.cost)) && (
                      <tr
                        key={`washup-charge-value-${item.label}`}
                        className="breakdown-item"
                      >
                        <td>{item.label}</td>
                        <td className="breakdown-price">
                          {NZD.format(Number(item.cost))}
                        </td>
                      </tr>
                    ),
                )}
              </>
            )}
          {Boolean(billBreakdown?.other) && billBreakdown?.other.length > 0 && (
            <>
              {billBreakdown.other.map(
                (item) =>
                  Boolean(Number(item.cost)) && (
                    <tr
                      key={`other-charge-value-${item.label}`}
                      className="breakdown-item"
                    >
                      <td>{item.label}</td>
                      <td className="breakdown-price">
                        {NZD.format(+item.cost)}
                      </td>
                    </tr>
                  ),
              )}
            </>
          )}
          <tr key={`gst-value`} className="breakdown-item">
            <td>Plus GST</td>
            <td className="breakdown-price">
              {NZD.format(Number(bill.total_payment_due.tax))}
            </td>
          </tr>
          <tr className="breakdown-total">
            <td>{includesSellback ? "Bill total" : labelForTotal(bill)}</td>
            <td className="breakdown-price">
              {NZD.format(Number(bill.total_payment_due.price_excl_sellback))}
            </td>
          </tr>
          {includesSellback && (
            <>
              <tr className="breakdown-item">
                <td>Electricity sold</td>
                <td className="breakdown-price">
                  {NZD.format(Number(bill.total_payment_due.sellback_price))}
                </td>
              </tr>
              <tr className="breakdown-total">
                <td>{labelForTotal(bill)}</td>
                <td className="breakdown-price">
                  {NZD.format(Number(bill.total_payment_due.price))}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
