import { TZDate } from "@date-fns/tz/date";
import { BarChartWrapper } from "components/charts/BarChartWrapper";
import { format } from "date-fns/format";
import React from "react";

import content from "./static/content.json";

const { chartTitle, yAxisText } = content;

type HourlyKwhCostChartProps = {
  selectedDayMaxYValue: number;
  dailyKwhValues: ChartUsage[];
};

export const HourlyKwhCostChart: React.FC<HourlyKwhCostChartProps> = ({
  dailyKwhValues,
  selectedDayMaxYValue,
}) => {
  const barColours: string[] = [];
  const data: number[] = [];
  const status: string[] = [];
  const labels: string[] = [];

  dailyKwhValues.forEach((item) => {
    barColours.push(item.barColour);
    data.push(item.value);
    status.push(item.barColour);
    labels.push(format(new TZDate(item.time, "Pacific/Auckland"), "h:mm a"));
  });

  const barChartConfig = {
    chartTitle,
    datasets: [
      {
        barColours,
        data,
        status,
      },
    ],
    labels,
    legendOptions: {
      display: false,
    },
    showZoom: true,
    yAxisText,
  };

  return (
    <BarChartWrapper
      key={selectedDayMaxYValue}
      chartConfig={barChartConfig}
      maxYValue={selectedDayMaxYValue}
    />
  );
};
