import { BaseInput } from "@flick/fep-library";
import React, { ChangeEvent } from "react";

type DateInputProps = {
  errorMessage?: string;
  extraClasses?: string;
  handleDateChange?: (value: string) => void;
  handleOnBlur?: () => void;
  hint?: string;
  label: string;
  min?: string | number;
  max?: string | number;
  name: string;
  required?: boolean;
  value?: string;
};

export const DateInput: React.FC<DateInputProps> = ({
  errorMessage = "",
  handleDateChange,
  handleOnBlur,
  hint,
  label,
  max,
  min,
  name,
  required = false,
  value,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleDateChange(event.target.value);
  };

  return (
    <BaseInput
      id={name}
      //   /**
      //    * due to how in dashboard, we do custom validation and this does not tie to native HTML valid state
      //    * (for example, mobile field,`0000000` is consider valid by the HTML
      //    * because it is a number, our custom validation however will state it is error because it is not NZ number)
      //    * so to work around the new wrapper input, we just display an empty error message to trigger error styling
      //    * we cannot just swap to use error message because in _input-base.scss
      //    *  &:valid { // "000000" is :valid so the error message will be hidden
      //    *    &~.input__hellper--error {
      //    *      display:none;
      //    *   }
      //    * }
      //    */
      errorText={errorMessage}
      helperText={hint}
      inputWidth="full"
      labelText={label}
      name={name}
      required={required}
      type="date"
      max={max}
      min={min}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      value={value}
    />
  );
};
