import { billingApi } from "queries/billingApi";
import { contractManagerApi } from "queries/contractManagerApi";
import { customerApi } from "queries/customerApi";
import { identityApi } from "queries/identityApi";
import { paymentSmootherApi } from "queries/paymentSmootherApi";
import { pricingRateCardApi } from "queries/pricingRateCardApi";
import { provisioningApi } from "queries/provisioningApi";
import { publicApi } from "queries/publicApi";
import { ratingApi } from "queries/ratingApi";
import { telemetryApi } from "queries/telemetryApi";
import { rollbar } from "utils/jwtClient";

const apiSlices = [
  billingApi,
  contractManagerApi,
  customerApi,
  identityApi,
  paymentSmootherApi,
  pricingRateCardApi,
  provisioningApi,
  publicApi,
  ratingApi,
  telemetryApi,
];

/**
 * @description: clear all queries caches
 */
export const clearCacheForAllApis = async () => {
  try {
    await Promise.all(
      apiSlices.flatMap((apiSlice) =>
        Object.values(apiSlice.endpoints).map((endpoint) =>
          apiSlice.util.invalidateTags(endpoint),
        ),
      ),
    );
  } catch (error) {
    rollbar.error("Error invalidating cache for APIs:", error);
  }
};
