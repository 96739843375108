import "./TotalUsageSection.scss";

import { ReactComponent as BoltIconSvg } from "app/assets/images/icons/bolt-pointy-solid.svg";
import { ReactComponent as DollarSignIconSvg } from "app/assets/images/icons/dollar-solid.svg";
import { ReactComponent as TooltipIconSvg } from "app/assets/images/icons/question-circle-black.svg";
import {
  ErrorBoundaryHOCProps,
  withErrorBoundary,
} from "components/fallback/withErrorBoundary";
import { LoadingSection } from "components/LoadingSection";
import { TotalValueComparisonChart } from "components/TotalValueComparisonChart";
import { SectionLinks } from "elements/SectionLinks";
import { Tooltip, TooltipContent } from "elements/Tooltip";
import content from "elements/Tooltip/static/content.json";
import { TotalWithUnit } from "elements/TotalWithUnit";
import { TabProvider } from "pages/UsagePage/components/TabPanel/TabContext";
import {
  Tab,
  TabList,
  TabPanel,
} from "pages/UsagePage/components/TabPanel/TabPanel";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import {
  selectCurrentUsageInDollarsWithFormat,
  selectCurrentUsageInPower,
  selectLatestValidDate,
  selectPrevUsageInDollars,
  selectPrevUsageInPower,
  selectYearAgoUsageInDollars,
  selectYearAgoUsageInPower,
} from "selectors/currentUsageSelector";
import { UNIT_CODE_TYPES } from "utils/constants";

const { kWhTip } = content;

export const TotalUsageSection: React.FC<
  {
    links?: { label: string; path: string }[];
    sectionHeading?: string;
  } & ErrorBoundaryHOCProps
> = withErrorBoundary(({ links, sectionHeading }) => {
  const { currentUsageData, currentBillingPeriod, isCurrentUsageDataFetching } =
    useAppSelector((store) => store.billingEntityData);

  const latestValidDate = useAppSelector(selectLatestValidDate);
  const currentUsageInDollars = useAppSelector(
    selectCurrentUsageInDollarsWithFormat,
  );
  const currentUsageInPower = useAppSelector(selectCurrentUsageInPower);
  const lastUsageInPower = useAppSelector(selectPrevUsageInPower);
  const lastUsageInDollars = useAppSelector(selectPrevUsageInDollars);
  const yearAgoUsageInPower = useAppSelector(selectYearAgoUsageInPower);
  const yearAgoUsageInDollars = useAppSelector(selectYearAgoUsageInDollars);

  if (!currentUsageData && isCurrentUsageDataFetching && currentBillingPeriod) {
    return <LoadingSection />;
  }

  return (
    <>
      {sectionHeading && (
        <h2 className="section-title heading h4">{sectionHeading}</h2>
      )}
      <div className="spacer--medium" />
      <TabProvider value="dollars">
        <TabList>
          <Tab label="Dollars" value="dollars" />
          <Tab label="kWh" value="kwh" />
        </TabList>
        <TabPanel value="dollars">
          <div className="usage-tab__container background--white">
            <div>
              {latestValidDate && <p>As at {latestValidDate}</p>}
              {currentUsageInDollars ? (
                <TotalWithUnit
                  icon={<DollarSignIconSvg />}
                  total={currentUsageInDollars}
                />
              ) : (
                <p>Current usage amount is not available</p>
              )}
              <TotalValueComparisonChart
                previousValue={lastUsageInDollars}
                comparisonValue={yearAgoUsageInDollars}
                periodName={currentBillingPeriod?.periodName}
                unitType={UNIT_CODE_TYPES.cost}
              />
            </div>
            <div className="spacer--medium" />
            <SectionLinks links={links} />
          </div>
        </TabPanel>
        <TabPanel value="kwh">
          <div className="usage-tab__container background--white">
            {latestValidDate && <p>As at {latestValidDate}</p>}
            {currentUsageInPower ? (
              <>
                <TotalWithUnit
                  icon={<BoltIconSvg />}
                  total={currentUsageInPower}
                  unitCode={"kWh"}
                >
                  <Tooltip>
                    <TooltipIconSvg className="tooltip-responsive__icon" />
                    <TooltipContent>{kWhTip}</TooltipContent>
                  </Tooltip>
                </TotalWithUnit>
              </>
            ) : (
              <p>Current usage amount is not available</p>
            )}
            <TotalValueComparisonChart
              previousValue={Number(lastUsageInPower)}
              comparisonValue={Number(yearAgoUsageInPower)}
              periodName={currentBillingPeriod?.periodName}
              unitType={UNIT_CODE_TYPES.usage}
            />
            <div className="spacer--medium" />
            <SectionLinks links={links} />
          </div>
        </TabPanel>
      </TabProvider>
    </>
  );
});
