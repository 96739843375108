/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useRef } from 'react';
import InputLabel from '@flick/front_end_patterns/src/js/forms/form_fields_label';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Tooltips from '@flick/front_end_patterns/src/js/dna/tooltips';
import Dialog from '../common/dialog';
import sendAnalytics, { sendAnalyticsToDataLayer } from '../../utils/analytics';
import {
  ANALYTICS_BILL_ESTIMATE_SHOW,
  ANALYTICS_BILL_ESTIMATE_SUBMIT,
  ANALYTICS_BILL_ESTIMATE_LINK,
  ANALYTICS_BILL_ESTIMATE_TOOLTIP,
} from '../../utils/constants';

const BillEstimateDialog = ({ billDetails, businessPlan, updateBillDetails, icpNumber }) => {
  const dialogRef = useRef();
  const hasExistingEstimate = !Object.values(billDetails).includes(null);
  const showDialog = () => {
    sendAnalytics('Bill Estimate Dialog: show', 'Clicked');
    sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_SHOW, billDetails);
    dialogRef?.current.show();
  };

  // TODO: this should be refactored
  const sanitizeBillData = useCallback((data) => {
    // bill estimate date ranges are inclusive
    const startDate = new Date(data.startDate).setHours(0);
    const endDate = new Date(data.endDate).setHours(24);
    const secondsInBillingPeriod = endDate - startDate;
    const daysInBillingPeriod = secondsInBillingPeriod / (1000 * 3600 * 24);
    updateBillDetails({
      ...data,
      daysInBillingPeriod,
    });
  });

  const defaultValues = {
    cost: billDetails.cost,
    usage: billDetails.usage,
    startDate: billDetails.startDate,
    endDate: billDetails.endDate,
  };

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    new InputLabel().start();
  }, []);

  useEffect(() => {
    if (!hasExistingEstimate) {
      reset(defaultValues);
    }
  }, [billDetails]);

  const hideDialog = () => dialogRef?.current.hide();

  const onSubmit = useCallback((data) => {
    sendAnalytics('Bill Estimate Dialog: submit', 'Clicked');
    sanitizeBillData(data);
    sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_SUBMIT, {
      usage: data.usage,
      cost: data.cost,
      start_date: data.startDate,
      end_date: data.endDate,
      icp_number: icpNumber,
    });
    hideDialog();
  });

  useEffect(() => {
    new Tooltips().start();
  }, []);

  const estimatePrompt = businessPlan ? 'Get a personalised bill estimate' : 'Estimate my bill';

  const afterStartDate = (endDate) => endDate > getValues('startDate');

  return (
    <div className="plan_card_component_filters_actions">
      <button className="button button_secondary" onClick={showDialog} type="button">
        {hasExistingEstimate ? 'Change bill details' : estimatePrompt}
      </button>

      <Dialog id="pricing-estimate" dialogRef={dialogRef} theme="echo" size="">
        <h1>Get an accurate estimate</h1>
        <p>
          Pop in some details from a recent bill. We&apos;ll update the estimates to reflect your
          usage.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form_row">
            <div className="form_field">
              <div>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  {...register('startDate', { required: true })}
                />
                <label htmlFor="startDate" required>
                  Start date for this bill
                </label>
              </div>
              {errors.startDate?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Please confirm the start date for this bill.
                </span>
              )}
            </div>
          </div>
          <div className="form_row">
            <div className="form_field">
              <div>
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  {...register('endDate', {
                    required: true,
                    validate: {
                      afterStartDate,
                    },
                  })}
                />
                <label htmlFor="endDate" required>
                  End date for this bill
                </label>
              </div>
              {errors.endDate?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Please confirm the end date for this bill.
                </span>
              )}
              {errors.endDate?.type === 'afterStartDate' && (
                <span className="form_field_helper form_field_helper_error">
                  End date must be after start date.
                </span>
              )}
            </div>
          </div>
          <div className="form_row">
            <div className="form_field">
              <div>
                <input
                  type="number"
                  name="usage"
                  id="usage"
                  step="any"
                  {...register('usage', { required: true })}
                />
                <label htmlFor="usage" required>
                  Total usage for the bill? (kWh)
                </label>
              </div>
              {errors.usage?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Please confirm the kilowatts used in this bill.
                </span>
              )}
            </div>
          </div>
          <div className="form_row" data-bias="center">
            <div className="form_field form_field_help--absolute">
              <div>
                <input
                  type="number"
                  step="any"
                  name="cost"
                  id="cost"
                  {...register('cost', { required: true })}
                />
                <label htmlFor="cost" required>
                  What was the total bill cost? (incl GST)
                </label>
                <span
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex="0"
                  onMouseOver={() => {
                    sendAnalytics('Bill Estimate Dialog: tooltip', 'Clicked');
                    sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_TOOLTIP, billDetails);
                  }}
                  onFocus={() => {
                    sendAnalytics('Bill Estimate Dialog: tooltip', 'Clicked');
                    sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_TOOLTIP, billDetails);
                  }}
                  className="tooltip_help"
                  data-tooltip="Please enter your power bill total, including any ongoing discounts and GST. If you are unsure how much this is, or if your power bill has any of the following, we suggest sending us a copy of a recent bill to get an accurate comparison: <ul>
                  <li>Additional services such as gas or internet</li>
                  <li>Non recurring credits</li>
                  <li>Debits (outstanding amounts from a previous bill)</li>
                  <li>Late bill fees</li>
                  <li>Top up bonuses</li>
                  <li>Powerpacks</li>
                  </ul>"
                />
              </div>
              {errors.cost?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  How much did this bill cost?
                </span>
              )}
            </div>
          </div>
          <div className="form_row form_actions" data-bias="split">
            <button className="button button_primary" type="submit">
              {hasExistingEstimate ? 'Update your usage' : 'Get comparison'}
            </button>
          </div>
        </form>
        <section>
          <h2 className="h5" style={{ margin: '.75em 0' }}>
            Still not sure?
          </h2>
          <p>
            <a
              className="link"
              href="https://www.flickelectric.co.nz/bill-comparison/"
              onClick={() => {
                sendAnalytics('Bill Estimate Dialog: Send us a bill link', 'Clicked');
                sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_LINK, billDetails);
              }}
            >
              Send us a bill
            </a>{' '}
            and we can get an accurate estimate for you.
          </p>
        </section>
      </Dialog>
    </div>
  );
};
export default BillEstimateDialog;

BillEstimateDialog.propTypes = {
  billDetails: PropTypes.object.isRequired,
  businessPlan: PropTypes.bool,
  updateBillDetails: PropTypes.func.isRequired,
  setGstInclusion: PropTypes.func.isRequired,
  icpNumber: PropTypes.string.isRequired,
};

BillEstimateDialog.defaultProps = {
  businessPlan: false,
};
