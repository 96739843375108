/**
 * @description RollBar config for RollBar https://www.npmjs.com/package/rollbar
 */
export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENV || process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
  ignoredMessages: [
    "Script error.",
    // the errors belows already has rollbar info logs
    "Redirect URI state code verification failed",
    "Refresh token with Authorization Code failed",
    "JWT not set",
    "API call failure: API response returns undefined",
    "JWT expired",
    "API call failure: Ignore this error",
    // the errors belows are not critical errors
    "useTabContext must be used within a TabProvider",
    "useInvoice must be used within an InvoiceProvider",
    "useMonthlyUsageChart must be used within a MonthlyUsageChartProvider",
    "User does not have enode access",
    "Not authorized for action 'show', resource: enode_user, app_name: 'telemetry'",
  ],
  checkIgnore: (isUncaught, args, payload) => {
    const errorUrl = payload.custom.response.url;
    if (errorUrl.includes("payment_smoother/customer_configuration")) {
      return true;
    }
    if (errorUrl.includes("customer/user_accounts_info")) {
      return true;
    }
  },
};
