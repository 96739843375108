import { BasicInput } from "components/Inputs/BasicInput";
import React, { ChangeEvent } from "react";

type PhoneNumberInputProps = {
  ariaLabel?: string;
  disabled?: boolean;
  errorMessage: string;
  handleOnBlur?: () => void;
  hint?: string;
  inputSize?: "default" | "large";
  inputWidth?: "default" | "full";
  label?: string;
  name: string;
  placeholderText?: string;
  required?: boolean;
  setValue: (value: string, index?: number) => void;
  value: string;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  ariaLabel,
  disabled,
  errorMessage,
  handleOnBlur,
  hint,
  inputSize,
  inputWidth,
  label,
  name,
  placeholderText,
  required,
  setValue,
  value,
}) => {
  const handleInputChange = (event: ChangeEvent) => {
    const { value: eventValue } = event.target as HTMLInputElement;
    setValue(eventValue);
  };

  return (
    <BasicInput
      ariaLabel={ariaLabel}
      errorMsg={errorMessage}
      handleInputChange={handleInputChange}
      handleOnBlur={handleOnBlur}
      hint={hint}
      inputMode="tel"
      inputSize={inputSize}
      inputWidth={inputWidth}
      label={label}
      name={name}
      placeholderText={placeholderText}
      required={required}
      state={disabled ? "disabled" : errorMessage ? "error" : "default"}
      type="tel"
      value={value}
    />
  );
};
