import { TZDate } from "@date-fns/tz/date";
import { createSelector } from "@reduxjs/toolkit";
import { format } from "date-fns/format";
import { RootState } from "reduxUtils/store";
import {
  getAggregatesTotalCostValue,
  getAggregatesTotalExportRevenue,
  getAggregatesTotalImportCost,
  getValidAggregateTotals,
} from "utils/aggregateCalculations/findValidAggregateTotals";
import { NZDNoDollarSign } from "utils/currency";
import { safeNumber } from "utils/safeNumber/safeNumber";
import {
  prepareUsageExportTotal,
  prepareUsageTotal,
} from "utils/usageCalculations/prepareUsageTotal";

//-------------------Helper selectors to extract usage data from state----------------
const selectCurrentUsageData = (state: RootState) =>
  state.billingEntityData?.currentUsageData;
const selectPrevUsageData = (state: RootState) =>
  state.billingEntityData?.prevUsageData;
const selectYearAgoUsageData = (state: RootState) =>
  state.billingEntityData?.yearAgoUsageData;

//------------------Helper to filter usage data and exclude missing aggregates--------
/**
 * Filter out aggregetes with "missing" status to correctly calculate the usage/sold/bought totals.
 * Please read Dashboard wiki - Aggregate data with "missing" status (https://github.com/FlickElectric/dashboard/wiki/Aggregate-data-%E2%80%90-%22missing%22-status) for more information
 */
const filterUsageToExcludeMissingData = (usageData: Aggregates | undefined) =>
  usageData ? getValidAggregateTotals(usageData) : undefined;

const filterCurrentUsageToExcludeMissingData = createSelector(
  selectCurrentUsageData,
  filterUsageToExcludeMissingData,
);

//-------------------Selectors to calculate usage in power --------------------
export const selectCurrentUsageInPower = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return prepareUsageTotal(filterUsageData);
  },
);
export const selectPrevUsageInPower = createSelector(
  selectPrevUsageData,
  (prevUsageData) => {
    return prevUsageData
      ? prepareUsageTotal(prevUsageData.aggregates)
      : undefined;
  },
);
export const selectYearAgoUsageInPower = createSelector(
  selectYearAgoUsageData,
  (yearAgoData) => {
    return yearAgoData ? prepareUsageTotal(yearAgoData.aggregates) : undefined;
  },
);

//-------------------Selectors to calculate usage in dollars--------------------
export const selectCurrentUsageInDollars = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return getAggregatesTotalCostValue(filterUsageData);
  },
);
export const selectCurrentUsageInDollarsWithFormat = createSelector(
  selectCurrentUsageInDollars,
  (usageTotalInDollar) => {
    return usageTotalInDollar
      ? NZDNoDollarSign.format(usageTotalInDollar).replace("NZD", "").trim()
      : undefined;
  },
);
export const selectPrevUsageInDollars = createSelector(
  selectPrevUsageData,
  (prevUsageData) => {
    return prevUsageData
      ? getAggregatesTotalCostValue(prevUsageData.aggregates)
      : undefined;
  },
);
export const selectYearAgoUsageInDollars = createSelector(
  selectYearAgoUsageData,
  (yearAgoUsageData) => {
    return yearAgoUsageData
      ? getAggregatesTotalCostValue(yearAgoUsageData.aggregates)
      : undefined;
  },
);

//------------------Helper function to calculate total import usage---------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createTotalImportInPowerSelector = (usageSelector: any) =>
  createSelector(usageSelector, (usageData) => {
    if (!usageData || !usageData.total_import_quantity) return undefined;
    return safeNumber(parseFloat(usageData.total_import_quantity), 3);
  });

//-------------------Selectors to calculate total import (bought) in power--------------------
export const selectCurrentTotalImportInPower = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return filterUsageData ? prepareUsageTotal(filterUsageData) : undefined;
  },
);
export const selectPrevTotalImportInPower =
  createTotalImportInPowerSelector(selectPrevUsageData);
export const selectYearAgoTotalImportInPower = createTotalImportInPowerSelector(
  selectYearAgoUsageData,
);

//------------------Helper function to caculate total import cost---------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculateTotalImportCost = (usageData: any) => {
  if (
    !usageData ||
    !usageData.total_import_cost ||
    !usageData.total_import_tax
  ) {
    return undefined;
  }
  return (
    parseFloat(usageData.total_import_cost) +
    parseFloat(usageData.total_import_tax)
  );
};

//------------------Selectors to calculate total import (bought) in dollars--------------------
export const selectCurrentTotalImportInDollars = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return getAggregatesTotalImportCost(filterUsageData);
  },
);
export const selectCurrentTotalImportInDollarsWithFormat = createSelector(
  selectCurrentTotalImportInDollars,
  (totalImportCost) => {
    return totalImportCost
      ? NZDNoDollarSign.format(safeNumber(totalImportCost, 2))
          .replace("NZD", "")
          .trim()
      : undefined;
  },
);
export const selectPrevTotalImportInDollars = createSelector(
  selectPrevUsageData,
  (usageData) => {
    const totalImportCost = calculateTotalImportCost(usageData);
    return safeNumber(totalImportCost, 2);
  },
);
export const selectYearAgoTotalImportInDollars = createSelector(
  selectYearAgoUsageData,
  (usageData) => {
    const totalImportCost = calculateTotalImportCost(usageData);
    return safeNumber(totalImportCost, 2);
  },
);

//------------------Helper function to calculate total export usage---------------------
const createTotalExportInPowerSelector = (selectUsageData) =>
  createSelector(selectUsageData, (usageData) => {
    if (!usageData?.total_export_quantity) return undefined;

    const totalExportQuantity = parseFloat(usageData.total_export_quantity);
    return safeNumber(totalExportQuantity, 3);
  });

//-------------------Selectors to calculate total export (sold) in power--------------------
export const selectCurrentTotalExportInPower = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return filterUsageData
      ? prepareUsageExportTotal(filterUsageData)
      : undefined;
  },
);
export const selectPrevTotalExportInPower =
  createTotalExportInPowerSelector(selectPrevUsageData);
export const selectYearAgoTotalExportInPower = createTotalExportInPowerSelector(
  selectYearAgoUsageData,
);

//------------------Helper function to caculate total export revenue---------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculateTotalExportRevenue = (usageData: any) => {
  if (!usageData?.total_export_revenue) return undefined;
  return Math.abs(parseFloat(String(usageData.total_export_revenue)));
};

//-------------------Selectors to calculate total export (sold) in dollars--------------------
export const selectCurrentTotalExportInDollars = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (filterUsageData) => {
    return getAggregatesTotalExportRevenue(filterUsageData);
  },
);
export const selectCurrentTotalExportInDollarsWithFormat = createSelector(
  selectCurrentTotalExportInDollars,
  (totalExportRevenue) => {
    return totalExportRevenue
      ? NZDNoDollarSign.format(safeNumber(totalExportRevenue, 2))
          .replace("NZD", "")
          .trim()
      : undefined;
  },
);
export const selectPrevTotalExportInDollars = createSelector(
  selectPrevUsageData,
  (usageData) => {
    const totalExportRevenue = calculateTotalExportRevenue(usageData);
    return safeNumber(totalExportRevenue, 2);
  },
);
export const selectYearAgoTotalExportInDollars = createSelector(
  selectYearAgoUsageData,
  (usageData) => {
    const totalExportRevenue = calculateTotalExportRevenue(usageData);
    return safeNumber(totalExportRevenue, 2);
  },
);

//-------------------------Selector to get the latest valid date-----------------------
export const selectLatestValidDate = createSelector(
  filterCurrentUsageToExcludeMissingData,
  (state: RootState) => state.billingEntityData?.currentUsageData,
  (filterUsageData, currentUsageData) => {
    if (!filterUsageData || !currentUsageData) return undefined;

    const allValidDates = filterUsageData.map((total) =>
      new Date(total.end_at).getTime(),
    );

    // Find the latest valid date
    const latestDate = allValidDates.length
      ? new Date(Math.max(...allValidDates))
      : new Date(currentUsageData.aggregates[0].end_at);

    return latestDate
      ? format(new TZDate(latestDate, "Pacific/Auckland"), "EEE d MMM")
      : undefined;
  },
);
