import { Form, FormRow } from "@flick/fep-library";
import { AccountPageLink } from "components/AccountPageLink";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { PhoneNumberInput } from "components/Inputs/PhoneNumberInput";
import { TextInput } from "components/Inputs/TextInput";
import { LoadingSection } from "components/LoadingSection";
import { SubmitButton } from "components/SubmitButton";
import { useAccountDetailsFormContext } from "pages/AccountDetailsPage/AccountDetailsPageContext";
import {
  UpdatePhoneNumbersParams,
  useUpdatePhoneNumbersMutation,
} from "queries/customerApi";
import { useUpdateUserDetailsMutation } from "queries/identityApi";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import {
  detectInputValuesChanged,
  formatPhoneNumberParams,
  getPhoneNumberArrays,
  prefixRemovedNZNumber,
} from "./AccountDetailsPage.utils";
import content from "./static/content.json";
const {
  emailHint,
  emailLabel,
  emailVerificationInProgressInfo,
  homeNumberLabel,
  homeNumberHint,
  mobileNumberLabel,
  preferredNameLabel,
  submitLabel,
  updatePhoneNumbersError,
  updateUserDetailsError,
} = content;

export const AccountDetailsPageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { accountNumber, active: accountActive } = useAppSelector(
    (store) => store.currentAccount,
  );
  const {
    preferredName: preferredNameState,
    email: emailState,
    userSub,
    unconfirmedEmail: unconfirmedEmailState,
    primaryContact,
  } = useAppSelector((store) => store.accountDetails);

  const [userDetailsResponseState, setUserDetailsResponseState] = useState({
    error: false,
    success: false,
    unconfirmedEmail: undefined,
    unconfirmedEmailUpdated: false,
  });

  const [phoneNumbersResponseState, setPhoneNumbersResponseState] = useState({
    error: false,
    success: false,
  });

  // divide phone numbers into home and mobile arrays
  const { mobileNumbers, homeNumbers } = useMemo(() => {
    return getPhoneNumberArrays(primaryContact?.phoneNumbers);
  }, [primaryContact?.phoneNumbers]);

  const {
    emailVerificationInProgress,
    isFormValid,
    preferredName: {
      fieldValue: { value: preferredNameValue, error: preferredNameError },
      handleOnChange: setPreferredName,
      handleOnBlur: handlePreferredNameBlur,
    },
    email: {
      fieldValue: { value: emailValue, error: emailError },
      handleOnChange: setEmail,
      handleOnBlur: handleEmailBlur,
    },
    mobileNumber: {
      fieldValueArray: mobileNumbersValue,
      addDynamicInputs: addMobileNumberInputs,
      handleOnChange: setMobileNumber,
      handleOnBlur: handleMobileNumberBlur,
    },
    homeNumber: {
      fieldValueArray: homeNumbersValue,
      addDynamicInputs: addHomeNumberInputs,
      handleOnChange: setHomeNumber,
      handleOnBlur: handleHomeNumberBlur,
    },
  } = useAccountDetailsFormContext();

  useEffect(() => {
    if (preferredNameState) {
      setPreferredName(preferredNameState);
    }
  }, [preferredNameState]);

  useEffect(() => {
    if (emailState) {
      setEmail(emailState);
    }
  }, [emailState]);

  useEffect(() => {
    if (primaryContact?.phoneNumbers) {
      addMobileNumberInputs({
        values: mobileNumbers,
        type: "mobile",
      });
      addHomeNumberInputs({
        values: homeNumbers,
        type: "home",
      });

      mobileNumbers.forEach(({ number }, index) => {
        setMobileNumber(number, index);
      });
      homeNumbers.forEach(({ number }, index) => {
        setHomeNumber(number, index);
      });
    }
  }, [primaryContact?.phoneNumbers]);

  useEffect(() => {
    const {
      error: userDetailsError,
      success: userDetailsSuccess,
      unconfirmedEmail,
      unconfirmedEmailUpdated,
    } = userDetailsResponseState;
    const { error: phoneNumbersError, success: phoneNumbersSuccess } =
      phoneNumbersResponseState;

    // display error banner if either post has failed
    if (userDetailsError || phoneNumbersError) {
      const errors = [];
      if (userDetailsError) errors.push({ detail: updateUserDetailsError });
      if (phoneNumbersError) errors.push({ detail: updatePhoneNumbersError });

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.accountDetails,
          page: pathname,
          additionalContent: errors,
        }),
      );
    }
    // display success banner if both posts succeed or if only one post is sent and is successful
    if (
      (userDetailsSuccess || !userDetailsChanged) &&
      (phoneNumbersSuccess || !phoneNumbersChanged)
    ) {
      dispatch(
        unconfirmedEmailUpdated
          ? setNotificationBanner({
              type: NotificationBannerTypes.success,
              name: NotificationBannerNames.accountDetailsEmail,
              page: pathname,
              params: {
                unconfirmedEmail,
              },
            })
          : setNotificationBanner({
              type: NotificationBannerTypes.success,
              name: NotificationBannerNames.accountDetails,
              page: pathname,
            }),
      );
    }
  }, [userDetailsResponseState, phoneNumbersResponseState]);

  /**
   * Submit calls two APIs - one to update userDetails (email, name), the other to update phone numbers
   * Some conditional logic is applied to only call the endpoint(s) for which values have changes, and
   * to only send the values of the inputs that have changed.
   */
  const [updateUserDetails] = useUpdateUserDetailsMutation();

  const handleUpdateUserDetails = async ({
    currentUserSub,
    preferredName,
    email,
  }) => {
    try {
      const data = await updateUserDetails({
        userId: currentUserSub,
        preferredName,
        email,
        jwtClient,
      }).unwrap();

      const unconfirmedEmail = data?.attributes?.unconfirmed_email;
      const unconfirmedEmailUpdated =
        emailChanged && unconfirmedEmail !== unconfirmedEmailState;

      setUserDetailsResponseState({
        error: false,
        success: true,
        unconfirmedEmail,
        unconfirmedEmailUpdated,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setUserDetailsResponseState({
        ...userDetailsResponseState,
        error: true,
        success: false,
      });
    }
  };

  const [updatePhoneNumbers] = useUpdatePhoneNumbersMutation();

  const handleUpdatePhoneNumber = async ({
    params: { contactId, mobileNumberParams, homeNumberParams },
  }: Omit<UpdatePhoneNumbersParams, "jwtClient" | "accountIdentifier">) => {
    try {
      await updatePhoneNumbers({
        jwtClient,
        accountIdentifier: accountNumber,
        params: {
          contactId,
          mobileNumberParams,
          homeNumberParams,
        },
      }).unwrap();

      setPhoneNumbersResponseState({ error: false, success: true });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setPhoneNumbersResponseState({ error: true, success: false });
    }
  };

  // call a util function to detect whether form field values have changed vs their values in state
  const {
    anyInputChanged,
    emailChanged,
    homephoneNumbersChanged,
    mobilephoneNumbersChanged,
    phoneNumbersChanged,
    preferredNameChanged,
    userDetailsChanged,
  } = detectInputValuesChanged({
    emailState,
    emailValue,
    homeNumbers,
    homeNumbersValue,
    mobileNumbers,
    mobileNumbersValue,
    preferredNameState,
    preferredNameValue,
    unconfirmedEmailState,
  });

  // only include updated fields in submit
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userDetailsChanged) {
      handleUpdateUserDetails({
        currentUserSub: userSub,
        ...(emailChanged && {
          email: emailValue,
        }),
        ...(preferredNameChanged && {
          preferredName: preferredNameValue,
        }),
      });
    }

    if (phoneNumbersChanged) {
      const mobileNumberParams = formatPhoneNumberParams(
        mobileNumbersValue,
        "mobile",
      );
      const homeNumberParams = formatPhoneNumberParams(
        homeNumbersValue,
        "home",
      );

      handleUpdatePhoneNumber({
        params: {
          contactId: primaryContact.id,
          ...(mobilephoneNumbersChanged && {
            mobileNumberParams,
          }),
          ...(homephoneNumbersChanged && { homeNumberParams }),
        },
      });
    }
  };

  if (!preferredNameState) return <LoadingSection />;

  return (
    <AccountPageWrapper
      headerLabel="Account Details"
      headerItems={[
        { key: preferredNameState, value: null },
        { key: "Account number", value: accountNumber },
      ]}
    >
      <div className="customer_tools_list__item">
        <AccountPageLink
          key={"/account/update-password"}
          linkText={"Update password"}
          linkTo={"/account/details/password"}
        />
      </div>
      {accountActive && (
        <div className="customer_tools_list__item">
          <AccountPageLink
            key={"/account/close-account"}
            linkText={"Close account"}
            linkTo={"/account/close-account"}
          />
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              errorMessage={preferredNameError}
              handleOnBlur={handlePreferredNameBlur}
              inputWidth="full"
              label={preferredNameLabel}
              name="preferred-name"
              required={true}
              setValue={setPreferredName}
              value={preferredNameValue}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              errorMessage={emailError}
              handleOnBlur={handleEmailBlur}
              hint={
                emailVerificationInProgress
                  ? emailVerificationInProgressInfo
                  : emailHint
              }
              inputMode="email"
              inputWidth="full"
              label={emailLabel}
              name="email"
              required={true}
              setValue={setEmail}
              type="email"
              value={emailValue}
            />
          </div>
        </FormRow>
        {mobileNumbersValue?.map(({ error, value }, index) => (
          <FormRow key={`mobile-input-${index}`}>
            <div className="spacer--large">
              <PhoneNumberInput
                errorMessage={error}
                handleOnBlur={() => handleMobileNumberBlur(index)}
                inputWidth="full"
                label={
                  index === 0
                    ? mobileNumberLabel
                    : `${mobileNumberLabel} ${index + 1}`
                }
                name={`mobile-number ${index}`}
                required={true}
                setValue={(newValue) => setMobileNumber(newValue, index)}
                value={prefixRemovedNZNumber(value)}
              />
            </div>
          </FormRow>
        ))}
        {homeNumbersValue?.map(({ error, value }, index) => (
          <FormRow key={`home-number-input-${index}`}>
            <div className="spacer--large">
              <PhoneNumberInput
                errorMessage={error}
                handleOnBlur={() => handleHomeNumberBlur(index)}
                hint={homeNumberHint}
                inputWidth="full"
                label={
                  index === 0
                    ? homeNumberLabel
                    : `${homeNumberLabel} ${index + 1}`
                }
                name={`home-number ${index}`}
                required={false}
                setValue={(newValue) => setHomeNumber(newValue, index)}
                value={value}
              />
            </div>
          </FormRow>
        ))}
        <SubmitButton
          isSubmitDisabled={
            !isFormValid || !anyInputChanged || emailVerificationInProgress
          }
          label={submitLabel}
        />
      </Form>
    </AccountPageWrapper>
  );
};
