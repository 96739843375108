import { ProgressBar } from "pages/UsagePage/elements/ProgressBar";
import React, { memo } from "react";

import {
  calculateDaysRemaining,
  calculateProgressPercentage,
} from "./DaysProgressBar.utils";
import content from "./static/content.json";

const { zeroDaysRemaining } = content;

type DaysProgressBarProps = {
  currentDate: Date;
  currentBillingPeriodEndAt: Date;
  duration: number;
  colour?: "pink" | "yellow";
};

export const DaysProgressBar: React.FC<DaysProgressBarProps> = memo(
  ({ colour, currentDate, currentBillingPeriodEndAt, duration }) => {
    const daysRemaining = calculateDaysRemaining(
      currentDate,
      currentBillingPeriodEndAt,
    );

    const progressPercentage = calculateProgressPercentage(
      duration,
      daysRemaining,
    );

    if (daysRemaining === 0) return <p>{zeroDaysRemaining}</p>;

    const labelText =
      daysRemaining === 1
        ? `${daysRemaining} day to go`
        : `${daysRemaining} days to go`;

    return (
      <ProgressBar
        label={labelText}
        progressPercentage={progressPercentage}
        extraClasses={`progress--light`}
        colour={colour}
      />
    );
  },
);
