import React, { createContext, ReactNode, useContext, useState } from "react";
import { useSearchParams } from "react-router";

// Define the shape of the context value
type TabContextType = {
  activeTab: string;
  handleChange: (newValue: string) => void;
};

// Create the context with a default value
export const LargeTabContext = createContext<TabContextType | undefined>(
  undefined,
);

type TabProviderProps = {
  value: string;
  children: ReactNode;
};

const LargeTabProvider: React.FC<TabProviderProps> = ({ value, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || value);

  const handleChange = (newValue: string) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setActiveTab(newValue);
  };

  return (
    <LargeTabContext.Provider value={{ activeTab, handleChange }}>
      {children}
    </LargeTabContext.Provider>
  );
};

const useLargeTabContext = () => {
  const context = useContext(LargeTabContext);
  if (!context) {
    throw new Error("useTabContext must be used within a TabProvider");
  }
  return context;
};

export { LargeTabProvider, useLargeTabContext };
