import React, { createContext, ReactNode, useContext, useState } from "react";

// Define the shape of the context value
type TabContextType = {
  activeTab: string;
  handleChange: (newValue: string) => void;
};

// Create the context with a default value
const TabContext = createContext<TabContextType | undefined>(undefined);

type TabProviderProps = {
  value: string;
  children: ReactNode;
};

const TabProvider: React.FC<TabProviderProps> = ({ value, children }) => {
  const [activeTab, setActiveTab] = useState(value);

  const handleChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <TabContext.Provider value={{ activeTab, handleChange }}>
      {children}
    </TabContext.Provider>
  );
};

const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("useTabContext must be used within a TabProvider");
  }
  return context;
};

export { TabProvider, useTabContext };
