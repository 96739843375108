import { Button } from "@flick/fep-library";
import { Dialog } from "components/Dialog";
import { DateInput } from "components/Inputs/DateInput";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { format } from "date-fns/format";
import { useCloseAccountMutation } from "queries/customerApi";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { CloseAccountDialog } from "./CloseAccountDialog";
import { useCloseAccountFormContext } from "./CloseAccountPageContext";
import content from "./static/content.json";
const { buttonContent, dateHint, dateInputLabel, dateLabel, title } = content;

export const CloseAccountPageContent: React.FC = () => {
  const { pathname } = useLocation();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { accountNumber } = useAppSelector((store) => store.currentAccount);
  const {
    closedDate: {
      fieldValue: { value: moveOutDateValue, error },
      handleOnChange: setClosedDate,
    },
    isFormValid,
  } = useCloseAccountFormContext();

  const [closeAccount, { isLoading }] = useCloseAccountMutation();

  const handleCloseAccount = async (account: string, moveOutDate: string) => {
    try {
      await closeAccount({
        accountNumber: account,
        jwtClient,
        endDate: moveOutDate,
      }).unwrap();

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.notice,
          name: NotificationBannerNames.accountClosure,
          params: {
            closedAccountDate: format(new Date(moveOutDateValue), "dd/MM/yy"),
          },
        }),
      );
    } catch (err) {
      const isCustomerAlreadyClosedError =
        err instanceof Error &&
        err.message.includes("Customer already has an end date.");

      const bannerName = isCustomerAlreadyClosedError
        ? NotificationBannerNames.accountClosureAlt
        : NotificationBannerNames.accountClosure;

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: bannerName,
          page: pathname,
          additionalContent: errorReasonDetails(err),
        }),
      );
    }
  };

  const handleDateChange = (value: string) => setClosedDate(value);

  const handleDialog = () => {
    setDialogIsOpen((prev) => !prev);
  };

  const confirmCloseAccount = () => {
    setDialogIsOpen(false);
    handleCloseAccount(accountNumber, moveOutDateValue);
  };

  return (
    <>
      <h4 className="h4">{title}</h4>
      <p>
        <strong>{dateLabel}</strong>
      </p>
      <DateInput
        errorMessage={error}
        handleDateChange={handleDateChange}
        hint={dateHint}
        label={dateInputLabel}
        name="closeAccountDate"
        required
        value={moveOutDateValue}
      />
      <Button
        buttonColor="danger"
        disabled={!isFormValid || isLoading}
        extraClasses="button--expanded spacer"
        onClick={handleDialog}
        type="submit"
      >
        {buttonContent}
      </Button>
      <Dialog handleDialogClose={handleDialog} dialogIsHidden={!dialogIsOpen}>
        <CloseAccountDialog
          closeDialog={handleDialog}
          confirmCloseAccount={confirmCloseAccount}
        />
      </Dialog>
    </>
  );
};
