/* eslint-disable import/named */
import "styles/Chart.scss";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
} from "chart.js";
import zoomPlugin, { pan } from "chartjs-plugin-zoom";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAppSelector } from "reduxUtils/hook";
import { highlightSelectedItem, selectedItemIndex } from "utils/charts";
import { MEDIUM_SCREEN_WIDTH } from "utils/constants";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

import { TextFallbackChart } from "./TextFallbackChart";
import { ZoomControls } from "./ZoomControls";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, zoomPlugin);

type BarChartProps = {
  chartConfig: BarChartConfig | GroupedBarChartConfig;
  className?: string;
  data: BarChartConfig;
  onClickMutation?: (value: number) => void;
  selectedDataIndex?: number;
  options: BarChartOptions;
  xLocationMobileOffset?: number;
};

/**
 * @description Renders a bar chart from react-chartjs-2
 */
export const BarChart: React.FC<BarChartProps> = ({
  chartConfig,
  className,
  data,
  onClickMutation,
  selectedDataIndex = undefined,
  options,
  xLocationMobileOffset = 10,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [pointerStartXLocation, setPointerStartXLocation] = useState(0);
  const chartRef = useRef();
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  const clickEventHandler = (event) => {
    const eventMovement = event.clientX - pointerStartXLocation;
    if (eventMovement <= -50 || eventMovement >= 50) return;
    const chart = chartRef.current;
    const itemIndex = selectedItemIndex(event, chart);
    // if user click outside of range then keep selected index as it was
    if (itemIndex < 0) return;
    onClickMutation(itemIndex);
    sendAnalyticsEvent("graph_interaction", {
      icpNumber,
      graph_click_date: new Date(
        chartConfig.dates[itemIndex],
      )?.toLocaleDateString(),
    });
  };

  const handlePointerDown = (event) => {
    setPointerStartXLocation(event.clientX);
    setIsDragging(true);
  };

  const handlePointerMove = (event) => {
    if (!isDragging && chartRef.current) return;
    const isMobile = event.view.innerWidth <= MEDIUM_SCREEN_WIDTH;

    const xLocationDesktop = (event.clientX - pointerStartXLocation) / 10;
    const xLocationMobile =
      (event.clientX - pointerStartXLocation) * xLocationMobileOffset;
    pan(
      chartRef.current,
      { x: isMobile ? xLocationMobile : xLocationDesktop },
      undefined,
      "normal",
    );
  };

  useEffect(
    () => {
      if (
        //@ts-ignore
        chartRef.current?.options.scales.x.ticks.font &&
        !isNaN(selectedDataIndex)
      ) {
        highlightSelectedItem(selectedDataIndex, chartRef.current);
      }
    },
    //@ts-ignore
    [chartRef.current?.options.scales.x.ticks],
  );

  return (
    <div className={className || "chart__container"}>
      {chartConfig.showZoom && (
        <ZoomControls
          chart={chartRef?.current}
          title={chartConfig.chartTitle}
        />
      )}
      {!chartConfig.showZoom && chartConfig.chartTitle && (
        <div className="chart__controls">
          <p className="small text text__secondary chart__title">
            {chartConfig.chartTitle}
          </p>
        </div>
      )}
      <Bar
        ref={chartRef}
        // Casting our own type as unknown before casting it to the library type. If the library types are fixed, we'll just use those.
        options={options as unknown as ChartOptions<"bar">}
        data={data as unknown as ChartData<"bar">}
        onClick={onClickMutation && clickEventHandler}
        onPointerMove={handlePointerMove}
        onPointerDown={handlePointerDown}
        onPointerUp={() => setIsDragging(false)}
        onTouchEnd={() => setIsDragging(false)}
        fallbackContent={<TextFallbackChart chartConfig={chartConfig} />}
      />
    </div>
  );
};
