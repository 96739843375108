import { Button, Card, CardBody, CardHeader } from "@flick/fep-library";
import React from "react";
import { useNavigate } from "react-router";

export enum CardColors {
  pink = "pink",
  yellow = "yellow",
}

type PromoCardProps = {
  bodyRightContent?: React.ReactNode;
  bodyText: string;
  buttonLink: string;
  buttonText: string;
  buttonTextColour?: string;
  cardColor?: CardColors;
  headerText: string;
  icon?: React.ReactNode;
  previousPath: string;
};

export const PromoCard: React.FC<PromoCardProps> = ({
  bodyRightContent = <></>,
  bodyText,
  buttonLink,
  buttonText,
  buttonTextColour,
  cardColor = CardColors.pink,
  headerText,
  previousPath,
}) => {
  const navigate = useNavigate();

  return (
    <Card cardColor={cardColor}>
      <CardHeader extraHeaderClasses="card__header--display-face">
        <p>{headerText}</p>
      </CardHeader>
      <CardBody bodyRightContent={bodyRightContent} extraBodyClasses={`h5`}>
        <section>
          <article className="card__body-side-content-text">{bodyText}</article>
          <Button
            extraClasses={`text__colour--${buttonTextColour || cardColor}`}
            onClick={() =>
              navigate(buttonLink, {
                state: { previousPath },
              })
            }
          >
            {buttonText}
          </Button>
        </section>
      </CardBody>
    </Card>
  );
};
