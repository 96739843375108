/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxUtils/store";

import {
  selectCurrentTotalExportInDollars,
  selectCurrentTotalImportInDollars,
  selectCurrentUsageInDollars,
  selectCurrentUsageInPower,
} from "./currentUsageSelector";

const selectCurrentUsageData = (state: RootState) =>
  state.billingEntityData?.currentUsageData;
const selectPrevUsageData = (state: RootState) =>
  state.billingEntityData?.prevUsageData;
const selectCurrentBillingPeriod = (state: RootState) =>
  state.billingEntityData?.currentBillingPeriod;

export const selectCurrentUsageToInvoice = createSelector(
  selectCurrentUsageInDollars,
  selectCurrentUsageInPower,
  selectCurrentTotalImportInDollars,
  selectCurrentTotalExportInDollars,
  selectCurrentBillingPeriod,
  selectPrevUsageData,
  (
    usageDataCost,
    usageDataValue,
    totalBoughtCost,
    totalSoldCost,
    billingPeriod,
    prevUsageData,
  ) => {
    if (!usageDataCost || !billingPeriod) return undefined;

    return {
      attributes: {
        number: 1,
        billing_period: {
          // convert back to string due to startAt/endAt is formatted to Date in ratingQueryDateRange util
          // this to keep consistency with other invoices detail return from api request
          period_started_at: billingPeriod.startAt.toString(),
          period_ended_at: billingPeriod.endAt.toString(),
          duration: billingPeriod.code,
        },
        ...(prevUsageData && {
          comparison_data: {
            previous: {
              started_at: prevUsageData.start_at,
              ended_at: prevUsageData.end_at,
            },
          },
        }),
        total_payment_due: {
          price: usageDataCost,
          currency_code: "NZD",
          value_added_tax_included: true,
          tax: "0",
          price_excl_tax: usageDataCost,
          price_excl_sellback: totalBoughtCost,
          sellback_price: totalSoldCost,
          sellback_tax: "0.00",
          remaining_balance: null,
        },
        usage: {
          value: usageDataValue,
          unit_code: "kWh",
        },
        final_bill: false,
      },
      previous_invoice_id: true,
      id: "01",
      type: "invoice_summary",
    };
  },
);
