import React from "react";

export const BillSmootherSetupSteps: React.FC = () => {
  return (
    <section>
      <h3 className="h4">Set Bill Smoother up in 2 simple steps:</h3>
      <ol className="ordered-list">
        <li className="ordered-list__item">
          <h4 className="h5">Crunch the numbers</h4>
          <p>
            To find the right amount to set for your Contribution Amount, we
            recommend you use your average bill spend. You can calculate that in
            a few ways:
          </p>
          <ul className="unordered-list">
            <li>
              <strong>Using your previous bills</strong>: Add together all your
              bills for the last 12 months then divide this by your billing
              cycle – for example, divide by 52 for weekly, 26 for fortnightly,
              and 12 for monthly. You can find your past Flick bills by heading
              to the 'Bills' tab of your Flick web dashboard or mobile app. OR
            </li>
            <li>
              <strong>If you don't have all your bills</strong>: Take one of
              your previous non-winter bills, and add an extra 20% (multiply it
              by 1.2). OR
            </li>
            <li>
              <strong>Need help?</strong>: If you're unsure, give us a call and
              we'll help you work it out.
            </li>
          </ul>
          {/* This div needs to be inside the li element, as divs are not valid
          children of ol elements. */}
          <div className="spacer--medium" />
        </li>
        <li className="ordered-list__item">
          <h4 className="h5">Set your Bill Smoother Contribution Amount</h4>
          <p>
            To set your Contribution Amount, head to the Flick web dashboard or
            mobile app {">"} Bills {">"} Take a look at Bill Smoother {">"}{" "}
            Setup Bill Smoother and punch in the number!
          </p>
        </li>
      </ol>
    </section>
  );
};
