import { ReactComponent as DollarSignIconSvg } from "app/assets/images/icons/dollar-solid.svg";
import { Subheading } from "elements/Subheading";
import { TotalWithUnit } from "elements/TotalWithUnit";
import React from "react";

type BalanceProps = {
  balanceType: string;
  amount: number;
};

export const Balance: React.FC<BalanceProps> = ({ balanceType, amount }) => {
  return (
    <>
      <Subheading title={balanceType} />
      <TotalWithUnit
        icon={<DollarSignIconSvg />}
        total={
          isNaN(amount) || amount === 0
            ? "0.00"
            : `${String(amount.toFixed(2))}`
        }
      />
    </>
  );
};
