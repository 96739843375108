import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { BillSmootherBalance } from "components/BillSmootherBalance";
import { SectionLinks } from "elements/SectionLinks";
import { useGetBillingBalanceQuery } from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React, { PropsWithChildren } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";

type YourBillSmootherBalanceProps = {
  links?: { label: string; path: string }[];
};

export const YourBillSmootherBalance: React.FC<
  PropsWithChildren<YourBillSmootherBalanceProps>
> = ({ links = [], children }) => {
  const { customerNumber } = useAppSelector((store) => store.currentAccount);
  const { billSmootherConfig } = useAppSelector(
    (store) => store.billSmootherConfig,
  );
  const {
    data: billSmootherBalance,
    isFetching: isBillSmootherBalanceFetching,
    isError: isBillSmootherBalanceError,
  } = useGetBillingBalanceQuery(
    { customerNumber, jwtClient },
    {
      skip:
        !billSmootherConfig || !billSmootherConfig?.enrolled || !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  return (
    <Card>
      <CardHeader>
        <h2 className="h4">Your Bill Smoother balance</h2>
      </CardHeader>
      <CardBody>
        <article className="customer_tools_section">
          <BillSmootherBalance
            accountBalance={billSmootherBalance}
            isAccountBalanceError={isBillSmootherBalanceError}
            isAccountBalanceFetching={isBillSmootherBalanceFetching}
          />
          <div className="spacer" />
          {children}
          <SectionLinks links={links} />
        </article>
      </CardBody>
    </Card>
  );
};
