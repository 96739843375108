import "./AggregatedInvoiceSection.scss";

import { SelectInput } from "components/Inputs/SelectInput";
import { LoadingSection } from "components/LoadingSection";
import { addSeconds } from "date-fns/addSeconds";
import { differenceInDays } from "date-fns/differenceInDays";
import { isBefore } from "date-fns/isBefore";
import { useLargeTabContext } from "pages/UsagePage/components/LargeTabPanel/LargeTabProvider";
import { NoInvoiceUsageData } from "pages/UsagePage/components/NoAvailableData/NoAvailableData";
import { InvoiceUsageChartProvider } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { prepareDailyUsageData } from "utils/prepareDailyUsageData";
import { prepareUsageTotal } from "utils/usageCalculations/prepareUsageTotal";

import { InvoiceUsageSection } from "../InvoiceUsageSection";
import { SolarInvoiceUsageSection } from "../InvoiceUsageSection/SolarInvoiceUsageSection";

type AggregatedInvoiceSectionProps = {
  periodEndDate: Date;
  hasPreviousData: boolean;
  periodName?: string;
  periodStartDate: Date;
  previousPeriodStartDate?: Date;
};

const selectOptions = [
  {
    label: "Dollars",
    value: "spend",
  },
  {
    label: "kWh",
    value: "consumption",
  },
];

// @TODO consider wrapping the whole component in memo
export const AggregatedInvoiceSection: React.FC<
  AggregatedInvoiceSectionProps
> = ({
  periodName,
  hasPreviousData,
  previousPeriodStartDate,
  periodStartDate,
  periodEndDate,
}) => {
  const { supplyNodeRef, active } = useAppSelector(
    (store) => store.currentAccount,
  );

  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const [displayType, setDisplayType] = useState(selectOptions[0].value);
  const { activeTab } = useLargeTabContext();

  const startAt = useMemo(
    () =>
      hasPreviousData && previousPeriodStartDate
        ? previousPeriodStartDate
        : periodStartDate,
    [hasPreviousData, previousPeriodStartDate],
  );

  /** Billing cycle is 1 millisecond before midnight but difference in days rounds down so we add an extra second to make the final day a whole day. */
  const endAt = useMemo(
    () => addSeconds(new Date(periodEndDate), 1),
    [periodEndDate],
  );

  const {
    data: aggregatesData,
    isFetching,
    isError,
    error: aggregateError,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt,
      endAt,
      jwtClient,
      source: "Aggregated Invoice Usage Section",
    },
    {
      skip:
        !supplyNodeRef ||
        !periodStartDate ||
        !periodEndDate ||
        !billingEntityData ||
        isBefore(startAt, new Date(billingEntityData.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const timePeriodDifference = useMemo(
    () => differenceInDays(endAt, new Date(periodStartDate)),
    [periodStartDate],
  );

  const dailyUsageData = useMemo(
    () =>
      aggregatesData
        ? prepareDailyUsageData(
            aggregatesData,
            periodStartDate,
            timePeriodDifference,
          )
        : [],
    [aggregatesData, periodStartDate, timePeriodDifference],
  );
  const thisPeriodUsage = useMemo(
    () => prepareUsageTotal(aggregatesData?.aggregates),
    [aggregatesData],
  );

  const showDailyUsageSection = useMemo(
    () =>
      !isFetching &&
      parseFloat(thisPeriodUsage) > 0 &&
      Boolean(aggregatesData) &&
      dailyUsageData.length > 0,
    [isFetching, thisPeriodUsage, aggregatesData, dailyUsageData],
  );

  const dataNotAvailable = useMemo(
    () =>
      (active &&
        !isFetching &&
        !aggregatesData &&
        parseFloat(thisPeriodUsage) >= 0) ||
      (active &&
        !isFetching &&
        Boolean(aggregatesData) &&
        parseFloat(thisPeriodUsage) === 0),
    [active, isFetching, aggregatesData, thisPeriodUsage],
  );

  const handleChangeDisplayTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDisplayType(e.target.value);
  };

  if (isFetching) return <LoadingSection />;

  if (dataNotAvailable) return <NoInvoiceUsageData />;

  if (isError) {
    // for ErrorBoundary in withErrorBoundary to catch and display fallback
    throw aggregateError;
  }

  return (
    <>
      <div className="usage-details-heading">
        <h2 className="section-title heading h5">
          {activeTab === "solar" ? "Sold & bought details" : "Usage details"}
        </h2>
        <SelectInput
          ariaLabel="Dollars or kWh chart disply select"
          fullWidth={false}
          name="display-type"
          defaultOption={selectOptions[0]}
          extraClasses="usage-display-type-select"
          options={selectOptions}
          handleOnChange={handleChangeDisplayTypeChange}
          value={displayType}
        />
      </div>
      <InvoiceUsageChartProvider lastDayIndex={dailyUsageData.length - 1}>
        {activeTab === "solar" && showDailyUsageSection && (
          <SolarInvoiceUsageSection
            dailyUsageData={dailyUsageData}
            periodName={periodName}
            displayType={displayType}
          />
        )}
        {activeTab !== "solar" && showDailyUsageSection && (
          <InvoiceUsageSection
            dailyUsageData={dailyUsageData}
            displayType={displayType}
            periodName={periodName}
          />
        )}
      </InvoiceUsageChartProvider>
    </>
  );
};
