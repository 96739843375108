/* eslint-disable jsx-a11y/label-has-associated-control */
import "../../components/AccountHeaderSection/AccountHeaderSection.scss";
import "./SolarPage.scss";

import { Button } from "@flick/fep-library";
import { ReactComponent as BatteryIcon } from "assets/icon-battery.svg";
import { AccountHeaderSection } from "components/AccountHeaderSection";
import { LoadingSection } from "components/LoadingSection";
import {
  useGetEnodeUserBatteriesQuery,
  useGetEnodeUserQuery,
  useLazyGetEnodeLinkUISessionQuery,
  useUpdateBatteryOperationModeMutation,
} from "queries/telemetryApi";
import React, { useMemo, useState } from "react";
import { jwtClient, rollbar } from "utils/jwtClient";
import { capitalize } from "utils/text";

import content from "./static/content.json";
const { pageHeader, subHeader, additionalText } = content;

import { HeaderWithIconSection } from "components/AccountHeaderSection/AccountHeaderSection";
import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import { useLocation } from "react-router";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  BATTERY_CHARGE_STATUS,
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { navigateLink, visitLink } from "utils/htmlLinks/visitLink";

/**
 * @description Build a tag indicating the status of the enode linked battery.
 */
const TagBuilder: React.FC<{
  chargingStatus: string;
}> = ({ chargingStatus }) => {
  let tagType = "";
  let tagText;
  if (chargingStatus === BATTERY_CHARGE_STATUS.charging) {
    tagText = "Charging";
    tagType = "tag--success";
  } else if (chargingStatus === BATTERY_CHARGE_STATUS.discharging) {
    tagType = "tag--warning";
    tagText = "Discharging";
  } else if (chargingStatus === BATTERY_CHARGE_STATUS.fault) {
    tagType = "tag--error";
    tagText = "Fault";
  } else if (chargingStatus === BATTERY_CHARGE_STATUS.idle) {
    tagText = "Idle";
  } else {
    tagText = "Unknown";
  }

  return (
    <div
      data-testid="batteryStatusTag"
      className={`tag ${tagType} battery_status`}
    >
      {tagText}
    </div>
  );
};

enum BatteryOperationMode {
  export = "EXPORT_FOCUS",
  import = "IMPORT_FOCUS",
  timeOfUse = "TIME_OF_USE",
  selfReliance = "SELF_RELIANCE",
}

const BatteryModeOptions = ({ id, currentOperationMode }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [operationMode, setOperationMode] = useState(
    `${id}-${currentOperationMode}`,
  );

  const [updateBatteryOperation] = useUpdateBatteryOperationModeMutation();

  const handleExportOnChange = async (
    batteryId: string,
    newOperationMode: BatteryOperationMode,
  ) => {
    try {
      // unwrap will throw error if mutation fails
      await updateBatteryOperation({
        jwtClient,
        id: batteryId,
        operationMode: newOperationMode,
      }).unwrap();
      // refetch enode batteries to get updated data if update is successful
      // temporary comment out due to update battery can be pending/failed https://developers.enode.com/api/reference/#postUserSetOperationMode (**)
      // this give the illusion that the update is successful while waiting for the actual update
      // dispatch(telemetryApi.util.invalidateTags(["enodeBatteries"]));
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.success,
          name: NotificationBannerNames.updateEnodeBatteries,
          page: pathname,
        }),
      );
    } catch (e) {
      rollbar.error("Failed to update battery", {
        jwtClient,
        batteryId,
        previousOperationMode: currentOperationMode,
        newOperationMode,
        error: e.message,
      });

      //reset display to the current Operation Mode due to update fails
      setOperationMode(`${id}-${currentOperationMode}`);
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.updateEnodeBatteries,
          page: pathname,
        }),
      );
    }
  };

  const options = useMemo(
    () => [
      { label: "Export", value: `${id}-${BatteryOperationMode.export}` },
      { label: "Charge", value: `${id}-${BatteryOperationMode.import}` },
      {
        label: "Time of use",
        value: `${id}-${BatteryOperationMode.timeOfUse}`,
      },
      {
        label: "Self-reliance",
        value: `${id}-${BatteryOperationMode.selfReliance}`,
      },
    ],
    [id],
  );

  const handleChange = (value: string) => setOperationMode(value);

  const isModeUnchanged = operationMode === `${id}-${currentOperationMode}`;

  return (
    <section>
      <p className="h5">Battery mode</p>
      <SingleSelectRadioGroup
        handleOnChange={handleChange}
        name="batteryMode"
        options={options}
        valueSelect={operationMode}
      />
      <div className="spacer--small" />
      <Button
        extraClasses="button--expanded"
        onClick={() =>
          handleExportOnChange(
            id,
            // example value: "b003c0a8-8927-4246-a3c9-c02ffcf2d00f-EXPORT_FOCUS"
            operationMode.split("-").pop() as BatteryOperationMode,
          )
        }
        disabled={isModeUnchanged}
      >
        Confirm mode change
      </Button>
    </section>
  );
};

export const SolarPage = () => {
  const { data: enodeUserData } = useGetEnodeUserQuery({ jwtClient });
  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);
  const { data: enodeUserBatteries, isFetching: isEnodeBatteriesFetching } =
    useGetEnodeUserBatteriesQuery(
      { jwtClient },
      {
        skip: !enodeUserData,
        //no cache for this endpoint, always fetch fresh data to resolve the issue coming from updateBatteryOperation (**)
        refetchOnMountOrArgChange: true,
      },
    );

  const [
    trigger,
    { isFetching: fetchingLinkUISession, isError: fetchingLinkSessionError },
  ] = useLazyGetEnodeLinkUISessionQuery();

  const enodeLinkUISession = async (event: React.MouseEvent) => {
    event.preventDefault();
    const { data = undefined } = await trigger({ jwtClient });
    const enodeURL = data?.links.session_url;

    if (nativeRenderMode && enodeURL) {
      visitLink({
        url: enodeURL,
      });
    }
    if (!nativeRenderMode && enodeURL) {
      navigateLink(enodeURL);
    }
  };

  if (isEnodeBatteriesFetching) {
    return <LoadingSection />;
  }

  if (
    !enodeUserData ||
    (Array.isArray(enodeUserBatteries) && enodeUserBatteries.length === 0)
  ) {
    return (
      <div className="page-section">
        <section className="page-section__column page-section__column--single">
          <AccountHeaderSection label={pageHeader} />
          <div className="spacer--large">
            <p className="h5">{subHeader}</p>
            <p>{additionalText}</p>
            <Button
              extraClasses="button--expanded"
              disabled={fetchingLinkUISession || fetchingLinkSessionError}
              onClick={enodeLinkUISession}
            >
              Connect battery
            </Button>
          </div>
        </section>
      </div>
    );
  }

  // if is enode but no battery info
  if (enodeUserData && !enodeUserBatteries) {
    // handle fallback screen here
    return <></>;
  }

  return (
    <div className="page-section">
      <section className="page-section__column page-section__column--single">
        <div className="spacer--medium" />
        {enodeUserBatteries.length > 0 &&
          enodeUserBatteries.map(({ attributes, id }) => {
            return (
              <React.Fragment key={id}>
                <HeaderWithIconSection
                  label={"Battery"}
                  hasHeaderIcon={true}
                  headerIcon={<BatteryIcon className="battery_icon" />}
                  headingLevel="h4"
                />
                <hr className="content_divider" />
                <section>
                  <p className="h5">
                    {capitalize(attributes.vendor.toLowerCase())}{" "}
                    {attributes.model}
                  </p>
                  <div className="battery_details_section">
                    <div className="battery_details_section__item">
                      <p className="battery_details_section__item_heading">
                        Storage capacity
                      </p>
                      <p className="battery_details_section__item_text">
                        {attributes.battery_capacity} kWh
                      </p>
                    </div>

                    <div className="battery_details_section__item">
                      <p className="battery_details_section__item_heading">
                        Charge rate
                      </p>
                      <p className="battery_details_section__item_text">
                        {attributes.charge_rate
                          ? `${attributes.charge_rate} kW`
                          : "Not available"}
                      </p>
                    </div>

                    <div className="battery_details_section__item">
                      <p className="battery_details_section__item_heading">
                        Status
                      </p>
                      <p className="battery_details_section__item_text">
                        {attributes.battery_level}%
                      </p>
                      <p className="battery_details_section__item_text">
                        {attributes.discharge_limit
                          ? `${attributes.discharge_limit} kWh`
                          : "Discharge limit not available"}
                      </p>
                    </div>
                    <div className="battery_details_section__item">
                      <TagBuilder chargingStatus={attributes.charging_status} />
                    </div>
                  </div>
                </section>
                <hr className="content_divider" />
                <BatteryModeOptions
                  id={id}
                  currentOperationMode={attributes.operation_mode}
                />
                <div className="spacer--large" />
              </React.Fragment>
            );
          })}
      </section>
    </div>
  );
};
