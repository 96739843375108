import { GroupedBarChart } from "components/charts/GroupedBarChart";
import { useInvoiceUsageChart } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import React, { memo, useMemo } from "react";

import {
  generateSolarDailyUsageGroupedBarChartConfig,
  getMaxYValueForSolarDisplayType,
} from "./SolarInvoiceUsageChart.utils";

type SolarInvoiceUsageChartProps = {
  dailyUsageData: DailyUsage[];
  displayType?: string;
};

/**
 * @description Used in SolarInvoiceUsageSection component
 */
export const SolarInvoiceUsageChart: React.FC<SolarInvoiceUsageChartProps> =
  memo(({ dailyUsageData, displayType }) => {
    const { selectedDayIndex, setSelectedDayIndex } = useInvoiceUsageChart();
    const maxYValue = useMemo(() => {
      return getMaxYValueForSolarDisplayType(dailyUsageData, displayType);
    }, [dailyUsageData, displayType]);

    const groupedBarChartConfig = generateSolarDailyUsageGroupedBarChartConfig(
      dailyUsageData,
      displayType,
    );

    return (
      <>
        {dailyUsageData.length > 0 && (
          <GroupedBarChart
            maxYValue={maxYValue}
            chartConfig={groupedBarChartConfig}
            onClickMutation={setSelectedDayIndex}
            selectedDataIndex={selectedDayIndex}
          />
        )}
      </>
    );
  });
