import { addDays } from "date-fns/addDays";
import { addSeconds } from "date-fns/addSeconds";
import { isAfter } from "date-fns/isAfter";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { subDays } from "date-fns/subDays";

/**
 * This function uses the current timetable and current date to find the current shift a customer is in.
 * returns an object containing the startDate and endDate for the shift and the currentShift object that contains the rateCode, start_at and
 * end_at times for that shift
 */

export const findCurrentShift = (
  currentTimetable: Shift[] | undefined,
  currentDate?: Date,
): CurrentShiftDetails | undefined => {
  if (!currentTimetable || currentTimetable.length === 0) return undefined;

  const result: CurrentShiftDetails = {
    currentShift: undefined,
    endDate: undefined,
    startDate: undefined,
  };

  const currentShift = currentTimetable.find((shift) => {
    const shiftStartAt = shift.start_at.split(":");
    const shiftEndAt = shift.end_at.split(":");
    let startDate = new Date(shift.startDate);
    startDate.setHours(+shiftStartAt[0]);
    startDate.setMinutes(+shiftStartAt[1]);
    let endDate = new Date(shift.endDate);
    endDate.setHours(+shiftEndAt[0]);
    endDate.setMinutes(+shiftEndAt[1]);
    // An hour isnt considered as being within a shift if it falls on the exact start time.
    // e.g if offpeak starts at 11am and the currentDate here is 11am exactly then it wont
    // be considered as an offpeak period.
    // We add an extra second here to help process static times, such as those associated
    // with usage data which can often fall on the exact hour.
    const adjustedCurrentDate = addSeconds(currentDate || new Date(), 1);
    // When a shift runs overnight, need to add a day to the date object to make sure
    // the range isn't incorrect during the isAfter and isBefore check below
    // i.e. if startDate > endDate then need to add 1 day to endDate.
    if (startDate >= endDate) {
      if (isAfter(adjustedCurrentDate, startDate)) {
        endDate = addDays(endDate, 1);
      } else {
        startDate = subDays(startDate, 1);
      }
    }

    const withinShift = isWithinInterval(adjustedCurrentDate, {
      start: startDate,
      end: endDate,
    });

    if (withinShift) {
      result.startDate = startDate;
      result.endDate = endDate;
    }

    return withinShift;
  });

  result.currentShift = currentShift;
  return result;
};
