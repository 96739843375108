import React from "react";

import { useLargeTabContext } from "../components/LargeTabPanel/LargeTabProvider";
import { TabProvider } from "../components/TabPanel/TabContext";
import { TabPanel } from "../components/TabPanel/TabPanel";
import { BillingPeriodView } from "./BillingPeriodView";
// import { MonthlySectionWrapper } from "./MonthlySection/MonthlySection";

export const PowerOrSolarBreakDown = () => {
  const { activeTab } = useLargeTabContext();

  return (
    <>
      <h2 className="section-title heading h4">
        {activeTab === "solar" ? "Power sold & bought" : "Your usage data"}
      </h2>
      <div className="spacer--medium" />
      <p className="h5">Compare</p>
      <div className="spacer--medium" />
      <TabProvider value="days">
        {/* <TabList> */}
        {/* <Tab label="Days" value="days" /> */}
        {/* <Tab label="Months" value="months" /> */}
        {/* </TabList> */}
        <TabPanel value="days">
          <BillingPeriodView />
        </TabPanel>
        {/* <TabPanel value="months">
          <MonthlySectionWrapper />
        </TabPanel> */}
      </TabProvider>
    </>
  );
};
