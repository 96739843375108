import { ReactComponent as DropIconSvg } from "app/assets/images/icons/drop-solid.svg";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { renderDeltaMessage } from "elements/DeltaMessage/DeltaMessageLegacy";
import React from "react";
import { useLocation } from "react-router";
import { calculatePercentageChangeDelta } from "utils/calculatePercentageChangeDelta";
import { AVAILABLE_PERIODS } from "utils/constants";
import { pathnameBrand } from "utils/pathnameBrand";

type WholesalePriceSectionProps = {
  duration?: string;
  currentGenerationPrice: string;
  previousGenerationPrice?: string;
};

export const WholesalePriceSection: React.FC<WholesalePriceSectionProps> = ({
  currentGenerationPrice,
  previousGenerationPrice,
  duration,
}) => {
  const { pathname } = useLocation();
  const { background } = pathnameBrand(pathname);

  const previousPeriodDelta = calculatePercentageChangeDelta(
    currentGenerationPrice,
    previousGenerationPrice,
  );

  if (!currentGenerationPrice || isNaN(Number(currentGenerationPrice))) {
    return (
      <section>
        <ComponentItemFallback componentTitle="Average generation price" />
      </section>
    );
  }

  return (
    <>
      <section>
        <div className="header_section">
          <DropIconSvg
            data-testid="icon"
            className={`icon_colour icon_colour--${background}`}
          />

          <h2 className="heading h4">
            Average generation price of{" "}
            {Number(currentGenerationPrice).toFixed(2)} c/kWh
          </h2>
          {previousGenerationPrice &&
            duration &&
            renderDeltaMessage({
              delta: previousPeriodDelta,
              periodLabel: `last ${AVAILABLE_PERIODS[duration].periodName}`,
            })}
        </div>
      </section>
    </>
  );
};
