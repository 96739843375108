import "elements/DeltaMessage/DeltaMessage.scss";

import { ReactComponent as BackIcon } from "app/assets/images/icons/chevron-left-lg.svg";
import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import { ReactComponent as RightArrowSvg } from "app/assets/images/icons/circle-arrow-right.svg";
import { ReactComponent as DollarSignIcon } from "app/assets/images/icons/dollar-solid.svg";
import { ReactComponent as PriceTagIconSvg } from "app/assets/images/icons/label-sold.svg";
import { ReactComponent as LeafIconSvg } from "app/assets/images/icons/leaf-solid.svg";
import { LoadingSection } from "components/LoadingSection";
import { PeriodComparisonMessage } from "components/PeriodComparisonMessage";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import { isWeekend } from "date-fns/isWeekend";
import { startOfDay } from "date-fns/startOfDay";
import { Subheading } from "elements/Subheading";
import { HourlyUsageChart } from "pages/UsagePage/components/HourlyUsageChart";
import { useGetTimeTableForContractQuery } from "queries/pricingRateCardApi";
import { useGetAggregateHourlyRatesQuery } from "queries/ratingApi";
import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import {
  AGGREGATE_DATA_STATUSES,
  DisplayUnit,
  PRODUCT_NAMES,
} from "utils/constants";
import { NZD } from "utils/currency";
import { jwtClient } from "utils/jwtClient";
import { getContractName } from "utils/productNameUtils";
import { findShifts } from "utils/rateCardHelpers/findCurrentTimetable";
import { timeOfUseSummary } from "utils/usageCalculations/timeOfUseSummary";

import { useInvoiceUsageChart } from "../../InvoiceUsageChartProvider/InvoiceUsageChartProvider";
import { HourlyKwhCostSection } from "../HourlyKwhCostSection";
import { prepareInvoiceDayUsageData } from "./InvoiceDayDetail.utils";

type InvoiceDayDetailProps = {
  periodName: string;
  usageData: DailyUsage;
};

/**
 * @description Displays individual day detail component used in Daily Usage Section
 */
export const InvoiceDayDetail: React.FC<InvoiceDayDetailProps> = ({
  periodName,
  usageData,
}) => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { isRateCardLoading } = useAppSelector((store) => store.rateCards);

  const {
    setSelectedDayIndex,
    isSelectedDayNotFirstDay,
    isSelectedDayNotLastDay,
  } = useInvoiceUsageChart();

  const day = usageData.date;

  const {
    data: selectedDayAggregateData,
    isFetching: isFetchingSelectedDayAggregateData,
    isError: isSelectedDayAggregateError,
    error: selectedDayAggregateError,
  } = useGetAggregateHourlyRatesQuery(
    {
      supplyNodeRef,
      startAt: startOfDay(day),
      endAt: endOfDay(day),
      jwtClient,
      source: "Individual Day - Hourly Selected Day",
    },
    { skip: !supplyNodeRef || !usageData.date },
  );

  const { data: timeTableForContract } = useGetTimeTableForContractQuery(
    {
      jwtClient,
      supplyNodeRef,
      generationPriceContract:
        selectedDayAggregateData?.generation_price_contracts[0],
    },
    {
      skip:
        !selectedDayAggregateData ||
        selectedDayAggregateData.generation_price_contracts?.length === 0 ||
        !supplyNodeRef,
    },
  );

  const {
    usageComponentValues: selectedDayUsageData,
    maxYValue: selectedDayMaxYValue,
  } = useMemo(
    () =>
      prepareInvoiceDayUsageData(
        selectedDayAggregateData,
        getContractName(
          selectedDayAggregateData?.generation_price_contracts[0],
        ),
        findShifts(timeTableForContract, day),
      ),
    [selectedDayAggregateData, timeTableForContract],
  );

  const selectedDayOffPeakUsagePercentage = useMemo(() => {
    return timeOfUseSummary(selectedDayUsageData);
  }, [selectedDayUsageData]);

  const hasUsageData = usageData.status !== AGGREGATE_DATA_STATUSES.missing;

  const productFlags = useMemo(
    () => ({
      isOffpeak:
        getContractName(
          selectedDayAggregateData?.generation_price_contracts[0],
        ) === PRODUCT_NAMES.offPeak,
      isWholesale:
        getContractName(
          selectedDayAggregateData?.generation_price_contracts[0],
        ) === PRODUCT_NAMES.wholesale,
    }),
    [selectedDayAggregateData],
  );

  const displayOffPeakPercentage = useMemo(
    () =>
      productFlags.isOffpeak &&
      Boolean(selectedDayUsageData) &&
      !isFetchingSelectedDayAggregateData &&
      !isNaN(Number(selectedDayOffPeakUsagePercentage)),
    [
      productFlags.isOffpeak,
      selectedDayUsageData,
      selectedDayOffPeakUsagePercentage,
      isFetchingSelectedDayAggregateData,
    ],
  );

  if (isSelectedDayAggregateError) {
    throw selectedDayAggregateError;
  }

  if (isRateCardLoading) return <LoadingSection />;

  return (
    <div className="usage-detail-container">
      <span className="spacer spacer--large" />
      <div className="usage-detail">
        <h3 className="h4 usage-detail__title">
          {format(usageData.date, "EEEE d MMMM")}
        </h3>
        <div className="usage-detail__controls">
          <button
            className="chevron-arrows chevron-arrows--alt-background"
            aria-disabled={!isSelectedDayNotFirstDay}
            disabled={!isSelectedDayNotFirstDay}
            onClick={() => {
              setSelectedDayIndex((prev) => prev - 1);
            }}
          >
            <BackIcon aria-label="Previous day" />
          </button>

          <button
            className="chevron-arrows chevron-arrows--alt-background"
            aria-disabled={!isSelectedDayNotLastDay}
            disabled={!isSelectedDayNotLastDay}
            onClick={() => {
              setSelectedDayIndex((prev) => prev + 1);
            }}
          >
            <ForwardIcon aria-label="Next day" />
          </button>
        </div>
      </div>

      <section>
        <Subheading
          title={`Total ${NZD.format(usageData.thisPeriod.spend)} (${usageData.thisPeriod.consumption.toFixed(3)} kWh)`}
          icon={<DollarSignIcon />}
        />
        <PeriodComparisonMessage
          displayUnit={DisplayUnit.dollar}
          lastPeriod={usageData.lastPeriod.spend}
          currentPeriod={usageData.thisPeriod.spend}
          periodLabel={`this time last ${periodName}`}
        />
      </section>
      <div className="spacer" />
      <section>
        {displayOffPeakPercentage && (
          <>
            <Subheading
              title={`${selectedDayOffPeakUsagePercentage}% off-peak`}
              icon={<LeafIconSvg />}
            />
            {isWeekend(usageData.date) && (
              <div className="delta-message">
                <RightArrowSvg className="arrow icon_colour--secondary" />
                <p className="text text__secondary" style={{ marginLeft: 5 }}>
                  Weekends are entirely off-peak!
                </p>
              </div>
            )}
          </>
        )}
      </section>
      <hr className="content_divider" />
      {hasUsageData && (
        <HourlyUsageChart
          displayLoadingIcon={isFetchingSelectedDayAggregateData}
          isSelectedDayAggregateError={isSelectedDayAggregateError}
          productIsOffpeak={productFlags.isOffpeak}
          selectedDayMaxYValue={selectedDayMaxYValue}
          selectedDayUsageData={selectedDayUsageData}
        />
      )}
      {productFlags.isWholesale && hasUsageData && (
        <>
          <hr className="content_divider spacer--large" />
          <div className="header_section">
            <PriceTagIconSvg data-testid="icon" />
            <strong>Price per kWh</strong>
          </div>
          <HourlyKwhCostSection
            endAt={endOfDay(day)}
            startAt={startOfDay(day)}
            supplyNodeRef={supplyNodeRef}
          />
        </>
      )}
    </div>
  );
};
