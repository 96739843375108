import { debounce } from "lodash";
import { useLazyVerifyEmailQuery } from "queries/publicApi";
import React, { useCallback, useContext, useState } from "react";
import { isEmptyValue, isValidInput } from "utils/forms/forms";
import { emailValidation } from "utils/validations/emailValidation";
import {
  mobilePhoneNumberValidation,
  phoneNumberValidation,
} from "utils/validations/phoneNumberValidation";
import { preferredNameValidation } from "utils/validations/preferredNameValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import { prefixRemovedNZNumber } from "./AccountDetailsPage.utils";
import content from "./static/content.json";
const {
  emailInvalidError,
  emailRequiredError,
  emailUnverifiedError,
  homeNumberRequired,
  phoneNumberInvalidError,
  preferredNameRequiredError,
  preferredNameInvalidError,
  mobileNumberRequired,
  mobileNumberInvalidError,
} = content;

interface AccountDetailsContext {
  email?: FormField;
  homeNumber?: FormField;
  isFormValid?: boolean;
  mobileNumber?: FormField;
  preferredName?: FormField;
  emailVerificationInProgress?: boolean;
}

const initialContext: AccountDetailsContext = {};

const AccountDetailsFormContext = React.createContext(initialContext);

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
  extraProps: undefined,
};

const validationPreferredName = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: preferredNameValidation,
    requiredError: preferredNameRequiredError,
    invalidError: preferredNameInvalidError,
  });
};

const validationHomeNumber = (value: string, isRequired?: boolean) => {
  return validationFunctionHelper({
    value,
    validationFunction: phoneNumberValidation,
    invalidError: phoneNumberInvalidError,
    ...(isRequired && { requiredError: homeNumberRequired }),
  });
};

const validationMobileNumber = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: mobilePhoneNumberValidation,
    requiredError: mobileNumberRequired,
    invalidError: mobileNumberInvalidError,
  });
};

export const useAccountDetailsFormContext = () =>
  useContext(AccountDetailsFormContext);

export const AccountDetailsFormContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [preferredName, setPreferredName] = useState(initialFormFieldState);
  const [email, setEmail] = useState(initialFormFieldState);
  const [mobileNumbers, setMobileNumbers] = useState([initialFormFieldState]);
  const [homeNumbers, setHomeNumbers] = useState([
    { ...initialFormFieldState, isRequired: false },
  ]);

  /**
   * This state is needed to set the email verification in progress status when email input changes - it disables submit and shows a hint message on the email field.
   * We can't use verifyingEmail (isFetching from the query) because there is a 500ms debounce on the verification call, so submit could occur before the verification starts.
   */
  const [emailVerificationInProgress, setEmailVerificationInProgress] =
    useState(false);

  const [verifyEmailTrigger, { isFetching: verifyingEmail }] =
    useLazyVerifyEmailQuery();

  /**
   * Note for future work: The form only supports updating existing, not creating new phone numbers.
   * This means we can only render inputs for existing numbers. ie if a customer has no optional home number
   * the home number input will not be present in the form
   */
  const addDynamicPhoneNumberInputs = ({ values, type }) => {
    const inputArray = values.map((pn: PhoneNumber) => {
      return {
        ...initialFormFieldState,
        extraProps: { id: pn.id },
        ...(type === "home" && { isRequired: !!pn.number }), // if a home number already exists, it is required
      };
    });
    if (type === "mobile") {
      setMobileNumbers(inputArray);
      return;
    }
    setHomeNumbers(inputArray);
  };

  const setValuePreferredName = (value: string, setError: boolean) => {
    const fieldInError = preferredName.error;
    const error = validationPreferredName(value);
    setPreferredName((prev) => ({
      ...prev,
      error: fieldInError || setError ? error : "",
      isValid: !error,
      value,
    }));
  };

  /**
   * Called on blur to validate the required status of email field.
   * Shows the required error if the field is empty, otherwise keeps the current error (which is "" if the field is valid).
   */
  const validateEmailRequired = (value: string) => {
    const fieldInError = email.error;

    const error = isEmptyValue(value) ? emailRequiredError : fieldInError;
    setEmail((prev) => ({
      ...prev,
      error,
      isValid: !error,
    }));
  };

  /**
   * Called on change to validate the email input.
   * Use the regex to check the email meets basic email format requirements.
   * Set the value and result of regex check
   * If the email is valid, set the email verification in progress status and call the email verification function.
   */
  const setValueEmailOnChange = (value: string) => {
    const vaildEmailFormat = emailValidation(value);
    setEmail((prev) => ({
      ...prev,
      value,
      isValid: vaildEmailFormat,
      error: vaildEmailFormat ? "" : emailInvalidError,
    }));

    if (Boolean(value) && vaildEmailFormat) {
      setEmailVerificationInProgress(true);
      handleEmailVerification(value);
    }
  };

  /**
   * Debounced function to handle email verification.
   * Call AddressFinder service to `verifyEmail` if valid.
   * Update state based on the verification result.
   */
  const handleEmailVerification = useCallback(
    debounce(async (value) => {
      const response = await verifyEmailTrigger({ email: value });

      setEmail((prev) => ({
        ...prev,
        error: !response.data?.is_verified ? emailUnverifiedError : "",
        isValid: response.data?.is_verified,
      }));

      setEmailVerificationInProgress(verifyingEmail);
    }, 500),
    [],
  );

  const setValueMobileNumber = (
    value: string,
    index: number,
    setError: boolean,
  ) => {
    const fieldInError = mobileNumbers[index]?.error || "";
    const error = validationMobileNumber(prefixRemovedNZNumber(value));
    setMobileNumbers((prev) => {
      prev[index] = {
        ...prev[index],
        error: fieldInError || setError ? error : "",
        isValid: !error,
        value,
      };
      return [...prev];
    });
  };

  const setValueHomeNumber = (
    value: string,
    index: number,
    setError: boolean,
  ) => {
    const fieldInError = homeNumbers[index]?.error || "";
    const error = validationHomeNumber(value, homeNumbers[index]?.isRequired);
    setHomeNumbers((prev) => {
      prev[index] = {
        ...prev[index],
        error: fieldInError || setError ? error : "",
        isValid: !error,
        value,
      };
      return [...prev];
    });
  };

  /**
   * @description Checking if all the inputs are valid to enable the submit button
   */
  const allInputsValid = [
    isValidInput(preferredName),
    isValidInput(email),
    ...[...mobileNumbers, ...homeNumbers].map((number: FormFieldDetails) =>
      isValidInput(number),
    ),
  ].every(Boolean);

  return (
    <AccountDetailsFormContext.Provider
      value={{
        preferredName: {
          fieldValue: preferredName,
          handleOnChange: (value) => setValuePreferredName(value, false),
          handleOnBlur: () => setValuePreferredName(preferredName.value, true),
        },
        email: {
          fieldValue: email,
          handleOnChange: (value) => setValueEmailOnChange(value),
          handleOnBlur: () => validateEmailRequired(email.value),
        },
        mobileNumber: {
          fieldValueArray: mobileNumbers,
          addDynamicInputs: addDynamicPhoneNumberInputs,
          handleOnChange: (value, index) =>
            setValueMobileNumber(value, index, false),
          handleOnBlur: (index) =>
            setValueMobileNumber(mobileNumbers[index].value, index, true),
        },
        homeNumber: {
          fieldValueArray: homeNumbers,
          addDynamicInputs: addDynamicPhoneNumberInputs,
          handleOnChange: (value, index) =>
            setValueHomeNumber(value, index, false),
          handleOnBlur: (index) =>
            setValueHomeNumber(homeNumbers[index].value, index, true),
        },
        isFormValid: allInputsValid,
        emailVerificationInProgress,
      }}
    >
      {children}
    </AccountDetailsFormContext.Provider>
  );
};
