import React from "react";

export const BillSmootherExampleSetup: React.FC = () => {
  return (
    <section>
      <h3 className="h4">Here's how it works</h3>
      <p>
        Once you've set your Contribution Amount (what you'll pay each billing
        cycle), for any power bills that are less than the amount you've set,
        the difference will go into your Bill Smoother account as credit.
        <br />
        <br />
        For example:
      </p>
      <ul className="unordered-list">
        <li>
          Your Contribution Amount is set to <strong>$40</strong>.
        </li>
        <li>
          Your power bill this week is <strong>$30</strong>.
        </li>
        <li>
          We take payment of <strong>$30</strong> to pay your bill, and{" "}
          <strong className="text__colour--crimson">$10</strong> goes into your
          Bill Smoother credit.
        </li>
      </ul>
      <div className="spacer--medium" />
      <p>
        For power bills that are more than the Contribution Amount you've set,
        your credit will automatically kick in to pay the difference. Seriously
        smooth!
        <br />
        <br />
        For example:
      </p>
      <ul className="unordered-list">
        <li>
          Your Contribution Amount is set to <strong>$40</strong>.
        </li>
        <li>
          Your power bill this fortnight is <strong>$50</strong>.
        </li>
        <li>
          We take payment of <strong>$40</strong>, and use{" "}
          <strong className="text__colour--green">$10</strong> of your Bill
          Smoother credit to pay your power bill.
        </li>
      </ul>
    </section>
  );
};
