import { useLazyGetInvoiceTimelineQuery } from "queries/billingApi";
import { useEffect } from "react";
import { appendInvoices } from "reducers/currentInvoices";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { safeDate } from "utils/safeDate/safeDate";

import { isTotalInvoicesMultipleOfInvoiceApiLimit } from "./DateRangeNavigator.utils";

/**
 * Hook to handle loading more invoices when user scrolls or navigates
 */
export const useLoadMoreInvoices = (
  invoices: InvoiceSummary[],
  invoiceNumber: number,
) => {
  const { customerNumber } = useAppSelector((store) => store.currentAccount);
  const dispatch = useAppDispatch();

  const [trigger, { data: timelineData, isFetching: isTimelineFetching }] =
    useLazyGetInvoiceTimelineQuery();

  const triggerInvoiceTimelineLoadmore = (invoiceEndDate?: string) => {
    const nextDate = safeDate(invoiceEndDate);
    if (nextDate) {
      trigger({
        customerNumber,
        jwtClient,
        endDate: nextDate.toString(),
      });
    }
  };
  /**
   * loadmore invoice on navigation click
   * lazy refetch more invoices when user is at 2nd to last invoice
   */
  useEffect(() => {
    /**
     * only load more invoices if it looks like users do have more invoices to load
     * and user is looking at the second to last invoice
     * we do this by checking if the total number of invoices is a multiple of BILL_QUERY_LIMIT
     * (get invoices api is limited by BILL_QUERY_LIMIT which is 30)
     */

    const filteredInvoices = invoices.filter(
      (invoice, index, self) =>
        index === self.findIndex((i) => i.id === invoice.id),
    );
    if (
      invoiceNumber === filteredInvoices.slice(-2)[0]?.attributes.number &&
      isTotalInvoicesMultipleOfInvoiceApiLimit(invoices)
    ) {
      triggerInvoiceTimelineLoadmore(
        /**
         * use the last invoice from current array to set for next invoices query (1*)
         */
        invoices[invoices.length - 1].attributes?.invoice_date,
      );
    }
  }, [invoiceNumber]);

  useEffect(() => {
    if (timelineData && timelineData.length > 0) {
      const timelineBills = [...timelineData];
      dispatch(appendInvoices(timelineBills));
    }
  }, [timelineData, dispatch]);

  /**
   * load more invoices when scrolling to the end of custom dropdown
   */
  const loadMoreInvoicesOnScroll: React.UIEventHandler<HTMLDivElement> = (
    e,
  ) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    // + 1px due to dropdown list has outline: var(--border-light);
    const atBottom = scrollTop + clientHeight + 1 >= scrollHeight;
    if (atBottom && !isTimelineFetching) {
      if (isTotalInvoicesMultipleOfInvoiceApiLimit(invoices)) {
        triggerInvoiceTimelineLoadmore(
          /**
           * use the last invoice from current array to set for next invoices query (1*)
           */
          invoices[invoices.length - 1].attributes?.invoice_date,
        );
      }
    }
  };

  return { loadMoreInvoicesOnScroll, isTimelineFetching };
};
