import { ReactComponent as ZoomInSvg } from "app/assets/images/icons/zoom-in.svg";
import { ReactComponent as ZoomOutSvg } from "app/assets/images/icons/zoom-out.svg";
import { Chart } from "chart.js";
import { zoom } from "chartjs-plugin-zoom";
import React from "react";

type ZoomControlsProps = {
  chart: Chart;
  title: string;
};

export const ZoomControls: React.FC<ZoomControlsProps> = ({ chart, title }) => {
  return (
    <div className="chart__controls">
      <p className="small text text__secondary chart__title">{title}</p>
      <div className="chart__buttons">
        <button
          className="chart__button"
          onClick={() => {
            zoom(chart, 1.5, "normal");
          }}
        >
          <ZoomInSvg className="chart__button-icon" aria-label="zoom in" />
        </button>
        <button
          className="chart__button"
          onClick={() => {
            zoom(chart, -1.5, "normal");
          }}
        >
          <ZoomOutSvg className="chart__button-icon" aria-label="zoom out" />
        </button>
      </div>
    </div>
  );
};
