import "./PageWrapperStyles.scss";

import { AccountSelectMenu } from "components/AccountSelectMenu";
import { AnnouncementBanner } from "components/AnnouncementBanner";
import { BackLink } from "components/BackLink";
import { NotificationBanner } from "components/NotificationBanner";
import { Page } from "components/Page";
import React, { useEffect } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { NotificationBannerTypes } from "utils/constants";

import { DisplayAnnouncementBanner } from "./DisplayAnnouncementBanner";

interface PageWrapperProps {
  children: JSX.Element;
  isAuthenticated: boolean;
  userIsOnMainPage: boolean;
  nativeRenderMode: boolean;
}

export const PageWrapper = ({
  isAuthenticated,
  userIsOnMainPage,
  nativeRenderMode,
  children,
}: PageWrapperProps): JSX.Element => {
  const { accountIndex } = useAppSelector((store) => store.currentAccount);
  const { accountDatas } = useAppSelector((store) => store.accountData);
  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );
  const announcementBanner: AnnouncementBanner = useAppSelector(
    (store) => store.announcementBanner,
  );

  useEffect(() => {
    if (
      [NotificationBannerTypes.success, NotificationBannerTypes.error].includes(
        notificationBanner?.type,
      )
    ) {
      window.scrollTo(0, 0);
    }
  }, [notificationBanner]);

  return (
    <>
      {isAuthenticated && announcementBanner && (
        <DisplayAnnouncementBanner
          enableNativeMode={announcementBanner.enableNativeMode}
          enableDesktopMode={announcementBanner.enableDesktopMode}
          nativeRenderMode={nativeRenderMode}
          hiddenBanner={announcementBanner.hideBanner}
        />
      )}
      {isAuthenticated && notificationBanner && (
        <div
          className={
            nativeRenderMode
              ? "notification_container notification_container--mobile"
              : "notification_container"
          }
        >
          <NotificationBanner />
        </div>
      )}
      <Page isAuthenticated={isAuthenticated}>
        {isAuthenticated && (
          <nav aria-label="Page" className="nav_customer_tools_subnav">
            <BackLink />
            {accountDatas?.length > 1 && userIsOnMainPage && (
              <AccountSelectMenu
                data={accountDatas}
                defaultValue={accountIndex}
              />
            )}
          </nav>
        )}
        {children}
      </Page>
    </>
  );
};
