import { UNIT_CODE_TYPES } from "utils/constants";
import { NZD } from "utils/currency";
import { capitalize } from "utils/text";
/**
 * @description Takes a unitType and a value and determines how the value needs to be shown in the total display bar
 * */
export const determineUnitCode = (
  unitType: string,
  value: number = undefined,
): string => {
  if (value === undefined) {
    return "No data available";
  }

  switch (unitType) {
    case UNIT_CODE_TYPES.cost:
      return NZD.format(value);
    case UNIT_CODE_TYPES.usage:
      return `${value} kWh`;
    case UNIT_CODE_TYPES.carbon:
      return `${value.toFixed(2)} gCO₂/kWh`;
    default:
      return value.toFixed(2);
  }
};

type PrepareTotalValueComparisonDataProps = {
  periodName: string;
  unitType: string;
  previousValue?: number;
  comparisonValue?: number;
};

/**
 * @returns An array of ComparisonChartData objects. Each object contains the following attributes:
 * label - string (containing period name),
 * unitType - string (unit type that the data relates to i.e. cost, usage, or carbon),
 * value - number or undefined (when the value is NaN)
 */
export const prepareTotalValueComparisonData = ({
  periodName,
  unitType,
  previousValue,
  comparisonValue,
}: PrepareTotalValueComparisonDataProps): ComparisonChartData[] => {
  return [
    {
      label:
        unitType === UNIT_CODE_TYPES.carbon
          ? `Last ${periodName}`
          : `Total last ${periodName}`,
      unitType: unitType,
      value: isNaN(previousValue) ? undefined : previousValue,
    },
    {
      label:
        unitType === UNIT_CODE_TYPES.carbon
          ? `${capitalize(periodName)} before`
          : `Total same ${periodName} last year`,
      unitType: unitType,
      value: isNaN(comparisonValue) ? undefined : comparisonValue,
    },
  ];
};
