import { createSlice } from "@reduxjs/toolkit";
import { billingApi } from "queries/billingApi";

const currentInvoices = createSlice({
  name: "currentInvoices",
  initialState: {
    currentLatestInvoices: [],
    isLatestInvoicesFetching: false,
  },
  reducers: {
    appendInvoices: (state, { payload }) => {
      // Append new invoices to the current list
      state.currentLatestInvoices = [
        ...state.currentLatestInvoices,
        ...payload,
      ];
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        billingApi.endpoints.getLatestInvoices.matchPending,
        (state, {}) => {
          Object.assign(state, {
            isLatestInvoicesFetching: true,
            currentLatestInvoices: [],
          });
        },
      )
      .addMatcher(
        billingApi.endpoints.getLatestInvoices.matchRejected,
        (state, {}) => {
          Object.assign(state, {
            isLatestInvoicesFetching: false,
          });
        },
      )
      .addMatcher(
        billingApi.endpoints.getLatestInvoices.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state, {
            isLatestInvoicesFetching: false,
            currentLatestInvoices: payload || [],
          });
        },
      ),
});

export const { appendInvoices } = currentInvoices.actions;
export const currentInvoicesReducer = currentInvoices.reducer;
