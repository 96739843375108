import React from "react";

type TotalDisplayBarProps = {
  label: string;
  totalValue: string;
  icon?: JSX.Element;
};

/**
 * @description Bar for displaying total values with different units.
 */
export const TotalDisplayBar: React.FC<TotalDisplayBarProps> = ({
  label,
  totalValue,
  icon,
}) => {
  return (
    <div className="progress">
      <div className="progress__bar progress__text_right">
        {icon ? (
          <div
            data-testid="progressWithIconAndText"
            className="progress__text_icon"
          >
            {icon}
            <p className="small">{label}</p>
          </div>
        ) : (
          <p className="small">{label}</p>
        )}
        <p className="small">{totalValue}</p>
      </div>
    </div>
  );
};
