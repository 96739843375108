import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { NZD } from "utils/currency";

export const BillSmootherSettingsDetails: React.FC = () => {
  const { billSmootherConfig } = useAppSelector(
    (store) => store.billSmootherConfig,
  );
  const billSmootherSetUpWithDifferentValues = useMemo(() => {
    return !billSmootherConfig
      ? false
      : billSmootherConfig.contribution_threshold !==
          billSmootherConfig.withdrawal_trigger;
  }, [billSmootherConfig]);

  return (
    <section className="spacer--medium">
      <h3 className="h4">Settings</h3>
      <span className="spacer" />
      {billSmootherSetUpWithDifferentValues ? (
        <>
          <h4 className="h6">Contribution amount:</h4>
          <p
            className="customer_tools_section__text"
            data-testid="contribution_threshold"
          >
            You're contributing at least{" "}
            <strong className="text__colour--green">
              {NZD.format(+billSmootherConfig.contribution_threshold)}
            </strong>{" "}
            at every bill
          </p>
          <span className="spacer" />
          <h4 className="h6">When to use your Bill Smoother credit:</h4>
          <p
            className="customer_tools_section__text"
            data-testid="withdrawal_trigger"
          >
            Help pay bills over{" "}
            <strong className="text__colour--crimson">
              {NZD.format(+billSmootherConfig.withdrawal_trigger)}
            </strong>
          </p>
        </>
      ) : (
        <p
          className="customer_tools_section__text"
          data-testid="contribution_threshold"
        >
          You're contributing at least{" "}
          <strong>
            {billSmootherConfig?.contribution_threshold
              ? NZD.format(+billSmootherConfig?.contribution_threshold)
              : "--.--"}
          </strong>{" "}
          at every bill
        </p>
      )}
    </section>
  );
};
