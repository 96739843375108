import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { billingApi } from "queries/billingApi";
import { carbonApi } from "queries/carbonApi";
import { contractManagerApi } from "queries/contractManagerApi";
import { customerApi } from "queries/customerApi";
import { identityApi } from "queries/identityApi";
import { paymentSmootherApi } from "queries/paymentSmootherApi";
import { pricingRateCardApi } from "queries/pricingRateCardApi";
import { provisioningApi } from "queries/provisioningApi";
import { publicApi } from "queries/publicApi";
import { ratingApi } from "queries/ratingApi";
import { telemetryApi } from "queries/telemetryApi";
import { accountDatasReducer } from "reducers/accountDatas";
import { accountDetailsSliceReducer } from "reducers/accountDetails";
import { announcementBannerSliceReducer } from "reducers/announcementBanner";
import { billingEntityDataSliceReducer } from "reducers/billingEntityData";
import { billSmootherSliceReducer } from "reducers/billSmoother";
import { currentAccountSliceReducer } from "reducers/currentAccount";
import { currentInvoicesReducer } from "reducers/currentInvoices";
import { currentProductSliceReducer } from "reducers/currentProduct";
import { notificationBannerReducer } from "reducers/notificationBanner";
import { rateCardsReducer } from "reducers/rateCards";
import { LOGOUT_ACTION } from "utils/constants";

const appReducer = combineReducers({
  accountData: accountDatasReducer,
  accountDetails: accountDetailsSliceReducer,
  announcementBanner: announcementBannerSliceReducer,
  billingEntityData: billingEntityDataSliceReducer,
  billSmootherConfig: billSmootherSliceReducer,
  currentAccount: currentAccountSliceReducer,
  currentProduct: currentProductSliceReducer,
  notificationBanner: notificationBannerReducer,
  rateCards: rateCardsReducer,
  currentInvoices: currentInvoicesReducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [provisioningApi.reducerPath]: provisioningApi.reducer,
  [contractManagerApi.reducerPath]: contractManagerApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [paymentSmootherApi.reducerPath]: paymentSmootherApi.reducer,
  [pricingRateCardApi.reducerPath]: pricingRateCardApi.reducer,
  [identityApi.reducerPath]: identityApi.reducer,
  [ratingApi.reducerPath]: ratingApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [carbonApi.reducerPath]: carbonApi.reducer,
  [telemetryApi.reducerPath]: telemetryApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_ACTION) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const logger = () => (next) => (action) => {
  console.log("dispatch", action);
  return next(action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const baseMiddleware = [
      publicApi.middleware,
      provisioningApi.middleware,
      contractManagerApi.middleware,
      customerApi.middleware,
      paymentSmootherApi.middleware,
      pricingRateCardApi.middleware,
      identityApi.middleware,
      ratingApi.middleware,
      billingApi.middleware,
      carbonApi.middleware,
      telemetryApi.middleware,
    ];

    if (process.env.NODE_ENV === `development`) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        logger,
        ...baseMiddleware,
      );
    }

    return getDefaultMiddleware({ serializableCheck: false }).concat(
      ...baseMiddleware,
    );
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
