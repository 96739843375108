import { TotalDisplayBar } from "elements/TotalDisplayBar";
import React, { useMemo } from "react";

import {
  determineUnitCode,
  prepareTotalValueComparisonData,
} from "./TotalValueComparisonChart.utils";

type TotalValueComparisonChartProps = {
  periodName: string;
  unitType: string;
  previousValue?: number;
  comparisonValue?: number;
};

export const TotalValueComparisonChart: React.FC<
  TotalValueComparisonChartProps
> = ({ periodName, unitType, previousValue, comparisonValue }) => {
  const previousPeriodsComparisonData = useMemo(
    () =>
      prepareTotalValueComparisonData({
        periodName,
        unitType,
        previousValue,
        comparisonValue,
      }),
    [previousValue, comparisonValue],
  );

  return (
    <>
      {previousPeriodsComparisonData
        .filter((data) => data.value > 0)
        .map((data) => (
          <div className="spacer--small" key={`${data.label}-${data.unitType}`}>
            <TotalDisplayBar
              key={`${data.unitType}-${data.label}`}
              label={data.label}
              totalValue={determineUnitCode(data.unitType, data.value)}
            />
          </div>
        ))}
    </>
  );
};
