import { useSearchParams } from "react-router";

const PATHS_TO_CLEAR_URL_PARAMS = ["/usage"];

export const useAccountChanged = (): (({ path }: { path: string }) => void) => {
  const [_, setSearchParams] = useSearchParams();

  const handleClearUrlParams = ({ path }) => {
    if (PATHS_TO_CLEAR_URL_PARAMS.includes(path)) {
      setSearchParams("");
    }
  };
  return handleClearUrlParams;
};
