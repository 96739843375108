/* eslint-disable jsx-a11y/anchor-is-valid */
import "styles/BillListPage.scss";

import { BillList } from "components/BillList";
import { BillSmootherDefault } from "components/BillSmoother/BillSmootherDefault";
import { CurrentYearCostsSection } from "components/CurrentYearCostsSection";
import { NoBillDataFallback } from "components/fallback/NoBillDataFallback";
import { Loader } from "components/Loader";
import { FriendGetFriendCard } from "components/PromoCard/FriendGetFriendCard";
import { YourBillSmootherBalance } from "compositions/YourBillSmootherBalance";
import { useLazyGetInvoiceTimelineQuery } from "queries/billingApi";
import React, { useEffect, useState } from "react";
import { appendInvoices } from "reducers/currentInvoices";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import { BILL_QUERY_LIMIT } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { refetchOnScroll } from "utils/refetchOnScroll";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

/**
 * @description A list of all of a customers bills from most recent to the least recent
 */
export const BillListPage: React.FC = () => {
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const { customerNumber, icpNumber, active } = useAppSelector(
    (store) => store.currentAccount,
  );
  const { billSmootherConfig: billSmootherConfigState } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  const { currentLatestInvoices, isLatestInvoicesFetching } = useAppSelector(
    (store) => store.currentInvoices,
  );
  const dispatch = useAppDispatch();

  const [storedTimelineBills, setStoredTimelineBills] = useState([]);
  const [infiniteScrollCount, setInfiniteScrollCount] = useState(0);

  useEffect(() => {
    // if current latest invoices is 0 it is during init/re-init, which means reset infinity scroll count
    if (currentLatestInvoices.length === 0) {
      setInfiniteScrollCount(0);
    }
    // Update storedInvoices if the new array differs
    if (
      JSON.stringify(currentLatestInvoices) !==
      JSON.stringify(storedTimelineBills)
    ) {
      setStoredTimelineBills([...currentLatestInvoices]);
    }
  }, [currentLatestInvoices]);

  /**
   * This returns invoices that have been sent to the customer already.
   * There is a gap between collecting the data for an invoice and the send date,
   * so the latest bill may not display for several days after the relevant period.
   */
  const [trigger, { data: timelineData, isFetching: isTimelineFetching }] =
    useLazyGetInvoiceTimelineQuery();

  useEffect(() => {
    if (storedTimelineBills?.length > 0) {
      const refetchInvoiceTimeline = async () => {
        /**
         * only load more invoices if it looks like users do have more invoices to load
         * we do this by checking if the total number of invoices is a multiple of BILL_QUERY_LIMIT
         * (get invoices api is limited by BILL_QUERY_LIMIT which is 30)
         */
        if (storedTimelineBills?.length % BILL_QUERY_LIMIT !== 0) return;
        const nextDate = new Date(
          storedTimelineBills[
            storedTimelineBills.length - 1
          ].attributes.invoice_date,
        );
        /**
         * use the last invoice from current array to set for next invoices query (1*)
         */
        await trigger({
          customerNumber,
          jwtClient,
          endDate: nextDate.toString(),
        });
      };
      return refetchOnScroll(refetchInvoiceTimeline);
    }
  }, [storedTimelineBills]);

  useEffect(() => {
    if (timelineData && timelineData.length > 0) {
      sendAnalyticsEvent("scroll", {
        icpNumber,
        scroll_number: infiniteScrollCount,
      });
      setInfiniteScrollCount(infiniteScrollCount + 1);

      const timelineBills = [...timelineData];
      dispatch(appendInvoices(timelineBills));
    }
  }, [timelineData]);

  return (
    <div className="page-section">
      {/* todo change page-section-bills__column to page-section__column when doing new design */}
      {active && (
        <section className="page-section__column page-section-bills__column--left">
          <CurrentYearCostsSection
            billingEntityData={billingEntityData}
            cardChildren={
              !billSmootherConfigState?.enrolled && <BillSmootherDefault />
            }
          />
          {billSmootherConfigState?.enrolled && (
            <>
              <div className="spacer--large" />
              <YourBillSmootherBalance
                links={[
                  {
                    label: "Bill Smoother details",
                    path: "/bills/bill-smoother",
                  },
                ]}
              />
            </>
          )}
          <div className="spacer--large" />
          <FriendGetFriendCard />
        </section>
      )}
      {/* todo change page-section-bills__column to page-section__column when doing new design */}
      <section className="page-section__column page-section-bills__column--right">
        <h2 className="page__heading">Bills</h2>
        <div className="spacer" />
        {!customerNumber && <NoBillDataFallback />}
        <nav>
          <ul className="customer_tools_list">
            {isLatestInvoicesFetching && active && (
              <li
                className="loading-section"
                data-testid="list-loading-indicator"
              >
                <Loader />
              </li>
            )}
            {!isLatestInvoicesFetching && (
              <>
                {billingEntityData && (
                  <BillList
                    bills={
                      // due to (1*), the first item in new invoice array will be duplicated,
                      // so remove duplicated item before render
                      storedTimelineBills.filter(
                        (invoice, index, self) =>
                          index === self.findIndex((i) => i.id === invoice.id),
                      )
                    }
                  />
                )}
                <li
                  className={
                    isTimelineFetching
                      ? "loading-section"
                      : "loading-section--invisible"
                  }
                  data-testid="timeline-fetching-loading-indicator"
                >
                  <Loader />
                </li>
              </>
            )}
          </ul>
        </nav>
      </section>
    </div>
  );
};
