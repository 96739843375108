import React from "react";
import { Link } from "react-router";
import { jwtClient } from "utils/jwtClient";

type ErrorPageProps = {
  error: Error;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  return (
    <div>
      <nav>
        <Link
          to="/welcome"
          className="link"
          onClick={() => jwtClient.clearJwt()}
        >
          Logout
        </Link>
      </nav>

      <h1 className="heading h6">Error</h1>

      <p>{error.message}</p>
    </div>
  );
};
