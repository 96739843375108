import "./InvoiceTotal.scss";

import { ReactComponent as BoltIconSvg } from "app/assets/images/icons/bolt-pointy-solid.svg";
import { ReactComponent as CartIconSvg } from "app/assets/images/icons/cart-solid.svg";
import { ReactComponent as DollarSignIconSvg } from "app/assets/images/icons/dollar-solid.svg";
import { ReactComponent as PriceTagIconSvg } from "app/assets/images/icons/label-sold.svg";
import { LoadingSection } from "components/LoadingSection";
import { TotalDisplayBar } from "elements/TotalDisplayBar";
import { useLargeTabContext } from "pages/UsagePage/components/LargeTabPanel/LargeTabProvider";
import { useInvoiceNumber } from "pages/UsagePage/compositions/InvoiceProvider";
import React, { useMemo } from "react";
import { Link } from "react-router";
import { NZD } from "utils/currency";
import { safeNumber } from "utils/safeNumber/safeNumber";
type InvoiceTotalProps = {
  invoices: InvoiceSummary[];
};

type InvoiceTotalDetail = {
  icon: React.ReactElement;
  label: string;
  total: string;
};

type InvoiceTotalsData = {
  [key: string]: InvoiceTotalDetail;
};

/**
 * @description Finds the invoice and formats the totals data to render in TotalDisplayBar components.
 * Gets solar or usage totals, based on the showSolar param (if activeTab === "solar")
 */
const prepareTotalsData = ({
  invoiceNumber,
  invoices,
  showSolar,
  isCurrentUsage,
}: {
  invoiceNumber: number;
  invoices: InvoiceSummary[];
  showSolar?: boolean;
  isCurrentUsage: boolean;
}): InvoiceTotalsData => {
  const invoice = invoices.find(
    ({ attributes }) => attributes.number === invoiceNumber,
  );

  const totals: InvoiceTotalsData = showSolar
    ? {
        bought: {
          icon: <CartIconSvg />,
          label: isCurrentUsage ? "Bought so far" : "Total bought",
          total: `${NZD.format(safeNumber(Number(invoice?.attributes.total_payment_due.price_excl_sellback), 2))}`,
        },
        sold: {
          icon: <PriceTagIconSvg />,
          label: isCurrentUsage ? "Sold so far" : "Total sold",
          total: `${NZD.format(safeNumber(Number(invoice?.attributes.total_payment_due.sellback_price), 2))}`,
        },
        balance: {
          icon: <DollarSignIconSvg />,
          label: isCurrentUsage ? "Balance so far" : "Total balance",
          total: `${NZD.format(safeNumber(Number(invoice?.attributes.total_payment_due.price), 2))}`,
        },
      }
    : {
        cost: {
          icon: <DollarSignIconSvg />,
          label: isCurrentUsage ? "Cost so far" : "Total cost",
          total: `${NZD.format(safeNumber(Number(invoice?.attributes.total_payment_due.price), 2))}`,
        },
        usage: {
          icon: <BoltIconSvg />,
          label: isCurrentUsage ? "Usage so far" : "Total usage",
          total: `${safeNumber(Number(invoice?.attributes.usage.value), 3)} kWh`,
        },
      };

  return totals;
};

/**
 * @description Prepares usage or solar totals data, and renders them in TotalDisplayBar(s).
 */
export const InvoiceTotal: React.FC<InvoiceTotalProps> = ({ invoices }) => {
  const { invoiceNumber, isCurrentUsage } = useInvoiceNumber();
  const { activeTab } = useLargeTabContext();

  const totalsData: InvoiceTotalsData = useMemo(() => {
    return invoiceNumber
      ? prepareTotalsData({
          invoiceNumber,
          invoices,
          showSolar: activeTab === "solar",
          isCurrentUsage,
        })
      : null;
  }, [invoiceNumber, invoices, activeTab, isCurrentUsage]);

  if (!Boolean(totalsData)) {
    return <LoadingSection />;
  }

  return (
    <div>
      {!isCurrentUsage && (
        <div className="invoice-number__container">
          <Link className="invoice-number__link" to={`/bills/${invoiceNumber}`}>
            <p className="small">Invoice no: {invoiceNumber}</p>
          </Link>
        </div>
      )}
      {isCurrentUsage && <div className="spacer spacer--large" />}
      <div className="invoice-total-container">
        {Object.entries(totalsData).map(([key, value]) => {
          return (
            <TotalDisplayBar
              key={key}
              icon={value.icon}
              label={value.label}
              totalValue={value.total}
            />
          );
        })}
      </div>
    </div>
  );
};
