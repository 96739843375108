/** This is where all external API calls are defined. */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * UAT emails will all be unverified when checked in the AddressFinder API so we need to mock the API response and
 * simulate a successful verification in UAT environment.
 */
const mockBaseQuery = async () => {
  return {
    data: {
      is_verified: true,
    },
  };
};

// change "uat" to "dev" for local development if needed
const baseQuery =
  process.env.REACT_APP_ENV === "uat"
    ? mockBaseQuery
    : fetchBaseQuery({ baseUrl: "" });

/*** END uat mock ***/

// Define the publicApi slice
export const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery,
  endpoints: (builder) => ({
    verifyEmail: builder.query({
      query: ({ email }: { email: string }) =>
        `https://api.addressfinder.io/api/email/v1/verification/?key=${process.env.REACT_APP_ADDRESS_FINDER_KEY}&email=${email}&format=json&features=domain%2Cconnection`,
    }),
  }),
});

export const { useLazyVerifyEmailQuery } = publicApi;
