import { DateInput } from "components/Inputs/DateInput";
import { format } from "date-fns/format";
import React from "react";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import { useMovingHouseFormContext } from "./MovingHouseContext";
import content from "./static/content.json";
const {
  moveInDateHint,
  moveOutDateHint,
  moveIndateInputLabel,
  moveOutdateInputLabel,
} = content;

/**
 * @description Returns the minimum date for the move in date, 2 weeks before today
 */
const getMinDate = (): string => {
  const today = new Date();
  return format(new Date(today.setDate(today.getDate() - 14)), "dd MMMM yyyy");
};

/**
 * @description Returns the maximum date for the move in date, 3 month after today
 */
const getMaxDate = (): string => {
  const today = new Date();
  return format(new Date(today.setMonth(today.getMonth() + 3)), "dd MMMM yyyy");
};

export const MovingHouseMoveInDate: React.FC = () => {
  const {
    moveDate: {
      fieldValue: { value, error },
      handleOnChange: setMoveDate,
    },
  } = useMovingHouseFormContext();

  const handleDateChange = (newValue: string) => {
    setMoveDate(newValue);
  };

  const dateHintValues = replaceContentParams(moveInDateHint || "", {
    moveInMinDate: getMinDate(),
    moveInMaxDate: getMaxDate(),
  });

  return (
    <div className="spacer spacer--medium">
      <DateInput
        errorMessage={error}
        handleDateChange={handleDateChange}
        hint={dateHintValues}
        label={moveIndateInputLabel}
        name="moveInDate"
        required
        value={value}
      />
    </div>
  );
};

export const MovingHouseMoveOutDate: React.FC = () => {
  const {
    moveOutDate: {
      fieldValue: { value, error },
      handleOnChange: setMoveOutDate,
    },
  } = useMovingHouseFormContext();

  const handleDateChange = (newValue: string) => {
    setMoveOutDate(newValue);
  };

  const dateHintValues = replaceContentParams(moveOutDateHint || "", {
    moveOutMinDate: format(new Date(), "dd MMMM yyyy"),
    moveOutMaxDate: getMaxDate(),
  });

  return (
    <div className="spacer spacer--medium">
      <DateInput
        errorMessage={error}
        handleDateChange={handleDateChange}
        hint={dateHintValues}
        label={moveOutdateInputLabel}
        name="moveOutDate"
        required
        value={value}
      />
    </div>
  );
};
