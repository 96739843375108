import { Balance } from "components/Balance";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { selectIsEnrolledInBillSmoother } from "selectors/billSmootherSelector";

type AccountBalanceProps = {
  accountBalance: BillingBalance;
  isAccountBalanceError: boolean;
  isAccountBalanceFetching: boolean;
};

export const AccountBalance: React.FC<AccountBalanceProps> = ({
  accountBalance,
  isAccountBalanceError,
  isAccountBalanceFetching,
}) => {
  const isEnrolledInBillSmoother = useAppSelector(
    selectIsEnrolledInBillSmoother,
  );

  if (isAccountBalanceFetching) {
    return <LoadingSection />;
  }

  if (isAccountBalanceError) {
    return <ComponentItemFallback componentTitle="Account balance" />;
  }

  return (
    <>
      {!isEnrolledInBillSmoother &&
        Number(accountBalance?.total_credits?.amount) > 0 && (
          <>
            <Balance
              balanceType="Account credit"
              amount={Number(accountBalance?.total_credits?.amount)}
            />
            <div className="spacer" />
          </>
        )}

      <Balance
        balanceType="Amount owing"
        amount={Number(accountBalance?.total_owing?.amount)}
      />
    </>
  );
};
