/**
 * @description Function that returns a filtered array of aggregates where status is not "missing". Used in CurrentBillPeriodSection and LastPeriodComparison components
 * @returns Aggregate[] | undefined
 */
export const getValidAggregateTotals = (
  aggregatesData: Aggregates,
): Aggregate[] | undefined => {
  if (
    !aggregatesData ||
    !Array.isArray(aggregatesData.aggregates) ||
    aggregatesData.aggregates.length === 0
  )
    return undefined;

  return aggregatesData.aggregates.filter(
    (aggregate) => aggregate.status !== "missing",
  );
};

/**
 * @description Function that takes the filtered array of aggregates and returns the summed total of each aggreagte cost value. Used in CurrentBillPeriodSection and LastPeriodComparison components
 * @returns number | undefined
 */
export const getAggregatesTotalCostValue = (
  validFilteredAggregates: Aggregate[],
): number | undefined => {
  if (
    !validFilteredAggregates ||
    Array.isArray(!validFilteredAggregates) ||
    validFilteredAggregates.length === 0
  )
    return undefined;

  return validFilteredAggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(`${aggregate.tax_inclusive_cost.value}`),
    0,
  );
};

/**
 * @description Function that takes the filtered array of aggregates and returns the summed total of each aggregate tax_inclusive_cost.import_value
 * @returns number | undefined
 */
export const getAggregatesTotalImportCost = (
  validFilteredAggregates: Aggregate[],
): number | undefined => {
  if (
    !validFilteredAggregates ||
    Array.isArray(!validFilteredAggregates) ||
    validFilteredAggregates.length === 0
  )
    return undefined;

  return validFilteredAggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(`${aggregate.tax_inclusive_cost.import_value}`),
    0,
  );
};

/**
 * @description Function that takes the filtered array of aggregates and returns the summed total of each aggregate tax_inclusive_cost.export_value
 * @returns number | undefined
 */
export const getAggregatesTotalExportRevenue = (
  validFilteredAggregates: Aggregate[],
): number | undefined => {
  if (
    !validFilteredAggregates ||
    Array.isArray(!validFilteredAggregates) ||
    validFilteredAggregates.length === 0
  )
    return undefined;

  return validFilteredAggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(`${aggregate.tax_inclusive_cost.export_value}`),
    0,
  );
};
