import { createApi } from "@reduxjs/toolkit/query/react";
import { endOfDay } from "date-fns/endOfDay";
import { startOfDay } from "date-fns/startOfDay";
import { JwtClient } from "utils/jwtClient";

import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);

  if (params.path === "/telemetry/v1/enode/user" && response.status === 404) {
    return { data: null };
  }

  if (params.path === "/telemetry/validated_measurements.csv") {
    const result = await response.text();
    return { data: result };
  }

  const result = await response?.json();

  return { data: result.data };
};

export type GetUsageDataCSVParams = {
  startAt: string;
  endAt: string;
  icpNumber: string;
  jwtClient: JwtClient;
};

export type updateBatteryOperationModeParams = {
  jwtClient: JwtClient;
  id: string;
  operationMode:
    | "IMPORT_FOCUS"
    | "EXPORT_FOCUS"
    | "TIME_OF_USE"
    | "SELF_RELIANCE";
};

type EnodeBattery = {
  attributes: {
    vendor: string;
    model: string;
    battery_capacity: string;
    charge_rate?: string;
    battery_level?: string;
    discharge_limit?: string;
    operation_mode: string;
    charging_status?: string;
  };
  id: string;
};

/**
 * Define the telemetryApi slice
 */
export const telemetryApi = createApi({
  reducerPath: "telemetryApi",
  baseQuery: apiCallBaseQuery,
  tagTypes: ["enodeBatteries"],
  endpoints: (builder) => ({
    getUsageDataCSV: builder.query<string, GetUsageDataCSVParams>({
      query({ jwtClient, startAt, endAt, icpNumber }) {
        const queryParams = {
          starting_at: startOfDay(new Date(startAt)).toString(),
          ending_at: endOfDay(new Date(endAt)).toString(),
          icp_number: icpNumber,
        };
        return {
          jwtClient: jwtClient,
          path: "/telemetry/validated_measurements.csv",
          queryParams,
        };
      },
    }),
    getEnodeUser: builder.query<
      EnodeUser,
      {
        jwtClient: JwtClient;
      }
    >({
      query({ jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: "/telemetry/v1/enode/user",
          queryParams: {
            include: "linked_vendors",
          },
        };
      },
    }),
    getEnodeLinkUISession: builder.query<
      EnodeLinkUISession,
      { jwtClient: JwtClient }
    >({
      query({ jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: "/telemetry/v1/enode/link_ui_session",
          queryParams: {
            vendor_type: "battery",
            redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/account/solar`,
          },
        };
      },
    }),
    getEnodeUserBatteries: builder.query<
      EnodeBattery[],
      {
        jwtClient: JwtClient;
      }
    >({
      query({ jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: "/telemetry/v1/enode/user_batteries",
        };
      },
      providesTags: ["enodeBatteries"],
    }),
    updateBatteryOperationMode: builder.mutation<
      BatteryOperationMode,
      updateBatteryOperationModeParams
    >({
      query({ jwtClient, id, operationMode }) {
        return {
          jwtClient: jwtClient,
          path: `/telemetry/v1/enode/batteries/${id}/operation_mode`,
          queryParams: {
            operation_mode: operationMode,
          },
          fetchParams: {
            method: "POST",
          },
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetUsageDataCSVQuery,
  useLazyGetUsageDataCSVQuery,
  useLazyGetEnodeLinkUISessionQuery,
  useGetEnodeUserQuery,
  useGetEnodeUserBatteriesQuery,
  useUpdateBatteryOperationModeMutation,
} = telemetryApi;
