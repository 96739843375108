export const LOW = 'low';
export const STANDARD = 'standard';
export const USER_TYPES = [LOW, STANDARD];

export const EXCLUSIVE = 'exclusive';
export const INCLUSIVE = 'inclusive';
export const GST_INCLUSION_OPTIONS = [INCLUSIVE, EXCLUSIVE];
export const HIGH_USAGE_THRESHOLD_NETWORKS = ['ORON', 'DUNE'];
export const BEST_PLAN_PROMISE_PRODUCTS = ['flat', 'off_peak'];

export const ANALYTICS_ENTER_ADDRESS = 'join_enter_address';
export const ANALYTICS_CHOOSE_PLAN = 'join_choose_plan';
export const ANALYTICS_BILL_ESTIMATE_SHOW = 'bill_estimate_show';
export const ANALYTICS_BILL_ESTIMATE_TOOLTIP = 'bill_estimate_tooltip';
export const ANALYTICS_BILL_ESTIMATE_SUBMIT = 'bill_estimate_submit';
export const ANALYTICS_BILL_ESTIMATE_LINK = 'bill_estimate_bill_link';
export const ANALYTICS_BILL_ESTIMATE_SAVINGS = 'bill_estimate_savings';
export const ANALYTICS_MANUAL_ADDRESS_ENTRY = 'manual';
export const ANALYTICS_AUTOMATIC_ADDRESS_ENTRY = 'automatic';
export const ANALYTICS_ADDRESS_MANY_FOUND = 'many';
export const ANALYTICS_VALUE_TRUE = 'yes';
export const ANALYTICS_VALUE_FALSE = 'no';
export const ANALYTICS_VALUE_MAYBE = 'maybe';

export const SAVINGS_THRESHOLD = 5;

export const PRODUCT_NAMES = {
  off_peak: 'off_peak',
  flat: 'flat',
  business: 'business',
  freestyle: 'wholesale',
  wholesale: 'wholesale',
};

export const RATE_CODES = {
  offpeak: 'Off-peak',
  peak: 'Peak',
  days: 'Daily charge',
  anytime: 'Anytime',
  shoulder: 'Shoulder',
  night: 'Night',
  summer: 'Summer',
  winter: 'Winter',
  standard: 'Usage',
  controlled: 'Controlled',
};

export const RATE_NAMES = {
  offpeak: 'Off-peak',
  peak: 'Peak',
  morningpeak: 'Morning-peak',
  eveningpeak: 'Evening-peak',
  summerpeak: 'Summer-peak',
  winterpeak: 'Winter-peak',
  'N|D': 'Day / Night',
  'D|N': 'Day / Night',
  CN: 'Controlled',
  IN: 'Inclusive',
  EG: 'Solar buy-back',
  default: 'Uncontrolled',
};

export const METER_NAME = {
  CONTROLLED: 'Controlled',
  DAY_NIGHT: 'Day / Night',
  INCLUSIVE: 'Inclusive',
  UNCONTROLLED: 'Uncontrolled',
  EMBEDDED_GENERATION: 'Embedded generation',
};

export const UNIT_CODES = {
  kwh: 'c/kWh',
  days: 'c/day',
};

export const GST_INCLUSION = {
  NO: 'exclusive',
  YES: 'inclusive',
};

export const GST = 1.15;

export const CONTENT_SLUG_TO_CLASS_MAPPINGS = {
  business: 'business',
  flat: 'fixed',
  off_peak: 'peak',
  wholesale: 'market',
};

export const CONTENT_SLUG_TO_THEME_MAPPINGS = {
  business: 'black',
  flat: 'charlie',
  off_peak: 'alpha',
  wholesale: 'delta',
};

export const CONTENT_SLUG_TO_BACKGROUND_MAPPINGS = {
  business: 'alpha',
  flat: 'charlie',
  off_peak: 'alpha',
  wholesale: 'delta',
};
