import "./DeltaMessage.scss";

import { ReactComponent as DownArrowSvg } from "app/assets/images/icons/circle-arrow-down.svg";
import { ReactComponent as RightArrowSvg } from "app/assets/images/icons/circle-arrow-right.svg";
import { ReactComponent as UpArrowSvg } from "app/assets/images/icons/circle-arrow-up.svg";
import React, { memo } from "react";

const DELTA_THRESHOLD = 5;

type DeltaMessageProps = {
  delta?: number;
  formatter?: (value: number) => string;
  periodLabel?: string;
};

export const DeltaMessage: React.FC<DeltaMessageProps> = memo(
  ({
    delta,
    formatter = (v) => `${v}`,
    periodLabel = "the previous period",
  }) => {
    if (delta === null || delta === undefined || isNaN(delta)) return <></>;

    const absoluteValueDelta = Number(Math.abs(delta).toFixed(3));

    const calculateComparisonData = () => {
      let ArrowIcon,
        deltaText = null,
        iconColour = `icon_colour`;

      if (absoluteValueDelta <= DELTA_THRESHOLD) {
        ArrowIcon = RightArrowSvg;
        deltaText = `about the same as ${periodLabel}`;
        iconColour = `icon_colour--secondary`;
      } else {
        ArrowIcon = delta < 0 ? DownArrowSvg : UpArrowSvg;
        deltaText =
          delta < 0
            ? `${formatter(absoluteValueDelta)} less than ${periodLabel}`
            : `${formatter(absoluteValueDelta)} more than ${periodLabel}`;
        iconColour = delta < 0 ? `icon_colour--green` : `icon_colour--crimson`;
      }

      return { ArrowIcon, deltaText, iconColour };
    };
    const { ArrowIcon, deltaText, iconColour } = calculateComparisonData();

    if (!ArrowIcon && !deltaText) return null;

    return (
      <div className="delta-message">
        <ArrowIcon data-testid="arrow-icon" className={`arrow ${iconColour}`} />
        <p className="text text__secondary" style={{ marginLeft: 5 }}>
          {deltaText}
        </p>
      </div>
    );
  },
);
