import { SolarInvoiceUsageChart } from "pages/UsagePage/components/InvoiceUsageChart";
import { useInvoiceUsageChart } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import React, { useMemo } from "react";

import { SolarInvoiceDayDetail } from "../InvoiceDayDetail";

type SolarInvoiceUsageSectionProps = {
  dailyUsageData: DailyUsage[];
  displayType: string;
  periodName: string;
};

/**
 * @description used in AggregatedInvoiceSection
 * daily usage data cannot be empty array
 */
export const SolarInvoiceUsageSection: React.FC<
  SolarInvoiceUsageSectionProps
> = ({ dailyUsageData, displayType, periodName }) => {
  const { selectedDayIndex, lastDayIndex } = useInvoiceUsageChart();

  const selectedDayData = useMemo(() => {
    if (selectedDayIndex > lastDayIndex) {
      // and default return first item in array
      return dailyUsageData[0];
    }
    return dailyUsageData[selectedDayIndex];
  }, [selectedDayIndex, dailyUsageData]);

  return (
    <>
      <SolarInvoiceUsageChart
        dailyUsageData={dailyUsageData}
        displayType={displayType}
      />
      <SolarInvoiceDayDetail
        selectedDayData={selectedDayData}
        periodName={periodName}
      />
    </>
  );
};
