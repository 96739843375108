import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { ApplicationJourneyTracker } from "components/ApplicationJourneyTracker/ApplicationJourneyTracker";
import { BillSmootherCard } from "components/PromoCard/BillSmootherCard";
import { CurrentBillingPeriodCountdown } from "compositions/CurrentBillingPeriodCountdown";
import { TotalUsageSection } from "compositions/TotalUsageSection";
import { YourBillSmootherBalance } from "compositions/YourBillSmootherBalance";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { selectIsEnrolledInBillSmoother } from "selectors/billSmootherSelector";
import {
  selectProductIsOffPeak,
  selectProductIsOffWholesale,
} from "selectors/currentProductSelector";
import { AVAILABLE_PERIODS } from "utils/constants";

// import { CarbonView } from "./compositions/CarbonView";
import { OffpeakDetailsSection } from "./compositions/OffpeakDetailsSection";
import { WholeSalePriceSection } from "./compositions/WholeSalePriceSection";
import { YourAccountBalance } from "./compositions/YourAccountBalance";
import content from "./static/content.json";
const { applicationJourneyWelcome } = content;

interface BillingPeriod {
  code: string;
  duration: number;
  periodName: string;
}

export const getBillingPeriod = (
  billingEntityData?: BillingEntity,
): BillingPeriod => {
  if (!billingEntityData) return undefined;
  return AVAILABLE_PERIODS[
    billingEntityData.nominated_billing_period
  ] as BillingPeriod;
};

/**
 * @description renders the Application Journey Tracker when it's called from the HomePage
 */
const ApplicationJourneyWrapper = (): JSX.Element => {
  return (
    <>
      <h1 className="heading h3">{applicationJourneyWelcome}</h1>
      <ApplicationJourneyTracker />
    </>
  );
};

/**
 * @description HomePage
 */
export const HomePage = (): JSX.Element => {
  const { active, accountNumber, customerNumber, isFetchAccountError } =
    useAppSelector((store) => store.currentAccount);

  const isEnrolledInBillSmoother = useAppSelector(
    selectIsEnrolledInBillSmoother,
  );

  const productIsOffpeak = useAppSelector(selectProductIsOffPeak);
  const productIsWholesale = useAppSelector(selectProductIsOffWholesale);

  /**
   * handle when useGetAccountInfoQuery returns 200 but no accountNumber existing
   * there is a seperate fallback screen within ApplicationJourney to handle if no associated application found
   */
  if (!isFetchAccountError && !accountNumber) {
    return <ApplicationJourneyWrapper />;
  }

  /**
   * show a fallback screen when useGetAccountInfoQuery falls over
   */
  if (isFetchAccountError) {
    return (
      <div
        data-testid="applicationJourneyNotificationBanner"
        className="notification_banner notification_notice icon"
      >
        <p className="heading">Sorry we can’t find your account information.</p>
        <p>Please contact us if the issue persists.</p>
      </div>
    );
  }

  return (
    <div className="page-section">
      <section className="page-section__column page-section__column--even">
        {productIsOffpeak && active && (
          <>
            <OffpeakDetailsSection />
            <div className="spacer--large" />
          </>
        )}
        {!productIsWholesale && active && (
          <>
            <CurrentBillingPeriodCountdown />
            <div className="spacer--large" />
            <>
              <TotalUsageSection
                componentTitle="Your bill"
                links={[
                  { label: "Your usage", path: "/usage" },
                  { label: "All bills", path: "/bills" },
                ]}
                sectionHeading="Your Bill"
              />
            </>
            <div className="spacer--large" />
          </>
        )}
        {productIsWholesale && active && (
          <>
            <Card>
              <CardHeader>
                <h2 className="h4">Wholesale details</h2>
              </CardHeader>
              <CardBody>
                <WholeSalePriceSection />
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </>
        )}
      </section>
      <section className="page-section__column page-section__column--even">
        {productIsWholesale && active && (
          <>
            <CurrentBillingPeriodCountdown />
            <div className="spacer--large" />
            <TotalUsageSection
              componentTitle="Your bill"
              links={[
                { label: "Your usage", path: "/usage" },
                { label: "All bills", path: "/bills" },
              ]}
              sectionHeading="Your Bill"
            />
            <div className="spacer--large" />
          </>
        )}
        {Boolean(customerNumber) && (
          <>
            <YourAccountBalance componentTitle="Your account balance" />
            <div className="spacer--large" />
          </>
        )}
      </section>
      <section className="page-section__column page-section__column--even">
        {/* Temporary placement here until we can release carbon for prod */}
        {isEnrolledInBillSmoother
          ? Boolean(customerNumber) &&
            active && (
              <>
                <YourBillSmootherBalance
                  links={[
                    {
                      label: "Bill Smoother details",
                      path: "/bills/bill-smoother",
                    },
                  ]}
                />
                <div className="spacer--large" />
              </>
            )
          : Boolean(customerNumber) &&
            active && (
              // TODO add new bill smoother promo card here
              <>
                <BillSmootherCard />
                <div className="spacer--large" />
              </>
            )}
        {/* Turn off until further decision on release date, has not been tested on UAT */}
        {/* <Card>
          <CardHeader>
            <h2 className="h4">Carbon tracker</h2>
          </CardHeader>
          <CardBody>
            <CarbonView componentTitle="Carbon tracker" />
          </CardBody>
        </Card>
        <div className="spacer--large" /> */}
      </section>
    </div>
  );
};
