import { BillingPeriodCountdown } from "components/BillingPeriodCountdown";
import { DaysProgressBar } from "compositions/DaysProgressBar";
import React from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

export const CurrentBillingPeriodCountdown = () => {
  const { pathname } = useLocation();
  const { currentBillingPeriod } = useAppSelector(
    (store) => store.billingEntityData,
  );

  if (!currentBillingPeriod?.startAt || !currentBillingPeriod?.endAt)
    return <></>;

  /**
   * Define the progress bar colour based on the current page (usage or home).
   * ProgressBar component defaults to pink of no colour is provided
   */
  const progressColour = {
    "/": "yellow",
    "/usage": "pink",
  };

  return (
    <>
      <BillingPeriodCountdown billingPeriod={currentBillingPeriod} />
      {currentBillingPeriod?.duration && (
        <DaysProgressBar
          currentDate={new Date()}
          currentBillingPeriodEndAt={currentBillingPeriod.endAt}
          duration={currentBillingPeriod?.duration}
          colour={progressColour[pathname]}
        />
      )}
    </>
  );
};
