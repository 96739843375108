import "./BillingPeriodCountdown.scss";

import { format } from "date-fns/format";
import React from "react";

import { ReactComponent as CalendarIcon } from "./icon-temp/calendar-icon.svg";

export const BillingPeriodCountdown = ({ billingPeriod }) => {
  if (!billingPeriod?.startAt || !billingPeriod?.endAt) {
    return <></>;
  }

  return (
    <>
      <h2 className="section-title heading h4">Billing period</h2>
      <div className="spacer--small" />
      <p className="date-range-display">
        <CalendarIcon height={24} width={24} />
        {`${format(billingPeriod.startAt, "d MMMM")} — ${format(billingPeriod.endAt, "d MMMM")}`}
      </p>
    </>
  );
};
