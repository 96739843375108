import "./BillSmootherFAQs.scss";

import React from "react";

/**
 * @description A collection of detail disclosure widgets that provides users
 * with information about frequently asked questions about Bill Smoother
 */
export const BillSmootherFAQs: React.FC = () => {
  return (
    <>
      <details className="accordion bill-smoother-accordion">
        <summary className="accordion__summary">FAQs</summary>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            How do I set up my Bill Smoother account?
          </summary>
          <p>
            Go to the Bills tab in your Flick web dashboard or mobile app and
            click 'Setup Bill Smoother'. After you set your Contribution Amount,
            your Bill Smoother account will be activated for the next week
            starting on Monday.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            How do I change my Bill Smoother Contribution Amount?
          </summary>
          <p>
            You can change your Contribution Amount in your Flick web dashboard
            or mobile app any time, and the change will take place after the
            next Sunday. If you want to pause your Bill Smoother for the week
            that we're in, or you want to pay your entire bill using your Bill
            Smoother stash, you'll need to give us a call.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Can I add a lump sum to Bill Smoother?
          </summary>
          <p>
            The way to do this is to add how much you want to put in to your
            Contribution Amount, and then change it back.
          </p>
          <p>
            So if your Contribution Amount is $30 and you want to add $100, then
            make your Contribution Amount $130 for one week (before Sunday) and
            then change it back after the payment is taken on Thursday (but
            before Sunday).
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Will I ever pay more than the amount I set Bill Smoother
            Contribution Amount to?
          </summary>
          <p>
            Possibly, it depends on what you set your Contribution Amount to.
            Remember, you have all the control! Here are the things that could
            happen...
          </p>
          <ul>
            <li>
              <strong>
                If your bill is lower than the Contribution Amount your Bill
                Smoother is set to:
              </strong>{" "}
              The difference goes into your Bill Smoother stash.
            </li>
            <li>
              <strong>
                If your bill is higher than the Contribution Amount you've set:
              </strong>{" "}
              You will be charged the amount your Contribution Amount is set to,
              and any outstanding balance will be covered by your Bill Smoother
              stash.
            </li>
            <li>
              <strong>
                If your bill is higher than the amount your Contribution Amount
                is set to but there isn't quite enough stashed in your Bill
                Smoother account
              </strong>{" "}
              You will be charged the amount your Contribution Amount is set to,
              your Bill Smoother stash will be emptied, and we will take payment
              for any outstanding balance from your bank account or credit card.
            </li>
          </ul>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Can I get my credit back?
          </summary>
          <p>
            If you need your Bill Smoother credit refunded to you, you can
            contact us on 0800 435 425 or hello@flickelectric.co.nz to request a
            credit refund. We will look at your debt balance, the timing of
            upcoming bills, and how much is in your Bill Smoother stash, to make
            a plan with you. If you are leaving Flick, any possible refund will
            be processed after your final bill is calculated. Check out the Bill
            Smoother Terms & Conditions for more details.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            When's the best time of year to set up my Bill Smoother?
          </summary>
          <p>
            As a tool, Bill Smoother helps to level out the seasonality of power
            bills, by storing credit during the warmer months which can be used
            to help pay higher bills during the winter time. You can set it up
            at any time, but we'd recommend doing it in the warmer months when
            your bills are lower and it's an easier time to be able to build up
            credit on your account so that you're well prepared for winter!
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Can I set Bill Smoother at a different cycle to my billing?
          </summary>
          <p>Nope, it needs to match your current billing cycle.</p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            How do I pause my Bill Smoother?
          </summary>
          <p>
            Head to the Bills tab and click 'Your Bill Smoother Details' and
            then 'Your Bill Smoother Settings', from there you should see the
            'Pause?' option. Just a heads up – pausing might not take effect
            right away. If your next billing cycle starts on Tuesday, make sure
            to pause Bill Smoother before Sunday to see the changes on your
            upcoming bill!
          </p>
          <p>
            When your Bill Smoother account is paused, you'll go back to paying
            the same amount as your bill on Thursday. If you have any Bill
            Smoother credit available at the time your Bill Smoother account is
            paused, we'll continue to apply that credit to the portion of your
            upcoming bills that exceeds your Contribution Amount until it's used
            up.
          </p>
          <p>
            When you reactivate your Bill Smoother account, the Contribution
            Amount you've set will start again on the next Thursday cycle.
          </p>
          <p>
            Remember you can always reduce your Contribution Amount if you find
            you're paying too much or need to reduce your outgoings.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            If I pause or make changes to my Bill Smoother settings, when will
            the changes take effect?
          </summary>
          <p>
            That's dependent on the day the request was made and the date of
            your next billing cycle. If your next billing cycle is on the
            following Tuesday, you'll need to change your Bill Smoother settings
            before Sunday in order for the change to take effect on your
            upcoming bill. If changes are made on or after Sunday, they won't
            come into effect until the following bill.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Is there a limit to how much credit I can have in my Bill Smoother
            account?
          </summary>
          <p>
            You can have up to $500 credit in your Bill Smoother account. If you
            reach this threshold, we'll automatically stop your contributions.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Can I use my credit to pay my next bill in full?
          </summary>
          <p>
            Yep – but you'll need to contact us on 0800 435 425 or
            hello@flickelectric.co.nz to request this be done manually.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            I'm moving house – do I need to set up a new Bill Smoother account
            at my new address?
          </summary>
          <p>
            Yes, you do! Once you've received your new Flick account, set up
            your new Bill Smoother account as soon as possible. That way, once
            you've paid your final bill on your old Flick account, the credit on
            your old Bill Smoother account will be transferred to your new Bill
            Smoother account and applied according to your Bill Smoother
            settings.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            What happens to my Bill Smoother credit if I don't set up a new Bill
            Smoother account?
          </summary>
          <p>
            After your final bill is successfully paid on your old Flick
            account, the credit is manually transferred over to your new account
            within two weeks, where it will be applied in full to your next
            bill. You can still set up a new Bill Smoother account at any stage,
            but if your credit has already been applied to a bill, it'll mean
            you need to build up your stash.
          </p>
        </details>
        <details className="accordion accordion--nested bill-smoother-accordion">
          <summary className="accordion__summary">
            Bill Smoother Legacy Users
          </summary>
          <p>
            If you are a legacy Bill Smoother user as of 14 December 2024, you
            can continue to use your nominated Bill Smoother Trigger Amount
            under your current account. However, if you choose to make your
            Trigger Amount the same as your Contribution Amount at any time,
            your settings will automatically default to a single Contribution
            Amount and you will no longer have the option to enter a Trigger
            Amount moving forward.
          </p>
          <p>
            Check out the Bill Smoother Terms & Conditions for more details or
            if you have any questions, you can contact us on 0800 435 425 or
            hello@flickelectric.co.nz.
          </p>
        </details>
      </details>
    </>
  );
};
