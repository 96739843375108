import React from "react";

export const getTagDetails = (
  enrolled: boolean = false,
  active: boolean = false,
) => {
  if (enrolled && active) {
    return {
      tagText: "active",
      /** TODO Temporary bill-smoother-tag--success class to override the background-color used for tag--success class. Remove once flick-styles tag--success background-color has been updated to include/use the variable for hex colour: #37604b */
      tagType: "tag--success bill-smoother-tag--success",
    };
  }

  if (enrolled && !active) {
    return {
      tagText: "paused",
      tagType: "tag--warning",
    };
  }

  return {
    tagText: "inactive",
    tagType: "",
  };
};

type BillSmootherStatusTagBuilderProps = {
  enrolled: boolean;
  active: boolean;
};

/**
 * @description Build a tag indicating the status of the bill smoother.
 */
export const BillSmootherStatusTagBuilder: React.FC<BillSmootherStatusTagBuilderProps> =
  React.memo(({ enrolled, active }) => {
    const { tagText, tagType } = getTagDetails(enrolled, active);
    return (
      <span data-testid="billSmootherTag" className={`tag ${tagType}`}>
        Bill Smoother {tagText}
      </span>
    );
  });
