import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router";

// Define the type for the context value
interface InvoiceContextType {
  invoiceNumber: number | null;
  setInvoiceNumber: (number: number) => void;
  isCurrentUsage: boolean;
}

// Create the context with a default value
export const InvoiceContext = createContext<InvoiceContextType | undefined>(
  undefined,
);

export const InvoiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // initialise the invoiceNumber with from URL param, if present, else `1` for current period
  const [invoiceNumber, setInvoiceNumber] = useState<number | null>(
    Number(searchParams.get("invoice")) || 1,
  );
  const [isCurrentUsage, setIsCurrentUsage] = useState<boolean>(false);

  useEffect(() => {
    if (invoiceNumber === null) return;
    const isCurrentPeriod = invoiceNumber === 1;
    setIsCurrentUsage(isCurrentPeriod);
    updateInvoiceURLParam(invoiceNumber, isCurrentPeriod);
  }, [invoiceNumber]);

  /**
   * Invoked when invoiceNumber is set.
   * Sets the invoice (number) param in searchParams, or deletes it if viewing current period
   * Avoids unnecessary operations by cheking against current searchParam
   */
  const updateInvoiceURLParam = (
    value: number,
    isCurrentPeriod: boolean,
  ): void => {
    const currentParam = searchParams.get("invoice");
    if (isCurrentPeriod && currentParam === null) return;

    if (isCurrentPeriod) {
      searchParams.delete("invoice");
    } else if (currentParam !== value.toString()) {
      searchParams.set("invoice", value.toString());
    }

    setSearchParams(searchParams);
  };

  return (
    <InvoiceContext.Provider
      value={{ invoiceNumber, setInvoiceNumber, isCurrentUsage }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export const useInvoiceNumber = () => {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error("useInvoice must be used within an InvoiceProvider");
  }
  return context;
};
