/**
 * @description Takes an array of wholesale price data and returns the data
 * in the format to display in a bar chart, and the maximum y axis value
 */

export const prepareHourlyKwhCostData = (
  pricesData: WholesalePrices,
): { dailyKwhValues: ChartUsage[]; maxYValue: number } => {
  if (!pricesData || pricesData.length === 0)
    return { dailyKwhValues: [], maxYValue: 0 };

  const values = pricesData.map((priceData) => {
    return Number(priceData.price.value);
  });

  const dailyKwhValues = pricesData.map((priceData) => {
    return {
      barColour: "yellow",
      time: Date.parse(priceData.startAt),
      value: Number(priceData.price.value),
    };
  });

  const maxYValue = Math.max(...values);

  return {
    dailyKwhValues,
    maxYValue,
  };
};
