import "styles/AccountPage.scss";

import { Button } from "@flick/fep-library";
import { AccountPageLink } from "components/AccountPageLink";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { LoadingSection } from "components/LoadingSection";
import { getAccountPageLinks } from "pages/AccountPage/accountPageLinks.utils";
import { expireTime } from "queries/queries.utils";
import { useGetEnodeUserQuery } from "queries/telemetryApi";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  clearNotificationBanner,
  setNotificationBanner,
} from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import content from "./static/content.json";
const { movingHouseHint } = content;

/**
 * ENODE implementation is POC and only enable for test users
 */
export const AccountPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    accountNumber,
    nativeRenderMode,
    active: accountActive,
  } = useAppSelector((store) => store.currentAccount);
  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );

  const { preferredName } = useAppSelector((store) => store.accountDetails);
  const {
    displayName: currentPlan,
    normalisedName: currentPlanNormalisedName,
  } = useAppSelector((store) => store.currentProduct);

  const handleLogout = () => {
    jwtClient.clearJwt();
  };

  // display or info banner when selected account is closed
  useEffect(() => {
    if (accountNumber && !accountActive) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.notice,
          name: NotificationBannerNames.accountClosed,
          page: pathname,
        }),
      );
      return;
    }

    if (notificationBanner) {
      dispatch(clearNotificationBanner());
    }
  }, [accountNumber, accountActive]);

  // Enode POC
  const { isFetching: isEnodeUserFetching, isError: isEnodeUserError } =
    useGetEnodeUserQuery(
      { jwtClient },
      {
        refetchOnMountOrArgChange: expireTime,
      },
    );

  const accountPageLinks = useMemo(
    () => getAccountPageLinks(currentPlanNormalisedName, accountActive),
    [currentPlanNormalisedName, accountActive],
  );

  if (!preferredName || isEnodeUserFetching) return <LoadingSection />;

  if (!preferredName) return <LoadingSection />;

  return (
    <div className="account_page">
      <AccountPageWrapper
        hasCancelButton={false}
        headerLabel={preferredName || "Your Account"}
        headerItems={[
          Boolean(accountNumber) && {
            key: "Account number",
            value: accountNumber,
          },
          Boolean(currentPlan) && { key: "Current plan", value: currentPlan },
        ]}
      >
        {!accountActive && !accountNumber && (
          <section>
            <h2 className="h4">Account information currently unavailable</h2>
            <p>
              We weren't able to retrieve any information about your account.
            </p>
            <p>
              If you've signed up recently, you'll see more account information
              here once your property has switched over to Flick.
            </p>
            <p>
              If you've been with Flick, for a while then this can be caused by
              a bad network connection or a problem with our servers. Please try
              again later.
            </p>
          </section>
        )}
        <ul className="customer_tools_list spacer spacer--small">
          {accountPageLinks
            // Enode POC
            .filter(
              ({ to }) =>
                // if get enode user return 401 then do not render solar option
                to !== "/account/solar" ||
                (!isEnodeUserError && !isEnodeUserFetching),
            )
            .map(({ icon, text, to }) => (
              <li key={to} className="customer_tools_list__item">
                <AccountPageLink
                  key={to}
                  icon={icon}
                  linkText={text}
                  linkTo={to}
                />
              </li>
            ))}
        </ul>
        {!nativeRenderMode && (
          <Button
            extraClasses="button--expanded spacer--large"
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
        {accountActive && (
          <section className="spacer">
            <h3 className="h4">Moving house?</h3>
            <p>{movingHouseHint}</p>
            <Button
              extraClasses="button--expanded spacer--large"
              onClick={() => navigate("/account/address/update")}
            >
              Move house
            </Button>
          </section>
        )}
      </AccountPageWrapper>
    </div>
  );
};
