import { AccountHeaderSection } from "components/AccountHeaderSection";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router";
import { useAppSelector } from "reduxUtils/hook";
import { FRIEND_GET_FRIEND_CREDIT_AMOUNT } from "utils/constants";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import { ClipboardLink } from "./components/ClipboardLink";
import { shortenReferralLink } from "./ReferPage.utils";
import content from "./static/content.json";
const {
  hotTipHeading,
  hotTipMessage,
  pageHeader,
  referralMessage,
  shareLinkHeading,
  noLinkErrorText,
  contactUsText,
} = content;

export const ReferPage = () => {
  const { referralLink } = useAppSelector((store) => store.accountDetails);

  const shortendReferralCode = shortenReferralLink(referralLink);

  const hotTipText = useMemo(() => {
    if (!shortendReferralCode) return "";
    return replaceContentParams(hotTipMessage || "", {
      referralCode: shortendReferralCode,
    });
  }, [shortendReferralCode]);

  const referralMessageText = useMemo(() => {
    return replaceContentParams(referralMessage || "", {
      creditAmount: FRIEND_GET_FRIEND_CREDIT_AMOUNT,
    });
  }, []);

  return (
    <div className="page-section">
      <section className="page-section__column page-section__column--single ">
        <AccountHeaderSection label={pageHeader} />
        <ReactMarkdown>{referralMessageText}</ReactMarkdown>
        <p>
          <strong>{shareLinkHeading}</strong>
        </p>
        {referralLink ? (
          <>
            <ClipboardLink
              url={referralLink}
              readableLinkText={shortendReferralCode}
            />
            <div className="spacer" />
            <p className="h4">{hotTipHeading}</p>
            <ReactMarkdown>{hotTipText}</ReactMarkdown>
          </>
        ) : (
          <p className="text__colour--crimson">
            {noLinkErrorText}{" "}
            <Link to={"/account/help"} className="text__colour--crimson">
              <strong>{contactUsText}</strong>
            </Link>
          </p>
        )}
      </section>
    </div>
  );
};
