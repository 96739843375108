import { getHours } from "date-fns/getHours";
import { isFriday } from "date-fns/isFriday";
import { isWeekend } from "date-fns/isWeekend";
import { nextMonday } from "date-fns/nextMonday";
import { previousFriday } from "date-fns/previousFriday";

/**
 * @description function that takes an array of Shift objects and sorts them by their starting hour
 * @param {Shift[]} shiftData - an array of Shift objects
 * @returns Shift[] - sorted array of shifts
 */
const sortShiftData = (shiftData: Shift[]) => {
  return [...shiftData].sort(
    (shift1, shift2) =>
      Number(shift1?.start_at?.split(":")[0]) -
      Number(shift2?.start_at?.split(":")[0]),
  );
};

/**
 * @description function that checks if the first shifts in an array have matching start and end times.
 * @param {Shift[]} shiftData - an array of Shift objects
 * @returns Boolean
 */
const shiftRunsAllDay = (shiftData: Shift[]) => {
  if (!Boolean(shiftData)) return false;

  return shiftData[0]?.start_at === shiftData[0]?.end_at;
};

/**
 * @description This function uses the current rate card and current date to find the current timetable for a customer. Because timetables start and end values are represented as 24hour strings (e.g start_at: "12:00") the cases this function needs to cover are:
 * - Weekday timetables with more than one shift and more than one rate code
 * - Friday timetables which are the same as a weekday timetable with the key difference that the final shift may span an entire weekend if weekend shifts are present.
 * - Weekend timetables which for OffPeak currently have a single all day shift and need to have their actual start and end dates represented as Friday to Monday (the begining of the last offpeak shift on a Friday to the end of the first offpeak shift on a Monday).
 * @param {RateCard | undefined} currentRateCard - the rate card containing timetables to sort through
 * @param {Date} currentDate - The default date to use when finding the current timetable. Does not have to be todays date as the day of the week is more relevant e.g usage charts may pass in dates from the past in order to find timetables when calculating historic offpeak usage.
 * @returns an array of start and end at shift times that make up the timetable as well as their rate codes and the start and end dates that the shift times are relevant for.
 */

export const findCurrentTimeTable = (
  currentRateCard: RateCard | undefined,
  currentDate?: Date,
): Shift[] => {
  return findShifts(
    currentRateCard?.attributes?.timetables,
    currentDate || new Date(),
  );
};

export const findShifts = (
  timetables: Timetables[] | undefined,
  date: Date,
): Shift[] => {
  return timetables
    ?.map((timetableData) => {
      let finalWeekdayShift;
      let startDate = date;
      let endDate = date;
      const weekdayShifts = timetableData.timetable.find(
        (timetableInfo) => !timetableInfo.weekends,
      );
      const weekendShifts = timetableData.timetable.find(
        (timetableInfo) => timetableInfo.weekends,
      );
      const currentShifts = isWeekend(date) ? weekendShifts : weekdayShifts;

      // if the current batch of shift data is for a weekend with shifts that run all day
      // use the data from the last shift on a Friday and span its start and end dates from
      // Friday to Monday.
      if (currentShifts?.weekends && shiftRunsAllDay(currentShifts?.shifts)) {
        finalWeekdayShift = sortShiftData(weekdayShifts?.shifts).slice(-1);
        startDate = previousFriday(date);
        endDate = nextMonday(date);
      }
      // If its a friday and a weekend timetable exists with shifts that run all day
      if (isFriday(date) && shiftRunsAllDay(weekendShifts?.shifts)) {
        const finalFridayShift = sortShiftData(currentShifts?.shifts).slice(-1);
        const currentHour = getHours(date);
        const lastShiftStartHour = Number(
          finalFridayShift[0].start_at.split(":")[0],
        );
        // if we're in the last shift of the day then return this shift but
        // set the end date to monday to allow for the all day weekend shifts.
        if (currentHour >= lastShiftStartHour) {
          finalWeekdayShift = finalFridayShift;
          endDate = nextMonday(date);
        }
      }

      const currentShift = finalWeekdayShift || currentShifts?.shifts;

      return currentShift?.map((shift) => ({
        ...shift,
        rateCode: timetableData.rate_code,
        startDate,
        endDate,
      }));
    })
    ?.flat()
    ?.filter(Boolean);
};
