import "./BillSmootherCard.scss";

import { CardColors, PromoCard } from "components/PromoCard";
import React from "react";

import content from "./static/content.json";

const {
  billSmootherButtonText: buttonText,
  billSmootherCardBody: cardBody,
  billSmootherCardHeaderText: cardHeaderText,
} = content;

type BillSmootherCardProps = {
  cardColor?: CardColors;
};

export const BillSmootherCard: React.FC<BillSmootherCardProps> = ({
  cardColor = CardColors.yellow,
}) => {
  return (
    <PromoCard
      bodyRightContent={
        <div>
          <section
            title="Bill Smoother icon"
            className="bill-smoother-card__icon"
          ></section>
        </div>
      }
      bodyText={cardBody}
      buttonLink="/bills/bill-smoother/more-information"
      buttonTextColour="white"
      buttonText={buttonText}
      cardColor={cardColor}
      headerText={cardHeaderText}
      previousPath={window.location.pathname}
    />
  );
};
