import "./DateRangeNavigator.scss";

import { ReactComponent as DownArrowSvg } from "app/assets/images/icons/chevron-down-lg.svg";
import { ReactComponent as BackIcon } from "app/assets/images/icons/chevron-left-lg.svg";
import { ReactComponent as ForwardIcon } from "app/assets/images/icons/chevron-right-lg.svg";
import classnames from "classnames";
import { LoadingIcon } from "components/LoadingIcon";
import { useOutSideAreaFocus } from "customHooks/useOutsideAreaFocus";
import { useInvoiceNumber } from "pages/UsagePage/compositions/InvoiceProvider";
import React, { memo, useEffect, useRef } from "react";

import { useLoadMoreInvoices } from "./DateRangeNavigator.customHooks";
import {
  formatBillingPeriod,
  isTotalInvoicesMultipleOfInvoiceApiLimit,
} from "./DateRangeNavigator.utils";

export const DateRangeNavigator: React.FC<{ invoices: InvoiceSummary[] }> =
  memo(({ invoices }) => {
    const { invoiceNumber, setInvoiceNumber } = useInvoiceNumber();

    useEffect(() => {
      if (invoiceNumber === null && invoices.length > 0) {
        setInvoiceNumber(invoices[0].attributes.number);
      }
    }, [invoiceNumber, invoices, setInvoiceNumber]);

    // Filter invoices to remove duplicates
    // due to (1*), the first item in new invoice array will be duplicated
    const filteredInvoices = invoices.filter(
      (invoice, index, self) =>
        index === self.findIndex((i) => i.id === invoice.id),
    );

    // Find the current invoice based on the invoice number
    const currentInvoice =
      invoiceNumber === null
        ? invoices[0]
        : filteredInvoices.find(
            (invoice) => invoice.attributes.number === invoiceNumber,
          );

    const handlePrevClick = () => {
      const currentInvoiceIndex = filteredInvoices.findIndex(
        (invoice) => invoice.attributes.number === invoiceNumber,
      );
      if (currentInvoiceIndex < filteredInvoices.length - 1) {
        setInvoiceNumber(
          filteredInvoices[currentInvoiceIndex + 1].attributes.number,
        );
      }
    };

    const handleNextClick = () => {
      const currentInvoiceIndex = filteredInvoices.findIndex(
        (invoice) => invoice.attributes.number === invoiceNumber,
      );
      if (currentInvoiceIndex > 0) {
        setInvoiceNumber(
          filteredInvoices[currentInvoiceIndex - 1].attributes.number,
        );
      }
    };

    /**
     * -------------------------LOAD MORE INVOICES START--------------------------------------------
     * lazy refetch more invoices when user is at 2nd to last invoice
     */
    const { loadMoreInvoicesOnScroll, isTimelineFetching } =
      useLoadMoreInvoices(invoices, invoiceNumber);

    /**
     * -------------------------LOAD MORE INVOICES END--------------------------------------------
     */

    /**
     * -------------------------TOGGLE INVOICE DROPDOWN-------------------------------------------
     */
    const clickAreaRef = useRef(null);
    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const { isFocusOutsideArea } = useOutSideAreaFocus(clickAreaRef);

    const toggleDropdown = () => {
      setDropdownOpen((prev) => !prev);
    };

    const handleSelect = (invoiceId: string) => {
      const selectedItem = invoices.find((i) => i.id === invoiceId);
      setInvoiceNumber(selectedItem.attributes.number);
      setDropdownOpen(false);
    };

    // Only close the dropdown if it's open and clicked outside
    useEffect(() => {
      if (isFocusOutsideArea && isDropdownOpen) {
        setDropdownOpen(false);
      }
    }, [isFocusOutsideArea, isDropdownOpen]);
    /**
     * -------------------------TOGGLE INVOICE DROPDOWN END-------------------------------------------
     */

    /**
     * due to we so remove duplicated item before render, the invoices user see is less than the actual invoices existing in the array
     * therefore, using index is not accurate
     * so we have to compare the current selected invoice and the actual last invoice after render
     */
    const isLastInvoice =
      isTotalInvoicesMultipleOfInvoiceApiLimit(invoices) === false &&
      invoiceNumber === filteredInvoices.slice(-1)[0]?.attributes.number;

    return (
      <div className="date-range-navigator">
        <button
          className="chevron-arrows"
          onClick={handlePrevClick}
          aria-disabled={!currentInvoice || isLastInvoice}
          disabled={!currentInvoice || isLastInvoice}
        >
          <BackIcon />
        </button>
        <div ref={clickAreaRef}>
          {currentInvoice && (
            <button
              className={classnames("date-range-navigator__dropdown-text", {
                "date-range-navigator__dropdown-text--selected": isDropdownOpen,
              })}
              onClick={toggleDropdown}
              aria-expanded={isDropdownOpen} // Indicates if the dropdown is open
              aria-haspopup="true" // Indicates that this button controls a dropdown
            >
              {formatBillingPeriod(
                currentInvoice.attributes.billing_period.period_started_at,
                currentInvoice.attributes.billing_period.period_ended_at,
              )}
              <DownArrowSvg
                className={classnames("date-range-navigator__arrow", {
                  "date-range-navigator__arrow--rotate": isDropdownOpen,
                })}
              />
            </button>
          )}
          {isDropdownOpen && (
            <div
              className="date-range-navigator__list"
              onScroll={loadMoreInvoicesOnScroll}
              // only for accessibility
              role="listbox"
              aria-activedescendant={currentInvoice?.id}
              aria-expanded="true"
              tabIndex={0}
            >
              {filteredInvoices.map((invoice) => (
                <div
                  key={invoice.id}
                  className={classnames("date-range-navigator__list-item", {
                    "date-range-navigator__list-item--selected":
                      invoice.id === currentInvoice.id,
                  })}
                  onClick={() => handleSelect(invoice.id)}
                  // only for accessibility
                  role="option"
                  aria-selected={invoice.id === currentInvoice.id} // Marks the selected item
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleSelect(invoice.id);
                    }
                  }}
                >
                  {formatBillingPeriod(
                    invoice.attributes.billing_period.period_started_at,
                    invoice.attributes.billing_period.period_ended_at,
                  )}
                </div>
              ))}
              {isTimelineFetching && <LoadingIcon />}
            </div>
          )}
        </div>
        <button
          className="chevron-arrows"
          onClick={handleNextClick}
          aria-disabled={
            !currentInvoice || currentInvoice === filteredInvoices[0]
          }
          disabled={!currentInvoice || currentInvoice === filteredInvoices[0]}
        >
          <ForwardIcon />
        </button>
      </div>
    );
  });
