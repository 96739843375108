import { Button, SecondaryButton } from "@flick/fep-library";
import { Dialog } from "components/Dialog";
import React from "react";
import { BILL_SMOOTHER_STATE } from "utils/constants";

import content from "./static/content.json";

type PauseBillSmootherDialogProps = {
  billSmootherData: BillSmootherConfig;
  handleClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      createData?: BillSmootherConfig;
    },
    newBannerText: string,
  ) => void;
  handleDialogClose: () => void;
  hidePauseBillSmootherDialog: boolean;
};

/**
 * @description used in BillSmootherSettingPage
 */
export const PauseBillSmootherDialog: React.FC<
  PauseBillSmootherDialogProps
> = ({
  hidePauseBillSmootherDialog,
  handleDialogClose,
  handleClick,
  billSmootherData,
}) => {
  const {
    pauseBillSmootherDialogHeading,
    pauseBillSmootherDialogContentOneText,
    pauseBillSmootherDialogContentOneListItems,
    pauseBillSmootherDialogContentTwoText,
  } = content;
  return (
    <Dialog
      handleDialogClose={handleDialogClose}
      dialogIsHidden={hidePauseBillSmootherDialog}
    >
      <h1 id="dialog-title" className="dialog_heading">
        {pauseBillSmootherDialogHeading}
      </h1>
      <p>{pauseBillSmootherDialogContentOneText}</p>
      <ul className="unordered-list">
        {pauseBillSmootherDialogContentOneListItems.map((listItem, index) => (
          <li key={index}>{listItem}</li>
        ))}
      </ul>
      <p>{pauseBillSmootherDialogContentTwoText}</p>
      <div className="dialog_contents__buttons spacer--medium">
        <Button
          type="submit"
          onClick={(event) =>
            handleClick(
              event,
              { active: !billSmootherData.active },
              !billSmootherData.active
                ? BILL_SMOOTHER_STATE.activated
                : BILL_SMOOTHER_STATE.paused,
            )
          }
        >
          Pause payments
        </Button>
        <div className="spacer--small" />
        <SecondaryButton id="close-cancel" onClick={handleDialogClose}>
          Cancel
        </SecondaryButton>
      </div>
    </Dialog>
  );
};
