import { BILL_QUERY_LIMIT } from "utils/constants";

/**
 * @description create new onScroll event to update nextEndDate state in the component lifecycle
 * currently only trigger when the current data array looks like it is more than 20 items
 */
export const refetchOnScroll = (triggerRefetch: () => Promise<void>) => {
  let fetching = false;
  const onScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
    if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
      fetching = true;
      // triggers refetch
      await triggerRefetch();
      fetching = false;
    }
  };

  document.addEventListener("scroll", onScroll);
  return () => {
    document.removeEventListener("scroll", onScroll);
  };
};
