import {
  encodeState,
  generateCodeChallenge,
  generateStateCode,
} from "auth/generateCodeChallenge";
import { generateCodeVerifier } from "auth/generateCodeVerifier";
import { getOauthTokens } from "queries/identity";
import Rollbar from "rollbar";
import { API_TOKEN_INVALID_ERROR, LOGOUT_ACTION } from "utils/constants";
import { rollbarConfig } from "utils/rollbarConfig";

export const rollbar = new Rollbar(rollbarConfig);

export class JwtClient {
  #jwt = null;
  #authenticatedInApp = false;
  #navigate = null;
  #dispatch = null;
  #setAuthExpired = null;

  setJwt(jwt) {
    this.#jwt = jwt;
    this.onAuthenticationChange(this.isAuthenticated());
  }

  // tempt work for logging in rollbar to debug
  getJwt() {
    return this.#jwt;
  }

  setNativeJwt(nativeJwt) {
    this.#jwt = nativeJwt;
  }

  setNavigate(navigate) {
    this.#navigate = navigate;
  }

  setDispatch(dispatch) {
    this.#dispatch = dispatch;
  }

  authenticatedInApp(authenticated) {
    this.#authenticatedInApp = authenticated;
  }

  setAuthExpired(setAuthExpired) {
    this.#setAuthExpired = setAuthExpired;
  }

  async authenticateWithIdentity(pathname) {
    if (this.#jwt) return;
    /**
     * generate and set code verifier for idenitity
     */
    const codeVerifier = generateCodeVerifier(48);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    window.sessionStorage.setItem("code_verifier", codeVerifier);
    /**
     * Generate and store state code on the redirect_uri state param
     * https://auth0.com/docs/secure/attack-protection/state-parameters#csrf-attacks
     */
    const stateCode = generateStateCode();
    // store state code in local storage for verifying later
    window.localStorage.setItem("state_code", stateCode);

    const requestData = {
      from: pathname,
      stateCode,
    };

    const state = encodeState(requestData);

    // @ts-ignore
    window.location = `${process.env.REACT_APP_API_BASE_URL}/identity/oauth/authorize?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&client_id=${process.env.REACT_APP_DASHBOARD_CLIENT_ID}&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&state=${state}`;
  }

  async getTokenWithAuthorizationCode(code, codeVerifier) {
    const params = `?code=${code}&grant_type=authorization_code&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&client_id=${process.env.REACT_APP_DASHBOARD_CLIENT_ID}&code_verifier=${codeVerifier}`;

    this.getTokens(params).catch((error) => {
      rollbar.info("Refresh token with Authorization Code failed", {
        errorMessage: error,
      });
      throw new Error("Refresh token with Authorization Code failed");
    });
  }

  /**
   * Verify that the code sent on the redirect_uri state param
   * matches the code received in the redirect_uri in AuthCallback.tsx
   * https://auth0.com/docs/secure/attack-protection/state-parameters#csrf-attacks
   */
  async verifyStateCode(initialCode, codeFromURL) {
    if (this.#jwt) return;
    if (initialCode !== codeFromURL) {
      rollbar.info("Redirect URI state code verification failed", {
        initialCode,
        urlCode: codeFromURL,
        isNative: this.#authenticatedInApp,
      });
      throw new Error("Redirect URI state code verification failed");
    }
    // remove state code after verifying successfully
    localStorage.removeItem("state_code");
  }

  // eslint-disable-next-line max-params
  async attemptLogin(refreshToken, navigateTo, redirectUrl, attachDebug) {
    const params = `?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${process.env.REACT_APP_DASHBOARD_CLIENT_ID}`;
    this.getTokens(params)
      .then(() => {
        navigateTo(redirectUrl);
        if (attachDebug) attachDebug();
      }) // Enables landing on original URL when user refreshes the browser
      .catch(() => this.clearJwt()); // if the refresh_token call returns an error, assume token is expired and clear it from localStorage.
  }

  handleApiError = (responseBody, apiURL, requestDetails) => {
    const responseBodyString = JSON.stringify(responseBody);

    if (
      /**
       * Network api is known to have performance issues atm, and it is used in the process
       * of getting any pricing details
       * so it is important to log this issue to monitor the performance of the network api
       *  but not as an error cause it noises our rollbar
       * */
      responseBodyString.includes("HTTP request timed out on /network/nz/")
    ) {
      rollbar.info(`API call failure due to Network Performance issue`, {
        responseBody: responseBodyString,
        apiUrl: apiURL,
        requestDetails,
      });
      return;
    }

    if (
      responseBodyString.includes(
        "Circuit breaker open for api.flick.energy/pricing",
      )
    ) {
      rollbar.info(
        `Rating/Aggregates call failure due to Circuit breaker open for api.flick.energy/pricing`,
        {
          responseBody: responseBodyString,
          apiUrl: apiURL,
          requestDetails,
        },
      );
      return;
    }

    if (
      responseBodyString.includes(
        "Unable to find all prices for the given ICP",
      ) ||
      /** Already monitoring this as info log https://app.rollbar.com/a/FlickElectric/fix/item/dashboard/3884#occurrences */
      /"detail":\s*"Couldn't find User with \[WHERE \\"users\\".\\"sub\\" = \$1\]"/.test(
        responseBodyString,
      )
    ) {
      rollbar.error(`API call failure: Ignore this error`);
      return;
    }

    /** handle other api errors as usual */
    rollbar.error(`API call failure: ${responseBodyString}`, {
      responseBody: responseBodyString,
      apiUrl: apiURL,
      requestDetails,
    });
  };

  /**
   * @param {{
   * path: string,
   * queryParams?: Object,
   * params?: Object,
   * fetchParams?: Object,
   * throwRollBar?: boolean
   * }} props
   * @returns {Promise<any>}
   */
  async apiCall({
    path,
    queryParams = {},
    params = {},
    fetchParams = {},
    throwRollBar = true, // Some APIs throw errors for 404, which can be valid response, e.g. payment_smoother customer_configuration
  }) {
    if (!this.#jwt && !this.#authenticatedInApp) {
      throw new Error("JWT not set");
    }

    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}${path}`);

    // Remove null and undefined values from being sent as query parameters
    const filteredQueryParams = Object.fromEntries(
      Object.entries(queryParams).filter(
        (entry) => entry[1] !== null && entry[1] !== undefined,
      ),
    );

    url.search = new URLSearchParams(filteredQueryParams).toString();
    const headers = {
      headers: {
        Authorization: `Bearer ${this.#jwt}`,
        ...params.headers,
      },
    };
    const apiParams = fetchParams || { method: "GET" };
    let fetchFailure;
    const response = await fetch(url, { ...apiParams, ...headers }).catch(
      (error) => (fetchFailure = error),
    );

    if (!response) {
      const apiCallDetails = {
        apiUrl: `${process.env.REACT_APP_API_BASE_URL}${path}`,
        requestDetails: {
          path,
          queryParams,
          params,
          fetchParams,
        },
      };
      /**
       * instead of loging error we just want the info
       * since this error happens often and bloat up the rollbar error
       * we still need to monitor this to find the cause
       */
      rollbar.info("API response is null/undefined", {
        apiCallDetails,
      });

      throw new Error(
        JSON.stringify("API call failure: API response returns undefined"),
      );
    }

    //--------------- ENODE ERROR RESPONSE ---------------//
    /**
     * for enode api, currently 404 is return with 500 so this to hack around that case
     * @TODO remove this hack when enode api returns proper 404
     */
    if (
      fetchFailure &&
      !response.status &&
      path === "/telemetry/v1/enode/user"
    ) {
      this.#setAuthExpired(false);
      return new Promise((resolve) => {
        const fake404response = new Response(null, {
          status: 404,
          statusText: "Not Found",
        });
        return resolve(fake404response);
      });
    }

    /**
     * for enode api, allow 401 to return and handle as an error response
     */
    if (response.status === 401 && path === "/telemetry/v1/enode/user") {
      this.#setAuthExpired(false);
      throw new Error(JSON.stringify("User does not have enode access"));
    }

    //--------------- END ENODE ERROR RESPONSE ---------------//

    //--------------- GENERAL ERROR RESPONSE ---------------//

    // The response technically returns a 401 but we cant access it due to CORs.
    // When this happens the fetch request outright fails though so that gets caught
    // and we assign the error value to fetchFailure.
    // This means we can use the presence of fetchFailure to determine if the request failed
    // due to an invalid JWT (which is what the combo of 401 and CORs infers).
    if (
      Boolean(fetchFailure) &&
      !Boolean(response.status) &&
      path !== "/telemetry/v1/enode/user"
    ) {
      this.#setAuthExpired(true);
      // log info instead of error due to this just means jwt token needs to be refreshed
      rollbar.info("API call failure: JWT expired");
      throw new Error("JWT expired");
    }

    if (
      (!response.status || response.status === 401) &&
      path !== "/telemetry/v1/enode/user"
    ) {
      const { error } = await response.json();
      this.#setAuthExpired(error === API_TOKEN_INVALID_ERROR);
      await this.refreshAuthentication();
      return;
    }

    if (!response.ok && throwRollBar) {
      this.#setAuthExpired(false);
      const responseBody = await response.json();
      const apiURL = `${process.env.REACT_APP_API_BASE_URL}${path}`;
      const requestDetails = { path, queryParams, params, fetchParams };
      this.handleApiError(responseBody, apiURL, requestDetails);
      throw new Error(JSON.stringify(responseBody));
    }

    //--------------- END GENERAL ERROR RESPONSE ---------------//

    this.#setAuthExpired(false);
    return response;
  }

  async refreshAuthentication() {
    if (this.#authenticatedInApp) {
      return;
    }
    const refreshToken = window.localStorage.getItem("refreshToken");
    const params = `?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${process.env.REACT_APP_DASHBOARD_CLIENT_ID}`;
    await this.getTokens(params).catch(() => this.clearJwt());
  }

  async getTokens(params) {
    const data = await getOauthTokens(params);
    this.setJwt(data.id_token);
    window.localStorage.setItem("refreshToken", data.refresh_token || null);
  }

  setOnAuthenticationChange(onAuthenticationChange) {
    this.onAuthenticationChange = onAuthenticationChange;
  }

  isAuthenticated() {
    return !!this.#jwt;
  }

  clearJwt() {
    window.localStorage.removeItem("state_code");
    window.localStorage.clear(); // remove refresh token and prevent attemptLogin call
    window.sessionStorage.removeItem("code_verifier");
    window.sessionStorage.removeItem("currentNavTab");

    /**
     * call the identity logout endpoint - which uses the jwt to get the Authorisation,
     * and gets the redirect URI from the Authorisation.client
     * it logs out of the rails session and redirects to client redirect URI - auth/callback
     */
    // @ts-ignore
    window.location = `${process.env.REACT_APP_API_BASE_URL}/identity/logout?id_token=${this.#jwt}`;
  }
}

export const jwtClient = new JwtClient();
