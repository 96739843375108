import { YourBillSmootherBalance } from "compositions/YourBillSmootherBalance";
import { BillSmootherSettingsDetails } from "pages/BillSmootherPage/components/BillSmootherSettingsDetails";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

import { BillSmootherTransactionsSection } from "./compositions/BillSmootherTransactionsSection";

export const BillSmootherPage: React.FC = () => {
  const navigate = useNavigate();
  const { billSmootherConfig } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  useEffect(() => {
    if (!billSmootherConfig?.enrolled) {
      navigate("/bills");
      return;
    }
  }, [billSmootherConfig]);

  return (
    <div className="page-section">
      <section className="page-section__column page-section__column--left">
        <YourBillSmootherBalance
          links={[
            {
              label: "Bill Smoother settings",
              path: "/bills/bill-smoother/settings",
            },
          ]}
        >
          <hr className="content__divider" />
          <BillSmootherSettingsDetails />
        </YourBillSmootherBalance>
      </section>
      <section className="page-section__column page-section__column--right">
        <BillSmootherTransactionsSection />
      </section>
    </div>
  );
};
