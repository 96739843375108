import "./TotalWithUnit.scss";

import React, { memo, PropsWithChildren } from "react";

type TotalWithUnitProps = {
  total: string;
  icon?: React.ReactNode;
  unitCode?: string;
};

export const TotalWithUnit: React.FC<PropsWithChildren<TotalWithUnitProps>> =
  memo(({ total, icon, unitCode, children }) => {
    return (
      <div className="total_with_unit">
        <div className="total_with_unit__content_group">
          {icon && icon}
          <p className="heading h2">{total}</p>
        </div>
        <div className="total_with_unit__content_group">
          {unitCode && <p className="h5">{unitCode}</p>}
          {children && children}
        </div>
      </div>
    );
  });
