import "styles/Chart.scss";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { BarChart } from "components/charts/BarChart";
import React, { useEffect, useState } from "react";
import { globalBarChartOptions } from "utils/charts";

import { prepareGroupedDataForBarChart } from "./GroupedBarChart.utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  zoomPlugin,
  ChartDataLabels,
);

type GroupedBarChartProps = {
  className?: string;
  chartConfig: GroupedBarChartConfig;
  maxYValue: number;
  selectedDataIndex?: number;
  onClickMutation?: (value: number) => void;
  smallChart?: boolean;
};

/**
 * @description Displays a grouped bar chart
 */
export const GroupedBarChart: React.FC<GroupedBarChartProps> = ({
  className,
  chartConfig,
  maxYValue,
  onClickMutation,
  selectedDataIndex,
  smallChart = false,
}) => {
  const [options, setOptions] = useState<BarChartOptions>(
    {} as BarChartOptions,
  );

  useEffect(() => {
    const chartOptions: BarChartOptions = globalBarChartOptions();
    chartOptions.scales.y.max = maxYValue;
    chartOptions.scales.y.title.text = chartConfig.yAxisText;
    if (smallChart) {
      chartOptions.scales.x.ticks.backdropPadding.left = 0;
      chartOptions.scales.y.title.padding = 0;
    }
    setOptions(chartOptions);
  }, [maxYValue]);

  const data = prepareGroupedDataForBarChart(chartConfig);

  return (
    <BarChart
      chartConfig={chartConfig}
      className={className}
      data={data}
      options={options}
      onClickMutation={onClickMutation}
      xLocationMobileOffset={5}
      selectedDataIndex={selectedDataIndex}
    />
  );
};
