import { format } from "date-fns/format";
import { hourlyUsageBreakdownLegend, offpeakLegend } from "utils/charts";
import { BRAND_COLOURS } from "utils/constants";

export const generateBarChartConfig = (
  selectedDayUsageData: ChartUsage[],
  productIsOffpeak: boolean,
): BarChartConfig => {
  const dataSets: {
    barColours: string[];
    data: number[];
    status: string[];
  } = {
    barColours: [],
    data: [],
    status: [],
  };

  const labels: string[] = [];

  selectedDayUsageData.forEach((item) => {
    dataSets.barColours.push(item.barColour || BRAND_COLOURS.black);
    dataSets.data.push(item.value || 0);
    dataSets.status.push(item.status);
    labels.push(item?.time ? format(item.time, "h:mmaa") : "");
  });

  const barChartConfig: BarChartConfig = {
    labels,
    yAxisText: "kWh",
    legendOptions: {
      display: true,
      labels: productIsOffpeak ? offpeakLegend : hourlyUsageBreakdownLegend,
    },
    chartTitle: "Hourly usage breakdown",
    datasets: [dataSets],
    showZoom: true,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
          tickWidth: 2,
        },
        ticks: {
          backdropColor: "transparent",
          backdropPadding: {
            top: 0,
            left: 10,
            right: 10,
            bottom: 15,
          },
          font: {
            weight: [400],
          },
          padding: 10,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          drawBorder: false,
          lineWidth: 2,
        },
        ticks: {
          autoSkip: true,
          display: true,
          font: {
            weight: [400],
          },
        },
        title: {
          display: true,
          align: "start",
        },
      },
    },
  };

  return barChartConfig;
};
