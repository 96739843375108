import { GroupedBarChart } from "components/charts/GroupedBarChart";
import { useInvoiceUsageChart } from "pages/UsagePage/compositions/InvoiceUsageChartProvider";
import React, { memo, useMemo } from "react";

import {
  determineLabelPeriod,
  generateDailyUsageGroupedBarChartConfig,
} from "./InvoiceUsageChart.utils";

type InvoiceUsageChartProps = {
  dailyUsageData: DailyUsage[];
  periodName: string;
  displayType?: string;
};

/**
 * @description Used in DailyUsageSection
 */
export const InvoiceUsageChart: React.FC<InvoiceUsageChartProps> = memo(
  ({ dailyUsageData, periodName, displayType = "consumption" }) => {
    const { selectedDayIndex, setSelectedDayIndex } = useInvoiceUsageChart();

    // Scale the Y axis to fit 110% of the largest day's usage or spend
    const maxYValue = useMemo(() => {
      return Math.ceil(
        1.1 *
          Math.max(
            ...dailyUsageData.map((d) => d.thisPeriod[displayType]),
            ...dailyUsageData.map((d) => d.lastPeriod[displayType]),
          ),
      );
    }, [displayType, dailyUsageData]);

    const labelPeriod = determineLabelPeriod(periodName);

    const groupedBarChartConfig = generateDailyUsageGroupedBarChartConfig({
      displayType,
      dailyUsageData,
      labelPeriod,
    });

    return (
      maxYValue && (
        <GroupedBarChart
          maxYValue={maxYValue}
          chartConfig={groupedBarChartConfig}
          onClickMutation={setSelectedDayIndex}
          selectedDataIndex={selectedDayIndex}
        />
      )
    );
  },
);
