import React from "react";

type ComponentItemFallbackProps = {
  componentTitle: string;
};

/**
 * @description Fallback for for general error
 */
export const ComponentItemFallback: React.FC<ComponentItemFallbackProps> = ({
  componentTitle,
}) => {
  return (
    <div className="notification_banner notification_notice fallback_message">
      <p className="heading">{componentTitle} not available</p>
      <p>
        We couldn't access information about your {`${componentTitle}`}. Try
        checking again later.
      </p>
    </div>
  );
};
