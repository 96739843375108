import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import { BillSmootherTransactions } from "pages/BillSmootherPage/components/BillSmootherTransactions";
import { useLazyGetBillSmootherTransactionsQuery } from "queries/billingApi";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { selectIsEnrolledInBillSmoother } from "selectors/billSmootherSelector";
import { BILL_QUERY_LIMIT } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { refetchOnScroll } from "utils/refetchOnScroll";

export const BillSmootherTransactionsSection: React.FC = () => {
  const { customerNumber } = useAppSelector((store) => store.currentAccount);

  const billSmootherEnrolled = useAppSelector(selectIsEnrolledInBillSmoother);

  const [storedTransactions, setStoredTransactions] = useState([]);

  const nextEndDate = useMemo(() => {
    return Array.isArray(storedTransactions) && storedTransactions.length > 0
      ? new Date(
          storedTransactions[
            storedTransactions.length - 1
          ].attributes.timestamps.created_at,
        )
      : new Date();
  }, [storedTransactions]);

  const [
    trigger,
    {
      data: transactions,
      isFetching: isTransactionFetching,
      isSuccess: isTransactionSuccess,
      isError: isTransactionError,
    },
  ] = useLazyGetBillSmootherTransactionsQuery();

  useEffect(() => {
    if (billSmootherEnrolled && customerNumber) {
      // when switching accounts, we need to reset the transactions
      setStoredTransactions([]);
      trigger({ customerNumber, jwtClient, endDate: new Date().toString() });
    }
  }, [customerNumber]);

  useEffect(() => {
    let nextDate = nextEndDate;
    if (
      JSON.stringify(transactions) !== JSON.stringify(storedTransactions) &&
      transactions?.length
    ) {
      nextDate = new Date(
        transactions[transactions.length - 1].attributes.timestamps.created_at,
      );
      const newTransactions = storedTransactions.concat(transactions);
      // remove any duplicated transaction id
      const cleanupTransactions = newTransactions.filter(
        (transaction, index, self) =>
          index === self.findIndex((t) => t.id === transaction.id),
      );
      setStoredTransactions(cleanupTransactions);
    }
    const refetchBillSmootherTimeline = async () => {
      if (!transactions || transactions.length < BILL_QUERY_LIMIT) return;
      await trigger({
        customerNumber,
        jwtClient,
        endDate: nextDate.toString(),
      });
    };
    return refetchOnScroll(refetchBillSmootherTimeline);
  }, [transactions]);

  return (
    <Card>
      <CardHeader>
        <h2 className="h4">Transactions</h2>
      </CardHeader>
      <CardBody>
        {isTransactionFetching && <LoadingSection />}

        {isTransactionError && (
          <>
            <div className="spacer" />

            <ComponentItemFallback componentTitle="Bill Smoother transactions" />
          </>
        )}

        {isTransactionSuccess && (
          <BillSmootherTransactions
            startDate={new Date()}
            transactions={storedTransactions}
          />
        )}
      </CardBody>
    </Card>
  );
};
