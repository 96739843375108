import React from "react";

type ProgressBarProps = {
  label: string;
  progressPercentage: number;
  extraClasses?: string;
  colour?: "pink" | "yellow";
};
export const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  progressPercentage,
  extraClasses,
  colour = "pink",
}) => {
  return (
    <div className={`progress ${extraClasses}`}>
      <div
        data-testid="progressPercentage"
        className={`progress__bar progress__bar--${colour}`}
        style={{ width: `${progressPercentage}%` }}
      >
        {label}
      </div>
    </div>
  );
};
