import { ReactComponent as BillingIcon } from "app/assets/images/icons/dollar-circle.svg";
import { ReactComponent as ReferralIcon } from "app/assets/images/icons/friend-get-friend.svg";
import { ReactComponent as AddressIcon } from "app/assets/images/icons/house.svg";
import { ReactComponent as AccountIcon } from "app/assets/images/icons/person.svg";
import { ReactComponent as HelpIcon } from "app/assets/images/icons/question-circle-black.svg";
import { ReactComponent as ChangePlanIcon } from "app/assets/images/icons/refresh.svg";
import { ReactComponent as SolarIcon } from "assets/icon-sun-solid.svg";
import React from "react";
import { PRODUCT_NAMES } from "utils/constants";

/**
 * @description Generates account page links with relevant link text for account plan type (if active)
 * If the account is not active, only the "Help" link is shown
 * @param currentPlanNormalisedName - name of the account's plan (e.g., 'business', 'offpeak')
 * @param accountActive - true if the account is active, false if not
 * @returns List of account page links with relevant link text if active, or only "Help" link if inactive.
 */
export const getAccountPageLinks = (
  currentPlanNormalisedName: string,
  accountActive: boolean,
): { icon: React.JSX.Element; text: string; to: string }[] => {
  const iconClassName =
    "nav_customer_tools__item_link_icon account_page__link__icon";

  return [
    ...(accountActive
      ? [
          {
            icon: <AccountIcon className={iconClassName} />,
            text: "Account details",
            to: "/account/details",
          },
          {
            icon: <BillingIcon className={iconClassName} />,
            text: "Billing",
            to: "/account/billing",
          },
          {
            icon: <AddressIcon className={iconClassName} />,
            text: "Address",
            to: "/account/address",
          },
          {
            icon: <SolarIcon className={iconClassName} />,
            text: "Solar",
            to: "/account/solar",
          },
          {
            icon: <ChangePlanIcon className={iconClassName} />,
            text:
              currentPlanNormalisedName === PRODUCT_NAMES.business
                ? "Plan details"
                : "Change plan",
            to: "/account/plan",
          },
          {
            icon: <ReferralIcon className={iconClassName} />,
            text: "Refer a friend",
            to: "/account/friend-get-friend",
          },
        ]
      : []),
    {
      icon: <HelpIcon className={iconClassName} />,
      text: "Help",
      to: "/account/help",
    },
  ];
};
